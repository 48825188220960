import { memo } from 'react';

import { getByPath } from '@/utils/widgets';
import { LinkView } from './link.type';
import { WidgetData } from '../../widget.type';
import { TextView } from '../text/text.view.type';

type Props = {
  view: LinkView;
  data: WidgetData;
};

const LinkViewImpl = memo(({ view, data }: Props) => {
  const { path, urlPath, rootUrl, openInNewTab } = view;

  const textData = getByPath(data, path);
  const urlData = getByPath(data, urlPath || path);
  return (
    <div className="truncate">
      <a
        href={`${rootUrl}${urlData}`}
        target={openInNewTab ? '_blank' : undefined}
        rel="noopener noreferrer"
      >
        {textData}
      </a>
    </div>
  );
});

// sort defaults to text on path
const getSortConfig = (view: LinkView): TextView => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: LinkView): TextView => ({
  type: 'text',
  path: view.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies text data at path
const getStringifyFunction = (view: LinkView) => {
  const { path } = view;
  return (data: WidgetData) => getByPath(data, path);
};

export const linkViewProvider = {
  component: LinkViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
