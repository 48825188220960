export default () => ({
  groupLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  groupAvatar: {
    marginRight: 8,
    flexShrink: 0
  }
});
