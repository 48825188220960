import React, { useState, useCallback } from 'react';
import to from 'await-to-js';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import Path from 'path-to-regexp';
import { withRouter } from 'react-router-dom';
import { stringify } from '@stratumn/canonicaljson';

import { Navbar } from 'components/layouts';

import {
  ROUTE_WORKFLOW_GROUPS,
  // ROUTE_WORKFLOW_BOARD, // Kanban view removed
  ROUTE_WORKFLOW_EXPORT
} from 'constant/routes';
import { withError } from 'components/errorBoundary';

import {
  TYPE_WORKFLOW,
  TYPE_WORKFLOW_CONFIG,
  TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD
} from 'gql/types';
import { notify } from 'components/toast';

import JsonEditor from 'components/ui/utils/jsonEditor';
import { Download, Settings, Members } from '@stratumn/icons';

import { withUser } from 'contexts';

const configEditorCodemirrorOptions = {
  theme: 'material'
};

export const WorkflowOverviewHeader = React.memo(props => {
  const {
    match,
    history,
    user,
    workflowQuery,
    isLoading,
    updateWorkflowConfigMutation
  } = props;

  const [showWorkflowConfigEditor, setShowWorkflowConfigEditor] =
    useState(false);
  const toggleShowWorkflowConfigEditor = useCallback(
    () => setShowWorkflowConfigEditor(!showWorkflowConfigEditor),
    [showWorkflowConfigEditor, setShowWorkflowConfigEditor]
  );

  const handleWorkflowConfigUpdate = useCallback(
    async newWorkflowConfigStr => {
      const { workflowByRowId: workflow } = workflowQuery;

      const {
        rowId: workflowRowId,
        config: { rowId: workflowConfigId, overview } = {}
      } = workflow;
      const newWorkflowConfig = newWorkflowConfigStr
        ? JSON.parse(newWorkflowConfigStr)
        : {};

      const {
        initState: newInitState,
        initActions: newInitActions,
        stateSchema: newStateSchema,
        actions: newActions,
        transitions: newTransitions
      } = newWorkflowConfig;

      const promise = updateWorkflowConfigMutation({
        variables: {
          workflowRowId,
          newInitState,
          newInitActions,
          newStateSchema,
          newActions,
          newTransitions
        },
        optimisticResponse: {
          newWorkflowConfig: {
            workflow: {
              rowId: workflowRowId,
              config: {
                rowId: workflowConfigId,
                overview,
                ...newWorkflowConfig,
                __typename: TYPE_WORKFLOW_CONFIG
              },
              __typename: TYPE_WORKFLOW
            },
            __typename: TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD
          }
        }
      });

      notify.promise(promise, {
        loading: '🛠️ Updating the workflow configuration...',
        success: `The workflow configuration was updated`,
        error: data => {
          console.error('Workflow configuration update error: ', data);
          return 'Something went wrong during the workflow configuration update...';
        }
      });

      await to(promise);
    },
    [workflowQuery, updateWorkflowConfigMutation]
  );

  const goToExport = useCallback(
    () =>
      history.push(
        Path.compile(ROUTE_WORKFLOW_EXPORT)({
          id: match.params.id
        })
      ),
    [history, match]
  );

  const DEV_createError = () => {
    const {
      // @ts-expect-error that's the point
      a: { b }
    } = undefined;
    b;
    throw new Error('This is a test error');
  };

  const goToGroupsSettings = useCallback(
    () =>
      history.push(
        Path.compile(ROUTE_WORKFLOW_GROUPS)({
          id: match.params.id
        })
      ),
    [history, match]
  );

  const { workflowByRowId: workflow } = workflowQuery;
  const { me } = user;

  let isSuperuser = false;
  let workflowConfigStr;

  if (!isLoading) {
    ({ isSuperuser } = me);
    const { initState, initActions, stateSchema, actions, transitions } =
      workflow.config;
    workflowConfigStr = stringify(
      {
        initState,
        initActions,
        stateSchema,
        actions,
        transitions
      },
      null,
      2
    );
  }

  const configHeader = {
    loading: isLoading,
    bottomLevel: {
      workflowPage: true,
      actions: {
        // // Kanban view removed
        // viewToggle: [
        //   {
        //     label: 'overview',
        //     active: true
        //   },
        //   {
        //     label: 'kanban',
        //     path: Path.compile(ROUTE_WORKFLOW_BOARD)({
        //       id: props.match.params.id
        //     }),
        //     active: false
        //   }
        // ],
        links: [
          ...(isSuperuser
            ? [
                {
                  icon: <Members />,
                  label: 'Manage groups',
                  onClick: goToGroupsSettings
                },
                {
                  icon: <Settings />,
                  label: 'Workflow configuration',
                  onClick: toggleShowWorkflowConfigEditor
                },
                {
                  icon: <Members />,
                  label: 'Throw an error',
                  onClick: DEV_createError
                }
              ]
            : []),
          {
            icon: <Download />,
            label: 'Export data',
            onClick: goToExport
          }
        ]
      }
    }
  };

  if (workflow) {
    configHeader.bottomLevel.infoContext = {
      links: [
        {
          icon: 'TableColumns',
          label: workflow?.name ?? 'Workflow'
        }
      ]
    };
  }

  return (
    <>
      <Navbar config={configHeader} />

      {isSuperuser && showWorkflowConfigEditor && (
        <JsonEditor
          title="Workflow configuration"
          jsonString={workflowConfigStr}
          onSubmit={handleWorkflowConfigUpdate}
          onClose={toggleShowWorkflowConfigEditor}
          codemirrorOptions={configEditorCodemirrorOptions}
        />
      )}
    </>
  );
});

WorkflowOverviewHeader.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  workflowQuery: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateWorkflowConfigMutation: PropTypes.func.isRequired
};

const UPDATE_WF_CONFIG_MUTATION = gql`
  mutation updateWorkflowConfigMutation(
    $workflowRowId: BigInt!
    $newInitState: JSON
    $newInitActions: JSON
    $newStateSchema: JSON
    $newActions: JSON
    $newTransitions: JSON
  ) {
    newWorkflowConfig(
      input: {
        config: {
          workflowRowId: $workflowRowId
          initState: $newInitState
          initActions: $newInitActions
          stateSchema: $newStateSchema
          actions: $newActions
          transitions: $newTransitions
        }
      }
    ) {
      workflow {
        rowId
        config {
          rowId
          overview
          initState
          initActions
          stateSchema
          actions
          transitions
        }
      }
    }
  }
`;

export default compose(
  graphql(UPDATE_WF_CONFIG_MUTATION, {
    name: 'updateWorkflowConfigMutation'
  }),
  withRouter,
  withUser,
  withError
)(WorkflowOverviewHeader);
