export default theme => ({
  styledBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  styledText: {
    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: 17
  },
  styledDiv: {
    marginBottom: 10,
    fontSize: 12,
    color: theme.grey2
  },
  descriptionMarkdown: {
    lineHeight: 1.4,
    fontSize: 12,
    '&:first-child': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
});
