import { useState, useContext, memo } from 'react';

import { getByPath } from '@/utils/widgets';

import { Icon } from '@stratumn/atomic';
import { Widget } from '@/components/ui/widget';
import SelectAllButton from '@/components/ui/widget/contexts/selectableItems/selectAllButton';
import ActionButton from '@/components/ui/widget/contexts/selectableItems/actionButton';
import { LocalStorageContext } from '@/contexts';

import { pluralize } from '@/utils/strings';

import BoxSectionContext from '../box/context';

import { cn } from '@/shadcn/lib/utils';
import { WindowViewType } from './window.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: WindowViewType;
  data: WidgetData;
};

export const WindowView = memo(({ view, data }: Props) => {
  const {
    title,
    displayItemCount,
    collapsable,
    items,
    conditionPath,
    itemName = title?.toLocaleLowerCase()?.includes('comment')
      ? 'comment'
      : 'item'
  } = view;

  if (items.length === 0) {
    return null;
  }

  // if conditionPath is set and falsy, just return nothing
  if (conditionPath !== undefined && !getByPath<boolean>(data, conditionPath)) {
    return null;
  }

  /**
   * Checks if trace state includes items.
   * If no items found, we do not render the window widget
   */
  const deepItems = getByPath<any[]>(data, items[0].view.itemsPath);

  if (!Array.isArray(items) && !Array.isArray(deepItems)) {
    return null;
  }

  const { index } = useContext(BoxSectionContext);
  const { userInfoConfig, setLocalStorage } =
    useContext(LocalStorageContext) || {};

  const initCollapse: boolean | undefined = userInfoConfig
    ? userInfoConfig[index as number]?.isCollapsed
    : true;

  const [collapse, setCollapse] = useState<boolean>(initCollapse ?? true);

  const itemsCount = (deepItems || []).length;
  const itemCountLabel = pluralize(itemsCount, itemName);

  const collapseHandler = () => {
    setCollapse(!collapse);
    if (setLocalStorage) setLocalStorage({ index, isCollapsed: !collapse });
  };

  return (
    <div className="border-border bg-card mb-[10px] flex w-full flex-col flex-nowrap rounded-md border">
      <div
        data-cy="window-toggle-expand"
        className={cn(
          'flex w-full cursor-pointer flex-row flex-nowrap items-center justify-between gap-5 p-2',
          !collapsable && 'cursor-default'
        )}
        onClick={collapsable ? collapseHandler : undefined}
      >
        <div className="text-card-foreground grow truncate pl-2 text-base font-semibold select-none first-letter:uppercase">
          {title}
        </div>
        <div className="flex flex-row flex-nowrap items-center gap-2">
          {displayItemCount && (
            <div className="text-muted-foreground text-xs whitespace-nowrap">
              {itemCountLabel}
            </div>
          )}
          {collapsable && (
            <div
              className={cn(
                'text-primary transition-transform duration-200 ease-in-out',
                collapse && 'text-card-foreground rotate-180'
              )}
            >
              <Icon size={25} name="ArrowMinTop" />
            </div>
          )}
        </div>
      </div>

      <section
        className={cn(
          'grid grid-rows-[0fr] overflow-hidden border-t border-transparent transition-all duration-100 ease-out',
          !collapse && 'border-border grid-rows-[1fr] py-4'
        )}
      >
        <div className="relative flex flex-col flex-nowrap gap-5 overflow-hidden px-4 py-0">
          <SelectAllButton />
          {view.items.map((item, index) => (
            <Widget
              key={index}
              widget={item}
              data={data}
              className="data-[is-hoverable=true]:border-border data-[is-hoverable=true]:bg-background hover:data-[is-hoverable=true]:border-primary empty:hidden data-[is-hoverable=true]:cursor-pointer data-[is-hoverable=true]:rounded-md data-[is-hoverable=true]:border data-[is-hoverable=true]:p-2.5"
            />
          ))}
          <ActionButton />
        </div>
      </section>
    </div>
  );
});

export const windowViewProvider = {
  component: WindowView
};
