import { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath, stringifyData } from '@/utils/widgets';
import { pluralize } from '@/utils/strings';
import { useToggle } from '@/utils/hooks';
import Tooltip from '@/components/ui/utils/tooltip';
import { TOOLTIP_PORTAL } from '@/constant/htmlIds';

import styles from './listCompact.style';

const TOOLTIP_DELAY = 100;
const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// compact list view
export const ListCompactViewImpl = memo(({ classes, view, data }) => {
  const { path, ordered, itemName = 'item' } = view;

  const listViewRef = useRef(null);
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  let listData = getByPath(data, path);
  // if pointing at something that is not an array, build one with this only element
  if (listData !== undefined && !Array.isArray(listData)) {
    listData = [listData];
  }

  const nbItems = listData?.length || 0;

  const listContent = useMemo(() => {
    if (nbItems < 2) return null;

    const listProps = {
      className: classes.listContent,
      'data-is-ordered': ordered
    };

    const listItems = listData.map((listItem, index) => (
      <li key={index}>{stringifyData(listItem)}</li>
    ));

    if (ordered) return <ol {...listProps}>{listItems}</ol>;
    return <ul {...listProps}>{listItems}</ul>;
  }, [listData, ordered, classes]);

  const tooltipArrow = useMemo(
    () => <div className={classes.tooltipArrowUp} />,
    [classes]
  );

  if (!nbItems) return null;

  // if only one item display it in plain text
  if (nbItems === 1)
    return <div className="truncate">{stringifyData(listData[0])}</div>;

  return (
    <div
      ref={listViewRef}
      onMouseEnter={toggleShowTooltip}
      onMouseLeave={toggleShowTooltip}
      className="hover:text-primary cursor-pointer truncate"
      data-cy="list-view"
    >
      {pluralize(nbItems, itemName, false, true)}
      {showTooltip && (
        <Tooltip
          clientEl={listViewRef.current}
          portalEl={document.getElementById(TOOLTIP_PORTAL)}
          position={TOOLTIP_POSITION}
          arrowUp={tooltipArrow}
          delay={TOOLTIP_DELAY}
        >
          {listContent}
        </Tooltip>
      )}
    </div>
  );
});
ListCompactViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies list data at path, join with ', '
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    const listData = getByPath(data, path);
    if (listData === undefined) return '';
    if (!Array.isArray(listData)) {
      return stringifyData(listData);
    }

    return listData.map(stringifyData).join(', ');
  };
};

export default {
  component: injectSheet(styles)(ListCompactViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
