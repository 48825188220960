import to from 'await-to-js';

import {
  DATA_PARSING_STATUS_DONE,
  DATA_PARSING_STATUS_ERROR
} from 'constant/dataParsing';

import { aggregateData } from '.';

import AggregationWorker from 'utils/aggregation/aggregation.worker?worker';

// create the aggregation worker
let aggregationWorker = null;
if (window.Worker) {
  aggregationWorker = new AggregationWorker();
}

// run the aggregation by checking if workers are enabled and handling both cases
export const runAggregation = async inputs => {
  // check if we can use a webworker for this
  if (aggregationWorker) {
    aggregateUsingWorker(inputs);
    return;
  }
  await awaitAggregate(inputs);
};

// if no webworker run the aggregateData function awaiting for its result before returning
const awaitAggregate = async inputs => {
  const {
    inputData,
    aggregationConfig,
    onError = () => {},
    onSuccess = () => {}
  } = inputs;

  // run the aggregation function and await
  // don't provide reporting config as we'll await for the result
  const [err, aggregationResult] = await to(
    aggregateData(inputData, aggregationConfig, undefined, undefined)
  );

  // catch potential errors of the aggregateData function
  if (err) {
    onError(err.message);
    return;
  }

  // callback in case of success
  onSuccess(aggregationResult);
};

// if webworker run the aggregateData function without awaiting but attach worker messages handling
const aggregateUsingWorker = async inputs => {
  const {
    onError = () => {},
    onSuccess = () => {},
    onReport = () => {},
    ...rest
  } = inputs;

  // run the worker
  aggregationWorker.postMessage({ ...rest });

  // attach messages handling
  // respond to updates from the worker
  aggregationWorker.onmessage = event => {
    const { status, step, message, progress, aggregationResult } = event.data;
    switch (status) {
      case DATA_PARSING_STATUS_ERROR:
        // catch the error
        onError(message);
        // stop listening to worker messages
        aggregationWorker.onmessage = null;
        break;
      case DATA_PARSING_STATUS_DONE:
        // complete the data aggregation
        onSuccess(aggregationResult);
        // stop listening to worker messages
        aggregationWorker.onmessage = null;
        break;
      default:
        // intermediate reporting
        // set the state to aggregating and report the progress
        onReport(status, step, progress);
    }
  };
};
