import { getByPath, setAtPath } from 'utils';
import {
  DATA_PARSING_STATUS_DONE,
  DATA_PARSING_STATUS_ERROR,
  DATA_PARSING_STATUS_AGGREGATING,
  AGGREGATION_STEP_INDEXING,
  AGGREGATION_STEP_AGGREGATING
} from 'constant/dataParsing';

const runAggregation = (
  inputData,
  aggregationConfig,
  reportingConfig,
  reportingFn
) => {
  // reportStep indicates parsing steps that trigger a message sent
  // to the function caller
  const { reportStep = 0.05 } = reportingConfig;

  // read aggregation configuration
  const { indexing: indexingKeys = [], aggregating: aggregatingConfig } =
    aggregationConfig;

  // build the path of the jmespath query that will serve for indexing
  if (indexingKeys.length === 0) {
    throw new Error('No indexing key provided');
  }
  const jmespathIndexingQuery = `[${indexingKeys
    .map(key => `to_string(${key} || '')`)
    .join(',')}].join('__',@)`;

  // index data
  // ie create a new list of data grouped by indexing keys
  // split between indices and data for each group
  const indexedData = [];
  const indexedRowsMap = {};
  let nbRows = inputData.length;
  let reportingBatchSize = nbRows * reportStep;
  let nextReportingStep = 0;
  inputData.forEach((row, idx) => {
    // report if need be
    if (idx >= nextReportingStep) {
      reportingFn({
        status: DATA_PARSING_STATUS_AGGREGATING,
        step: AGGREGATION_STEP_INDEXING,
        progress: (0.5 * nextReportingStep) / nbRows
      });
      // update the next reporting step
      nextReportingStep += reportingBatchSize;
    }

    // index this row
    const rowIndex = getByPath(row, jmespathIndexingQuery);
    if (!indexedRowsMap[rowIndex]) {
      // index does not exist yet, restructure and add this record
      const newIndexedRow = {};

      // add indexing keys
      const newIndexedRowIndices = {};
      indexingKeys.forEach(key =>
        setAtPath(newIndexedRowIndices, key, getByPath(row, key))
      );
      newIndexedRow.indices = newIndexedRowIndices;

      // set first group row data
      newIndexedRow.data = [row];

      // append this aggregated row to the list and to the map for faster access
      indexedData.push(newIndexedRow);
      indexedRowsMap[rowIndex] = newIndexedRow;
      return;
    }
    // otherwise update the existing indexed row with the new row data
    indexedRowsMap[rowIndex].data.push(row);
  });

  // aggregate data
  const aggregatedData = [];
  nbRows = indexedData.length;
  reportingBatchSize = nbRows * reportStep;
  nextReportingStep = 0;
  indexedData.forEach((row, idx) => {
    // report if need be
    if (idx >= nextReportingStep) {
      reportingFn({
        status: DATA_PARSING_STATUS_AGGREGATING,
        step: AGGREGATION_STEP_AGGREGATING,
        progress: 0.5 * (1.0 + nextReportingStep / nbRows)
      });
      // update the next reporting step
      nextReportingStep += reportingBatchSize;
    }

    // apply the aggregation
    const { indices: rowIndices, data: rowData } = row;
    // initialise with the indexing fields
    const aggregatedRow = { ...rowIndices };
    if (aggregatingConfig) {
      // apply a set of queries run on the group data list, and set at the group root level
      aggregatingConfig.forEach(({ from, to }) => {
        // throw if no 'to' key provided
        if (to === undefined) {
          throw new Error(
            `No destination key provided for aggregation query ${from}`
          );
        }
        setAtPath(aggregatedRow, to, getByPath(rowData, from));
        // TODO ? Maybe add reducers for transformations that jmespath cannot handle,
        // like min / max with dates interpreter
      });
    } else {
      // no aggregation specified, just add the group data
      aggregatedRow.data = rowData;
    }

    // add this aggregated row
    aggregatedData.push(aggregatedRow);
  });

  return aggregatedData;
};

export const aggregateData = async (
  inputData,
  aggregationConfig,
  reportingConfig = {},
  reportingFn = () => {}
) =>
  new Promise((resolve, reject) => {
    try {
      const data = runAggregation(
        inputData,
        aggregationConfig,
        reportingConfig,
        reportingFn
      );
      // report the % status before
      reportingFn({
        status: DATA_PARSING_STATUS_AGGREGATING,
        step: AGGREGATION_STEP_AGGREGATING,
        progress: 1
      });
      // report the completion of aggregation
      const aggregationResult = {
        data
      };
      reportingFn({
        status: DATA_PARSING_STATUS_DONE,
        aggregationResult
      });
      resolve(aggregationResult);
    } catch (err) {
      reportingFn({
        status: DATA_PARSING_STATUS_ERROR,
        message: err.message
      });
      reject(new Error(err.message));
    }
  });
