import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Transforms } from 'slate';
import {
  useSlate,
  useSelected,
  useFocused,
  ReactEditor,
  useSlateStatic
} from 'slate-react';

import { Icon, InfoTooltip, Button, LoaderTraceLogo } from '@stratumn/atomic';

import { BLOCK_TYPES, TEXT_ALIGN_TYPES } from './constants';
import {
  isBlockActive,
  toggleBlock,
  isMarkActive,
  toggleMark,
  isLinkActive,
  toggleLink,
  isSubjectActive
} from './utils';

import styles from './richEditor.style';
import {
  isTableActive,
  insertTable,
  insertRow,
  insertColumn,
  deleteTable,
  deleteRow,
  deleteColumn
} from './tables';

export const ElementComponent = props => {
  const {
    classes,
    attributes,
    children,
    element,
    withSubject = false,
    withMentions = false,
    readOnly = false
  } = props;

  const style = { textAlign: element.align };
  const Heading1 = withSubject ? 'h2' : 'h1';
  const Heading2 = withSubject ? 'h3' : 'h2';
  const newAttributes = {
    ...attributes,
    'data-is-empty': element.isEmpty,
    'data-is-selected': element.isSelected
  };

  // Subject specific case
  if (withSubject && element.type === BLOCK_TYPES.SUBJECT) {
    return (
      <h1 className="subject" style={style} {...newAttributes}>
        {children}
      </h1>
    );
  }
  // Mentions specific case
  if ((withMentions || readOnly) && element.type === BLOCK_TYPES.MENTION) {
    return (
      <Mention attributes={newAttributes} element={element}>
        {children}
      </Mention>
    );
  }
  switch (element.type) {
    case BLOCK_TYPES.BLOCKQUOTE:
      return (
        <blockquote style={style} {...newAttributes}>
          {children}
        </blockquote>
      );
    case BLOCK_TYPES.UNORDERED_LIST:
      return (
        <ul style={style} {...newAttributes}>
          {children}
        </ul>
      );
    case BLOCK_TYPES.HEADING1:
      return (
        <Heading1 style={style} {...newAttributes}>
          {children}
        </Heading1>
      );
    case BLOCK_TYPES.HEADING2:
      return (
        <Heading2 style={style} {...newAttributes}>
          {children}
        </Heading2>
      );
    case BLOCK_TYPES.LIST_ITEM:
      return (
        <li style={style} {...newAttributes}>
          {children}
        </li>
      );
    case BLOCK_TYPES.ORDERED_LIST:
      return (
        <ol style={style} {...newAttributes}>
          {children}
        </ol>
      );
    case BLOCK_TYPES.LINK:
      return <Link {...props} />;
    case BLOCK_TYPES.TABLE:
      return (
        <div className={classes.tableContainer}>
          <table>
            <tbody {...newAttributes}>{children}</tbody>
          </table>
        </div>
      );
    case BLOCK_TYPES.TABLE_ROW:
      return <tr {...newAttributes}>{children}</tr>;
    case BLOCK_TYPES.TABLE_CELL:
      return (
        <td style={style} {...newAttributes}>
          {children}
        </td>
      );
    case BLOCK_TYPES.IMAGE:
      return <Image {...props} />;
    default:
      return (
        <p style={style} {...newAttributes}>
          {children}
        </p>
      );
  }
};
ElementComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
  withSubject: PropTypes.bool,
  withMentions: PropTypes.bool,
  readOnly: PropTypes.bool
};

export const Element = injectSheet(styles)(ElementComponent);

export const Leaf = ({ attributes, children, leaf }) => {
  let wrappedChildren = children;
  if (leaf.bold) {
    wrappedChildren = <strong>{wrappedChildren}</strong>;
  }
  if (leaf.code) {
    wrappedChildren = <code>{wrappedChildren}</code>;
  }
  if (leaf.italic) {
    wrappedChildren = <em>{wrappedChildren}</em>;
  }
  if (leaf.underline) {
    wrappedChildren = <u>{wrappedChildren}</u>;
  }
  if (leaf.mark) {
    wrappedChildren = <mark>{wrappedChildren}</mark>;
  }
  if (leaf.highlight) {
    wrappedChildren = (
      <span
        data-cy="search-highlighted"
        style={{
          fontWeight: leaf.bold ? 'bold' : 'normal',
          backgroundColor: '#ffeeba'
        }}
      >
        {wrappedChildren}
      </span>
    );
  }
  return <span {...attributes}>{wrappedChildren}</span>;
};
Leaf.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  leaf: PropTypes.object.isRequired
};

const ButtonTooltip = ({ disabled, format, children }) =>
  disabled ? (
    children
  ) : (
    <InfoTooltip
      text={format}
      padding="5px 10px"
      textAlign="center"
      minWidth="50"
      position={{
        place: 'above',
        placeShift: 3,
        adjustPlace: true,
        anchor: 'center'
      }}
    >
      {children}
    </InfoTooltip>
  );

export const BlockButtonComponent = ({
  disabled = false,
  format,
  classes,
  children
}) => {
  const editor = useSlate();
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  return (
    <ButtonTooltip disabled={disabled} format={format}>
      <button
        disabled={disabled}
        type="button"
        className={classNames(classes.button, {
          [classes.buttonActive]: isActive
        })}
        onMouseDown={event => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        {children}
      </button>
    </ButtonTooltip>
  );
};
BlockButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

export const BlockButton = injectSheet(styles)(BlockButtonComponent);

const MarkButtonComponent = ({
  disabled = false,
  format,
  classes,
  children
}) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  return (
    <ButtonTooltip disabled={disabled} format={format}>
      <button
        disabled={disabled}
        type="button"
        className={classNames(classes.button, {
          [classes.buttonActive]: isActive
        })}
        onMouseDown={event => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        {children}
      </button>
    </ButtonTooltip>
  );
};
MarkButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

export const MarkButton = injectSheet(styles)(MarkButtonComponent);

const LinkButtonComponent = ({ disabled = false, classes, children }) => {
  const editor = useSlate();
  const isActive = isLinkActive(editor);
  return (
    <ButtonTooltip disabled={disabled} format="link">
      <button
        disabled={disabled}
        type="button"
        className={classNames(classes.button, {
          [classes.buttonActive]: isActive
        })}
        onMouseDown={event => {
          event.preventDefault();
          toggleLink(editor);
        }}
      >
        {children}
      </button>
    </ButtonTooltip>
  );
};
LinkButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

export const LinkButton = injectSheet(styles)(LinkButtonComponent);

const GenericButtonComponent = ({
  disabled,
  children,
  classes,
  onMouseDown,
  tooltip
}) => (
  <ButtonTooltip disabled={disabled} format={tooltip}>
    <button
      disabled={disabled}
      type="button"
      className={classNames(classes.button)}
      onMouseDown={e => {
        e.preventDefault();
        onMouseDown();
      }}
    >
      {children}
    </button>
  </ButtonTooltip>
);
GenericButtonComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
GenericButtonComponent.defaultProps = {
  disabled: false
};
export const GenericButton = injectSheet(styles)(GenericButtonComponent);

export const ToolbarComponent = ({ disabled = false, classes }) => {
  const editor = useSlate();
  const isDisabled = disabled || isSubjectActive(editor);
  const isInTable = isTableActive(editor);
  return (
    <div className={classes.toolbar}>
      <div className={classes.toolbarButtonGroup}>
        <MarkButton disabled={isDisabled} format="bold">
          <strong>B</strong>
        </MarkButton>
        <MarkButton disabled={isDisabled} format="italic">
          <em>I</em>
        </MarkButton>
        <MarkButton disabled={isDisabled} format="underline">
          <u>U</u>
        </MarkButton>
        <BlockButton
          disabled={isDisabled || isInTable}
          format={BLOCK_TYPES.HEADING1}
        >
          <strong>H1</strong>
        </BlockButton>
        <BlockButton
          disabled={isDisabled || isInTable}
          format={BLOCK_TYPES.UNORDERED_LIST}
        >
          <Icon size={16} name="Bullets" />
        </BlockButton>
        <LinkButton disabled={isDisabled}>
          <Icon size={16} name="Link" />
        </LinkButton>
        <GenericButton
          disabled={isDisabled || isInTable}
          tooltip="Insert table"
          onMouseDown={() => insertTable(editor)}
        >
          <Icon size={16} name="TableInsert" />
        </GenericButton>
      </div>
      {isInTable && (
        <div className={classes.toolbarButtonGroup}>
          <GenericButton
            disabled={isDisabled}
            tooltip="Insert row"
            onMouseDown={() => insertRow(editor)}
          >
            <Icon size={16} name="RowInsert" />
          </GenericButton>
          <GenericButton
            disabled={isDisabled}
            tooltip="Delete row"
            onMouseDown={() => deleteRow(editor)}
          >
            <Icon size={16} name="RowDelete" />
          </GenericButton>
          <GenericButton
            disabled={isDisabled}
            tooltip="Insert column"
            onMouseDown={() => insertColumn(editor)}
          >
            <Icon size={16} name="ColumnInsert" />
          </GenericButton>
          <GenericButton
            disabled={isDisabled}
            tooltip="Delete column"
            onMouseDown={() => deleteColumn(editor)}
          >
            <Icon size={16} name="ColumnDelete" />
          </GenericButton>
          <GenericButton
            disabled={isDisabled}
            tooltip="Delete table"
            onMouseDown={() => deleteTable(editor)}
          >
            <Icon size={16} name="TableDelete" />
          </GenericButton>
        </div>
      )}
      <div
        className={classNames(
          classes.toolbarButtonGroup,
          classes.toolbarButtonGroupHistory
        )}
      >
        <GenericButton
          disabled={isDisabled || editor.history.undos.length <= 1}
          tooltip="Undo"
          onMouseDown={editor.undo}
        >
          <Icon size={16} name="Undo" />
        </GenericButton>
        <GenericButton
          disabled={isDisabled || editor.history.redos.length === 0}
          tooltip="Redo"
          onMouseDown={editor.redo}
        >
          <Icon size={16} name="Redo" />
        </GenericButton>
      </div>
    </div>
  );
};
ToolbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export const Toolbar = injectSheet(styles)(ToolbarComponent);

const Portal = ({ children }) =>
  typeof document === 'object' ? createPortal(children, document.body) : null;

const UserListComponent = ({
  classes,
  users,
  index,
  search,
  target = null
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  useEffect(() => {
    if (target && users?.length > 0 && ref.current) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [users?.length, editor, index, search, target]);

  if (target && users?.length > 0) {
    return (
      <Portal>
        <div ref={ref} className={classes.autoCompleteList}>
          {users?.map((user, i) => (
            <div
              key={user.id}
              className={classNames(classes.autoCompleteListItem, {
                [classes.autoCompleteListItemActive]: i === index
              })}
            >
              {user.name}
            </div>
          ))}
        </div>
      </Portal>
    );
  }

  return null;
};
UserListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  target: PropTypes.object
};

export const UserList = injectSheet(styles)(UserListComponent);

const MentionComponent = ({ attributes, children, element, classes }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      className={classNames(classes.mention, {
        [classes.mentionSelected]: selected && focused
      })}
    >
      {children}@{element.user.name}
    </span>
  );
};
MentionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired
};
const Mention = injectSheet(styles)(MentionComponent);

const LinkComponent = ({ classes, attributes, children, element }) => {
  const selected = useSelected();
  return (
    <a
      {...attributes}
      href={element.url}
      className={classNames(classes.link, {
        [classes.linkSelected]: selected
      })}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};
LinkComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired
};
const Link = injectSheet(styles)(LinkComponent);

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);
const SubjectListComponent = ({ classes, subjects, index }) => {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  useEffect(() => {
    const el = ref.current;
    if (el && subjects?.length > 0 && ref.current) {
      const domRange = ReactEditor.toDOMRange(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 }
      });
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [subjects?.length, editor, index]);

  if (subjects?.length > 0) {
    return (
      <Portal>
        <div ref={ref} className={classes.autoCompleteList}>
          {subjects?.map((subject, i) => (
            <div
              key={subject}
              className={classNames(classes.autoCompleteListItem, {
                [classes.autoCompleteListItemActive]: i === index
              })}
            >
              {subject}
            </div>
          ))}
        </div>
      </Portal>
    );
  }
  return null;
};
export const SubjectAutoComplete = injectSheet(styles)(SubjectListComponent);

const ImageComponent = ({
  classes,
  attributes,
  children,
  element,
  readOnly = false,
  uploadImage,
  downloadImage
}) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const selected = useSelected();
  const focused = useFocused();

  const onFileSuccess = useCallback(
    file => {
      const dimension = { width: 0, height: 0 };
      if (imgRef.current) {
        dimension.width = imgRef.current.width;
        dimension.height = imgRef.current.height;
      }

      const media = {
        digest: file.digest,
        key: file.key
      };

      downloadImage(
        file,
        setLoading,
        false,
        file => {
          Transforms.setNodes(
            editor,
            { url: URL.createObjectURL(file), media, dimension },
            { at: path }
          );
          setLoading(false);
        },
        false
      );
    },
    [editor, path]
  );

  const onFileError = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (readOnly && element?.media) {
      downloadImage(
        element.media,
        setLoading,
        loading,
        file => {
          if (!file) return;
          Transforms.setNodes(
            editor,
            { url: URL.createObjectURL(file) },
            { at: path }
          );
        },
        false
      );
    }

    if (!element?.media && !readOnly) {
      setLoading(true);
      uploadImage(element.url, onFileSuccess, onFileError, () => {});
    }
  }, []);

  // Use temporary 'a' tag to download the image to bypass form blocking
  const downloadFile = evt => {
    evt.preventDefault();
    if (!imgRef || !imgRef.current) return;
    const link = document.createElement('a');
    const canvas = document.createElement('canvas');
    canvas.width = imgRef.current.naturalWidth;
    canvas.height = imgRef.current.naturalHeight;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(imgRef.current, 0, 0);
    link.href = canvas.toDataURL('image/png');
    link.download = element.media.name || 'image';
    link.click();
    link.remove();
  };

  return (
    <>
      <div {...attributes}>
        {children}
        <div contentEditable={false} className={classes.image}>
          <img
            ref={imgRef}
            src={element.url}
            alt=""
            onClick={() => setFullScreen(true)}
            style={{
              boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`
            }}
          />
          <div className="image-actions">
            {!readOnly && (
              <Button
                onClick={() => Transforms.removeNodes(editor, { at: path })}
              >
                <Icon name="Trash" size={25} />
              </Button>
            )}
            <Button onClick={evt => downloadFile(evt)}>
              <Icon name="Download" size={25} />
            </Button>
          </div>
          {element && readOnly && loading && (
            <div
              className="image-placeholder"
              style={{
                width: `${element?.dimension?.width}px`,
                height: `${element?.dimension?.height}px`
              }}
            />
          )}
          {loading && !readOnly && (
            <div className={classes.imageLoader}>
              <LoaderTraceLogo />
            </div>
          )}
        </div>
      </div>
      {fullScreen && (
        <Portal>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(0 0 0 / 86%)',
              width: '100%',
              height: '100%',
              cursor: 'zoom-out',
              zIndex: 4
            }}
            onClick={() => setFullScreen(false)}
          >
            <img
              src={element.url}
              style={{
                maxWidth: '100%',
                maxHeight: '100%'
              }}
              alt=""
            />
          </div>
        </Portal>
      )}
    </>
  );
};
ImageComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
  uploadImage: PropTypes.func.isRequired,
  downloadImage: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export const Image = injectSheet(styles)(memo(ImageComponent));
