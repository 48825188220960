export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 17,
    marginRight: 20,
    gap: '10px',
    color: theme.grey1,
    '&[data-is-error=true]': {
      color: '#BD0024'
    }
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    gap: '7px'
  },
  name: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey1,
    flexGrow: 1,
    overflow: 'hidden'
  },
  fileDetails: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    color: theme.grey4,
    gap: '5px'
  },
  checkIcon: {
    color: theme.validGreenDark
  },
  errorMessage: {
    color: theme.warningRed
  },
  deleteBtn: {
    outline: 'none',
    color: theme.grey1,
    cursor: 'pointer',
    transition: 'color 100ms linear',
    '&:focus': {
      color: theme.indigo3
    },
    '&:hover': {
      color: theme.indigo3
    }
  }
});
