import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { SmartDropdown } from '@stratumn/atomic';
import styles from './dropDown.style';

const DropDownFilter = React.memo(
  ({ classes, colConfig, setColumnFilter, filterConfig, delay = 600 }) => {
    const filterStr = colConfig.filter ? colConfig.filter.value : '';

    const [inputStr, setInputStr] = useState(filterStr);

    useEffect(() => {
      setInputStr(filterStr);
    }, [filterStr]);

    useEffect(() => {
      if (inputStr === filterStr) return undefined;

      // set column filter after delay
      const handler = setTimeout(() => {
        setColumnFilter(
          colConfig.key,
          inputStr === '' ? undefined : inputStr,
          filterConfig
        );
      }, delay);

      // Cancel the timeout if value changes (also on unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    }, [inputStr]);

    return (
      <div>
        <SmartDropdown
          classes={classes}
          placeholder={filterConfig.placeholder || 'Search'}
          onSelect={selectedValue => setInputStr(selectedValue)}
          value={inputStr}
          options={filterConfig.enum || []}
          multiselect
        />
      </div>
    );
  }
);
DropDownFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  colConfig: PropTypes.object.isRequired,
  setColumnFilter: PropTypes.func.isRequired,
  filterConfig: PropTypes.object.isRequired,
  delay: PropTypes.number
};

export default injectSheet(styles)(DropDownFilter);
