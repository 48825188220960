import Form from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import validator from '@rjsf/validator-ajv8';

import FieldTemplate from './fieldTemplate';
import ArrayFieldTemplate from './arrayFieldTemplate';

import { fieldsReader } from './fields';
import widgets from './widgets';

import { ObjectFieldReaderTemplate } from '@/components/forms/components/formTemplates';
import { WorkflowContext } from '@/components/forms/utils/workflowContext';

interface Props {
  schema: JSONSchema7;
  uiSchema: UiSchema;
  formData: Record<string, unknown>;
  downloadFile: () => void;
  downloadImage: () => void;
  workflowContext?: WorkflowContext;
  downloadAllFiles: () => void;
}

export const FormReader = ({
  schema,
  uiSchema,
  formData,
  downloadFile,
  downloadImage,
  workflowContext,
  downloadAllFiles
}: Props) => (
  <Form
    disabled
    schema={schema}
    uiSchema={uiSchema}
    formData={formData}
    widgets={widgets as any}
    fields={fieldsReader as any}
    templates={{
      FieldTemplate: FieldTemplate as any,
      ObjectFieldTemplate: ObjectFieldReaderTemplate,
      ArrayFieldTemplate
    }}
    showErrorList={false}
    formContext={{
      downloadFile,
      downloadImage,
      workflowContext,
      downloadAllFiles
    }}
    noValidate
    noHtml5Validate
    validator={validator}
  >
    <button type="submit" style={{ display: 'none' }} />
  </Form>
);
