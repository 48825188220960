import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { nameToInitials } from 'utils';
import { useInput } from 'utils/hooks';

import {
  uploadFile,
  handleUploadFileOnError,
  getDownloadURL
} from 'client/media';
import UploadAvatar from 'components/ui/uploadAvatar';

import styles from './editableGroupFields.style';

export const EditableGroupFields = ({
  classes,
  group,
  updateGroup,
  updating = false
}) => {
  const { name, avatar } = group;

  const groupNameInputRef = useRef(null);
  const [inputGroupName, setInputGroupName] = useInput(name);

  const updateGroupName = useCallback(() => {
    if (inputGroupName !== name) updateGroup({ name: inputGroupName });
  }, [updateGroup, name, inputGroupName]);

  const checkKeyPress = useCallback(event => {
    if (event.key === 'Enter') {
      groupNameInputRef.current.blur();
    }
  }, []);

  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  const updateAvatar = useCallback(
    newAvatarFile => {
      setUploadingAvatar(true);
      uploadFile(
        newAvatarFile,
        async savedFile => {
          const avatarUrl = getDownloadURL(savedFile);
          updateGroup({ avatar: avatarUrl });
          setUploadingAvatar(false);
        },
        () => {
          setUploadingAvatar(false);
          handleUploadFileOnError(
            newAvatarFile.name,
            newAvatarFile.response.status
          );
        },
        null,
        true
      );
    },
    [updateGroup]
  );

  return (
    <>
      <div className={classes.groupAvatar}>
        <UploadAvatar
          avatar={avatar}
          submitNewAvatar={updateAvatar}
          size={30}
          initials={inputGroupName ? nameToInitials(inputGroupName) : null}
          loading={updating || uploadingAvatar}
          dataCy="upload-avatar"
        />
      </div>
      <input
        type="text"
        className={classes.groupName}
        ref={groupNameInputRef}
        value={inputGroupName}
        onChange={setInputGroupName}
        onKeyPress={checkKeyPress}
        onBlur={updateGroupName}
        data-cy="name-input"
        spellCheck="false"
      />
    </>
  );
};

EditableGroupFields.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  updateGroup: PropTypes.func.isRequired,
  updating: PropTypes.bool
};

export default compose(injectSheet(styles), React.memo)(EditableGroupFields);
