import { memo, useContext } from 'react';

import { getByPath, sortData } from 'utils';

import {
  Widget,
  getSortConfig as getWidgetSortConfig
} from 'components/ui/widget';
import SearchContextProvider, {
  SearchContext
} from 'components/ui/search/searchContext';
import { SearchBar } from '@/components/ui/search/search.array';
import { filterComments } from 'utils/comment';
import { WidgetData } from '../../widget.type';
import { ArrayView } from './array.type';

type Props = {
  view: ArrayView;
  data: WidgetData;
};

const ArrayViewImpl = memo(({ view, data }: Props) => {
  const { itemsPath, itemsWidget, defaultSort } = view;
  const { state: searchState } = useContext(SearchContext) || {};
  const items = getByPath(data, itemsPath);

  if (!Array.isArray(items)) {
    return null;
  }

  let itemsToDisplay = items;
  if (defaultSort) {
    const { direction } = defaultSort;
    const defaultSortConfig = getWidgetSortConfig(itemsWidget || {});
    itemsToDisplay = sortData(itemsToDisplay, defaultSortConfig, direction);
  }

  if (searchState?.string) {
    itemsToDisplay = filterComments(itemsToDisplay, searchState?.string);
  }
  return view.searchBar ? (
    <div className="flex flex-col flex-nowrap gap-3">
      <div className="absolute top-0 right-4">
        <SearchBar config={view.searchBar} />
      </div>
      {itemsToDisplay.map((item, index) => (
        <Widget key={index} widget={itemsWidget} data={item} />
      ))}
      {itemsToDisplay.length === 0 && <div className="min-h-8" />}
    </div>
  ) : (
    <div className="flex flex-col flex-nowrap gap-3">
      {itemsToDisplay.map((item, index) => (
        <Widget key={index} widget={itemsWidget} data={item} />
      ))}
    </div>
  );
});

const ArrayComponent = (props: Props) => {
  const searchBarConfig = props.view.searchBar;
  if (searchBarConfig) {
    return (
      <SearchContextProvider>
        <ArrayViewImpl {...props} />
      </SearchContextProvider>
    );
  }
  return <ArrayViewImpl {...props} />;
};

export default {
  component: ArrayComponent
};
