import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import classNames from 'classnames';

import styles from './arrayFieldTemplate.style';
import { KeyValue } from '@stratumn/atomic';

interface Props {
  classes: ClassNameMap<string>;
  title: any;
  items: [];
}

export class ArrayField extends React.PureComponent<Props> {
  render() {
    const { classes, title, items } = this.props;
    const arrayLength = items.length;

    if (!arrayLength) return null;

    const hasMultipleItems = arrayLength > 1;

    return (
      <KeyValue
        label={
          title
            ? `${title} (${arrayLength} item${arrayLength === 1 ? '' : 's'})`
            : undefined
        }
      >
        {items.map((item: any, index: number) => (
          <div
            key={item.index}
            className={classNames({
              [classes.fieldWrapper]: true,
              [classes.fieldWrapperWithBorder]: hasMultipleItems
            })}
            style={{ marginBottom: index < items.length - 1 ? '20px' : '' }}
          >
            {item.children}
          </div>
        ))}
      </KeyValue>
    );
  }
}

export default injectSheet(styles)(ArrayField);
