import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import styles from './linkWrapper.style';

const LinkWrapper = React.memo(
  ({ classes, children, url, openInNewTab = true }) => (
    <a
      href={url}
      className={classes.linkWrapper}
      target={openInNewTab ? '_blank' : null}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
);

LinkWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool
};

export default injectSheet(styles)(LinkWrapper);
