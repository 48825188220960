import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tray } from '@stratumn/atomic';

import { TRAY_PORTAL_RIGHT } from 'constant/htmlIds';
import { getNextActionsArray, toTransitionsToNextActions } from 'utils';

import { ActionsList } from '../ui';
import { withWorkflowOverviewContext } from './context';
import { findGroupTraceStatesByIndex } from './utils';

export const WorkflowOverviewActions = React.memo(props => {
  const [firstActions, setFirstActions] = useState([]);
  const [possibleActions, setPossibleActions] = useState([]);
  const { workflow, selectedGroupIndex = null, context } = props;
  const {
    selectedTraceIds,
    showNewTracesTray,
    showTracesUpdateTray,
    toggleNewTracesTray,
    toggleUpdateTracesTray
  } = context;
  const {
    groups,
    groupedTraceStates,
    rowId: workflowRowId,
    config: { actions, initActions, transitions }
  } = workflow;

  // Feed init actions on tray status change
  const computeFirstActions = async () => {
    const nextActions =
      transitions && transitions['-']
        ? await toTransitionsToNextActions(transitions['-'])
        : initActions;
    setFirstActions(
      getNextActionsArray({
        nextActions,
        groups: groups.nodes,
        actions: Object.values(actions)
      })
    );
  };
  useEffect(() => {
    if (!showNewTracesTray || !actions) {
      setFirstActions([]);
    } else {
      computeFirstActions();
    }
  }, [showNewTracesTray]);

  // Feed possible actions on tray status change
  const computePossibleActions = async selectedTraceStates => {
    const allNextActions = selectedTraceStates.map(state => state.nextActions);

    // Get the intersection of all nextActions for all selected traces
    // and normalize them to the "v2" next actions structure
    // ({ [group]: { action1: bool, action2: bool, ... } })
    const nextActionsIntersection = allNextActions.reduce(
      (intersection, nextActions) => {
        // initialize possible actions with nextActions of the first trace
        if (!intersection) {
          const result = {};
          Object.keys(nextActions).forEach(groupLabel => {
            if (!nextActions[groupLabel]) return;
            result[groupLabel] = {};
            if (Array.isArray(nextActions[groupLabel])) {
              // v1
              if (!nextActions[groupLabel].length) return;
              nextActions[groupLabel].forEach(actionKey => {
                result[groupLabel][actionKey] = true;
              });
            } else {
              // v2
              result[groupLabel] = nextActions[groupLabel];
            }
          });
          return result;
        }

        // Abort if no next actions possible
        if (!Object.keys(intersection).length) return intersection;

        // otherwise compute intersection of current possible actions and next actions of the current trace
        Object.keys(nextActions).forEach(groupLabel => {
          if (
            !intersection[groupLabel] ||
            !intersection['*'] ||
            groupLabel !== '*'
          ) {
            return;
          }
          const newActions = {};
          if (Array.isArray(nextActions[groupLabel])) {
            // v1
            nextActions[groupLabel].forEach(actionKey => {
              if (!intersection[groupLabel][actionKey]) return;
              newActions[actionKey] = true;
            });
          } else {
            // v2
            Object.entries(nextActions[groupLabel]).forEach(
              ([actionKey, condition]) => {
                if (
                  // Named intersection
                  intersection[groupLabel][actionKey] ||
                  // Wildcards
                  intersection[groupLabel]['*'] ||
                  intersection['*'][actionKey] ||
                  intersection['*']['*']
                ) {
                  newActions[actionKey] = condition;
                }
              }
            );
          }
          if (!Object.keys(newActions).length) {
            delete intersection[groupLabel];
          } else {
            intersection[groupLabel] = newActions;
          }
        });

        // If the group is not featured in next actions, drop all possibilities from that group
        Object.keys(intersection).forEach(groupLabel => {
          if (!nextActions[groupLabel] && !nextActions['*']) {
            delete intersection[groupLabel];
          }
        });

        return intersection;
      },
      null
    );

    // If multiple traces are selected, do not include actions that use the data importer or editor,
    // as these are tailored towards single traces.
    const filteredActions =
      selectedTraceStates.length === 1
        ? Object.values(actions)
        : Object.values(actions).filter(a => !a.dataImporter && !a.dataEditor);

    return setPossibleActions(
      getNextActionsArray({
        nextActions: nextActionsIntersection,
        groups: groups.nodes,
        actions: filteredActions
      })
    );
  };
  useEffect(() => {
    if (!showTracesUpdateTray || !selectedTraceIds.length) {
      setPossibleActions([]);
    } else {
      const traceStates = findGroupTraceStatesByIndex(
        groupedTraceStates,
        selectedGroupIndex
      );
      // filter selected traces
      const selectedTraceIdsSet = new Set(selectedTraceIds);
      const selectedTraceStates = traceStates.nodes.filter(traceState =>
        selectedTraceIdsSet.has(traceState.trace.rowId)
      );

      if (selectedTraceStates.length === 0) {
        setPossibleActions([]);
      } else {
        computePossibleActions(selectedTraceStates);
      }
    }
  }, [showTracesUpdateTray, selectedTraceIds]);

  const tracePluraliser = selectedTraceIds.length > 1 ? 's' : '';

  const newTracesTrayMessage = useMemo(() => {
    if (showNewTracesTray && !firstActions.length) {
      return 'You have no permission to create a trace on this workflow.';
    }
    return 'The following trace initialisations are available.';
  }, [showNewTracesTray, firstActions]);

  const updateTracesTrayMessage = useMemo(() => {
    if (showTracesUpdateTray && selectedTraceIds.length) {
      if (!possibleActions.length) {
        return `There is no action available for the selected trace${tracePluraliser}.`;
      }
      return `The following actions are available for the selected trace${tracePluraliser}.`;
    }
    return 'Please select at least one trace.';
  }, [showTracesUpdateTray, selectedTraceIds, possibleActions]);

  return (
    <>
      {showNewTracesTray && (
        <Tray
          portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
          title="Create New Trace"
          onClose={toggleNewTracesTray}
        >
          <ActionsList
            nextActions={firstActions}
            workflowId={workflowRowId}
            message={newTracesTrayMessage}
            toggleTray={toggleNewTracesTray}
          />
        </Tray>
      )}
      {showTracesUpdateTray && (
        <Tray
          portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
          title={`Update the selected trace${tracePluraliser}`}
          onClose={toggleUpdateTracesTray}
        >
          <ActionsList
            nextActions={possibleActions}
            workflowId={workflowRowId}
            traceIds={selectedTraceIds}
            message={updateTracesTrayMessage}
            toggleTray={toggleUpdateTracesTray}
          />
        </Tray>
      )}
    </>
  );
});

WorkflowOverviewActions.propTypes = {
  workflow: PropTypes.object.isRequired,
  selectedGroupIndex: PropTypes.string,
  context: PropTypes.shape({
    selectedTraceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    showNewTracesTray: PropTypes.bool.isRequired,
    showTracesUpdateTray: PropTypes.bool.isRequired,
    toggleNewTracesTray: PropTypes.func.isRequired,
    toggleUpdateTracesTray: PropTypes.func.isRequired
  }).isRequired
};

export default withWorkflowOverviewContext(WorkflowOverviewActions);
