import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';

import filesize from 'filesize';

import { Icon, TextEllipsis } from '@stratumn/atomic';

import ProgressBar from './progressBar';

import { extension } from '../utils';
import { File as FileType } from '../types';

import styles from './file.style';

interface Props {
  classes: ClassNameMap<string>;
  file: FileType;
  maxFileSize: number;
  handleDeleteFile: (x: any) => void;
  disabled: boolean;
}

export const File: React.FC<Props> = ({
  classes,
  file,
  handleDeleteFile,
  maxFileSize,
  disabled
}) => {
  const { id, name, size, uploadPercent, errorType } = file;

  const isUploading = uploadPercent! >= 0;

  const renderSize = React.useCallback(
    () =>
      isUploading ? (
        <ProgressBar completed={uploadPercent} />
      ) : (
        <>
          {filesize(size, { round: 1 })}
          <div className={classes.checkIcon}>
            <Icon name="Tick" size={16} />
          </div>
        </>
      ),
    [isUploading, uploadPercent, size, classes.checkIcon]
  );

  const renderError = React.useCallback(() => {
    switch (errorType) {
      case 'size':
        return (
          <div data-cy="forms-js-file-error" className={classes.errorMessage}>
            File size error: this file is over the {filesize(maxFileSize)}{' '}
            limit.
          </div>
        );
      case 'extension':
        return (
          <div data-cy="forms-js-file-error" className={classes.errorMessage}>
            File type error:{' '}
            {`.${extension(name)} files are not supported in
                Trace.`}
          </div>
        );
      default:
        return (
          <div data-cy="forms-js-file-error" className={classes.errorMessage}>
            File error.
          </div>
        );
    }
  }, [classes.errorMessage, errorType, maxFileSize, name]);

  return (
    <li
      className={classes.root}
      key={id}
      data-is-uploading={isUploading}
      data-is-error={!!errorType}
      data-cy="forms-js-file"
    >
      <div className={classes.fileWrapper}>
        <TextEllipsis className={classes.name} text={name} middleEllipsis />

        <div className={classes.fileDetails}>
          {!errorType ? renderSize() : renderError()}
        </div>
      </div>
      {!disabled && (
        <button
          data-cy="forms-js-delete-file"
          className={classes.deleteBtn}
          title="delete file"
          type="button"
          onClick={() => handleDeleteFile(id)}
        >
          <Icon name="Cross" size={16} />
        </button>
      )}
    </li>
  );
};

export default injectSheet(styles)(React.memo(File));
