import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Input } from 'components/ui';

import styles from './text.style';

// text filter
const TextFilter = React.memo(
  ({ classes, colConfig, setColumnFilter, filterConfig, delay = 666 }) => {
    // get current filter string value
    const filterStr = colConfig.filter ? colConfig.filter.value : '';

    const [inputStr, setInputStr] = useState(filterStr);

    // change the text displayed if the filter value in the colConfig changes
    useEffect(
      () => {
        setInputStr(filterStr);
      },
      [filterStr] // Only re-call effect if colConfig changes
    );

    // debounce update of the column filter
    useEffect(
      () => {
        if (inputStr === filterStr) return undefined;

        // set column filter after delay
        const handler = setTimeout(() => {
          setColumnFilter(
            colConfig.key,
            inputStr === '' ? undefined : inputStr,
            filterConfig
          );
        }, delay);

        // Cancel the timeout if value changes (also on unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [inputStr] // Only re-call effect if inputStr changes
    );

    return (
      <div className={classes.textFilter}>
        <Input
          type="search"
          placeholder={filterConfig.placeholder || 'Search'}
          onChange={e => setInputStr(e.target.value)}
          value={inputStr}
          top
        />
      </div>
    );
  }
);
TextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  colConfig: PropTypes.object.isRequired,
  setColumnFilter: PropTypes.func.isRequired,
  filterConfig: PropTypes.object.isRequired,
  delay: PropTypes.number
};

export default injectSheet(styles)(TextFilter);
