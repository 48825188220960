import React, { useState } from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import Dropzone from 'react-dropzone';
import { WidgetProps, IdSchema } from '@rjsf/utils';
import compose from 'lodash.flowright';

import filesize from 'filesize';
import shortid from 'shortid';
import { extension } from './utils';
import { isLockedWidget, FormLinks } from '../../../../../utils/links';

import { FieldTemplate } from '../../../../formTemplates';

import { Icon, Switch } from '@stratumn/atomic';

import File from './file';
import Label from './label';
import AcceptedFilesDescription from './acceptedFilesDescription';

import { File as FileType, HandleFileFn } from './types';
import { UploadFileFn } from '../../../../../utils/media';
import useMeasure from 'utils/hooks/useMeasure';
import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import {
  ADD_FILES,
  REMOVE_FILE,
  ERROR,
  PROGRESS,
  SUCCESS
} from './constant/actions';
import { ERROR_TYPE_SIZE, ERROR_TYPE_EXTENSION } from './constant/errors';
import { applyFilesChange } from './reducer';

import styles from './fileUpload.style';

const BATCHED_FORM_WIDTH = 375;

interface Props {
  classes: ClassNameMap<string>;
  formData: FileType[];
  onChange: HandleFileFn;
  required: boolean;
  schema: WidgetProps;
  formContext: {
    uploadFile: UploadFileFn;
    uploadConfig: {
      maxFileSize?: number;
      fileExtensionWhitelistMap?: Record<string, string[]>;
    };
    links: FormLinks;
  };
  idSchema: IdSchema;
  uiSchema;
}

export const FileUploadField: React.FC<Props> = props => {
  const {
    classes,
    schema: { title },
    formContext: { uploadFile, uploadConfig, links },
    formData,
    onChange,
    required,
    idSchema,
    uiSchema
  } = props;
  // store the files list in a ref and update at each rerender
  // this is so that upload function gets bound to the ref and not the list itself
  // so that it can know the real list state at each asynchronous update
  const filesRef = React.useRef([] as FileType[]);
  filesRef.current = formData || [];

  // check if the widget is locked because of forms links
  const fieldId = idSchema.$id;
  const isLocked = isLockedWidget(fieldId, links);

  const [dropzoneActive, setDropzoneActive] = React.useState(false);

  let dropzoneRef: any | undefined = React.useRef(null);

  // Measures for responsive design
  const container: any | undefined = React.useRef();
  const [measureRef, bounds] = useMeasure(container, 0);

  /**
   * Count constants in order to display number of:
   * - files uploaded
   * - error files
   */
  const filesCount = filesRef.current.length;
  const errorsCount = filesRef.current.filter(f => f.error).length;

  // Set to fit within the batched forms
  const responsive = bounds.width <= BATCHED_FORM_WIDTH;

  const maxFileSize =
    (uploadConfig && uploadConfig.maxFileSize) || 1024 * 1024 * 10;

  // media-api should be feeding this map through the client (e.g: trace-ui)
  const fileExtensionWhitelistMap =
    (uploadConfig && uploadConfig.fileExtensionWhitelistMap) || null;

  /**
   * @function handleNewFiles - handles the files that are dropped
   * or selected using react-dropzone
   * @param {Array.<Object>} acceptedFiles
   */
  const handleNewFiles = React.useCallback(
    acceptedFiles => {
      if (isLocked) return;

      // Generate a flat list in order to check for errors on the client side
      const fileExtensionWhitelist =
        (uploadConfig &&
          uploadConfig.fileExtensionWhitelistMap &&
          (Object.values(uploadConfig.fileExtensionWhitelistMap) as any).flat(
            1
          )) ||
        [];

      const newFiles = acceptedFiles.filter(
        f => !filesRef.current.find(i => f.name === i.name)
      );

      onDragLeave();

      if (!newFiles.length) return;

      const forbiddenFileExtensions = acceptedFiles.filter(
        file =>
          fileExtensionWhitelist.length > 0 &&
          !fileExtensionWhitelist.includes(extension(file.name))
      );

      // If a file is over the size limit and its extension is not allowed,
      // we only include this file once in the forbiddenFileExtension array
      const exceedSizeLimit = acceptedFiles.filter(
        file =>
          file.size > maxFileSize && !forbiddenFileExtensions.includes(file)
      );

      const readyToUpload = acceptedFiles.filter(
        el =>
          !exceedSizeLimit.includes(el) && !forbiddenFileExtensions.includes(el)
      );

      /**
       * @function onFileSuccess - reports a file with no error and set uploading to false
       * @param id
       */
      const onFileSuccess = (id: string) => (file: FileType) =>
        applyFilesChange(
          filesRef.current,
          {
            type: SUCCESS,
            payload: { id, file }
          },
          onChange
        );

      /**
       * @function onFileError - reports media-api error.
       * @param id
       * Fallback if the UI is unable to catch the error.
       * e.g: the file size after encryption exceeds media-api's size limit
       */
      const onFileError = (id: string) => () =>
        applyFilesChange(
          filesRef.current,
          { type: ERROR, payload: { id } },
          onChange
        );

      /**
       * @function onFileProgress - reports the percentage of the uploading file
       * @param id
       * @param percentage
       */
      const onFileProgress = (id: string, percentage: number) =>
        applyFilesChange(
          filesRef.current,
          {
            type: PROGRESS,
            payload: { id, percentage }
          },
          onChange
        );

      const uploading = readyToUpload.map(file => {
        const id = `valid-${shortid.generate()}`;
        uploadFile(file, onFileSuccess(id), onFileError(id), progress =>
          onFileProgress(id, progress.uploadPercent)
        );
        return {
          id,
          name: file.name,
          size: file.size,
          uploading: true,
          uploadPercent: 0
        };
      });

      const errorsExceedSizeLimit = exceedSizeLimit.map(file => {
        const id = `err-too-big-${shortid.generate()}`;
        return {
          id,
          name: file.name,
          size: file.size,
          error: true,
          errorType: ERROR_TYPE_SIZE,
          uploading: false
        };
      });

      const errorsForbiddenExtension = forbiddenFileExtensions.map(file => {
        const id = `err-wrong-ext-${shortid.generate()}`;
        return {
          id,
          name: file.name,
          size: file.size,
          error: true,
          errorType: ERROR_TYPE_EXTENSION,
          uploading: false
        };
      });

      const errors = [...errorsExceedSizeLimit, ...errorsForbiddenExtension];
      const mergedFiles = [...uploading, ...errors];

      applyFilesChange(
        filesRef.current,
        { type: ADD_FILES, payload: { mergedFiles } },
        onChange
      );
    },
    [isLocked, maxFileSize, onChange, uploadFile, uploadConfig]
  );

  const handleDeleteFile = React.useCallback(
    (id: string) =>
      applyFilesChange(
        filesRef.current,
        { type: REMOVE_FILE, payload: { id } },
        onChange
      ),
    [onChange]
  );

  const onDragEnter = () => !isLocked && setDropzoneActive(true);

  const onDragLeave = () => setDropzoneActive(false);

  const handleFileButtonClick = () => dropzoneRef.open();

  const renderUploadedFiles = React.useMemo(() => {
    if (filesRef.current.length === 0) return null;

    return filesRef.current.map(file => (
      <File
        key={file.id}
        file={file}
        handleDeleteFile={handleDeleteFile}
        maxFileSize={maxFileSize}
        disabled={isLocked}
      />
    ));
  }, [handleDeleteFile, maxFileSize, isLocked]);
  const showSwitch = !!uiSchema['ui:options']?.visibilitySwitchLabel; // Replace with uiSchema.files.options.showVisibilitySwitch
  const switchLabel = uiSchema['ui:options']?.visibilitySwitchLabel; // Replace with uiSchema.files.options.visibilitySwitchLabel
  const [showUpload, setShowUpload] = useState(!showSwitch);
  const handleSwitchClick = () => {
    setShowUpload(prevState => !prevState);
  };
  return (
    <>
      {showSwitch && (
        <Switch
          label={switchLabel}
          on={showUpload}
          handleChange={handleSwitchClick}
          showLabel
        />
      )}
      {showUpload && (
        <FieldTemplate id={fieldId} label={title} {...(props as any)}>
          <Label filesCount={filesCount} errorsCount={errorsCount} />
          <div
            className={classes.root}
            data-drag-enter={dropzoneActive}
            ref={measureRef}
          >
            <Dropzone
              className={classes.dropzoneWrapper}
              data-is-responsive={responsive}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={handleNewFiles}
              inputProps={{ required }}
              data-drag-enter={dropzoneActive}
              multiple
              disableClick
              ref={e => {
                dropzoneRef = e;
              }}
            >
              <div
                className={classes.dropzoneBody}
                data-is-responsive={responsive}
              >
                {!isLocked && (
                  <div
                    className={classes.leftColumn}
                    data-is-responsive={responsive}
                  >
                    <div
                      className={classes.leftColumnContent}
                      data-drag-enter={dropzoneActive}
                      data-has-files={filesCount > 0}
                      data-cy="forms-js-dropzone-fileupload"
                    >
                      <div className={classes.dropzoneLabel}>
                        <p>Drop files here</p>
                        <p>or</p>
                      </div>

                      <button
                        className={classes.fileSelectBtn}
                        type="button"
                        onClick={handleFileButtonClick}
                        data-cy="forms-js-select-fileupload"
                      >
                        Select files
                      </button>
                      {maxFileSize && (
                        <span className={classes.sizeWarning}>
                          {`Maximum file size is ${filesize(maxFileSize)}`}
                        </span>
                      )}
                    </div>
                    <AcceptedFilesDescription
                      fileExtensionWhitelistMap={fileExtensionWhitelistMap}
                      responsive={responsive}
                      filesLength={filesRef.current.length}
                    />
                  </div>
                )}
                <ul
                  className={classes.rightColumn}
                  data-is-responsive={responsive}
                  data-is-disabled={isLocked}
                >
                  {renderUploadedFiles}
                </ul>
              </div>
            </Dropzone>
            <div
              className={classes.dropzoneIcon}
              data-drag-enter={dropzoneActive}
            >
              <Icon name="ArrowDown" size={22} />
            </div>
          </div>
        </FieldTemplate>
      )}
    </>
  );
};

export default compose(
  withInlineValidation,
  injectSheet(styles)
)(React.memo(FileUploadField));
