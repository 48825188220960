import { memo } from 'react';

import { getByPath, formatNumber } from '@/utils';

import { cn } from '@/shadcn/lib/utils';
import { NumberView } from './number.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: NumberView;
  data: WidgetData;
};

const NumberViewImpl = memo(({ view, data }: Props) => {
  const { path, format, denominatorPath, tabRight } = view;
  // get the number to display and format it
  const numberValue = getByPath<number>(data, path);

  if (numberValue === undefined) return null; // ensure we return empty view and not 0 if data is not here

  let displayedNumber = formatNumber(numberValue, format);

  // check if we have to display a denominator
  if (denominatorPath !== undefined) {
    const denominatorValue = getByPath<number>(data, denominatorPath);
    const displayedDenominator = formatNumber(denominatorValue, format);
    displayedNumber += ` / ${displayedDenominator}`;
  }

  return (
    <div
      className={cn('w-full truncate', tabRight && 'text-right')}
      data-tabbed-right={tabRight}
    >
      {displayedNumber}
    </div>
  );
});

// sort defaults to number on path
const getSortConfig = (view: NumberView): NumberView => ({
  type: 'number',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: NumberView) => ({
  type: 'text',
  path: view.path,
  placeholder: 'Eg >20, 10<<15',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = (view: NumberView) => {
  const { path } = view;

  return (data: WidgetData) => {
    // get the number to display and format it
    const numberValue = getByPath<number>(data, path);
    if (numberValue === undefined) return '';

    return Number(numberValue);
  };
};

// apparently, numbers should aggregate in sum by default
// whatever their meaning and level of homogeneity ¯\_(ツ)_/¯
// we return exactly the same number view config
// just changing the path to return the sum across all data rows
const getAggregationConfig = (view: NumberView): NumberView => {
  const { path, aggregationKey, aggregationValue, aggregationFunction } = view;

  let aggregationView;
  if (aggregationKey && aggregationValue) {
    aggregationView = {
      view: {
        ...view,
        aggregationKey,
        aggregationValue: aggregationValue,
        path: `data[?${aggregationKey}=='${aggregationValue}'].${path} | ${
          aggregationFunction || 'sum'
        }(@)`
      }
    };
  } else {
    aggregationView = {
      view: {
        ...view,
        path: `data[].${path} | sum(@)`
      }
    };
  }
  return aggregationView;
};
export const numberViewProvider = {
  component: NumberViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction,
  getAggregationConfig
};
