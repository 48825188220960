import moment from 'moment';

export const validYMD = (date: moment.MomentInput) => {
  const ymd = moment(date, 'YYYY-MM-DD', true);
  const ydm = moment(date, 'YYYY-DD-MM', true);
  const dmy = moment(date, 'DD-MM-YYYY', true);
  const mdy = moment(date, 'MM-DD-YYYY', true);
  const ymd2 = moment(date, 'YYYY/MM/DD', true);
  const ydm2 = moment(date, 'YYYY/DD/MM', true);
  const dmy2 = moment(date, 'DD/MM/YYYY', true);
  const mdy2 = moment(date, 'MM/DD/YYYY', true);
  if (
    ymd.isValid() ||
    ydm.isValid() ||
    dmy.isValid() ||
    mdy.isValid() ||
    ymd2.isValid() ||
    ydm2.isValid() ||
    dmy2.isValid() ||
    mdy2.isValid()
  ) {
    return true;
  }
  return false;
};

export const validString = (date: moment.MomentInput) => {
  const m = moment(date, moment.defaultFormat, true);
  if (m.isValid()) {
    return true;
  }
  return false;
};
