import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import {
  Pushbutton,
  Modal,
  ModalContent,
  ModalActions,
  Prose
} from '@stratumn/atomic';

import styles from './removeGroupMembersModal.style';

// The modal to confirm the removal of a set of group members
export const RemoveGroupMembersModal = ({
  classes,
  groupName,
  participantsStr,
  submit,
  cancel
}) => (
  <Modal
    title="Remove from group"
    closeButtonLabel="Cancel"
    handleCollapse={cancel}
    hideHeaderBorderBottom
  >
    <ModalContent>
      <div className={classes.modalContent}>
        <Prose
          text={`Are you sure you want to remove **${participantsStr}** from the **${groupName}** group ?`}
        />
      </div>
    </ModalContent>
    <ModalActions
      adverseAction={
        <Pushbutton onClick={cancel} dataCy="cancel-button">
          cancel
        </Pushbutton>
      }
    >
      <Pushbutton
        warning
        onClick={submit}
        disabled={!groupName}
        dataCy="submit-button"
      >
        remove
      </Pushbutton>
    </ModalActions>
  </Modal>
);
RemoveGroupMembersModal.propTypes = {
  classes: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  participantsStr: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default compose(
  injectSheet(styles),
  React.memo
)(RemoveGroupMembersModal);
