export default theme => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    marginBottom: 15,
    width: '10%'
  },
  filesInfo: {
    display: 'flex',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px'
  },
  errorsCount: {
    display: 'inline-flex',
    paddingRight: 10,
    color: theme.warningRed,
    '& span:last-child': {
      paddingLeft: 3
    }
  },
  filesCount: {
    display: 'inline-flex',
    width: '100%',
    color: theme.indigoPastel1,
    '& span:last-child': {
      paddingLeft: 3
    }
  }
});
