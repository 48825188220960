import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';

import { Reset, ArrowRight, ArrowLeft } from '@stratumn/icons';

import Month from './month';

import styles, { dayPickerNavigationInlineStyles } from './datePicker.style';

// single date picker with month / year selection from dropdowns
export const DatePicker = ({
  classes,
  dateFormat = null,
  nbMonths = 1,
  value = null,
  onSelect,
  displayReset = true
}) => {
  // receive new date object (from date picker or from parsed string input)
  const onDateChange = useCallback(
    newDate => {
      // cast date to string
      const newDateStr = newDate ? newDate.format(dateFormat) : undefined;

      // reset value
      onSelect(!newDateStr ? undefined : newDateStr);
    },
    [onSelect, dateFormat]
  );

  // build a memo to get date object
  const dateObj = useMemo(() => {
    if (!value) return undefined;
    const momentDate = moment.utc(value, dateFormat, true);
    return momentDate.isValid() ? momentDate : undefined;
  }, [value, dateFormat]);

  // Memo for the reset section where we can clear the date
  const resetSection = useMemo(
    () =>
      displayReset ? (
        <div
          className={classes.resetBtnWrapper}
          onClick={() => onDateChange(undefined)}
          data-cy="reset-button"
        >
          <Reset size={14} />
          <span>Reset</span>
        </div>
      ) : null,
    [displayReset, onDateChange]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.picker}>
        <DayPickerSingleDateController
          date={dateObj || null}
          onDateChange={onDateChange}
          hideKeyboardShortcutsPanel
          enableOutsideDays
          numberOfMonths={nbMonths}
          noBorder
          focused
          renderMonthElement={Month} // This renders the Month + Year selection element
          navPrev={<ArrowLeft className={classes.monthNavigationIcon} />}
          navNext={
            <ArrowRight className={classes.monthNavigationIcon} data-is-right />
          }
          dayPickerNavigationInlineStyles={dayPickerNavigationInlineStyles}
        />
      </div>
      {resetSection}
    </div>
  );
};
DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  dateFormat: PropTypes.string,
  nbMonths: PropTypes.number,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  displayReset: PropTypes.bool
};

export default injectSheet(styles)(React.memo(DatePicker));
