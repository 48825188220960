export type WorkflowGroup = {
  rowId: string; // group rowId, if queries might be required later on
  label: string; // group label, that will potentially be stored in form data
  name?: string; // name of the group owner
  avatar?: string; // group avatar used to display in groups selection list
};

export type WorkflowContext = {
  rowId: string; // workflow rowId, if queries might be required later on
  author?: WorkflowGroup; // group that is authoring the form edition
  groups: WorkflowGroup[]; // all the groups that can act on the workflow
  subjects?: string[];
};

export type WorkflowGroupOptions = {
  includeAuthorGroup?: boolean; // whether to keep the group authoring the form in the groups selection (defaults to false)
  blacklist?: string[]; // list of specific group labels or names to exclude from the selection
};

export const buildSelectableGroupsList = (
  workflowContext: WorkflowContext,
  options: WorkflowGroupOptions
): WorkflowGroup[] => {
  // by default, remove the group that is currently authoring the form
  const { includeAuthorGroup, blacklist = [] } = options || {};
  const { groups, author } = workflowContext;

  const filteredGroups = groups.filter(
    group =>
      !blacklist.includes(group.label) &&
      !(group.name && blacklist.includes(group.name)) &&
      author &&
      (includeAuthorGroup || group.label !== author.label)
  );

  return filteredGroups;
};

export const getSelectedGroupDetails = (
  workflowContext: WorkflowContext,
  label: string
) => {
  const { groups } = workflowContext;

  return groups.find(group => group.label === label);
};

// validation
export const workflowGroupCustomFormat =
  (workflowContext?: WorkflowContext) => value => {
    // if no workflow context specified, anything is valid....
    if (!workflowContext) return true;

    // note: not possible yet to pass options to ajv's custom format validation...
    // so any group of the workflow would be valid
    // hence options will be usable only as interface constraints.... ¯\_(ツ)_/¯
    const validGroups = buildSelectableGroupsList(workflowContext, {
      includeAuthorGroup: true
    });
    return !!validGroups.find(group => group.label === value);
  };
