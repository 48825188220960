import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Modal, Pushbutton, Tray } from '@stratumn/atomic';

import { TRAY_PORTAL_RIGHT } from 'constant/htmlIds';

import CodeViewer from '../code';

import styles from './jsonEditor.style';

// json editor is a modal with title, editable codemirror body and a submit button
export const JsonEditor = React.memo(
  ({
    classes,
    inModal = false,
    title = 'Edit JSON string',
    jsonString,
    onSubmit = null,
    onClose,
    codemirrorOptions = null,
    submitButtonLabel = 'Save',
    closeAfterSubmit = false
  }) => {
    const [parsingErr, setParsingErr] = useState(null);
    const editorRef = useRef(null);

    const [submitting, setSubmitting] = useState(false);

    const submit = async () => {
      if (!onSubmit) return;
      const currentStr = editorRef.current.getValue();
      try {
        if (currentStr) JSON.parse(currentStr);
        setParsingErr(null);
      } catch (e) {
        const errorStr = `Error parsing object:\n${e.toString()}`;
        setParsingErr(errorStr);
        console.error(errorStr);
        return;
      }
      setSubmitting(true);
      await onSubmit(currentStr);
      setSubmitting(false);
      if (closeAfterSubmit) {
        onClose();
      }
    };

    // ctrl + enter to submit
    const handleKeyPress = event => {
      if (event.key === 'Enter' && event.ctrlKey) {
        submit();
      }
    };

    // refresh udl editor
    const refreshEditor = useCallback(() => editorRef.current.refresh(), []);

    // json editor component
    const editorComponent = (
      <div className={classes.jsonEditor} data-is-in-modal={inModal}>
        <div
          className={classes.jsonEditorBody}
          onKeyPress={handleKeyPress}
          data-is-in-modal={inModal}
          data-cy="editor-body"
        >
          <CodeViewer
            codeString={jsonString}
            codemirrorOptions={codemirrorOptions}
            readOnly={false}
            setEditorRef={editor => {
              editorRef.current = editor;
            }}
          />
        </div>
        <div className={classes.jsonEditorFooter}>
          <div className={classes.jsonEditorErrorMessage} title={parsingErr}>
            {parsingErr && 'Invalid JSON string !'}
          </div>
          <div className={classes.jsonEditorActions}>
            <div className={classes.jsonEditorAction}>
              <Pushbutton
                secondary
                onClick={onClose}
                disabled={false}
                dataCy="close-btn"
              >
                CLOSE
              </Pushbutton>
            </div>
            {onSubmit && (
              <div className={classes.jsonEditorAction}>
                <Pushbutton
                  primary
                  onClick={submit}
                  disabled={submitting}
                  dataCy="submit-btn"
                >
                  {submitButtonLabel}
                </Pushbutton>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    // display it either in modal or in tray
    return inModal ? (
      <Modal title={title} handleCollapse={onClose} large>
        {editorComponent}
      </Modal>
    ) : (
      <Tray
        portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
        title={title}
        width={600}
        onResize={refreshEditor}
        onClose={onClose}
      >
        {editorComponent}
      </Tray>
    );
  }
);

JsonEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  inModal: PropTypes.bool,
  title: PropTypes.string,
  jsonString: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  codemirrorOptions: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  closeAfterSubmit: PropTypes.bool
};

export default injectSheet(styles)(JsonEditor);
