import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import moment from 'moment';

import Select from '../select';

import styles from './month.style';

// list of years selection options provided to the Select component
const generateYearsList = (start, end) =>
  Array.from(Array(end - start + 1), (_, i) => {
    const year = end - i;
    return {
      value: year,
      label: year.toString()
    };
  });

// list of month selection options provided to the Select component
const monthsList = moment.months().map((monthStr, idx) => ({
  label: monthStr,
  value: idx
}));
// function to display the selected month label in the button
const displayMonthShort = monthStr =>
  // return the first 3 letters of the month
  // with a '.' if the months has more than 3 letters
  // note that we are not using moment.monthsShort() precisely because of the later condition...
  `${monthStr.substring(0, 3)}${monthStr.length > 3 ? '.' : ''}`;

// month header displayed above the date picker
export const Month = ({
  classes,
  month,
  onMonthSelect,
  onYearSelect,
  yearsStart = 1900,
  yearsEnd = 2050
}) => {
  const yearsList = useMemo(
    () => generateYearsList(yearsStart, yearsEnd),
    [yearsStart, yearsEnd]
  );

  const currentMonth = month.month();
  const currentYear = month.year();

  const selectMonth = useCallback(
    value => onMonthSelect(month, value),
    [onMonthSelect, month]
  );
  const selectYear = useCallback(
    value => onYearSelect(month, value),
    [onYearSelect, month]
  );

  return (
    <div className={classes.root}>
      <Select
        className={classes.dropdown}
        value={currentMonth}
        options={monthsList}
        onSelect={selectMonth}
        labelDisplay={displayMonthShort}
        dataCy="month-select"
      />
      <Select
        className={classes.dropdown}
        value={currentYear}
        options={yearsList}
        onSelect={selectYear}
        dataCy="year-select"
      />
    </div>
  );
};
Month.propTypes = {
  classes: PropTypes.object.isRequired,
  month: PropTypes.object.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  yearsStart: PropTypes.number,
  yearsEnd: PropTypes.number
};

const WrappedMonth = injectSheet(styles)(React.memo(Month));
// note: Month is consumed by react-dates as a function (ie a render prop) so we export it this way...
export default props => <WrappedMonth {...props} />;
