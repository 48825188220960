import { memo } from 'react';
import { createPortal } from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { TOOLTIP_PORTAL } from '@/constant/htmlIds';

import { ResizableColHeader } from '../resizableColHeader/resizableColHeader';

import { cn } from '@/shadcn/lib/utils';

type ColumnConfig = {
  key: string;
  width: number;
  header: string;
  def: {
    header: string;
  };
};

type Props = {
  height: number;
  columns: ColumnConfig[];
  reorderColumns: (result: any) => void;
  setColumnWidth: (key: string, width: number) => void;
};

/**
 * Table column headers editor
 * Drag and drop functionality and columns resizing
 */
export const ColHeadersEditor = memo(
  ({ height, columns, reorderColumns, setColumnWidth }: Props) => (
    <DragDropContext
      onDragEnd={result => {
        reorderColumns(result);
      }}
    >
      <div
        className="border-border bg-card text-muted-foreground flex w-fit flex-row flex-nowrap border-b-2 text-left text-[10px] tracking-wide"
        style={{
          height: height + 2
        }}
      >
        <Droppable
          droppableId="columns-headers-droppable"
          direction="horizontal"
        >
          {providedDroppable => (
            <div
              className="flex w-full flex-row flex-nowrap items-center"
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {columns.map((column, index) => (
                <Draggable
                  key={column.key}
                  draggableId={column.key}
                  index={index}
                >
                  {(providedDraggable, snapshotDraggable) => {
                    const transform =
                      providedDraggable.draggableProps.style?.transform;

                    const horizontalTransform = transform
                      ? `${transform.split(',')[0]}, 0px)`
                      : transform;

                    const draggableItem = (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        className={cn(
                          snapshotDraggable.isDragging &&
                            'bg-primary text-primary-foreground text-left text-[10px] tracking-wide'
                        )}
                        data-is-dragging={snapshotDraggable.isDragging}
                        style={{
                          ...providedDraggable.draggableProps.style,
                          transform: snapshotDraggable.isDragging
                            ? horizontalTransform
                            : transform
                        }}
                      >
                        <ResizableColHeader
                          width={column.width}
                          height={height}
                          columnKey={column.key}
                          headerName={column.def.header}
                          setColumnWidth={setColumnWidth}
                          dragHandleProps={providedDraggable.dragHandleProps}
                          allowResizing={!snapshotDraggable.isDragging}
                        />
                      </div>
                    );
                    return snapshotDraggable.isDragging
                      ? createPortal(
                          draggableItem,
                          document.getElementById(TOOLTIP_PORTAL)!
                        )
                      : draggableItem;
                  }}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
);

export default ColHeadersEditor;
