import { memo, useState, useRef, useEffect } from 'react';

import { GripHorizontal } from 'lucide-react';

import { Resizable } from 're-resizable';
import { type DraggableProvided } from 'react-beautiful-dnd';
import InfoTooltip from '@/components/ui/utils/infoTooltip';

import { columnsWidthPresetsInverted } from '../displayConfigUtils';
import { cn } from '@/shadcn/lib/utils';

type Props = {
  width: number;
  height: number;
  columnKey: string;
  headerName: string;
  setColumnWidth: (columnKey: string, width: number) => void;
  dragHandleProps: DraggableProvided['dragHandleProps'];
  allowResizing: boolean;
};

/**
 * Resizable column header with a tooltip to show current width
 * (its icon bears the draggable handle as well)
 */
export const ResizableColHeader = memo(
  ({
    width,
    height,
    columnKey,
    headerName,
    setColumnWidth,
    dragHandleProps,
    allowResizing
  }: Props) => {
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [currentWidth, setCurrentWidth] = useState<number>(width);
    const headerRef = useRef<HTMLDivElement>(null);

    // update the current width if the prop changes
    // eg if another column has been resized manually and this one has been subsequently resized
    useEffect(() => {
      if (width !== currentWidth) setCurrentWidth(width);
    }, [width]);

    const currentWidthPresetLabel = columnsWidthPresetsInverted[currentWidth];
    const tooltipLabel = `Column Width:\n${currentWidth} px${
      currentWidthPresetLabel ? ` (${currentWidthPresetLabel})` : ''
    }`;

    return (
      <>
        <Resizable
          className={cn(allowResizing && 'border-primary border-r')}
          data-is-resizable={allowResizing}
          size={{
            width: currentWidth,
            height
          }}
          grid={[5, 1]}
          enable={{
            top: false,
            right: allowResizing,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={() => {
            setIsResizing(false);
            if (currentWidth !== width) {
              setColumnWidth(columnKey, currentWidth);
            }
          }}
          onResize={(_e, _direction, _ref, d) => {
            setCurrentWidth(width + d.width);
          }} // update the tooltip content dynamically
        >
          <div
            ref={headerRef}
            className="box-border flex w-full flex-row items-center justify-between py-[4px] pl-2"
          >
            <div className="truncate uppercase">{headerName}</div>
            <div
              className="hover:bg-primary/5 mr-1 shrink-0 cursor-move rounded-sm p-[2px]"
              {...dragHandleProps}
            >
              <GripHorizontal
                size={18}
                className={cn(
                  'text-primary',
                  !allowResizing && 'text-primary-foreground'
                )}
                data-is-dragging={!allowResizing}
              />
            </div>
          </div>
        </Resizable>
        {isResizing && (
          <InfoTooltip
            clientRef={headerRef}
            text={tooltipLabel}
            textColor="white"
            backgroundColor="black"
            position={{
              place: 'above',
              placeShift: 3,
              adjustPlace: true,
              anchor: 'right'
            }}
            delay={100}
          />
        )}
      </>
    );
  }
);
