import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useReducer
} from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import {
  Pushbutton,
  Pushtext,
  Icon,
  FieldTextAreaCompact
} from '@stratumn/atomic';

import {
  DATA_EDITOR_SET,
  DATA_EDITOR_UNSET,
  DATA_EDITOR_BATCH_UPDATE
} from 'constant/dataEditor';

import { withLeavingAlertContext } from 'components/beforeLeavingAlert';
import Table from 'components/ui/table';
import { getByPath, deepCopy, formatNumber } from 'utils';

import BatchEditModal from './batchEditModal';
import { applyModifiedDataChange } from './reducers';

import styles from './dataEditor.style';

export const DataEditor = React.memo(
  ({
    classes,
    data,
    config,
    onSubmit,
    submitLabel = 'Submit Changes',
    submitDisabled = false,
    onCancel = () => {},
    cancelLabel = 'Cancel',
    cachedUpdatesKey = null,
    traceInfoViewer = null,
    setAskBeforeLeaving,
    setLeavingCallback
  }) => {
    // reading props
    const {
      modifiedDataKey = 'changes',
      addInitialData,
      initialDataKey = 'rows',
      addComments,
      commentsKey = 'comments',
      dataPath,
      table
    } = config;

    const tableData = getByPath(data, dataPath) || [];

    const dataSelectorPath = table.dataSelectorPath || 'rowId';

    // data editor state
    // bind reducer with some props
    const modifiedDataReducer = useCallback(
      (currentModifiedData, action) =>
        applyModifiedDataChange(
          tableData,
          dataSelectorPath,
          cachedUpdatesKey,
          currentModifiedData,
          action
        ),
      [tableData, dataSelectorPath, cachedUpdatesKey]
    );
    const [modifiedData, onDataModified] = useReducer(modifiedDataReducer, {});

    // If modifiedData object contains modified lines, the user will be asked for confirmation before leaving the page or changing route.
    // If modifiedData is cleared (empty object), the user will not be asked for confirmation.
    useEffect(() => {
      const askBeforeLeaving =
        typeof modifiedData === 'object' &&
        Object.keys(modifiedData).length > 0;
      setAskBeforeLeaving(askBeforeLeaving);
    }, [modifiedData]);

    const [summaryData, setSummaryData] = useState(null);

    // comments state
    const [commentsInputFocused, setCommentsInputFocused] = useState(false);
    const [comments, setComments] = useState('');

    // batch edit modal state
    const [selectedRows, setSelectedRows] = useState([]);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const [batchUpdateCache, setBatchUpdateCache] = useState(null);
    const toggleBatchModal = useCallback(
      () => setShowBatchModal(!showBatchModal),
      [showBatchModal]
    );

    // at first mount, eventually get the data updates cached in local storage
    useEffect(() => {
      if (cachedUpdatesKey) {
        const localStorageModifiedDataStr =
          localStorage.getItem(cachedUpdatesKey);
        if (localStorageModifiedDataStr) {
          const localStorageModifiedData = JSON.parse(
            localStorageModifiedDataStr
          );
          onDataModified({
            type: DATA_EDITOR_SET,
            modifiedData: localStorageModifiedData,
            disableCache: true // do not cache what we have just read...
          });
        }
        const localStorageCommentsStr = localStorage.getItem(
          `${cachedUpdatesKey}_comments`
        );
        if (localStorageCommentsStr) {
          setComments(localStorageCommentsStr);
        }
      }
    }, [cachedUpdatesKey]);

    // this memo is passed to the table as the 'update' context
    // it holds all the patched data
    // and provides the update function only if the editor is not in 'summary' view
    const tableUpdate = useMemo(
      () => ({
        userDisplay: {
          localStorageKey: `${cachedUpdatesKey}_tableConfig`
        },
        setSelectedRows,
        edit: {
          modifiedData,
          onDataModified: summaryData ? null : onDataModified,
          onClickBatchEdit: summaryData ? null : toggleBatchModal,
          batchEditClicked: showBatchModal,
          allowDisplayDiffs: true
        }
      }),
      [
        cachedUpdatesKey,
        modifiedData,
        summaryData,
        showBatchModal,
        toggleBatchModal
      ]
    );

    // callback to clear all patches
    const clearModifiedData = useCallback(() => {
      onDataModified({
        type: DATA_EDITOR_UNSET
      });
    }, []);

    const clearSummaryData = useCallback(() => {
      setSummaryData(null);
    }, []);
    const buildSummaryData = useCallback(() => {
      const builtSummaryData = tableData.filter(rowData => {
        // get the value of the row selector
        const rowSelector = getByPath(rowData, dataSelectorPath);
        // check it is in the modified data map
        return !!modifiedData[rowSelector];
      });
      setSummaryData(builtSummaryData);
    }, [tableData, modifiedData, dataSelectorPath]);

    // handle comments state
    const focusComments = useCallback(() => {
      setCommentsInputFocused(true);
    }, []);
    const blurComments = useCallback(() => {
      setCommentsInputFocused(false);
    }, []);
    const updateComments = useCallback(
      e => {
        const newComment = e.target.value;
        setComments(newComment);
        if (cachedUpdatesKey) {
          if (!newComment) {
            localStorage.removeItem(`${cachedUpdatesKey}_comments`);
            return;
          }
          localStorage.setItem(`${cachedUpdatesKey}_comments`, newComment);
        }
      },
      [cachedUpdatesKey]
    );

    // if submit is successful, dataEditor will cleanup all related localStorage items
    const cleanupLocalStorage = useCallback(() => {
      if (cachedUpdatesKey) {
        localStorage.removeItem(cachedUpdatesKey);
        localStorage.removeItem(`${cachedUpdatesKey}_comments`);
        localStorage.removeItem(`${cachedUpdatesKey}_tableConfig`);
      }
    }, [cachedUpdatesKey]);

    // Update leaving alert callback
    useEffect(() => {
      setLeavingCallback(cleanupLocalStorage);
    }, [setLeavingCallback, cleanupLocalStorage]);

    // submit the data
    const submitData = useCallback(() => {
      const formData = {
        [modifiedDataKey]: modifiedData
      };
      if (comments) formData[commentsKey] = comments;
      if (addInitialData) formData[initialDataKey] = summaryData;
      onSubmit(formData, cleanupLocalStorage);
    }, [
      modifiedDataKey,
      modifiedData,
      summaryData,
      comments,
      commentsKey,
      addInitialData,
      initialDataKey,
      onSubmit,
      cleanupLocalStorage
    ]);

    // submit a batch patch when closing the batch edit modal
    const submitBatchPatch = useCallback(
      (patch, editablePaths) => {
        // close the modal
        toggleBatchModal();

        // keep track of modifiedData just before batch edit
        setBatchUpdateCache({
          cache: deepCopy(modifiedData),
          nbRows: selectedRows.length
        });

        // update the batch
        onDataModified({
          type: DATA_EDITOR_BATCH_UPDATE,
          rowSelectors: selectedRows,
          patch,
          editablePaths
        });

        // set the timeout to clear the cache
        const batchUpdateTimeout = setTimeout(() => {
          setBatchUpdateCache(null);
        }, 5000);

        // clear the timeout at unmount
        return () => {
          clearTimeout(batchUpdateTimeout);
        };
      },
      [selectedRows, toggleBatchModal]
    );
    const undoBatchUpdate = useCallback(() => {
      onDataModified({
        type: DATA_EDITOR_SET,
        modifiedData: batchUpdateCache.cache
      });
      setBatchUpdateCache(null);
    }, [batchUpdateCache]);

    // temp message to indicate that a batch edit has just been applied
    // with option to undo
    const batchUpdateMessage = batchUpdateCache && (
      <div className={classes.batchUpdateCacheMessage}>
        <b>{`${formatNumber(batchUpdateCache.nbRows)} row${
          batchUpdateCache.nbRows > 1 ? 's' : ''
        }`}</b>
        {` ${batchUpdateCache.nbRows > 1 ? 'have' : 'has'} been edited`}
        <button
          dataCy="undo-batch"
          className={classes.batchUpdateUndoBtn}
          onClick={undoBatchUpdate}
        >
          Undo
        </button>
      </div>
    );

    // submit button on summary view
    const submitBtn = (
      <Pushbutton
        primary
        onClick={submitData}
        disabled={submitDisabled}
        dataCy="submit"
      >
        {submitLabel}
      </Pushbutton>
    );

    const nbRowsModified = Object.keys(modifiedData).length;

    // base data editor (before confirmation step)
    if (!summaryData) {
      return (
        <>
          <div className={classes.dataEditor}>
            {batchUpdateMessage}
            <div className={classes.editorDataTable}>
              <Table data={tableData} config={table} update={tableUpdate} />
            </div>
          </div>
          <div className={classes.dataEditorFooter}>
            <div className={classes.dataEditorFooterLeft}>
              <Pushbutton onClick={onCancel} disabled={false} dataCy="cancel">
                {cancelLabel}
              </Pushbutton>
              {!!nbRowsModified && (
                <div className={classes.dataEditorUndoButton}>
                  <Pushbutton
                    onClick={clearModifiedData}
                    disabled={false}
                    warning
                    dataCy="undo"
                  >
                    undo all
                  </Pushbutton>
                </div>
              )}
              {traceInfoViewer && (
                <Pushtext
                  onClick={traceInfoViewer.toggleShowTraceInfoTray}
                  disabled={traceInfoViewer.showTraceInfoTray}
                  prefix={<Icon name="Bullets" size={20} />}
                  dataCy="toggle-trace-info"
                >
                  Show Trace Info
                </Pushtext>
              )}
            </div>
            <div className={classes.dataEditorFooterRight}>
              <div className={classes.dataEditorNbModifiedRows}>
                {`${nbRowsModified} rows changed.`}
              </div>
              <Pushbutton
                primary
                onClick={buildSummaryData}
                disabled={!nbRowsModified}
                dataCy="confirm"
              >
                confirm changes
              </Pushbutton>
            </div>
          </div>
          {showBatchModal && (
            <BatchEditModal
              data={data}
              modifiedData={modifiedData}
              selectedRows={selectedRows}
              tableConfig={table}
              onSubmit={submitBatchPatch}
              onCancel={toggleBatchModal}
            />
          )}
        </>
      );
    }

    // summary data editor (confirmation step)
    return (
      <>
        <div className={classes.dataEditor}>
          <div className={classes.summaryDataBoard}>
            <button
              className={classes.summaryDataGoBack}
              type="button"
              onClick={clearSummaryData}
              dataCy="go-back"
            >
              Go Back
            </button>
            <div className={classes.summaryDataSubmit}>
              <div className={classes.summaryDataCount}>{`${formatNumber(
                summaryData.length
              )} of ${formatNumber(
                tableData.length
              )} rows have been changed`}</div>
              {submitBtn}
            </div>
          </div>
          <div className={classes.editorDataTable}>
            <Table data={summaryData} config={table} update={tableUpdate} />
          </div>
        </div>
        <div className={classes.dataEditorFooter}>
          <div className={classes.dataEditorFooterLeft}>
            {traceInfoViewer ? (
              <Pushtext
                onClick={traceInfoViewer.toggleShowTraceInfoTray}
                disabled={traceInfoViewer.showTraceInfoTray}
                prefix={<Icon name="Bullets" size={20} />}
                dataCy="toggle-trace-info"
              >
                Show Trace Info
              </Pushtext>
            ) : (
              <div />
            )}
          </div>
          {addComments && (
            <div className={classes.dataEditorCommentsContainer}>
              <FieldTextAreaCompact
                value={comments}
                label="Add comments"
                onValueChange={updateComments}
                onFocus={focusComments}
                onBlur={blurComments}
                rows={commentsInputFocused ? 5 : 1}
                noResize
              />
            </div>
          )}
          <div className={classes.dataEditorFooterRight}>{submitBtn}</div>
        </div>
      </>
    );
  }
);
DataEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  cachedUpdatesKey: PropTypes.string,
  traceInfoViewer: PropTypes.shape({
    showTraceInfoTray: PropTypes.bool.isRequired,
    toggleShowTraceInfoTray: PropTypes.func.isRequired
  }),
  setAskBeforeLeaving: PropTypes.func.isRequired,
  setLeavingCallback: PropTypes.func.isRequired
};

export default compose(
  injectSheet(styles),
  withLeavingAlertContext
)(DataEditor);
