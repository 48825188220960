import * as React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import Item from './item';

import styles from './fileField.style';
import { KeyValue } from '@stratumn/atomic';

interface Props {
  classes: ClassNameMap<string>;
  formData: any[];
  schema: {
    title: string;
  };
  uiSchema;
  formContext: {
    downloadFile: () => void;
    downloadAllFiles: () => void;
  };
}

export const FileField: React.FC<Props> = ({
  classes,
  formData,
  schema,
  formContext,
  uiSchema
}) => {
  const { downloadFile } = formContext || {};
  const options = uiSchema['ui:options'];

  return (
    <div className={classes.keyValueContainer}>
      <KeyValue label={!options?.noLabel ? schema.title : undefined}>
        <ul className={classes.root}>
          {formData.map(file => (
            <li key={file.name} className={classes.file}>
              <Item file={file} downloadFile={downloadFile} />
            </li>
          ))}
        </ul>
      </KeyValue>
    </div>
  );
};

export default injectSheet(styles)(FileField);
