import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';

import injectSheet, { ClassNameMap } from 'react-jss';
import classNames from 'classnames';
import compose from 'lodash.flowright';

import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate } from 'slate-react';
import {
  createEditor,
  Editor,
  Transforms,
  Range,
  Text,
  Descendant
} from 'slate';
import { withHistory } from 'slate-history';
import { ErrorBoundary } from 'react-error-boundary';

import {
  toggleMark,
  withMentionsPlugin,
  insertMention,
  focusEditor,
  withLinks,
  getWithSubjectLine,
  isSubjectActive,
  defaultEmptyValue,
  parseValue,
  insertSubject,
  withIsEmptyAttribute,
  withImages
} from './utils';
import {
  getWithTables,
  isTableActive,
  handleTableKeydown,
  handleTableMouseDown
} from './tables';
import {
  Element,
  Leaf,
  Toolbar,
  UserList,
  SubjectAutoComplete
} from './components';
import { HOTKEYS } from './constants';

import styles from './richEditor.style';
import { Textinput } from '@stratumn/atomic';

interface RichEditorProps {
  className?: string;
  classes?: ClassNameMap;
  placeholder?: string;
  subjectPlaceholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: string | undefined) => void;
  onBlur?: () => void;
  value?: string;
  autoFocus?: boolean;
  withSubject?: boolean;
  withMentions?: boolean;
  workflowContext?: any;
  searchValue?: string;
  uploadImage?: any;
  downloadImage?: any;
}

const defaultProps: Partial<RichEditorProps> = {
  className: '',
  classes: {},
  placeholder: '',
  subjectPlaceholder: '',
  disabled: false,
  readOnly: false,
  autoFocus: false,
  workflowContext: {},
  searchValue: '',
  onChange: () => {},
  onBlur: () => {},
  uploadImage: () => {},
  downloadImage: () => {}
};

export type RichEditorRef = {
  clearEditor: () => void;
};
export const RichEditorInner = forwardRef(
  (
    {
      className = '',
      placeholder = '',
      subjectPlaceholder = '',
      disabled = false,
      onChange = () => {},
      onBlur = () => {},
      value,
      autoFocus = false,
      withSubject = false,
      withMentions = false,
      readOnly = false,
      classes = {},
      workflowContext,
      searchValue,
      uploadImage,
      downloadImage,
      ...props
    }: RichEditorProps,
    ref
  ) => {
    const renderElement = useCallback(
      elementProps => (
        <Element
          withSubject={withSubject}
          withMentions={withMentions}
          readOnly={readOnly}
          uploadImage={uploadImage}
          downloadImage={downloadImage}
          {...elementProps}
        />
      ),
      [withSubject, withMentions, readOnly, uploadImage, downloadImage]
    );
    const renderLeaf = useCallback(leafProps => <Leaf {...leafProps} />, []);

    const withTables = getWithTables(readOnly);
    const withSubjectLine = getWithSubjectLine(withSubject, readOnly);
    const editor = useMemo(
      () =>
        compose(
          withImages,
          withTables,
          withSubjectLine,
          withLinks,
          withMentionsPlugin,
          withReact,
          withHistory,
          withIsEmptyAttribute
        )(createEditor()),
      []
    );

    const [isFocused, setIsFocused] = useState(false);

    const [editorValue, setEditorValue] = useState<Descendant[]>(() =>
      parseValue(value)
    );
    const [target, setTarget] = useState<Range | null>();
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [subjectIndex, setSubjectIndex] = useState(0);
    const [subjectSearch, setSubjectSearch] = useState('');
    const users =
      withMentions &&
      workflowContext?.members
        ?.filter(c => c.name.toLowerCase().startsWith(search.toLowerCase()))
        .slice(0, 10);
    const subjectsList = workflowContext?.subjects;
    const [subjects, setSubjects] = useState(
      subjectSearch?.length
        ? subjectsList
            ?.filter(sub =>
              sub.toLowerCase().startsWith(subjectSearch.toLowerCase())
            )
            .slice(0, 10)
        : ''
    );

    useImperativeHandle(ref, () => ({
      clearEditor() {
        const point = { path: [0, 0], offset: 0 };
        editor.selection = { anchor: point, focus: point };
        editor.history = { redos: [], undos: [] };
        editor.children = JSON.parse(defaultEmptyValue);
        Editor.normalize(editor, { force: true });
      }
    }));

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleEditorMouseDown = e => {
      if (e.currentTarget === e.target) {
        e.preventDefault();
        focusEditor(editor);
      }
      handleTableMouseDown(e, editor);
    };

    const handleKeyDown = useCallback(
      event => {
        if (isTableActive(editor)) {
          handleTableKeydown(event, editor);
        }
        const { selection } = editor;
        const subjectIsActive = isSubjectActive(editor);
        setIsDirty(true);
        const subjectsAreActive =
          subjectIsActive &&
          (subjects?.length || subjectSearch?.length) &&
          withSubject;
        // Subject Suggestions
        if (event.key === ' ' && event.ctrlKey && withSubject)
          setSubjects(subjectsList.splice(0, 10));
        // Key event actions for mentions and subjects
        const keyEventActions = data => {
          const dataIndex = target ? index : subjectIndex;
          const setDataIndex = dataIndex =>
            target ? setIndex(dataIndex) : setSubjectIndex(dataIndex);
          switch (event.key) {
            case 'ArrowDown': {
              event.preventDefault();
              const prevIndex =
                dataIndex >= data?.length - 1 ? 0 : dataIndex + 1;
              setDataIndex(prevIndex);
              break;
            }
            case 'ArrowUp': {
              event.preventDefault();
              const nextIndex =
                dataIndex <= 0 ? data?.length - 1 : dataIndex - 1;
              setDataIndex(nextIndex);
              break;
            }
            case 'Tab':
            case 'Enter': {
              if (!target && !data) return;
              if (data[index]) {
                event.preventDefault();
                if (target) {
                  Transforms.select(editor, target);
                  insertMention(editor, data[index]);
                  setTarget(null);
                } else {
                  insertSubject(editor, data[subjectIndex]);
                }
              }
              break;
            }
            case 'Escape':
              event.preventDefault();
              if (target) {
                setTarget(null);
              } else {
                setSubjectSearch('');
              }
              break;
            default:
              break;
          }
        };
        // triggers key events based on mentions or subjects
        if (withMentions && target) {
          keyEventActions(users);
        } else if (subjectsAreActive) {
          keyEventActions(subjects);
        }

        // Right/left events to move inside/outside of a link element
        if (selection && Range.isCollapsed(selection)) {
          const { nativeEvent } = event;
          if (isHotkey('left', nativeEvent)) {
            event.preventDefault();
            Transforms.move(editor, { unit: 'offset', reverse: true });
            return;
          }
          if (isHotkey('right', nativeEvent)) {
            event.preventDefault();
            Transforms.move(editor, { unit: 'offset' });
            return;
          }
        }

        // Hotkeys shortcuts
        if (!subjectIsActive) {
          Object.keys(HOTKEYS).forEach(hotkey => {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          });
        }
        // Line break
        if (isHotkey('shift+Enter', event)) {
          event.preventDefault();
          if (!subjectIsActive) {
            editor.insertText('\n');
          }
        }
      },
      [
        editor,
        index,
        subjectIndex,
        subjectSearch?.length,
        subjects,
        subjectsList,
        target,
        users,
        withMentions,
        withSubject
      ]
    );
    const handleChange = value => {
      const { selection } = editor;
      if (
        withSubject &&
        value.find(val => val.type === 'subject')?.children[0]
      ) {
        setSubjectSearch(
          value.find(val => val.type === 'subject')?.children[0]?.text
        );
        setSubjectIndex(0);
      }
      if (users?.length > 0 && selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);

        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          setIndex(0);
          return;
        }
      }

      setTarget(null);
      setEditorValue(value);
    };
    const decorate = useCallback(
      ([node, path]) => {
        const ranges: Range[] = [];

        if (searchValue && Text.isText(node)) {
          const { text } = node;
          const parts = text.toLowerCase().split(searchValue.toLowerCase());
          let offset = 0;

          parts.forEach((part, i) => {
            if (i !== 0) {
              ranges.push({
                anchor: { path, offset: offset - searchValue.length },
                focus: { path, offset },
                highlight: true
              });
            }

            offset = offset + part.length + searchValue.length;
          });
        }
        return ranges;
      },
      [searchValue]
    );

    /**
     * Effect to:
     * - Force normalisation of the value on first render
     * - Allow a falsy value (undefined or empty string) to be updated throug the props so the field is reset
     */
    const initiated = useRef(false);
    useEffect(() => {
      if (!initiated.current || readOnly || !value) {
        initiated.current = true;
        // Parse string value to get slate format
        const parsedValue = parseValue(value);
        // Effect to force content normalization
        editor.children = parsedValue;
        Editor.normalize(editor, { force: true });
        // We set the internal value so that the rendering can take over from here
        setEditorValue(editor.children);
      }
    }, [value, readOnly, editor]);

    useEffect(() => {
      setSubjects(
        subjectSearch?.length
          ? subjectsList
              ?.filter(sub =>
                sub.toLowerCase().startsWith(subjectSearch.toLowerCase())
              )
              .slice(0, 10)
          : ''
      );
    }, [subjectSearch, subjectsList]);

    useEffect(() => {
      // Set a timeout to update debounced value
      const handler = setTimeout(() => {
        if (isDirty) {
          onChange(JSON.stringify(editorValue));
        }
      }, 200);
      // Cleanup the timeout if `editorValue` changes before the timeout expires
      return () => {
        clearTimeout(handler);
      };
    }, [editorValue, isDirty]);

    useEffect(() => {
      if (autoFocus) {
        focusEditor(editor);
      }
    }, [autoFocus, editor]);
    return (
      <div
        className={classes.root}
        data-is-disabled={disabled}
        data-with-subject={withSubject}
        data-cy="atomic-rich-editor"
      >
        <Slate
          editor={editor}
          initialValue={editorValue}
          onChange={handleChange}
        >
          {!readOnly && <Toolbar disabled={disabled || !isFocused} />}
          <div
            className={classNames({
              [classes.editorWrapper]: true,
              [classes.editorWrapperReadOnly]: readOnly,
              [className]: !!className
            })}
            data-has-focus={isFocused}
            onMouseDown={handleEditorMouseDown}
          >
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              spellCheck
              autoFocus={autoFocus}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...props}
              readOnly={readOnly || disabled}
              decorate={decorate}
            />
          </div>
          {users?.length > 0 && (
            <UserList
              users={users}
              target={target}
              index={index}
              search={search}
            />
          )}
          <SubjectAutoComplete index={subjectIndex} subjects={subjects} />
        </Slate>
      </div>
    );
  }
);

const RichEditorWithStyles = injectSheet(styles)(RichEditorInner);
RichEditorInner.defaultProps = defaultProps;

const RichEditor = forwardRef((props: RichEditorProps, ref) => (
  <ErrorBoundary fallback={<Textinput value={props.value || ''} {...props} />}>
    <RichEditorWithStyles {...props} innerRef={ref} />
  </ErrorBoundary>
));
RichEditor.defaultProps = defaultProps;

// Add getStringFromValueFromRichTextEditor utils to RichEditor
export default RichEditor;
