import React, { useMemo, Fragment } from 'react';
import compose from 'lodash.flowright';
import { Link, withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';

import { history } from 'components/root';
import { withUser } from 'contexts';
import { deepGet } from 'utils';
import { logout } from 'client/account';

import { ROUTE_WORKFLOW_DASHBOARD, ROUTE_LOGIN } from 'constant/routes';
import { ANALYTICS_URL, ACCOUNT_URL } from 'constant/api';
import { env } from 'constant/env';

import { Navbar as AtomicNavbar, BreadCrumbs } from '@stratumn/atomic';
import BottomLink from './bottomLink';

import styles from './header.styles';

interface NavbarProps {
  classes: any;
  user: User;
  config?: Config;
}

export const Navbar: React.NamedExoticComponent<NavbarProps> = React.memo(
  ({
    classes,
    user: { me },
    config: { loading, bottomLevel } = { loading: false, bottomLevel: null }
  }) => {
    const renderSubNavbarLeftSide: JSX.Element | null = useMemo(() => {
      if (!deepGet(bottomLevel, 'infoContext.links')) return null;
      if (!bottomLevel?.infoContext?.links) return null;
      if (loading) return null;

      const items = [
        {
          icon: 'House',
          label: 'Home',
          path: ROUTE_WORKFLOW_DASHBOARD
        },
        ...bottomLevel.infoContext.links
      ];

      return (
        <BreadCrumbs
          items={items}
          linkComponent={Link}
          separatorIcon="ArrowSimpleRight"
        />
      );
    }, [!loading && deepGet(bottomLevel, 'infoContext.links')]);

    const renderSubNavbarRightSide = useMemo(() => {
      if (!bottomLevel || !bottomLevel.actions) return null;

      const { viewToggle, links } = bottomLevel.actions;

      // Toggle views between 2 or more items
      // e.g: switch between overview and kanban
      const displayViewToggle = viewToggle && (
        <div className={classes.viewToggleWrapper}>
          <span className={classes.actionsLabel}>View Type:</span>
          <div className={classes.viewToggle}>
            {viewToggle.map(item => (
              <Fragment key={item.label}>
                <BottomLink
                  label={item.label}
                  path={item.path}
                  active={item.active}
                  type="viewToggle"
                />
              </Fragment>
            ))}
          </div>
        </div>
      );

      const displayLinks =
        links &&
        links.map(
          link =>
            link && (
              <BottomLink
                key={link.label}
                label={link.label}
                onClick={link.onClick}
                type="standard"
                icon={link.icon}
              />
            )
        );

      return (
        <div className={classes.actionsWrapper}>
          {displayViewToggle}
          {displayLinks}
        </div>
      );
    }, [bottomLevel && bottomLevel.actions]);

    const signOut = async () => {
      await logout();
      history.push(ROUTE_LOGIN);
    };

    const currentPage = {
      name: 'Trace',
      url: ROUTE_WORKFLOW_DASHBOARD
    };

    const links = [
      currentPage,
      {
        name: 'Analytics',
        url: ANALYTICS_URL
      }
    ];

    const menuLinks = [
      {
        link: {
          name: 'Account',
          url: ACCOUNT_URL
        },
        iconName: 'Profile'
      }
    ];

    return (
      <AtomicNavbar
        currentPage={currentPage}
        loading={loading}
        signOut={() => signOut()}
        links={links}
        menuLinks={menuLinks}
        subNavbarPrimary={
          (bottomLevel && bottomLevel.infoContext && renderSubNavbarLeftSide) ||
          null
        }
        subNavbarSecondary={
          (bottomLevel &&
            bottomLevel.workflowPage &&
            renderSubNavbarRightSide) ||
          null
        }
        userAvatarPath={loading ? '' : me && me.avatar}
        environmentName={env.VITE_ENVIRONMENT}
      />
    );
  }
);

interface User {
  me: any;
}

interface Config {
  loading?: boolean;
  bottomLevel?: BottomLevel;
}

interface BottomLevel {
  workflowPage?: boolean;
  infoContext?: InfoContext;
  actions?: Actions;
}

interface InfoContext {
  links?: InfoContextLinks[];
}

interface InfoContextLinks {
  icon?: string;
  label?: string;
  path?: string;
}

interface Actions {
  viewToggle?: ViewToggle[];
  links?: ActionLink[];
}

interface ViewToggle {
  label?: string;
  path?: string;
  active?: boolean;
}

interface ActionLink {
  icon?: string;
  label?: string;
  onClick?: () => void;
}

const NavbarComponent = compose(withUser)(
  withRouter(injectSheet(styles)(Navbar))
);
export default NavbarComponent;
