import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Dropdown,
  OptionDrop
} from '@stratumn/atomic';
import { withUser } from 'contexts';

import styles from './newLink.style';

export class AnswerGroupDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    selectedGroup: PropTypes.string,
    setSelectedGroup: PropTypes.func.isRequired,
    toggleSignDialog: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedGroup: null
  };

  render() {
    const {
      open,
      groups,
      selectedGroup,
      setSelectedGroup,
      onClose,
      toggleSignDialog
    } = this.props;

    if (!open) return null;

    const handleChangeGroup = e => {
      setSelectedGroup(e.target.value);
    };

    const handleClose = () => {
      onClose();
      toggleSignDialog();
    };
    return (
      <Modal
        handleCollapse={onClose}
        closeButtonLabel="Cancel"
        title="Select your group"
      >
        <ModalContent>
          You would like to save your answer as a member of:
          <Dropdown
            label="Your groups"
            hideLabel
            onValueChange={handleChangeGroup}
          >
            {groups.map(group => (
              <OptionDrop
                key={group.rowId}
                label={group.name}
                value={group.rowId}
                selected={selectedGroup === group.rowId}
              />
            ))}
          </Dropdown>
        </ModalContent>
        <ModalActions
          adverseAction={<Pushbutton onClick={onClose}>Cancel</Pushbutton>}
        >
          <Pushbutton
            primary
            onClick={handleClose}
            disabled={!selectedGroup}
            dataCy="sign"
          >
            Submit
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  }
}

export default compose(withUser, injectSheet(styles))(AnswerGroupDialog);
