export default theme => ({
  fieldWrapper: {
    paddingTop: 14
  },
  fieldWrapperWithBorder: {
    borderLeft: `2px solid ${theme.grey7}`,
    paddingLeft: 16,
    '&:hover': {
      borderColor: theme.blue900
    }
  },
  text: {
    color: theme.grey1,
    fontSize: 14,
    weight: 600,
    marginBlock: 10
  }
});
