import moment from 'moment';
import TextWidget from '../textWidget';

export type DateWidgetProps = {
  value: string;
  label?: string;
  options?: {
    dateFormat?: string;
  };
};
const DEFAULT_DISPLAY_FORMAT = 'DD/MM/YYYY' as const;
export const DateWidget = ({
  value = '',
  options,
  ...props
}: DateWidgetProps) => {
  if (!value) return null;

  const date = moment(value);

  const dateFormat = options?.dateFormat || DEFAULT_DISPLAY_FORMAT;

  return <TextWidget value={date.format(dateFormat)} {...props} />;
};

export default DateWidget;
