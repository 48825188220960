export default () => ({
  radioBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '&[data-is-inline=true]': {
      flexDirection: 'row'
    }
  },
  radioBtn: {
    padding: 7
  }
});
