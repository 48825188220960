import { useEffect } from 'react';

import { Oops } from '@stratumn/atomic';
import { history } from '@/components/root';

import { ROUTE_WORKFLOW_DASHBOARD } from '@/constant/routes';

type Props = {
  description?: string;
  label?: string;
};

export const PageNotFound = ({
  description = "Sorry, we can't find the page you're looking for.",
  label = 'take me home'
}: Props) => {
  const goTo = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  useEffect(() => {
    document.title = 'Oops! Page not found - Trace';
  }, []);

  return <Oops onHomeClick={goTo} description={description} label={label} />;
};
