import { memo, useMemo } from 'react';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';
import { Check } from '@stratumn/atomic';

import { cn } from '@/shadcn/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/shadcn/tooltip';

export const HEADER_CHECKBOX = 'CHECKBOX';
export const HEADER_CHECKBOX_INDETERMINATE = 'CHECKBOX_INDETERMINATE';

type Props = {
  className?: string;
  label?: string;
  icon: string;
  iconIfClicked?: string;
  clicked: boolean;
  onClick?: () => void;
  tooltip?: string;
};

export const TableHeaderElement = memo(
  ({
    className,
    label,
    icon,
    iconIfClicked,
    clicked,
    onClick = () => {},
    tooltip
  }: Props) => {
    const isCheckbox = useMemo(() => {
      const uCaseIcon = icon.toUpperCase();
      return (
        uCaseIcon === HEADER_CHECKBOX ||
        uCaseIcon === HEADER_CHECKBOX_INDETERMINATE
      );
    }, [icon]);

    const getIconComponent = () => {
      if (isCheckbox) {
        return (
          <Check
            label=""
            checked={clicked}
            showLabel={false}
            handleChange={onClick}
          />
        );
      }
      return (
        <DynamicIcon
          icon={clicked && iconIfClicked ? iconIfClicked : icon}
          className="block size-[1.375rem] shrink-0"
        />
      );
    };

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className={cn(
                'text-accent-foreground hover:bg-accent hover:text-accent-foreground hover:outline-accent flex cursor-pointer flex-row flex-nowrap items-center rounded-sm px-2 py-1 text-xs outline outline-transparent select-none',
                clicked && 'text-primary',
                isCheckbox && 'mr-2 bg-transparent! p-0',
                className
              )}
              data-cy={`table-header-${(label || icon)
                .replace(' ', '-')
                .toLowerCase()}`}
              onClick={onClick}
            >
              <span>{getIconComponent()}</span>
              {label && (
                <span className="block grow pl-2 text-xs">{label}</span>
              )}
            </div>
          </TooltipTrigger>
          {tooltip && (
            <TooltipContent side="left" variant="contrast">
              {tooltip}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    );
  }
);
