import { createContext, PropsWithChildren, Reducer, useReducer } from 'react';
import {
  SearchActionType,
  SearchContextType,
  SearchState
} from './search.type';

export const SearchContext = createContext<SearchContextType | null>(null);
const SearchContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer<Reducer<SearchState, SearchActionType>>(
    // @ts-expect-error skill issue from me, sorry
    (prevState, action) => {
      switch (action.type) {
        case 'addString':
          return { ...prevState, string: action.payload };
        case 'setFilter':
          return {
            ...prevState,
            filters: {
              ...prevState.filters,
              [action.payload.name]: action.payload.value
            }
          };
        case 'removeFilter': {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [action.payload]: _removedFilter, ...restFilters } = (
            prevState as Record<string, any>
          ).filters;
          return { ...prevState, filters: restFilters };
        }
        case 'setOrderBy':
          return {
            ...prevState,
            orderBy: { param: action.payload.name, order: action.payload.order }
          };
        case 'removeOrderBy':
          return { ...prevState, orderBy: { param: '' } };
        default:
          throw new Error();
      }
    },
    {
      string: '',
      filters: {},
      orderBy: {
        param: '',
        order: 'desc'
      }
    }
  );
  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
