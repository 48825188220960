import { memo } from 'react';

import { cn } from '@/shadcn/lib/utils';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/shadcn/tooltip';
import { SortConfig, TableColumn } from '../table.type';
import { onSortIconClick } from './colHeaders.utils';
import { SORT_ASCENDING } from '@/utils/sorting';
import { SortSetup } from '@/utils/sorting/sorting.types';
import { SortIcon } from './sortIcon.colHeaders';

type Props = {
  height: number;
  selectBoxWidth?: number;
  columns: TableColumn[];
  sortSetup?: SortSetup;
  setSortSetup: (sortSetup: SortSetup | null, sortConfig: SortConfig) => void;
};

export const ColHeaders = memo(
  ({ height, selectBoxWidth = 0, columns, sortSetup, setSortSetup }: Props) => {
    const sortThisColumn = colConfig => {
      onSortIconClick(colConfig, sortSetup, setSortSetup);
    };
    return (
      <div className="border-border bg-card text-muted-foreground flex w-fit flex-row flex-nowrap border-b-2 text-left text-[10px] tracking-wide">
        {selectBoxWidth > 0 && (
          <div
            className="border-border border-r"
            style={{
              width: selectBoxWidth,
              height
            }}
          />
        )}
        {columns.map((colConfig, index) => {
          const isSorted = sortSetup && sortSetup.key === colConfig.key;
          return (
            <div
              key={index + (colConfig?.def?.header ?? '')}
              className={cn(
                'border-border box-border flex flex-row flex-nowrap items-center justify-between border-r py-[4px] pl-2',
                isSorted && 'bg-accent text-accent-foreground!'
              )}
              style={{
                width: colConfig.width,
                height
              }}
            >
              <div className="text-muted-foreground truncate uppercase">
                {colConfig.def?.header}
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div
                      className="hover:bg-accent hover:text-foreground mr-1 flex aspect-square h-full cursor-pointer flex-row flex-nowrap items-center rounded-md px-1"
                      onClick={() => sortThisColumn(colConfig)}
                    >
                      <SortIcon
                        isSorted={isSorted}
                        direction={sortSetup?.direction}
                        size={15}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent alignOffset={10}>
                    {isSorted ? (
                      sortSetup?.direction === SORT_ASCENDING ? (
                        <p>
                          Click to sort in <b>descending</b> order
                        </p>
                      ) : (
                        <p>
                          Click to <b>reset</b> sorting
                        </p>
                      )
                    ) : (
                      <p>
                        Click to sort in <b>ascending</b> order
                      </p>
                    )}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        })}
      </div>
    );
  }
);
