import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';

import RichEditor from 'components/ui/richEditor';

import SlateReaderWidget from 'components/forms/components/form/reader/widgets/slateReaderWidget';

import withInlineValidation from 'components/forms/wrappers/withInlineValidation';

import { isLockedWidget } from 'components/forms/utils/links';

import styles from './slate.style';

type Props = {
  classes: ClassNameMap<string>;
  options: {
    withSubject: boolean;
    withMentions: boolean;
  };
} & Pick<
  WidgetProps,
  'id' | 'value' | 'onChange' | 'formContext' | 'placeholder' | 'options'
>;

export const SlateWidget = ({
  classes,
  id,
  value,
  formContext,
  placeholder,
  onChange,
  options = {
    withSubject: true,
    withMentions: false
  }
}: Props) => {
  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);
  // if it is locked redirect to slate reader widget
  if (isLocked)
    return (
      <div className={classes.readerWrapper}>
        <SlateReaderWidget
          downloadImage={formContext.downloadImage}
          value={value}
          schema={{}}
        />
      </div>
    );

  return (
    <RichEditor
      subjectPlaceholder={placeholder || 'Subject'}
      placeholder={placeholder || 'Enter your comment here.'}
      value={value}
      onChange={onChange}
      withSubject={options.withSubject}
      withMentions={options.withMentions}
      workflowContext={formContext.workflowContext}
      uploadImage={formContext.uploadImage}
      downloadImage={formContext.downloadImage}
    />
  );
};

export default withInlineValidation(injectSheet(styles)(SlateWidget));
