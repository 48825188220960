// data parsing and reporting constants
export const DATA_PARSING_STATUS_DONE = 'done' as const;
export const DATA_PARSING_STATUS_ERROR = 'error' as const;
export const DATA_PARSING_STATUS_PARSING = 'parsing' as const;
export const DATA_PARSING_STATUS_AGGREGATING = 'aggregating' as const;

// csv reporting steps
export const PARSING_STEP_OPENING = 'Opening' as const;
export const PARSING_STEP_IMPORTING = 'Importing' as const;

// data aggregation reporting steps
export const AGGREGATION_STEP_INDEXING = 'Indexing' as const;
export const AGGREGATION_STEP_AGGREGATING = 'Aggregating' as const;
