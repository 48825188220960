import { memo } from 'react';

import { getByPath, getNumberConditionalStyle, formatNumber } from 'utils';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider
} from '@/shadcn/tooltip';
import { ProgressView } from './progress.type';
import { NumberView } from '../number/number.type';
import { WidgetData } from '../../widget.type';

// % number displayed as a progrees bar
// default default conditional colors
const defaultProgressConditionalStyle = [
  { style: { backgroundColor: '#E8544D' } },
  {
    condition: { minimum: 0.25 },
    style: { backgroundColor: '#E4E26D' }
  },
  {
    condition: { minimum: 0.5 },
    style: { backgroundColor: '#8ED376' }
  },
  {
    condition: { minimum: 0.75 },
    style: { backgroundColor: '#16AA00' }
  }
];

type Props = {
  view: ProgressView;
  data: WidgetData;
};

const ProgressViewImpl = memo(({ view, data }: Props) => {
  const { path, denominatorPath } = view;

  let percentage = getByPath<number>(data, path) ?? 0;
  // check if scaling is needed
  if (denominatorPath !== undefined) {
    const denominatorValue = Number(getByPath(data, denominatorPath));
    if (!Number.isNaN(denominatorValue) && denominatorValue !== 0) {
      percentage /= denominatorValue;
    }
  }

  const appliedStyle = getNumberConditionalStyle(
    percentage,
    defaultProgressConditionalStyle
  );
  const percentageWidth = `${percentage * 100}%`;
  const voidWidth = `${(1.0 - percentage) * 100}%`;

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div className="flex size-full items-center">
            <div className="bg-muted-foreground/10 flex h-2 w-full flex-row flex-nowrap overflow-hidden rounded-full">
              <div
                className="h-full rounded-full"
                style={{
                  width: percentageWidth,
                  ...appliedStyle
                }}
              />
              <div className="h-full w-0" />
              <div
                className="h-full"
                style={{
                  width: voidWidth
                }}
              />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent variant="contrast" side="left">
          {formatNumber(percentage, {
            options: { style: 'percent', minimumFractionDigits: 0 }
          })}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

// sort defaults to number on path
const getSortConfig = (view: ProgressView): NumberView => ({
  type: 'number',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: ProgressView) => ({
  type: 'text',
  path: view.path,
  placeholder: 'Eg >0.5, 0.25<<0.75',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = (view: ProgressView) => {
  const { path, denominatorPath } = view;
  return (data: WidgetData) => {
    let percentage = getByPath<number>(data, path) ?? 0;
    // check if scaling is needed
    if (denominatorPath !== undefined) {
      const denominatorValue = Number(getByPath(data, denominatorPath));
      if (!Number.isNaN(denominatorValue) && denominatorValue !== 0) {
        percentage /= denominatorValue;
      }
    }
    return percentage;
  };
};

export const progressViewProvider = {
  component: ProgressViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
