export default theme => ({
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.grey3,
    marginBottom: 3
  },
  value: {
    display: 'flex',
    fontSize: 14,
    color: theme.grey1
  }
});
