import { WidgetProps } from '@rjsf/utils';
import { ErrorMessage } from '@stratumn/atomic';

type Props = {
  label: string;
  rawErrors: string[];
} & Pick<WidgetProps, 'formContext' | 'schema'>;

/**
 * HOC to bring inline error validation to our widgets.
 * @function withInlineValidation
 * @param WrappedComponent - widget
 * @returns - wrapped component with onBlur event and errorMessage
 */
const withInlineValidation = WrappedComponent => (props: Props) => {
  const {
    label,
    schema: { title },
    formContext: { blurredFields, onErrorTriggered, onBlur },
    rawErrors
  } = props;

  const handleBlur = () =>
    label && setTimeout(() => onBlur(label.replace(' ', '_').toLowerCase()), 0);

  // This sets the border to red and this cannot be done through the formTemplates for the field.
  const invalid =
    rawErrors &&
    rawErrors.length > 0 &&
    ((label && blurredFields.includes(label.toLowerCase().replace(' ', '_'))) ||
      onErrorTriggered);

  return (
    <>
      <div onBlur={handleBlur}>
        <WrappedComponent invalid={invalid} {...props} />
      </div>
      {invalid && <ErrorMessage text={`${label || title} ${rawErrors[0]}`} />}
    </>
  );
};

export default withInlineValidation;
