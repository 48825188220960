// function to check if an element is at least partially hidden in its container
const isElementHidden = (elementSize, containerSize) =>
  elementSize.right > containerSize.right ||
  elementSize.bottom > containerSize.bottom;

// function to truncate the content of an element
// by setting intermediate children to display:'none' until the last element is visible
// (which is assumed to be a placeholder)
// returns the number of hidden items
export default containerEl => {
  if (!containerEl) return 0;

  // check the available size of the container
  const containerSize = containerEl.getBoundingClientRect();

  // reset the children initial displays to perform the backward iteration again
  const childrenEls = Array.from(containerEl.children);
  const [infoEl] = childrenEls.splice(-1, 1); // remove info container from this array
  childrenEls.forEach(childEl => {
    childEl.style.removeProperty('display');
  });

  let nbHiddenChildren = 0;
  const lastChildSize =
    childrenEls[childrenEls.length - 1].getBoundingClientRect();
  // if the very last child is fully displayed there is no need for an ellipsis info anyway
  if (!isElementHidden(lastChildSize, containerSize)) {
    infoEl.style.setProperty('display', 'none');
  } else {
    // tooltip info will need to be displayed
    infoEl.style.removeProperty('display');

    // as long as the info element is still not visible, remove the preceding child
    for (let idx = childrenEls.length - 1; idx >= 0; idx -= 1) {
      const infoSize = infoEl.getBoundingClientRect();
      const childEl = childrenEls[idx];
      if (isElementHidden(infoSize, containerSize)) {
        childEl.style.setProperty('display', 'none');
        nbHiddenChildren += 1;
      } else {
        break;
      }
    }
  }

  return nbHiddenChildren;
};
