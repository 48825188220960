import React from 'react';
import PropType from 'prop-types';

import { Icon, icons as atomicIcons } from '@stratumn/atomic';
import { icons as lucideIcons, LucideIcon } from 'lucide-react';
import StratumnIcons from '@stratumn/icons/lib/DynamicIcon';

type Props = {
  icon: string;
  size: number;
};

export const DynamicIcon = ({ icon = 'Doc', size = 35 }: Props) => {
  if (icon?.startsWith(LUCIDE_PREFIX)) {
    const iconName = icon.replace(LUCIDE_PREFIX, '');

    const LucideIconGenerated = lucideIcons[iconName] as LucideIcon | undefined;
    if (LucideIconGenerated) {
      const iconSize = size * 0.9; // Lucide icons are bigger than stratumn ones.
      return <LucideIconGenerated size={iconSize} />;
    }
  }

  // Atomic icons
  if (atomicIcons[icon]) {
    return <Icon name={icon} size={size} />;
  }
  // @stratumn/icons
  return <StratumnIcons icon={icon} style={{ width: size, height: size }} />;
};

const LUCIDE_PREFIX = 'lucide_' as const;

DynamicIcon.propTypes = {
  icon: PropType.string,
  size: PropType.number
};

export default React.memo(DynamicIcon);
