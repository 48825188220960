import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import numeral from 'numeral';
import { WidgetProps } from '@rjsf/utils';

import styles from './numericWidget.style';

type Props = {
  classes: ClassNameMap<string>;
} & Pick<WidgetProps, 'value'>;

const NumericWidget: React.FC<Props> = ({ classes, value = '' }) => {
  const error = value && value < 0;

  return (
    <div className={classes.root} data-is-error={error}>
      {value !== null ? numeral(value).format('0,0.00') : ''}
    </div>
  );
};

export default injectSheet(styles)(NumericWidget);
