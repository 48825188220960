import moment from 'moment';

// from Date to yyyy-mm-dd
export const convertToYMD = (date: moment.MomentInput) => {
  const newDate = moment(date).format('YYYY-MM-DD');
  return newDate;
};

// from yyyy-mm-dd to Date
export const convertToDate = (date: moment.MomentInput) => {
  const convertedDate = moment(date).format('YYYY-MM-DD');
  const newDate = moment(convertedDate, moment.defaultFormat).toDate();
  return newDate;
};

export const getTodayDateStr = () => {
  const today = moment.utc();
  return today.format('YYYY-MM-DD');
};
