import { useMemo } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import { graphql } from '@apollo/client/react/hoc';

import TraceIconSpinner from 'components/ui/traceIconSpinner';
import { checkCommentExists } from 'utils';

import SegmentDetail from '../segmentDetail';
import SegmentUserDetail from '../segmentUserDetail';
import Answers from './answers';

const LINK_CREATED_BY_ACCOUNT_QUERY = gql`
  query linkCreatedByAccountQuery($linkId: ID!) {
    link(id: $linkId) {
      createdByAccount {
        rowId
        signingKey {
          rowId
          publicKey
        }
        entity {
          ... on Account_User {
            rowId
            name
            avatar
            email
            phone
          }
          ... on Account_Bot {
            rowId
            name
            avatar
          }
        }
      }
    }
  }
`;

export const SegmentInfo = ({
  activeSegment,
  traceId,
  workflowContext,
  linkCreatedByAccountQuery,
  links,
  answers,
  groups,
  nextActions,
  refetchTraceQuery
}) => {
  const linkCreatedByAccount =
    linkCreatedByAccountQuery?.link?.createdByAccount;

  if (linkCreatedByAccountQuery?.loading) {
    return <TraceIconSpinner className="mx-auto my-20 size-10" />;
  }

  const isAnswerable = useMemo(() => {
    // Get in the actions' definition the fields that are considered as answerable comments
    const answerableComments = workflowContext.actions?.find(
      a => a.key === activeSegment.actionKey
    )?.answerableComments;
    // To define if a link is answerable
    // One of the key should be present in the link's data AND the data should be a valid comment
    return (
      activeSegment.data &&
      !!Object.entries(activeSegment.data)?.find(
        ([key, value]) =>
          answerableComments?.includes(key) && checkCommentExists(value)
      )
    );
  }, [activeSegment, workflowContext]);

  return (
    activeSegment && (
      <>
        <SegmentUserDetail
          link={activeSegment}
          linkCreatedByAccount={linkCreatedByAccount}
        />
        <div className="flex flex-col pt-5">
          <SegmentDetail
            traceId={traceId}
            link={activeSegment}
            linkCreatedByAccount={linkCreatedByAccount}
            workflowContext={workflowContext}
          />
        </div>
        {isAnswerable && (
          <Answers
            action={activeSegment?.action}
            workflowContext={workflowContext}
            traces={links}
            traceId={traceId}
            link={activeSegment}
            answers={answers}
            groups={groups}
            linkCreatedByAccount={linkCreatedByAccount}
            nextActions={nextActions}
            refetchTraceQuery={refetchTraceQuery}
          />
        )}
      </>
    )
  );
};

SegmentInfo.propTypes = {
  activeSegment: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired,
  workflowContext: PropTypes.object.isRequired,
  linkCreatedByAccountQuery: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  nextActions: PropTypes.array.isRequired,
  refetchTraceQuery: PropTypes.func.isRequired
};

export default compose(
  graphql(LINK_CREATED_BY_ACCOUNT_QUERY, {
    name: 'linkCreatedByAccountQuery',
    options: ({ activeSegment }) => ({
      variables: { linkId: activeSegment.id }
    })
  })
)(SegmentInfo);
