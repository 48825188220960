export const base64ToFile = (base64String, fileName) => {
  const mimeType = base64String.split(':')[1].split(';')[0];

  const file = new File(
    [
      new Uint8Array(
        atob(base64String.split(',')[1])
          .split('')
          .map(char => char.charCodeAt(0))
      )
    ],
    fileName,
    { type: mimeType }
  );

  return file;
};
