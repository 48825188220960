import { memo } from 'react';

import { getByPath } from '@/utils/widgets';

import { LabelsView } from './labels.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: LabelsView;
  data: WidgetData;
};

const LabelsViewImpl = memo(({ view, data }: Props) => {
  const { path } = view;

  const labels = getByPath<string | string[]>(data, path);
  if (!labels) return null;

  const finalLabels = Array.isArray(labels) ? labels : [labels];

  return (
    <div className="scrollbar-thin flex h-full w-full flex-row flex-nowrap items-center gap-px overflow-x-auto overflow-y-hidden">
      {finalLabels.map((label, index) => (
        <div
          key={index}
          className="border-border bg-accent text-accent-foreground m-px h-[18px] shrink-0 truncate rounded-sm border-[0.5px] px-[5px] py-[2px] text-[11px] leading-[14px]"
        >
          {label}
        </div>
      ))}
    </div>
  );
});

// sort defaults to length on path
const getSortConfig = (view: LabelsView) => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: LabelsView) => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at path
const getStringifyFunction = (view: LabelsView) => {
  const { path } = view;
  return (data: WidgetData) => {
    const labels = getByPath(data, path);
    if (!labels) return '';

    const finalLabels = Array.isArray(labels) ? labels : [labels];

    return finalLabels.join(', ');
  };
};

export const labelsViewProvider = {
  component: LabelsViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
