import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Modal, ModalContent } from '@stratumn/atomic';
import { DocumentSigned } from '@stratumn/icons';

import Table from 'components/ui/table';
import { setAtPath, formatNumber } from 'utils';

// data editor reader component
const DataEditorReader = React.memo(({ classes, dataEditor, data }) => {
  // state displaying the table data modal
  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = useCallback(
    () => setShowModal(!showModal),
    [showModal]
  );

  const {
    modifiedDataKey = 'changes',
    initialDataKey = 'rows',
    modifiedDataTitle = 'Edited Data',
    table: tableConfig
  } = dataEditor;

  const { [modifiedDataKey]: modifiedData, [initialDataKey]: initialData } =
    data;

  // if initial data has not been saved with the form just display empty initial data with the correct indexing
  const dataSelectorPath = tableConfig.dataSelectorPath || 'rowId';
  const tableData = useMemo(
    () =>
      initialData ||
      Object.keys(modifiedData).map(key => {
        const rowData = {};
        setAtPath(rowData, dataSelectorPath, key);
        return rowData;
      }),
    [initialData, modifiedData, dataSelectorPath]
  );

  const tableUpdate = useMemo(
    () => ({
      edit: {
        modifiedData,
        allowDisplayDiffs: true
      }
    }),
    [modifiedData]
  );

  const nbChangesMsg = `${formatNumber(
    tableData.length
  )} rows have been modified.`;

  return (
    <>
      <div>
        <div className={classes.readerItemTitle}>{modifiedDataTitle}</div>
        <div className={classes.readerInterfaceContainer}>
          <div
            className={classnames(
              classes.readerInterfaceItem,
              classes.readerInterface
            )}
            onClick={toggleShowModal}
          >
            <div className={classes.readerInterfaceLink}>
              <DocumentSigned className={classes.readerInterfaceIcon} />
              <div>
                <div className={classes.readerInterfaceInfoHeader}>View</div>
                <div className={classes.readerInterfaceInfoSubHeader}>
                  edits
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames(
              classes.readerInterfaceItem,
              classes.readerInterfaceInfoSubHeader
            )}
          >
            {nbChangesMsg}
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          title={
            <div className={classes.readerModalTitle}>
              <div>{modifiedDataTitle}</div>
              <div className={classes.readerModalTitleSubHeader}>
                {nbChangesMsg}
              </div>
            </div>
          }
          handleCollapse={toggleShowModal}
          fullscreen
        >
          <ModalContent>
            <div className={classes.readerModalBody}>
              <Table
                data={tableData}
                config={tableConfig}
                update={tableUpdate}
              />
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  );
});

DataEditorReader.propTypes = {
  classes: PropTypes.object.isRequired,
  dataEditor: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default DataEditorReader;
