export const BLOCK_TYPES = {
  SUBJECT: 'subject',
  PARAGRAPH: 'paragraph',
  HEADING1: 'heading-one',
  HEADING2: 'heading-two',
  BLOCKQUOTE: 'block-quote',
  UNORDERED_LIST: 'unordered-list',
  ORDERED_LIST: 'ordered-list',
  LIST_ITEM: 'list-item',
  MENTION: 'mention',
  LINK: 'link',
  TABLE: 'table',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  IMAGE: 'image'
};

export const FORMAT_TYPES = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  HIGHLIGHT: 'highlight'
};

export const LIST_TYPES = [
  BLOCK_TYPES.ORDERED_LIST,
  BLOCK_TYPES.UNORDERED_LIST
];

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
};
