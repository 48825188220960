import React, { useState, useRef, useCallback } from 'react';
import PropType from 'prop-types';
import ReactCrop from 'react-image-crop';

import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton
} from '@stratumn/atomic';

import 'react-image-crop/dist/ReactCrop.css';

const croppedFileName = 'cropped.png';

export const CropDialog = ({
  imageSrc,
  actionLabel = null,
  submit,
  cancel
}) => {
  const [cropState, setCropState] = useState({ aspect: 1 }); // Make sure image is cropped as a square.

  const [cropFile, setCropFile] = useState({
    url: null,
    file: null
  });

  const loadedImageRef = useRef(null);
  const onImageLoaded = useCallback(image => {
    loadedImageRef.current = image;
  }, []);

  const onCropChange = useCallback(crop => setCropState(crop), []);

  const onCropComplete = useCallback(
    crop => {
      if (loadedImageRef.current && crop.width && crop.height) {
        const canvas = document.createElement('canvas');
        const scaleX =
          loadedImageRef.current.naturalWidth / loadedImageRef.current.width;
        const scaleY =
          loadedImageRef.current.naturalHeight / loadedImageRef.current.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          loadedImageRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const { url } = cropFile;
        canvas.toBlob(blob => {
          blob.name = croppedFileName;
          window.URL.revokeObjectURL(url);
          const fileUrl = window.URL.createObjectURL(blob);
          const fileObj = new File([blob], croppedFileName, {
            type: 'image/png'
          });
          setCropFile({
            url: fileUrl,
            file: fileObj
          });
        }, 'image/png');
      }
    },
    [cropFile]
  );

  const onSubmit = useCallback(() => submit(cropFile), [cropFile, submit]);

  return (
    <Modal
      title="Crop your image."
      handleCollapse={cancel}
      closeButtonLabel="Cancel"
    >
      <ModalContent centered>
        <ReactCrop
          src={imageSrc}
          crop={cropState}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={cancel} dataCy="crop-cancel-button">
          cancel
        </Pushbutton>
        <Pushbutton
          disabled={!(cropState.height > 0 && cropState.width > 0)}
          onClick={onSubmit}
          primary
          dataCy="crop-submit-button"
        >
          {actionLabel}
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

CropDialog.propTypes = {
  imageSrc: PropType.string.isRequired,
  actionLabel: PropType.string,
  submit: PropType.func.isRequired,
  cancel: PropType.func.isRequired
};

export default React.memo(CropDialog);
