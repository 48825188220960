import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import Tooltip from '../../../utils/tooltip';

import styles from './tooltipWrapper.style';

const TooltipWrapper = React.memo(
  ({
    classes,
    children,
    title = null,
    body,
    position = {},
    delay = 0,
    withArrow = true
  }) => {
    const cellRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    /**
     * Function to handle global mousemove and click events and check that the cursor is still inside the cell
     * This is necessary when the cell has another tooltip triggerd inside it (for example a comment editor) which opens a new overlay above the cell making it impossible to trigger the mouseLeaveEvent which would block the tootlip in an open state utill the cursor enters and leaves the cell again.
     * This function will help check that the cursor is still hovering an element that is inside the cell. If not, the tooltip will be closed.
     * The function is wrapped in a useRef so its reference stays consistent accross re-renders, allowing to remove the event listeners triggering the function.
     */
    const { current: handleMouseMove } = useRef(e => {
      const element = document.elementFromPoint(e.clientX, e.clientY);
      const cellElement = cellRef.current;
      if (!cellElement.contains(element)) {
        setShowTooltip(false);
      }
    });
    function registerEvents() {
      document.addEventListener('mousemove', handleMouseMove, {
        passive: true
      });
      document.addEventListener('click', handleMouseMove);
    }
    function unregisterEvents() {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleMouseMove);
    }
    // useEffect cleanup function to remove envent listeners when the component is unmounted
    useEffect(() => unregisterEvents, []);

    // Cell event handlers
    const handleMouseEnter = () => {
      registerEvents();
      setShowTooltip(true);
    };
    const handleMouseLeave = () => {
      unregisterEvents();
      setShowTooltip(false);
    };

    return (
      <>
        <div
          ref={cellRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={classes.tooltipWrapper}
        >
          {children}
          {showTooltip && (
            <Tooltip
              clientEl={cellRef.current}
              portalEl={document.getElementById(TOOLTIP_PORTAL)}
              position={position}
              arrowUp={
                withArrow && <div className={classes.tooltipWrapperArrowUp} />
              }
              delay={delay}
            >
              <div className={classes.tooltipWrapperContent}>
                {title?.props?.children && (
                  <div className={classes.tooltipWrapperTitle}>{title}</div>
                )}
                <div className={classes.tooltipWrapperBody}>{body}</div>
              </div>
            </Tooltip>
          )}
        </div>
      </>
    );
  }
);

TooltipWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  body: PropTypes.node.isRequired,
  position: PropTypes.object,
  delay: PropTypes.number,
  withArrow: PropTypes.bool
};

export default injectSheet(styles)(TooltipWrapper);
