export default theme => ({
  // container: {
  //   flexShrink: 0,
  //   overflow: 'visible',
  //   backgroundColor: theme.white1,
  //   padding: '30px 15px',
  //   display: 'flex',
  //   flexFlow: 'column nowrap'
  // },
  header: {
    marginBottom: 10,
    padding: '0px 15px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  headerTitle: {
    flexShrink: 0,
    marginRight: 30,
    display: 'flex',
    flexFlow: 'column nowrap',
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'justify'
  },
  headerTitleUnderline: {
    backgroundColor: theme.teal1,
    width: 15,
    height: 5,
    borderRadius: 10
  },
  listContainer: {
    overflowY: 'auto',
    maxHeight: 238,
    position: 'relative'
  },
  workflowsTable: {
    borderSpacing: 0,
    width: '100%',
    // '& th, & td': {
    //   paddingLeft: 15,
    //   paddingRight: 15
    // },
    '& thead': {
      // color: theme.grey4,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '1px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      position: 'sticky',
      zIndex: 1,
      top: 0
      // backgroundColor: theme.white1
    },
    '& thead th': {
      textTransform: 'uppercase',
      paddingTop: 17,
      paddingBottom: 11,
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1
        // backgroundColor: theme.grey6
      }
    },
    '& tbody': {
      // color: theme.grey1,
      whiteSpace: 'nowrap'
      // '& tr': {
      //   '&:not(:first-child)': {
      //     // borderTop: `1px solid ${theme.grey6}`
      //   },
      //   '&:hover': {
      //     // backgroundColor: theme.grey9
      //   }
      // }
    }
  },
  columnName: {
    width: '50%',
    maxWidth: 0, // this is needed to allow ellipsis
    minWidth: 150,
    overflow: 'hidden'
  },
  columnTraces: {
    whiteSpace: 'nowrap'
  },
  columnGroups: {
    whiteSpace: 'nowrap'
  },
  columnDocumentation: {},
  columnActions: {
    width: 0
  },
  emptyListMessage: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.grey1
  },
  listInfoContainer: {
    height: 86,
    width: '100%',
    opacity: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
