import React, { PureComponent } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { Textinput } from '@stratumn/atomic';

import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../../../../utils/links';

type Props = {
  invalid: boolean;
} & WidgetProps;

export class TextInput extends PureComponent<Props> {
  static defaultProps = {
    id: '',
    label: '',
    value: '',
    placeholder: null,
    required: false,
    disabled: false,
    onChange: () => {}
  };

  handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(value === '' ? this.props.options.emptyValue : value);
  };

  render() {
    const {
      id,
      label,
      placeholder,
      value,
      disabled,
      options,
      invalid,
      formContext
    } = this.props;

    const type = options.inputType || 'text';

    // check if the widget is locked because of forms links
    const isLocked = isLockedWidget(id, formContext.links);

    const FieldTextProps = {
      value: value === null ? '' : value!.toString(),
      id,
      label,
      placeholder,
      disabled: disabled || isLocked,
      handleChange: this.handleChange,
      type,
      status: invalid ? 'invalid' : null
    };

    return <Textinput {...FieldTextProps} hideLabel />;
  }
}

export default withInlineValidation(TextInput);
