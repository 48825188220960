import shortid from 'shortid';
import { getByPath, setAtPath } from 'utils';

// constants for action key
export const FORMS_BATCH_MAIN_FORM_ID = 'mainForm';
export const FORMS_BATCH_ADD = 'add';
export const FORMS_BATCH_DELETE = 'delete';
export const FORMS_BATCH_UPDATE = 'update';
export const FORMS_BATCH_INIT_VALIDATION = 'initValidation';
export const FORMS_BATCH_REPORT_VALIDATION_STATUS = 'reportValidationStatus';
export const FORMS_BATCH_SET_ACTIVE_ERROR_FORM = 'setActiveErrorForm';
export const FORMS_BATCH_TOGGLE_LOCK = 'toggleLock';
export const FORMS_BATCH_TOGGLE_LINK = 'toggleLink';

export const FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING =
  'globalValidation';
export const FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS =
  'globalValidationSuccess';
export const FORMS_BATCH_GLOBAL_VALIDATION_STATUS_ERROR =
  'globalValidationError';

/**
 * @desc get the initial setup of forms
 * @desc request coming from the forms themseleves
 * @param {object} initSetup - object used for initialisation (cachedUpdatesKey)
 * @returns {object} the initial setup of forms {mainFormData,locks,additionalForms}
 */
export const initFormsData = initSetup => {
  const { cachedUpdatesKey } = initSetup;

  if (cachedUpdatesKey) {
    const formsDataStr = localStorage.getItem(cachedUpdatesKey);
    if (formsDataStr) return JSON.parse(formsDataStr);
  }

  // no data in the local storage initialize empty forms data
  return {
    mainFormData: {},
    locks: [],
    additionalForms: []
  };
};

/**
 * @desc update in local storage if a key is provided
 * @param {object} formsData - forms data to store (partially...)
 * @param {object} cachedUpdatesKey - key to store data in the local storage
 * @param {object} action - the action that triggered the save
 */
const updateLocalStorage = (formsData, cachedUpdatesKey, action) => {
  if (cachedUpdatesKey && !action.disableCache) {
    localStorage.setItem(
      cachedUpdatesKey,
      JSON.stringify({
        mainFormData: formsData.mainFormData,
        locks: formsData.locks,
        additionalForms: formsData.additionalForms
      })
    );
  }
};

const getFormIdxById = (forms, id) => forms.findIndex(form => form.id === id);

// add an additional form
const addForm = (currentFormsData, action, cachedUpdatesKey) => {
  const { mainFormData, additionalForms, locks } = currentFormsData;

  // initialize empty form data
  const newFormData = {};

  // sync all the locked fields
  locks.forEach(path => {
    const valueAtPath = getByPath(mainFormData, path);
    setAtPath(newFormData, path, valueAtPath);
  });

  // add it to the list of additionalForms
  const newFormsData = {
    ...currentFormsData,
    additionalForms: [
      ...additionalForms,
      {
        id: shortid.generate(),
        data: newFormData
      }
    ]
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};

// delete an additional form
const deleteForm = (currentFormsData, action, cachedUpdatesKey) => {
  const { additionalForms, errors } = currentFormsData;

  // get the index of the form to remove
  const formIdx = getFormIdxById(additionalForms, action.id);

  // remove it from the list of additionalForms
  const newAdditionalForms = [...additionalForms];
  newAdditionalForms.splice(formIdx, 1);

  // update errors if not null
  let newErrors = errors;
  if (errors) {
    const { forms, activeFormId } = errors;

    // if the deleted form had errors, remove it from the list
    const errorFormIdx = forms.findIndex(id => id === action.id);
    if (errorFormIdx > -1) {
      const newErrorFormsList = [...forms];
      newErrorFormsList.splice(errorFormIdx, 1);

      // if that was the only form with errors, remove errors object
      if (newErrorFormsList.length === 0) {
        newErrors = undefined;
      } else {
        newErrors = {
          forms: newErrorFormsList,
          activeFormId
        };
        // if it was the active error form, move to the next one and if none to the previous one
        if (action.id === activeFormId) {
          newErrors.activeFormId =
            errorFormIdx === forms.length - 1
              ? forms[errorFormIdx - 1]
              : forms[errorFormIdx + 1];
        }
      }
    }
  }

  const newFormsData = {
    ...currentFormsData,
    additionalForms: newAdditionalForms,
    errors: newErrors
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};

// perform a data update
const updateData = (currentFormsData, action, cachedUpdatesKey) => {
  if (action.id === FORMS_BATCH_MAIN_FORM_ID) {
    return updateMainFormData(currentFormsData, action, cachedUpdatesKey);
  }
  return updateAdditionalFormData(currentFormsData, action, cachedUpdatesKey);
};
// update data for the main form
const updateMainFormData = (currentFormsData, action, cachedUpdatesKey) => {
  const { mainFormData, additionalForms, locks } = currentFormsData;

  // update the main form data
  const newMainFormData = action.data;

  // then sync locks
  let newAdditionalForms = additionalForms;
  if (locks.length > 0) {
    newAdditionalForms = newAdditionalForms.map(({ id, links, data }) => ({
      id,
      links,
      data: { ...data }
    }));
    locks.forEach(path => {
      // check if this locked field value changed in the main form
      const currentValue = getByPath(mainFormData, path);
      const newValue = getByPath(newMainFormData, path);
      if (currentValue !== newValue) {
        // update it for all the additional forms
        newAdditionalForms.forEach(additionalForm => {
          setAtPath(additionalForm.data, path, newValue);
        });
      }
    });
  }

  // then sync links
  const hasLinks = newAdditionalForms.reduce(
    (prev, form) => prev || (form.links && form.links.length > 0),
    false
  );
  if (hasLinks) {
    newAdditionalForms = newAdditionalForms.map(form => {
      const { links, data } = form;
      if (!links || !links.length) return form;

      const newFormData = { ...data };

      links.forEach(path => {
        // check if this linked field value changed in the main form
        const currentValue = getByPath(mainFormData, path);
        const newValue = getByPath(newMainFormData, path);
        if (currentValue !== newValue) {
          setAtPath(newFormData, path, newValue);
        }
      });

      return {
        ...form,
        data: newFormData
      };
    });
  }

  const newFormsData = {
    ...currentFormsData,
    mainFormData: newMainFormData,
    additionalForms: newAdditionalForms
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};
// update data for an additional form
const updateAdditionalFormData = (
  currentFormsData,
  action,
  cachedUpdatesKey
) => {
  const { additionalForms } = currentFormsData;

  // get the index of the form to update
  const formIdx = getFormIdxById(additionalForms, action.id);

  // update its data
  const newAdditionalForms = [...additionalForms];
  newAdditionalForms[formIdx].data = action.data;

  const newFormsData = {
    ...currentFormsData,
    additionalForms: newAdditionalForms
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};

const checkIsEmptyComment = comment => {
  const commentArray = JSON.parse(comment);
  return commentArray.every(item => item.isEmpty && item.type !== 'image');
};

const removeEmptyCommentFromForm = currentFormsData => {
  const { mainFormData, additionalForms } = currentFormsData;

  if (mainFormData?.comment && checkIsEmptyComment(mainFormData?.comment)) {
    delete mainFormData.comment;
  }

  additionalForms.forEach(form => {
    if (form.data?.comment && checkIsEmptyComment(form.data?.comment)) {
      delete form.data.comment;
    }
  });
};

// init validation state
const initValidation = currentFormsData => {
  // set the validation statuses map to an empty object
  // that can start collecting validation statuses from each form
  const newFormsData = {
    ...currentFormsData,
    validationStatuses: {},
    globalValidationStatus: FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING
  };

  return newFormsData;
};

// report validation status for a given form
const reportValidationStatus = (currentFormsData, action) => {
  const newFormsData = {
    ...currentFormsData
  };

  // Remove empty comment before submit
  removeEmptyCommentFromForm(newFormsData);

  // each form will display its own errors following the validation request
  // log whether current form reports an error
  if (!newFormsData.validationStatuses) newFormsData.validationStatuses = {};
  newFormsData.validationStatuses[action.id] = action.success;

  // if the validation state is completed
  // build the list of form ids that have errors
  // and reset validation statuses
  if (
    Object.keys(newFormsData.validationStatuses).length ===
    newFormsData.additionalForms.length + 1
  ) {
    const errorForms = [];
    if (!newFormsData.validationStatuses[FORMS_BATCH_MAIN_FORM_ID]) {
      errorForms.push(FORMS_BATCH_MAIN_FORM_ID);
    }
    newFormsData.additionalForms.forEach(({ id: additionalFormId }) => {
      if (!newFormsData.validationStatuses[additionalFormId]) {
        errorForms.push(additionalFormId);
      }
    });

    newFormsData.validationStatuses = {};
    if (errorForms.length) {
      newFormsData.globalValidationStatus =
        FORMS_BATCH_GLOBAL_VALIDATION_STATUS_ERROR;
      newFormsData.errors = {
        forms: errorForms,
        activeFormId: errorForms[0]
      };
    } else {
      newFormsData.globalValidationStatus =
        FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS;
      newFormsData.errors = undefined;
    }
  }

  return newFormsData;
};

// change the active error form
const setActiveErrorForm = (currentFormsData, action) => {
  const newFormsData = {
    ...currentFormsData,
    errors: {
      ...currentFormsData.errors,
      activeFormId: action.id
    }
  };

  return newFormsData;
};

// toggle lock for a given field accross forms
const toggleLock = (currentFormsData, action, cachedUpdatesKey) => {
  const { mainFormData, additionalForms, locks } = currentFormsData;
  const { path: pathToLock } = action;

  // check the current lock status for this path
  const newLocks = [...locks];
  let newAdditionalForms = additionalForms;
  const pathIdx = newLocks.findIndex(path => path === pathToLock);
  if (pathIdx < 0) {
    // add the lock
    newLocks.push(pathToLock);

    // sync the path value with all additional forms
    // get the data at the path to sync
    const pathDataValue = getByPath(mainFormData, pathToLock);

    // update all additional forms at this path
    // also check if the path is in each form's links already and remove it
    newAdditionalForms = additionalForms.map(form => {
      // set the new value in this form
      const newFormData = {
        ...form.data
      };
      setAtPath(newFormData, pathToLock, pathDataValue);

      // potentially remove the link to the same path
      let newLinks = form.links || [];
      const pathLinksIdx = newLinks.findIndex(
        linkPath => linkPath === pathToLock
      );
      if (pathLinksIdx >= 0) {
        newLinks = [...newLinks];
        newLinks.splice(pathLinksIdx, 1);
      }

      return {
        ...form,
        data: newFormData,
        links: newLinks
      };
    });
  } else {
    // just remove the lock
    newLocks.splice(pathIdx, 1);
  }

  const newFormsData = {
    ...currentFormsData,
    additionalForms: newAdditionalForms,
    locks: newLocks
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};

// toggle link for a given field between a given form and the main form
const toggleLink = (currentFormsData, action, cachedUpdatesKey) => {
  const { mainFormData, additionalForms, locks } = currentFormsData;
  const { id: formId, path: pathToLink } = action;

  // note: if the path is already locked by the main form there is nothing do to
  // this should never happen anyway as in this case atomic/forms would disable it
  if (locks.includes(pathToLink)) return currentFormsData;

  // get the form that needs to be linked
  const formIdx = getFormIdxById(additionalForms, formId);
  const form = additionalForms[formIdx];
  const { links = [] } = form;

  // check the current link status for this path
  const newForm = { ...form };
  const newLinks = [...links];
  const pathIdx = newLinks.findIndex(path => path === pathToLink);
  if (pathIdx < 0) {
    // add the link and sync
    newLinks.push(pathToLink);

    // get the data at the path to sync
    const pathDataValue = getByPath(mainFormData, pathToLink);

    // update the linked form data at this path
    const newFormData = {
      ...form.data
    };
    setAtPath(newFormData, pathToLink, pathDataValue);
    newForm.data = newFormData;
  } else {
    // just remove the link
    newLinks.splice(pathIdx, 1);
  }

  // replace the new links for this form
  newForm.links = newLinks;

  // replace the new form
  const newAdditionalForms = [...additionalForms];
  newAdditionalForms[formIdx] = newForm;

  const newFormsData = {
    ...currentFormsData,
    additionalForms: newAdditionalForms
  };

  // add new state to local storage
  updateLocalStorage(newFormsData, cachedUpdatesKey, action);

  return newFormsData;
};

/**
 * @desc reducer function to apply forms data modifications
 * @param {object} currentFormsData - the current state of forms data
 * @param {object} action - the action to apply
 * @param {string} [cachedUpdatesKey] - the key to data cached in the local storage
 * @returns {object} the new state of forms data
 */
export const applyFormsDataChange = (
  currentFormsData,
  action,
  cachedUpdatesKey
) => {
  // filter action required
  switch (action.type) {
    case FORMS_BATCH_ADD:
      return addForm(currentFormsData, action, cachedUpdatesKey);

    case FORMS_BATCH_DELETE:
      return deleteForm(currentFormsData, action, cachedUpdatesKey);

    case FORMS_BATCH_UPDATE:
      return updateData(currentFormsData, action, cachedUpdatesKey);

    case FORMS_BATCH_INIT_VALIDATION:
      return initValidation(currentFormsData);

    case FORMS_BATCH_REPORT_VALIDATION_STATUS:
      return reportValidationStatus(currentFormsData, action);

    case FORMS_BATCH_SET_ACTIVE_ERROR_FORM:
      return setActiveErrorForm(currentFormsData, action);

    case FORMS_BATCH_TOGGLE_LOCK:
      return toggleLock(currentFormsData, action, cachedUpdatesKey);

    case FORMS_BATCH_TOGGLE_LINK:
      return toggleLink(currentFormsData, action, cachedUpdatesKey);

    default:
      throw new Error(`Unknown data reducer type ${action.type}`);
  }
};
