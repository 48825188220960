import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import filesize from 'filesize';
import compose from 'lodash.flowright';
import {
  uploadFile,
  uploadConfig,
  handleUploadFileOnError
} from 'client/media';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Pushtext,
  Icon,
  FieldTextAreaCompact,
  RadioButton
} from '@stratumn/atomic';

import {
  DATA_PARSING_STATUS_PARSING,
  DATA_PARSING_STATUS_AGGREGATING
} from 'constant/dataParsing';

import { runCsvParser } from 'utils/csv/csv.runner';
import { runXMLParser } from 'utils/xml';
import { readXls, runXlsParser, sheetToJson } from 'utils/xls';
import { runAggregation } from 'utils/aggregation/aggregation.runner';
import { formatNumber } from 'utils';

import { withLeavingAlertContext } from 'components/beforeLeavingAlert';
import DropZone from 'components/ui/dropZone';
import Table from 'components/ui/table';
import { notify } from 'components/toast';

import {
  importTableCommonConfig,
  buildTableConfigFromMapping,
  buildTableConfigFromColumnNames
} from './utils';

import styles from './dataImporter.style';

const emptyTableData = [];

export const DataImporter = React.memo(
  ({
    classes,
    config,
    onSubmit = () => {},
    submitLabel = 'Submit Data',
    submitDisabled = false,
    onCancel = () => {},
    cancelLabel = 'Cancel',
    cachedUpdatesKey = null,
    traceInfoViewer = null,
    setAskBeforeLeaving,
    setLeavingCallback
  }) => {
    // data importer state
    const [importState, setImportState] = useState({});
    const [error, setError] = useState(null);
    const [importedData, setImportedData] = useState(null);
    const [dropZoneActive, setDropZoneActive] = useState(false);
    const [sheets, setSheets] = useState([]);
    const [workbook, setWorkbook] = useState({});
    const [showSheetsModal, setShowSheetsModal] = useState(false);
    const [fileData, setFile] = useState('');
    const [selectedSheet, setSelectedSheet] = useState('');

    // comments state
    const [commentsInputFocused, setCommentsInputFocused] = useState(false);
    const [comments, setComments] = useState('');

    // media-api uploading state
    const [uploadingFile, setUploadingFile] = useState(false);

    // at first mount, eventually get the data updates cached in local storage
    useEffect(() => {
      if (cachedUpdatesKey) {
        const localStorageCommentsStr = localStorage.getItem(
          `${cachedUpdatesKey}_comments`
        );
        if (localStorageCommentsStr) {
          setComments(localStorageCommentsStr);
        }
      }
    }, []);

    // If importedData is defined, the user will be asked for confirmation before leaving the page or changing route.
    // If impotedData is cleared (= null), the user will not be asked for confirmation.
    useEffect(() => {
      setAskBeforeLeaving(!!importedData);
    }, [importedData]);

    const {
      importedDataKey = 'rows',
      addComments,
      commentsKey = 'comments',
      addFile,
      fileKey = 'file',
      beforeDataHooks = [],
      parsing = {},
      mapping,
      aggregation,
      table
    } = config;
    const { encoding = 'utf8', csvParser, xmlParser } = parsing;

    // cache a static table config which is:
    // 'table' config provided in the props
    // or a table config built from the mapping config if provided
    // or a default one for empty tables if no mapping config provided
    const staticTableConfig = useMemo(
      () =>
        table ||
        (mapping
          ? buildTableConfigFromMapping(mapping)
          : importTableCommonConfig),
      [mapping, table]
    );

    // read the current value of imported data
    const {
      data = emptyTableData,
      tableConfig = staticTableConfig,
      file
    } = importedData || {};

    const clearImportedData = useCallback(() => {
      setImportedData(null);
    }, []);

    // handle the completion of csv parsing
    const completeDataImport = useCallback(
      (importFile, importResult) => {
        const { data: importData, foundColumns } = importResult;
        const importDataArray = Array.isArray(importData)
          ? importData
          : [importData];

        // final build of the table config
        // if we received foundColumns from the csv-parser it means that no mapping was provided and we need to resolve the table config at each csv upload
        // otherwise it means we can rely on the staticTableConfig built as a memo at the component first mount
        const importTableConfig = foundColumns
          ? buildTableConfigFromColumnNames(foundColumns)
          : staticTableConfig;

        // build the received data structure and send it
        const receivedData = {
          data: importDataArray,
          tableConfig: importTableConfig,
          file: importFile
        };

        setError(null);
        setImportState({});
        setImportedData(receivedData);
      },
      [staticTableConfig]
    );

    // run aggregation on the csv import result
    const runDataAggregation = useCallback(
      (importFile, parsingResult) => {
        // now run aggregation if specified
        const { data: parsingData } = parsingResult;
        runAggregation({
          inputData: parsingData,
          aggregationConfig: aggregation,
          workerReportingConfig: {
            reportStep: 0.01 // report every %
          },
          onSuccess: aggregationResult => {
            completeDataImport(importFile, aggregationResult);
          },
          onError: message => {
            setError({
              fileName: importFile ? importFile.name : 'Data',
              message: 'does not match the expected data structure',
              additionalInfo: message
            });
            setImportState({});
          },
          onReport: (status, step, progress) => {
            // set the state to aggregating and report the progress
            setImportState({
              status,
              step,
              progress: 0.5 * (1 + progress),
              file: importFile
            });
          }
        });
      },
      [aggregation, completeDataImport]
    );

    // handle upload of a new file
    const handleNewFile = useCallback(
      acceptedFiles => {
        if (!acceptedFiles || acceptedFiles.length === 0) return;
        setDropZoneActive(false);
        // call the csv parsing now
        const inputFile = acceptedFiles[0];
        if (inputFile.type === 'text/xml' && xmlParser) {
          runXMLParser(inputFile, xmlParser)
            .then(parsingResult => {
              if (aggregation) {
                // now run aggregation if specified
                runDataAggregation(inputFile, parsingResult);
                return;
              }
              completeDataImport(inputFile, parsingResult);
            })
            .catch(({ message, cause }) => {
              setError({
                fileName: inputFile ? inputFile.name : 'Data',
                message:
                  cause === 'notValidXML'
                    ? 'is not a valid xml'
                    : 'does not match the expected data structure',
                additionalInfo: message
              });
            });
        } else if (inputFile.type === 'text/csv') {
          runCsvParser({
            csvInput: inputFile,
            csvParserConfig: csvParser,
            mappingConfig: mapping,
            beforeDataHooks: beforeDataHooks,
            fileEncoding: encoding,
            workerReportingConfig: {
              reportStep: 0.01 // report every %
            },
            onSuccess: parsingResult => {
              if (aggregation) {
                // now run aggregation if specified
                runDataAggregation(inputFile, parsingResult);
                return;
              }
              completeDataImport(inputFile, parsingResult);
            },
            onError: message => {
              setError({
                fileName: inputFile ? inputFile.name : 'Data',
                message: 'does not match the expected data structure',
                additionalInfo: message
              });
              setImportState({});
            },
            onReport: (status, step, progress) => {
              // set the state to parsing and report the progress
              setImportState({
                status,
                step,
                progress: (aggregation ? 0.5 : 1) * progress,
                file: inputFile
              });
            }
          });
        } else if (
          inputFile.type === 'application/vnd.ms-excel' ||
          inputFile.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          readXls({ inputFile, setWorkbook })
            .then(xlsFile => {
              runXlsParser({
                xlsFile,
                mappingConfig: mapping,
                onSuccess: parsingResult => {
                  if (aggregation) {
                    // now run aggregation if specified
                    runDataAggregation(inputFile, parsingResult);
                    return;
                  }
                  completeDataImport(inputFile, parsingResult);
                },
                onError: message => {
                  setError({
                    fileName: inputFile ? inputFile.name : 'Data',
                    message: 'does not match the expected data structure',
                    additionalInfo: message
                  });
                  setImportState({});
                }
              });
            })
            .catch(err => {
              const { message, cause } = err;
              if (cause.type === 'dataNotReadable') {
                setError({
                  fileName: inputFile.name,
                  message
                });
              }

              if (cause.type === 'multiplesSheets') {
                setWorkbook(cause.workbook);
                setSheets(cause.workbook.SheetNames);
                setFile(inputFile);
                setShowSheetsModal(true);
              }
            });
        } else {
          setError({
            fileName: inputFile.name,
            message: `file's format (${inputFile.type}) is not valid`
          });
        }
      },
      [
        csvParser,
        xmlParser,
        mapping,
        encoding,
        aggregation,
        completeDataImport,
        workbook
      ]
    );

    const handleXlsFile = (inputFile, sheet) => {
      const xlsFile = sheetToJson(sheet, workbook);

      runXlsParser({
        xlsFile,
        mappingConfig: mapping,
        onSuccess: parsingResult => {
          if (aggregation) {
            // now run aggregation if specified
            runDataAggregation(inputFile, parsingResult);
            return;
          }
          completeDataImport(inputFile, parsingResult);
        },
        onError: message => {
          setError({
            fileName: inputFile.name,
            message: 'does not match the expected data structure',
            additionalInfo: message
          });
          setImportState({});
        }
      });
    };

    // set unset active drop zone
    const onDropZoneEnter = useCallback(() => {
      setDropZoneActive(true);
    }, []);
    const onDropZoneLeave = useCallback(() => {
      setDropZoneActive(false);
    }, []);

    // handle comments state
    const focusComments = useCallback(() => {
      setCommentsInputFocused(true);
    }, []);
    const blurComments = useCallback(() => {
      setCommentsInputFocused(false);
    }, []);
    const updateComments = useCallback(
      e => {
        const newComment = e.target.value;
        setComments(newComment);
        if (cachedUpdatesKey) {
          if (!newComment) {
            localStorage.removeItem(`${cachedUpdatesKey}_comments`);
            return;
          }
          localStorage.setItem(`${cachedUpdatesKey}_comments`, newComment);
        }
      },
      [cachedUpdatesKey]
    );

    // this memo is passed to the table as the 'update' context
    // it only lets the table cache user display config if there is a predefined mapping or a table config provided
    const tableUpdate = useMemo(
      () => ({
        userDisplay: {
          localStorageKey:
            mapping || table ? `${cachedUpdatesKey}_tableConfig` : undefined
        }
      }),
      [cachedUpdatesKey, mapping, table]
    );

    // if submit is successful, dataImporter will cleanup all related localStorage items
    const cleanupLocalStorage = useCallback(() => {
      if (cachedUpdatesKey) {
        localStorage.removeItem(`${cachedUpdatesKey}_comments`);
        localStorage.removeItem(`${cachedUpdatesKey}_tableConfig`);
      }
    }, [cachedUpdatesKey]);

    // Update leaving alert callback
    useEffect(() => {
      setLeavingCallback(cleanupLocalStorage);
    }, [setLeavingCallback, cleanupLocalStorage]);

    // submit the data
    const submitData = useCallback(() => {
      const formData = {
        [importedDataKey]: data,
        [commentsKey]: !comments ? undefined : comments
      };
      // check if we should add the file to the form data (and hence to media)
      if (addFile) {
        if (!file) {
          const errMessage = 'No file attached';
          console.error(errMessage);
          notify.error(errMessage);
          return;
        }
        setUploadingFile(true);
        uploadFile(
          file,
          savedFile => {
            formData[fileKey] = savedFile;
            setUploadingFile(false);
            onSubmit(formData, cleanupLocalStorage);
          },
          () => {
            setUploadingFile(false);
            handleUploadFileOnError(file.name, file.response.status);
          }
        );
        return;
      }
      onSubmit(formData, cleanupLocalStorage);
    }, [data, comments, file, config, onSubmit, cleanupLocalStorage]);

    // Xls sheets modal
    const handleSheetSelect = () => {
      handleXlsFile(fileData, selectedSheet);
      setShowSheetsModal(false);
    };

    const { MAX_FILE_SIZE } = uploadConfig;

    return (
      <>
        {/* If no valid imported data show the drop zone */}
        {!importedData && (
          <div className={classes.importZone}>
            {importState.status === DATA_PARSING_STATUS_PARSING ||
            importState.status === DATA_PARSING_STATUS_AGGREGATING ? (
              <div className={classes.importStatus}>
                <div className={classes.importStatusMessage}>
                  {`${importState.step}`}
                  <div className={classes.importedFileName}>
                    {`${importState.file.name} (${filesize(
                      importState.file.size,
                      { round: 0 }
                    )})`}
                  </div>
                </div>
                <div className={classes.importProgressBarContainer}>
                  <div
                    className={classes.importProgressBar}
                    style={{ width: `${importState.progress * 100}%` }}
                  />
                </div>
                <div>{`${Math.floor(importState.progress * 100)}%`}</div>
              </div>
            ) : (
              <div className={classes.dropZoneWrapper}>
                <DropZone
                  onDropZoneEnter={onDropZoneEnter}
                  onDropZoneLeave={onDropZoneLeave}
                  onDrop={handleNewFile}
                  dropZoneActive={dropZoneActive}
                  title={
                    xmlParser
                      ? 'Drag an XML, CSV or Excel file here'
                      : 'Drag a CSV or Excel file here'
                  }
                  buttonLabel="or select a file from your device"
                  error={error}
                  maxFileSize={MAX_FILE_SIZE}
                />
              </div>
            )}
          </div>
        )}
        <div className={classes.dataImporter}>
          <div className={classes.dataZone} data-is-behind={!importedData}>
            {importedData && (
              <div className={classes.importedDataMessage}>
                <div>{`${formatNumber(
                  data.length
                )} records have been successfully imported from`}</div>
                <div className={classes.importedFileName}>{`${
                  file.name
                } (${filesize(file.size, { round: 0 })})`}</div>
                <button
                  className={classes.dataImporterButton}
                  type="button"
                  onClick={clearImportedData}
                  data-cy="retry"
                >
                  try again
                </button>
              </div>
            )}
            <div className={classes.importedDataTable}>
              <Table data={data} config={tableConfig} update={tableUpdate} />
            </div>
          </div>
        </div>
        <div className={classes.dataImporterFooter}>
          <div className={classes.dataImporterFooterLeft}>
            <Pushbutton onClick={onCancel} disabled={false} dataCy="cancel">
              {cancelLabel}
            </Pushbutton>
            {traceInfoViewer && (
              <Pushtext
                onClick={traceInfoViewer.toggleShowTraceInfoTray}
                disabled={traceInfoViewer.showTraceInfoTray}
                prefix={<Icon name="Bullets" size={20} />}
                dataCy="toggle-trace-info"
              >
                Show Trace Info
              </Pushtext>
            )}
          </div>
          {addComments && (
            <div className={classes.dataImporterCommentsContainer}>
              <FieldTextAreaCompact
                value={comments}
                label="Add comments"
                onValueChange={updateComments}
                onFocus={focusComments}
                onBlur={blurComments}
                rows={commentsInputFocused ? 5 : 1}
                noResize
              />
            </div>
          )}
          <Pushbutton
            primary
            onClick={submitData}
            disabled={
              !importedData ||
              !importedData.data.length ||
              submitDisabled ||
              uploadingFile
            }
            dataCy="submit"
          >
            {submitLabel}
          </Pushbutton>
        </div>
        {showSheetsModal && (
          <Modal
            title="Choose an Excel sheet"
            closeButtonLabel="Cancel"
            handleCollapse={() => setShowSheetsModal(false)}
          >
            <ModalContent>
              <ul className={classes.sheetList}>
                {sheets.map(sheet => (
                  <li key={sheet}>
                    <RadioButton
                      dataCy={`select-${sheet}`}
                      showLabel
                      label={sheet}
                      value={sheet}
                      checked={selectedSheet === sheet}
                      handleChange={() => setSelectedSheet(sheet)}
                    />
                  </li>
                ))}
              </ul>
            </ModalContent>
            <ModalActions>
              <Pushbutton
                dataCy="cancel"
                onClick={() => setShowSheetsModal(false)}
              >
                Cancel
              </Pushbutton>
              <Pushbutton
                primary
                onClick={handleSheetSelect}
                disabled={!selectedSheet}
              >
                Confirm
              </Pushbutton>
            </ModalActions>
          </Modal>
        )}
      </>
    );
  }
);
DataImporter.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  cachedUpdatesKey: PropTypes.string,
  traceInfoViewer: PropTypes.shape({
    showTraceInfoTray: PropTypes.bool.isRequired,
    toggleShowTraceInfoTray: PropTypes.func.isRequired
  }),
  setAskBeforeLeaving: PropTypes.func.isRequired,
  setLeavingCallback: PropTypes.func.isRequired
};

export default compose(
  injectSheet(styles),
  withLeavingAlertContext
)(DataImporter);
