import { memo } from 'react';
import { getByPath } from '@/utils/widgets';

import { formatNumber } from '@/utils';
import { Progress } from './progress/progress';
import { NumberView } from '../number/number.type';
import { StatusView } from './status.type';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from '@/shadcn/tooltip';
import { WidgetData } from '../../widget.type';

type Props = {
  view: StatusView;
  data: WidgetData;
};

type Status = {
  value: string;
  progress: number;
};

const StatusViewImpl = memo(({ view, data }: Props) => {
  const { path, progressPath, progressSize, cancelValue = 0 } = view;

  const status: Status = {
    value: getByPath<string>(data, path) ?? 'Status not found',
    progress: getByPath<number>(data, progressPath) ?? -1
  };

  return (
    <TooltipProvider delayDuration={400}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex size-full items-center px-[5px]">
            <div className="flex w-full items-center gap-3">
              <div className="flex shrink-0 items-center justify-center">
                <Progress
                  progress={status.progress}
                  size={progressSize || 16}
                  cancelValue={cancelValue}
                />
              </div>
              <div className="-mb-[2px] grow truncate">{status.value}</div>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent variant="contrast" side="bottom">
          <p>
            Progress:{' '}
            {formatNumber(status.progress, {
              options: { style: 'percent', minimumFractionDigits: 0 }
            })}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

// sort defaults to text on path
const getSortConfig = (view: StatusView): NumberView => ({
  type: 'number',
  path: view.progressPath
});

// filtering defaults to text search on path
const getFilterConfig = (view: StatusView) => ({
  type: 'text',
  path: view.progressPath,
  placeholder: 'Eg >0.5, 0.25<<0.75',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'small';

// stringifies data at path
const getStringifyFunction = (view: StatusView) => {
  const { path } = view;
  return (data: WidgetData) => getByPath<string>(data, path);
};

export const statusViewProvider = {
  component: StatusViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
