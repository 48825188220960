import { history } from '@/components/root';
import { ROUTE_WORKFLOW_DASHBOARD } from '@/constant/routes';
import { UnauthorizedIcon } from './unauthorizedIcon';

import { Button } from '@/shadcn/button';

export const PageUnauthorized = () => {
  const goToHome = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  return (
    <div className="m-16 flex w-screen flex-col items-center justify-center">
      <UnauthorizedIcon className="mb-10 w-96" />
      <div className="text-2xl">Stop!</div>
      <div className="text-base">You don&#39;t have access to this page</div>
      <div className="mt-5 flex justify-center">
        <Button onClick={goToHome}>Take me home</Button>
      </div>
    </div>
  );
};
