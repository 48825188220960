import { memo, useMemo } from 'react';

import moment from 'moment';

import Ellipsis from '@/components/ui/utils/ellipsis';
import { nameToInitials } from '@/utils';
import { AvatarGroup } from '@stratumn/atomic';
import { Expand, Minimize } from 'lucide-react';

import { parseEntities } from '../utils';

import { cn } from '@/shadcn/lib/utils';

const WELCOME_MESSAGE =
  'Welcome back to your dashboard! All your monitoring information is here. You can see pending traces, your tasks and more.' as const;

const missingItemsInfo = (nbMissingItems: number): string => {
  return `+ ${nbMissingItems} other team${nbMissingItems > 1 ? 's' : ''}`;
};

type Props = {
  className?: string;
  me?: Partial<{
    firstName: string;
    memberOf: {
      nodes: {
        rowId: string;
        entity: {
          avatar: string;
          name: string;
        };
      }[];
    };
  }>;
  collapsed?: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

// header of the user dashboard
export const UserDashboardHeader = memo(
  ({ className, me = {}, collapsed = false, setCollapsed }: Props) => {
    const { firstName, memberOf: { nodes: entities = [] } = {} } = me;

    const { organization, teams } = useMemo(
      () => parseEntities(entities),
      [entities]
    );

    const { name: organizationName, avatar: organizationAvatar } =
      organization || {};

    return (
      <div
        className={cn(
          'bg-primary-dark relative flex h-[238px] flex-row flex-nowrap items-start justify-between overflow-hidden bg-cover bg-no-repeat p-7 text-white/90 transition-all duration-200 ease-in *:z-1',
          'before:absolute before:inset-0 before:bg-[url("/src/assets/IndigoTrace_Map.svg")] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-30 dark:before:bg-none!',
          collapsed && 'h-[103px] py-5',
          className
        )}
      >
        <div className="flex h-full grow flex-row flex-nowrap overflow-hidden">
          <div
            className={cn(
              'w-[300px] shrink-0 overflow-hidden transition-[width] duration-200 ease-linear',
              collapsed && 'w-[220px]'
            )}
          >
            <div className="text-xl">Welcome back{firstName ? `,` : `!`}</div>
            {firstName && (
              <div className="text-3xl font-semibold">{`${firstName}!`}</div>
            )}
            <div
              className={cn(
                'mt-4 text-sm opacity-100 transition-all duration-400 ease-in',
                collapsed && 'h-0 opacity-0'
              )}
            >
              {WELCOME_MESSAGE}
            </div>
          </div>
          <div
            className={cn(
              'mx-8 flex h-full grow flex-col flex-nowrap overflow-hidden',
              collapsed && 'flex-row'
            )}
          >
            <div
              className={cn(
                'mr-3 mb-2 flex flex-col flex-nowrap justify-start overflow-hidden',
                collapsed && 'mr-12 mb-0'
              )}
            >
              <div className="mb-2 text-base font-semibold text-nowrap">
                Your organization
              </div>
              <div className="mr-4 mb-2 flex flex-row flex-nowrap items-center overflow-hidden">
                {organizationName ? (
                  <>
                    <AvatarGroup
                      src={organizationAvatar}
                      size={collapsed ? 22 : 32}
                      nth={0}
                      initials={nameToInitials(organizationName)}
                    />
                    <div
                      className={cn(
                        'ml-3 truncate text-[25px]',
                        collapsed && 'text-sm'
                      )}
                    >
                      {organizationName}
                    </div>
                  </>
                ) : (
                  <div className="text-sm">
                    You don&#39;t belong to any organization
                  </div>
                )}
              </div>
            </div>

            <div
              className={cn(
                'mr-3 flex grow flex-col flex-nowrap overflow-hidden',
                collapsed && 'mr-[50px]'
              )}
            >
              <div className="mb-2 text-base font-semibold">
                {`Your team${teams?.length > 1 ? 's' : ''}`}
              </div>
              {teams?.length > 0 ? (
                <Ellipsis
                  className="flex grow flex-row flex-wrap items-center"
                  infoMessage={missingItemsInfo}
                  infoClassName="pb-1 text-sm"
                  tooltipClassName="text-sm"
                >
                  {teams.map((team, idx) => {
                    const {
                      rowId,
                      entity: { avatar, name }
                    } = team;
                    return (
                      <div
                        key={rowId}
                        className="mr-4 mb-2 flex flex-row flex-nowrap items-center overflow-hidden"
                      >
                        <AvatarGroup
                          src={avatar}
                          size={22}
                          nth={idx}
                          initials={nameToInitials(name)}
                        />
                        <div className="ml-3 truncate text-sm">{name}</div>
                      </div>
                    );
                  })}
                </Ellipsis>
              ) : (
                <div className="text-sm">You don&#39;t belong to any team</div>
              )}
            </div>
          </div>
        </div>
        <div
          className={cn(
            'flex w-[280px] shrink-0 flex-col flex-nowrap opacity-100 transition-all duration-200 ease-linear',
            collapsed && 'w-0 opacity-0'
          )}
        >
          <div className="mb-2 flex flex-row flex-nowrap items-center text-base font-semibold">
            Today
          </div>
          <div className="text-3xl font-light whitespace-nowrap">
            {moment().format('MMM. DD, YYYY')}
          </div>
        </div>
        <button
          className={cn(
            'absolute top-0 right-0 p-6 text-white/90 outline-hidden transition-all duration-200 ease-in-out hover:scale-110',
            'before:bg-card/10 before:absolute before:top-1/2 before:left-1/2 before:size-7 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:opacity-0 before:transition-all before:duration-100 before:ease-in-out hover:before:opacity-100'
          )}
          onClick={() => setCollapsed(!collapsed)}
          data-cy="collapse-trigger"
        >
          {collapsed ? (
            <Expand size={16} className="stroke-2!" />
          ) : (
            <Minimize size={16} className="stroke-2!" />
          )}
        </button>
      </div>
    );
  }
);
