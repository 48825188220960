import PropTypes from 'prop-types';

/**
 * @function
 * @param {object} props
 * @param {string} props.children
 * @param {string} [props.maxWidth] Either we set a maxWidth in the parent node or
 * we pass it as a prop
 */

export const Ellipsis = ({ children, maxWidth = 'inherit' }) => (
  <div className="truncate" style={{ maxWidth }}>
    {children}
  </div>
);

Ellipsis.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string
};

export default Ellipsis;
