import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import { Icon, LoaderTraceLogo } from '@stratumn/atomic';

import styles from './LoadingIndicator.style';

/**
 * Returns a component that can wrap an element and display either a loading or a success state
 * @param {boolean} spinning - shows loading indicator
 * @param {boolean} showSuccess - shows success indicator
 */
export const LoadingIndicator = ({
  children = null,
  classes,
  spinning,
  showSuccess = false
}) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    if (showSuccess) setHover(true);
  };
  const handleMouseLeave = () => {
    if (showSuccess) setHover(false);
  };

  // When the download starts, set the hover state back to false even if the mouse is still over the item
  // This will allow the check mark to be displayed after download, even if the user clicks again on the item (without moving its mouse outside)
  useEffect(() => {
    if (spinning === true) {
      setHover(false);
    }
  }, [spinning]);

  return (
    <div
      className={classes.loadingIndicatorContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {spinning && (
        <div className={classes.loadingIndicator}>
          <LoaderTraceLogo />
        </div>
      )}
      {showSuccess && !spinning && (
        <div
          className={classNames({
            [classes.loadingIndicator]: true,
            [classes.loadingIndicatorSuccess]: true,
            [classes.loadingIndicatorSuccessHovered]: hover
          })}
        >
          <Icon size={30} color="#30ceca" name="CheckCircle" />
        </div>
      )}
      {children}
    </div>
  );
};

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  spinning: PropTypes.bool.isRequired,
  showSuccess: PropTypes.bool,
  children: PropTypes.node
};

export default injectSheet(styles)(LoadingIndicator);
