import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';

import styles from './progressBar.style';

interface Props {
  classes: ClassNameMap<string>;
  completed?: number;
  step?: string;
}

export const ProgressBar = ({
  classes,
  completed,
  step = 'uploading'
}: Props) => (
  <div className={classes.root} data-cy="forms-js-progress-bar">
    <div className={classes.fullBar}>
      <div className={classes.dynamicBar} />
    </div>
    <div className={classes.labelWrapper}>
      <div className={classes.progressWrapper}>
        <span>{String(completed)}</span>
        <span>%</span>
      </div>
      <span>({step}...)</span>
    </div>
  </div>
);

ProgressBar.defaultProps = {
  completed: undefined,
  step: undefined
} as Partial<Props>;

export default injectSheet(styles)(React.memo(ProgressBar));
