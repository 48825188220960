import { PureComponent } from 'react';
import { WidgetProps } from '@rjsf/utils';
import injectSheet, { ClassNameMap } from 'react-jss';

import { Icon } from '@stratumn/atomic';

import Button from '../../ui/button';

import styles from './arrayField.style';

type Props = {
  classes: ClassNameMap<string>;
  items: any[];
  canAdd: boolean;
  onAddClick: () => void;
  rawErrors?: any[];
} & WidgetProps;

export class ArrayField extends PureComponent<Props> {
  static defaultProps = {
    rawErrors: []
  };

  render() {
    const { classes, title, items, canAdd, onAddClick, rawErrors } = this.props;
    const error = rawErrors && rawErrors.length > 0;

    return (
      <div className={classes.root} data-has-error={error}>
        <div className={classes.styledDiv}>{title}</div>
        {items.map(item => (
          <div key={item.index} className={classes.fieldWrapper}>
            {item.children}
            {item.hasRemove && (
              <div className={classes.styledBox}>
                <Button
                  onClick={e => {
                    item.onDropIndexClick(item.index)(e);
                  }}
                >
                  <Icon name="Trash" size={25} />
                </Button>
              </div>
            )}
          </div>
        ))}
        {canAdd && (
          <Button onClick={onAddClick}>
            <Icon name="CirclePlus" size={25} />
          </Button>
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(ArrayField);
