export const ROUTE_WORKFLOW_DASHBOARD = '/' as const;
// export const ROUTE_WORKFLOW_BOARD = '/workflow/:id'; // Kanban view removed
export const ROUTE_WORKFLOW_OVERVIEW = '/workflowoverview/:id' as const;
export const ROUTE_WORKFLOW_EXPORT = '/workflow/:id/export' as const;
// export const ROUTE_GROUP_BOARD = '/group/:id'; // Kanban view removed
// export const ROUTE_GROUP_FORMS = '/group/:id/forms'; // Kanban view removed
// export const ROUTE_GROUP_FORM = '/group/:groupid/form/:actionkey'; // ? not used
export const ROUTE_GROUP_SETTINGS = '/group/:id/settings' as const;
export const ROUTE_NEW_LINK = '/workflow/:wfid/newlink' as const;
export const ROUTE_INSPECT_TRACE = '/trace/:id' as const;
export const ROUTE_INSPECT_TRACE_LINK = '/trace/:id/:linkid' as const;
export const ROUTE_USER_DASHBOARD = '/userdashboard' as const;
export const ROUTE_WORKFLOW_GROUPS = '/workflow/:id/settings/groups' as const;
export const ROUTE_LOGIN = '/login' as const;
export const ROUTE_OAUTH = '/oauth' as const;
export const ROUTE_UNAUTHORIZED = '/unauthorized' as const;
export const ROUTE_GDPR_POLICY = '/gdpr-privacy-policy' as const;

// These routes are only for demo purposes.
// export const ROUTE_WORKFLOW_DATA_DASHBOARD = '/workflow/:id/dashboard'; // ? not used
