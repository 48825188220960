import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import injectSheet, { ClassNameMap } from 'react-jss';
import Markdown from 'react-markdown';

import styles from './objectField.style';

type Props = {
  classes: ClassNameMap<string>;
  description?: string | null;
  properties: any[];
} & WidgetProps;

const ObjectFieldReader: React.FC<Props> = ({
  classes,
  description,
  properties
}) => (
  <>
    {description && (
      <div className={classes.styledBox}>
        <div className={classes.styledDiv}>
          <Markdown className="descriptionMarkdown" source={description} />
        </div>
      </div>
    )}
    {properties.map(property => property.content)}
  </>
);

export default injectSheet(styles)(ObjectFieldReader);
