import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import Header from 'components/layouts/header';

import classNames from 'classnames';
import styles from './gdprPolicy.style';
import { gdprPolicyContent } from './gdprPolicyContent';

export const GdprPolicy = React.memo(props => {
  const { classes } = props;
  const {
    preamble,
    identification,
    purposes,
    whatData,
    howDataCollectedTable,
    legalBasis,
    recipients,
    howLong,
    exerciseRights
  } = gdprPolicyContent;
  return (
    <>
      <Header fullLogo />
      <article className={classes.GDPRwrapper}>
        <div className={classes.container}>
          <h1>GDPR</h1>
          <section>
            <h3>Preamble</h3>
            <span>{preamble}</span>
          </section>
          <section>
            <h3>{identification.title}</h3>
            <div>
              <ul>
                {identification.bullets.map((bullet, index) => (
                  <li key={index}>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{purposes.title}</h3>
            <span>{purposes.intro}</span>
            <div>
              <ul>
                {purposes.bullets.map((bullet, index) => (
                  <li key={index}>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{whatData.title}</h3>
            <span>{whatData.intro}</span>
            <div>
              <ul>
                {whatData.bullets.map((bullet, index) => (
                  <li key={index}>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{howDataCollectedTable.title}</h3>
            <div
              className={classNames(
                classes.howDataCollectedTableGrid,
                classes.grid
              )}
            >
              {howDataCollectedTable.items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    gridRowStart: item.rowStart,
                    gridRowEnd: item.rowEnd,
                    gridColumnStart: item.column
                  }}
                >
                  <span>{item.text}</span>
                </div>
              ))}
            </div>
          </section>
          <section>
            <h3>{legalBasis.title}</h3>
            <div className={classNames(classes.legalBasisGrid, classes.grid)}>
              {legalBasis.items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    gridRowStart: item.rowStart,
                    gridRowEnd: item.rowEnd,
                    gridColumnStart: item.column
                  }}
                >
                  <span>{item.text}</span>
                </div>
              ))}
            </div>
          </section>
          <section>
            <h3>{recipients.title}</h3>
            <span>{recipients.intro}</span>
            <div>
              <ul>
                {recipients.bullets.map((bullet, index) => (
                  <li key={index}>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{howLong.title}</h3>
            <ul>
              <li>
                <span>{howLong.bullets.title}</span>
              </li>
              <li>
                <span>
                  {howLong.bullets.insideBullet.title}
                  <ul>
                    {howLong.bullets.insideBullet.insideBullets.map(
                      (x, index) => (
                        <li key={index} className={classes.insideBullet}>
                          <span>{x}</span>
                        </li>
                      )
                    )}
                  </ul>
                </span>
              </li>
            </ul>
          </section>
          <section>
            <h3>{exerciseRights.title}</h3>
            <span>{exerciseRights.intro}</span>
            <div>
              <ul>
                <li>
                  <span>{exerciseRights.emailText}</span>
                  <a href={`mailto:${exerciseRights.email}`}>
                    {exerciseRights.email}
                  </a>
                </li>
                <li>
                  <span> {exerciseRights.postText}</span>
                  <div> {exerciseRights.postAddress}</div>
                </li>
              </ul>
              <span>{exerciseRights.outroText}</span>
            </div>
          </section>
        </div>
      </article>
    </>
  );
});
GdprPolicy.propTypes = {
  classes: PropType.object.isRequired
};
export default injectSheet(styles)(GdprPolicy);
