import { createContext } from 'react';

type Context = {
  index: number | null;
};
const BoxSectionContext = createContext<Context>({
  index: null
});

export default BoxSectionContext;
