export default theme => ({
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.grey3,
    marginBottom: 3
  },
  value: {
    display: 'flex',
    fontSize: 14,
    color: theme.grey1
  },
  title: {
    color: theme.blue900,
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    marginBottom: 3
  },
  valueContainer: {
    padding: '0 16px',
    marginLeft: 9,
    borderLeft: `2px solid ${theme.grey7}`
  },
  externalLink: {
    color: theme.blue900
  }
});
