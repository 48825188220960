import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { Pushbutton } from '@stratumn/atomic';
import { Add } from '@stratumn/icons';

import { pluralize } from 'utils';

import EditableGroupFields from './editableGroupFields';

import styles from './subHeader.style';

export const SubHeader = ({
  classes,
  group = null,
  updateGroup,
  nbParticipants = 0,
  nbTeams = 0,
  openAddParticipantsModal
}) => {
  let participantsCount = 'No participants added yet';
  if (nbParticipants) {
    participantsCount = pluralize(nbParticipants, 'participant', false, true);
  }

  let teamsCount = 'No teams added yet';
  if (nbTeams) teamsCount = pluralize(nbTeams, 'team', false, true);

  return (
    <div className={classes.subHeader}>
      <div className={classes.subHeaderLeft}>
        {group && (
          <EditableGroupFields group={group} updateGroup={updateGroup} />
        )}
      </div>
      <div className={classes.subHeaderRight}>
        {group && (
          <>
            <div className={classes.countInfo}>{teamsCount}</div>
            <div className={classes.countInfo}>|</div>
            <div className={classes.countInfo}>{participantsCount}</div>
          </>
        )}
        <Pushbutton
          primary
          onClick={openAddParticipantsModal}
          dataCy="add-participants-button"
          className={classes.addParticipantButton}
        >
          <div className={classes.addGroupLabel}>
            Add Participants
            <Add className={classes.addGroupIcon} />
          </div>
        </Pushbutton>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object,
  updateGroup: PropTypes.func.isRequired,
  nbParticipants: PropTypes.number,
  nbTeams: PropTypes.number,
  openAddParticipantsModal: PropTypes.func.isRequired
};

export default compose(injectSheet(styles), React.memo)(SubHeader);
