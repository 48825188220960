import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';

import { AvatarGroup } from '@stratumn/atomic';

import styles from './workflowGroup.style';

interface Props {
  classes: ClassNameMap<string>;
  name: string;
  avatar?: string | null;
}

export const WorkflowGroup: React.FC<Props> = ({
  classes,
  name,
  avatar = null
}) => (
  <div className={classes.groupLabel}>
    {avatar && (
      <div className={classes.groupAvatar}>
        <AvatarGroup src={avatar} size={22} />
      </div>
    )}
    {name}
  </div>
);

export default injectSheet(styles)(React.memo(WorkflowGroup));
