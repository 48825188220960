import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { validate as isValidEmail } from 'isemail';

import { Pushbutton, ModalContent, ModalActions } from '@stratumn/atomic';

import { pluralize } from 'utils';

import MultipleValuesTextArea from 'components/ui/multipleValuesTextArea';

// static function exported to be tested independently
export const newEmailValidation = (str, emails) => {
  const lCaseStr = str.toLowerCase();
  if (emails.includes(lCaseStr))
    return `Email "${str}" has already been added to the list.`;
  if (!isValidEmail(lCaseStr)) return `"${str}" is not a valid email adress`;
  return null;
};

// Modal content to add a set of users from their emails
export const AddUsers = ({ submit, cancel }) => {
  // state for handling list of emails
  const [emails, setEmails] = useState([]);

  // callback to report if a new string value can be added to the list of emails
  const validateNewEmail = useCallback(
    str => newEmailValidation(str, emails),
    [emails]
  );

  return (
    <>
      <ModalContent>
        <MultipleValuesTextArea
          values={emails}
          updateValues={setEmails}
          placeholder="Enter email addresses separated by a Space, or with Entry / Tab keys."
          errorFn={validateNewEmail}
          toLowerCase
          splitKeys={[' ', 'Enter', 'Tab']}
        />
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          primary
          onClick={() => submit(emails)}
          disabled={!emails.length}
          dataCy="submit-button"
        >
          {emails.length
            ? `Add ${pluralize(emails.length, 'participant', false, true)}`
            : 'Add participant'}
        </Pushbutton>
      </ModalActions>
    </>
  );
};
AddUsers.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default React.memo(AddUsers);
