import React from 'react';
import { type WorkflowContextType } from '@/types/workflow.types';

export const buildWorkflowContext = (
  workflow,
  authorGroupLabel
): WorkflowContextType => {
  const { rowId } = workflow;

  if (!rowId) return { rowId: undefined, groups: [], author: {} };
  // build a list of groups rowId, label and name
  const groups = workflow.groups.nodes;
  const actions = workflow.actions?.nodes;

  // find the currently authoring group info
  const author = authorGroupLabel
    ? groups.find(group => group.label === authorGroupLabel)
    : null;

  const totalRowCount =
    workflow.groupedTraceStates?.nodes[0]?.traceStates.totalCount || 0;

  const subjectsList =
    workflow.config?.actions?.comment?.form.schema.properties?.comment
      ?.subjectsList;

  const readers = workflow.traces?.nodes.reduce((acc, trace) => {
    // Get and filter each trace readers
    const readersToAdd = trace.readers?.filter(
      r =>
        // Account needs to be 'user'
        r.accountType === 'user' &&
        // The user should not already be in the accumulator (if listed in the previously reduced traces)
        !acc.find(x => x.accountId !== r.accountId)
    );
    // Return accumulator with new readers
    return readersToAdd ? [...acc, ...readersToAdd] : acc;
  }, []);

  return {
    rowId: rowId,
    groups,
    author,
    actions,
    subjects: subjectsList,
    members: readers,
    totalRowCount
  };
};

// Sharing workflow context with sub components
export const WorkflowContext = React.createContext(null);
export const withWorkflowContext = Component => props => (
  <WorkflowContext.Consumer>
    {value => <Component workflowContext={value} {...props} />}
  </WorkflowContext.Consumer>
);
