export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '&[data-is-inline=true]': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  title: {
    color: theme.grey1,
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 13,
    letterSpacing: 0
  },
  selectAllCheck: {
    marginBottom: 12
  },
  checkboxWrapper: {
    marginBottom: 12,
    '& label': {
      alignItems: 'unset',
      marginLeft: 20
    },
    '& checkbox': {
      marginTop: 2
    }
  }
});
