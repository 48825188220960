import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';

import {
  WorkflowContext,
  getSelectedGroupDetails
} from '../../../../../utils/workflowContext';

import { WorkflowGroup } from '../../../../ui';

import styles from './groupWidget.style';

export type GroupWidgetProps = { classes: ClassNameMap<string> } & Pick<
  WidgetProps,
  'label' | 'value' | 'formContext'
>;

export const GroupWidget = ({
  classes,
  label = '',
  value = '',
  formContext
}: GroupWidgetProps) => {
  const selectedGroup = getSelectedGroupDetails(
    formContext.workflowContext as WorkflowContext,
    value
  );

  return (
    <>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>
        {selectedGroup ? (
          <WorkflowGroup
            name={selectedGroup.name || value}
            avatar={selectedGroup.avatar}
          />
        ) : (
          value
        )}
      </div>
    </>
  );
};

export default injectSheet(styles)(GroupWidget);
