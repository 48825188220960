export const PATHS = {
  NAME: 'user.name',
  AVATAR: 'user.avatar',
  GROUP: 'group',
  DATE: 'date',
  ACTION: 'action',
  COMMENT: 'comment',
  HASH: 'linkHash',
  HEIGHT: 'height',
  FILES: 'files',
  ANSWERS: 'answers'
} as const;
