import { useState, memo, useCallback, Dispatch, SetStateAction } from 'react';
import injectSheet from 'react-jss';

import { RadioButton, AvatarGroup, Icon } from '@stratumn/atomic';

import styles from './groupActionsList.style';
import { NextAction } from '../actionList.types';

export interface PrioritySettings {
  priorityGroupLabel: string;
  priorityActionKey: string[];
}

export interface Link {
  groupKey: string;
  actionKey: string;
}

interface GroupActionsListProps {
  classes: any;
  groupActions: NextAction;
  selectedLink: Partial<Link>;
  setSelectedLink: Dispatch<SetStateAction<Partial<Link>>>;
  initDisplay: boolean;
  priority?: PrioritySettings;
}

export const GroupActionsList = memo<GroupActionsListProps>(
  ({
    classes,
    groupActions,
    selectedLink,
    setSelectedLink,
    initDisplay,
    priority
  }) => {
    const [displayGroupActions, setDisplayGroupActions] =
      useState<boolean>(initDisplay);

    const toggleDisplayGroupActions = useCallback(() => {
      setDisplayGroupActions(!displayGroupActions);
    }, [displayGroupActions]);

    // check if one of the actions is currently selected
    const selectedGroupAction =
      selectedLink?.groupKey === groupActions?.group?.label
        ? groupActions.actions.find(
            action => selectedLink.actionKey === action.key
          )
        : undefined;

    const getActionClassNameByPriority = (group: string, action: string) => {
      if (
        priority &&
        group === priority.priorityGroupLabel &&
        priority.priorityActionKey?.includes(action)
      ) {
        return classes.radioButtonSelected;
      }
      return classes.radioButton;
    };

    return (
      <div className={classes.groupActions}>
        {!displayGroupActions && selectedGroupAction && (
          <div className={classes.groupActionsSelectedIndicator} />
        )}
        <div
          data-cy="groupHeader"
          className={classes.groupHeader}
          onClick={toggleDisplayGroupActions}
        >
          <div className={classes.groupHeaderLeft}>
            <div className={classes.groupAvatarContainer}>
              <AvatarGroup size={35} src={groupActions.group.avatar} />
            </div>
            <div
              className={classes.groupKey}
            >{`As a member of ${groupActions.group.name}`}</div>
          </div>
          <div className={classes.groupHeaderRight}>
            {`${groupActions.actions.length} action${
              groupActions.actions.length > 1 ? 's' : ''
            }`}
            <div className={classes.groupActionsToggleIcon}>
              <Icon
                size={10}
                name={displayGroupActions ? 'ArrowDownFill' : 'ArrowDown'}
              />
            </div>
          </div>
        </div>
        <div
          className={classes.actionsWrapper}
          data-is-visible={displayGroupActions}
        >
          {groupActions.actions.map(action => (
            <div
              key={action.key}
              className={getActionClassNameByPriority(
                groupActions.group.label,
                action.key
              )}
            >
              <RadioButton
                dataCy="select"
                showLabel
                label={action.title}
                value={action.key}
                checked={
                  selectedGroupAction && selectedGroupAction.key === action.key
                }
                handleChange={() =>
                  setSelectedLink({
                    groupKey: groupActions.group.label,
                    actionKey: action.key
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

const GroupActionsListComponent = injectSheet(styles)(GroupActionsList);
export default GroupActionsListComponent;
