import React from 'react';

interface Props {
  title: string;
}

const TitleField: React.FC<Props> = ({ title = '' }) =>
  title ? <p>{title}</p> : null;

export default TitleField;
