import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';
import { notify } from 'components/toast';
import { sig } from '@stratumn/js-crypto';

import { deepGet } from 'utils';
import { IdentityKey } from '@stratumn/icons';
import { withUser } from 'contexts';
import { withSpan, SpanType } from '../../tracing';

import styles from './signLinkDialog.styles';

export class SignLinkDialog extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onSendLink: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    focusElement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func
    ])
  };

  static defaultProps = {
    description: null,
    children: null,
    disabled: false,
    focusElement: null
  };

  state = {
    password: undefined,
    wrongPassword: false
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value, wrongPassword: false });
  };

  // ==========================================================================
  // Get the user password if the key is protected
  // ==========================================================================

  decryptWallet = () => {
    const {
      user: {
        setPassword,
        me: {
          account: { signingKey }
        }
      }
    } = this.props;
    const { password } = this.state;

    try {
      if (deepGet(signingKey, 'privateKey.passwordProtected', false)) {
        new sig.SigningPrivateKey({
          pemPrivateKey: signingKey.privateKey.decrypted,
          password
        }).export();
      }
    } catch (err) {
      this.setState({ wrongPassword: true });
      return;
    }

    setPassword(password);
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.decryptWallet();
    }
  };

  renderPasswordEntry = () => {
    const { classes } = this.props;
    const { wrongPassword, password } = this.state;

    return (
      <>
        <div className={classes.passwordContainer}>
          <IdentityKey
            className={wrongPassword ? classes.badPassword : classes.keyIcon}
          />
          <div className={classes.inputDescription}>
            Your key is password protected.
            <br />
            Enter your password to decrypt it.
          </div>
          <input
            className={classes.input}
            value={password}
            type="password"
            placeholder="Password"
            onChange={this.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>
      </>
    );
  };

  // ==========================================================================
  // Use the key to sign the link
  // ==========================================================================

  getSigningPrivateKey = () => {
    const {
      user: {
        me: {
          account: { signingKey }
        },
        password
      }
    } = this.props;

    const { passwordProtected, decrypted } = signingKey.privateKey;

    if (passwordProtected)
      return new sig.SigningPrivateKey({
        pemPrivateKey: decrypted,
        password
      }).export();

    return new sig.SigningPrivateKey({
      pemPrivateKey: decrypted
    }).export();
  };

  createLink = () =>
    withSpan('signLink', SpanType.crypto, () => {
      const { onSendLink } = this.props;

      let signingKey;
      try {
        signingKey = this.getSigningPrivateKey();
      } catch (err) {
        notify.error(err);
        return;
      }

      onSendLink(signingKey).catch(err => {
        console.error(err);
        notify.error('Something went wrong while creating a link.');
      });
    });

  renderNoKey = () => {
    const { title, onClose } = this.props;

    return (
      <Modal title={title} closeButtonLabel="Cancel" handleCollapse={onClose}>
        <ModalContent>
          <Prose text="Your private signing key is not available through your Stratumn Account." />
          <Prose text="To perform actions on Trace, please update your account." />
        </ModalContent>
      </Modal>
    );
  };

  renderSignLink = () => {
    const { description, onClose, disabled, open, children, user } = this.props;

    if (!open) return null;
    const {
      me: {
        account: { signingKey }
      }
    } = user;

    if (!signingKey || !signingKey.privateKey) return this.renderNoKey();

    return (
      <>
        <ModalContent>
          {description && <Prose breakAfter={!!children} text={description} />}
          {children && <Prose text={children} />}
        </ModalContent>
        <ModalActions
          adverseAction={<Pushbutton onClick={onClose}>Cancel</Pushbutton>}
        >
          <Pushbutton
            primary
            onClick={this.createLink}
            disabled={disabled}
            dataCy="sign"
          >
            Sign action
          </Pushbutton>
        </ModalActions>
      </>
    );
  };

  render() {
    const { title, onClose, open, focusElement, user } = this.props;

    if (!open) return null;
    const {
      me: {
        account: { signingKey }
      },
      password
    } = user;

    if (!signingKey || !signingKey.privateKey) return this.renderNoKey();

    const passwordPrompt = signingKey.privateKey.passwordProtected && !password;

    return (
      <Modal
        handleCollapse={onClose}
        closeButtonLabel="Cancel"
        title={title}
        focusElement={focusElement}
      >
        {passwordPrompt ? this.renderPasswordEntry() : this.renderSignLink()}
      </Modal>
    );
  }
}

export default compose(withUser, injectSheet(styles))(SignLinkDialog);
