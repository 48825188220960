import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';
import compose from 'lodash.flowright';

import { RadioButton } from '@stratumn/atomic';

import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../../../../utils/links';

import styles from './radio.style';

type Props = {
  classes: ClassNameMap<string>;
  options: {
    enumOptions: [{ label: string; value: string }];
    enumDisabled: string[];
  };
} & WidgetProps;

export const Radio: React.FC<Props> = props => {
  const {
    classes,
    id,
    options,
    value = '',
    disabled,
    readonly,
    onChange,
    formContext
  } = props;

  const { enumOptions, enumDisabled, inline } = options;

  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);

  const disabledGroup = disabled || readonly || isLocked;

  const radioBtnWrapperProps = {
    className: classes.radioBtnWrapper,
    'data-is-inline': inline
  };

  return (
    <div {...radioBtnWrapperProps}>
      {enumOptions.map(option => {
        const disabledOption =
          enumDisabled && enumDisabled.indexOf(option.value) !== -1;

        if (enumOptions.length < 2)
          console.error(
            'Minimum 2 options should be added to radio buttons within the JSONSchema'
          );

        return (
          <div key={option.label} className={classes.radioBtn}>
            <RadioButton
              showLabel
              label={option.label}
              value={option.label}
              disabled={disabledGroup || disabledOption}
              handleChange={() => onChange(option.value)}
              checked={option.value === value}
            />
          </div>
        );
      })}
    </div>
  );
};

export default compose(withInlineValidation, injectSheet(styles))(Radio);
