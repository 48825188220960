import { FieldProps, RJSFSchema } from '@rjsf/utils';

import { isLockedWidget } from '@/components/forms/utils/links';
import {
  FormTableEditor,
  FormTableEditorValue,
  TableConfiguration
} from '@/components/ui/dataEditor/form/tableEditor.form';
import { FormWriterContext } from '@/components/forms/components/form/writer/index';

type CustomFieldProps = FieldProps<
  FormTableEditorValue,
  RJSFSchema,
  FormWriterContext
>;
type Props = CustomFieldProps & {
  uiSchema: Omit<CustomFieldProps['uiSchema'], 'ui:options'> & {
    'ui:options': {
      tableConfig: TableConfiguration;
    };
  };
};

export const TableEditorField = (props: Props) => {
  const config = props.uiSchema?.['ui:options']?.tableConfig;
  const errorSchema = props.errorSchema?.dataByKey;

  if (!config) {
    return (
      <div className="text-destructive">Table editor config is missing</div>
    );
  }

  if (isLockedWidget(props.id, props.formContext?.links)) {
    return <div className=""></div>;
  }

  return (
    <div className="h-96 w-full" id={props.id}>
      <FormTableEditor
        title={props.schema?.title}
        required={props.required}
        description={props.schema?.description}
        value={props.value ?? []}
        traceContext={props.formContext?.traceContext}
        config={config}
        errorSchema={errorSchema}
        onValueChange={props.onChange}
      />
    </div>
  );
};
