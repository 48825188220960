import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';

import styles from './checkboxWidget.style';
import { KeyValue } from '@stratumn/atomic';

type Props = {
  classes: ClassNameMap<string>;
} & Pick<WidgetProps, 'label' | 'value' | 'options'>;

export const CheckboxWidget: React.FC<Props> = ({ label, value, options }) => {
  const { enumOptions } = options as {
    enumOptions: { label?: string; value: string }[];
  };

  /**
   * @returns a pair {label, value}
   */
  const selectedOption = enumOptions.find(option => option.value === value) || {
    label: value
  };

  return (
    <KeyValue label={label}>
      {selectedOption.label && <span>{selectedOption.label}</span>}
    </KeyValue>
  );
};

export default injectSheet(styles)(CheckboxWidget);
