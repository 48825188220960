import { HexaColor } from '../status.type';

type Props = {
  progress: number;
  size: number;
};

export const CircularProgress = ({ progress, size }: Props) => {
  const color = getColor(progress);

  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <circle
        cx="7"
        cy="7"
        r="6"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeDasharray="3.14 0"
        strokeDashoffset="-0.7"
      />
      <circle
        cx="7"
        cy="7"
        r="2"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeDasharray={`${MAX_PROGRESS * progress} 100`}
        strokeDashoffset="0"
        transform="rotate(-90 7 7)"
      />
    </svg>
  );
};

/**
 * ✨ Magic number used as the base in the Rule of Three used to calculate the progress in the `strokeDashArray` attribute.
 *  @example MAX_PROGRESS * 0.5 // => half filled inner circle
 */
const MAX_PROGRESS = 12.5;

const getColor = (progress: number): HexaColor => {
  if (progress <= 0.25) return '#f2994a';
  if (progress <= 0.5) return '#f2be00';
  if (progress <= 0.75) return '#4cb782';
  return '#16AA00';
};
