import { memo, useState } from 'react';
import filesize from 'filesize';
import { FileDown } from 'lucide-react';

import { LoaderTraceLogo } from '@stratumn/atomic';
import { downloadFile } from '@/utils/downloadFile';
import Ellipsis from '@/components/ui/ellipsis';

import { cn } from '@/shadcn/lib/utils';

type Props = {
  data: {
    name: string;
    size: number;
  };
  readonly?: boolean;
};

export const DownloadFile = memo(({ data, readonly = false }: Props) => {
  const [loading, setLoading] = useState(false);

  if (loading) return <LoaderTraceLogo />;

  return (
    <div
      className={cn(
        'flex items-center overflow-hidden',
        !readonly && 'cursor-pointer'
      )}
      data-is-downloadable={!readonly}
      onClick={
        !readonly ? () => downloadFile(data, setLoading, loading) : undefined
      }
    >
      <FileDown className="block h-6 w-auto shrink-0" />
      <div
        className="ml-1 flex flex-col items-start overflow-hidden text-xs tracking-wide"
        data-cy="download-documentation"
      >
        <div className="max-w-full capitalize">
          <Ellipsis>{data.name}</Ellipsis>
        </div>

        <div className="text-muted-foreground max-w-full text-[10px] uppercase">
          {filesize(data.size)}
        </div>
      </div>
    </div>
  );
});

export default DownloadFile;
