const spacing = 10;

export default theme => ({
  root: {},
  editorWrapper: {
    minHeight: 'fit-content',
    maxHeight: 300,
    fontWeight: 400,
    borderRadius: 5,
    overflow: 'auto',
    padding: spacing,
    fontSize: 14,
    border: `1px solid ${theme.grey5}`,
    transition: 'border-color 150ms linear 0ms',
    '&[data-has-focus="true"]': {
      borderColor: theme.indigo3
    },
    '& [data-slate-editor]': {
      outline: 'none',
      '& > *': {
        margin: '8px 0'
      },
      '& > h1.subject': {
        fontSize: 14,
        position: 'sticky',
        zIndex: 1,
        top: `${-spacing}px`,
        fontWeight: 700,
        background: theme.white1,
        padding: '1em 0 0.5em',
        borderBottom: `1px solid ${theme.grey6}`,
        margin: `${-spacing}px 0 ${spacing}px`,
        // When subject is not in a disabled parent and is empty
        '[data-is-disabled="false"] & > span[data-slate-node]:only-child > span[data-slate-leaf]:only-child > span[data-slate-length="0"]:before':
          {
            content: props => `"${props.subjectPlaceholder}"`,
            color: `${theme.grey6}`,
            position: 'absolute',
            fontWeight: 'normal',
            borderBottom: `0px solid ${theme.grey6}`
          },
        // When subject is not in a disabled parent target the following block that is emtpy
        '[data-is-disabled="false"] & + *:last-child > span[data-slate-node]:only-child > span[data-slate-leaf]:only-child > span[data-slate-length="0"]:before':
          {
            content: props => `"${props.placeholder}"`,
            color: `${theme.grey6}`,
            position: 'absolute',
            fontWeight: 'normal'
          }
      },
      '& > h1, [data-with-subject="true"] & > h2': {
        fontSize: 20,
        fontWeight: 'bold'
      },
      '& > h2, [data-with-subject="true"] & > h3': {
        fontSize: 16,
        fontWeight: 'bold'
      },
      '& > blockquote': {
        borderLeft: `2px solid ${theme.grey6}`,
        padding: '5px 0 5px 10px',
        fontStyle: 'italic',
        color: theme.grey2
      },
      '& ul': {
        listStyleType: 'disc',
        marginLeft: '1.5em'
      },
      '& ol': {
        listStyleType: 'decimal',
        marginLeft: '1.5em'
      },
      '& strong': {
        fontWeight: 'bold'
      },
      '& em': {
        fontStyle: 'italic'
      },
      '& u': {
        textDecoration: 'underline'
      },
      '& mark': {
        backgroundColor: theme.indigoPastel2,
        padding: '4px 2px'
      },
      '& code': {
        backgroundColor: theme.grey6,
        padding: '4px 6px',
        borderRadius: 2
      },
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
        '[data-is-disabled="false"] &': {
          width: 'calc(100% - 10px)'
        }
      },
      '& td': {
        padding: 10,
        border: `1px solid ${theme.grey6}`,
        position: 'relative'
      },
      '& [data-is-selected="true"] td': {
        backgroundColor: theme.indigoPastel3,
        border: `1px solid ${theme.indigo3}`,
        // '& *::selection': {
        //   backgroundColor: "transparent"
        // },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: -1,
          left: -1,
          height: 1,
          width: 'calc(100% + 2px)',
          backgroundColor: theme.indigo3
        }
      }
    }
  },
  editorWrapperReadOnly: {
    border: 0,
    padding: 0,
    minHeight: 'none',
    maxHeight: 'none',
    '& [data-slate-editor]': {
      '& [data-is-empty="true"]:not(td)': {
        display: 'none'
      },
      '& > h1.subject': {
        borderBottom: `0px`,
        '&::before': {
          content: '"Subject: "'
        }
      }
    }
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 5,
    justifyContent: 'flex-start',
    gap: '8px 16px'
  },
  toolbarButtonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px'
  },
  toolbarButtonGroupHistory: {
    marginLeft: 'auto'
  },
  toolbarSeparator: {
    borderRight: `1px solid ${theme.grey5}`,
    marginRight: 12,
    paddingRight: 8
  },
  button: {
    '&[disabled]': {
      cursor: 'not-allowed',
      color: `${theme.grey5}`,
      borderColor: `${theme.grey5}`
    },
    minWidth: 20,
    height: 20,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.indigo2}`,
    color: theme.indigo3,
    borderRadius: 2,
    outline: 'none',
    boxShadow: `0 0 0 0 ${theme.indigoPastel2}`,
    transition:
      'box-shadow 650ms ease, background-color 650ms ease, border-color 650ms ease',
    '&:not([disabled])': {
      '&:hover, &:focus': {
        borderColor: theme.indigo2,
        backgroundColor: theme.indigo2,
        color: theme.white1
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.indigoPastel2}`
      }
    },

    '& strong': {
      fontWeight: 'bold'
    },
    '& em': {
      fontStyle: 'italic'
    },
    '& u': {
      textDecoration: 'underline'
    }
  },
  buttonActive: {
    borderColor: theme.indigo3,
    backgroundColor: theme.indigo3,
    color: theme.white1
  },
  autoCompleteList: {
    top: '-9999px',
    left: '-9999px',
    position: 'absolute',
    zIndex: 2,
    padding: 3,
    background: theme.white1,
    borderRadius: 2,
    boxShadow: theme.indigoShadow2,
    fontSize: 14
  },
  autoCompleteListItem: {
    padding: '1px 3px',
    borderRadius: '3px',
    background: 'transparent',
    color: theme.grey2
  },
  autoCompleteListItemActive: {
    background: theme.indigoPastel2,
    color: 'unset'
  },
  mention: {
    backgroundColor: theme.blue50,
    padding: '0 6px',
    marginInline: '1px',
    color: theme.blue800,
    borderRadius: 14,
    fontSize: '0.9em',
    verticalAlign: 'baseline',
    display: 'inline-block'
  },
  mentionSelected: {
    outline: `1px solid ${theme.blue800}`
  },
  link: {
    color: theme.indigo3
  },
  linkSelected: {
    outline: `2px solid ${theme.indigoPastel1}`
  },
  tableContainer: {
    with: '100%',
    overflow: 'auto'
  },
  image: {
    position: 'relative',
    width: 'fit-content',
    '& .image-actions': {
      display: 'none',
      position: 'absolute',
      top: '0.5em',
      left: '0.5em'
    },
    '& .image-placeholder': {
      position: 'absolute',
      top: 0,
      pointerEvents: 'none',
      animation: 'skeleton-loading 1s linear infinite alternate'
    },
    '& img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '20em',
      cursor: 'zoom-in'
    },
    '& a': {
      textDecoration: 'none'
    },
    '& button': {
      border: `1px solid ${theme.indigo2}`,
      color: theme.indigo3,
      borderRadius: 2,
      backgroundColor: theme.white1,
      height: 'fit-content',

      '&:hover': {
        borderColor: theme.indigo2,
        backgroundColor: theme.indigo2,
        color: theme.white1
      }
    },
    '&:hover': {
      '& .image-actions': {
        display: 'flex',
        gap: '10px'
      }
    }
  },
  imageLoader: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(255 255 255 / 50%)'
  },
  '@keyframes skeleton-loading': {
    '0%': {
      backgroundColor: 'hsl(200, 20%, 80%)'
    },
    '100%': {
      backgroundColor: 'hsl(200, 20%, 95%)'
    }
  }
});
