import { env } from './env';

/**
 * Whether or not to display the toggle to turn on sub totals in the
 * table view.
 */
export const ENABLE_SUB_TOTAL_AGGREGATION =
  env.VITE_ENABLE_SUB_TOTAL_AGGREGATION;

/**
 * Whether or not to display the toggle to turn on batch download
 * in the table view.
 */
export const ENABLE_BATCH_DOWNLOAD = env.VITE_ENABLE_BATCH_DOWNLOAD;
