import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

// React Window
import { FixedSizeList as List } from 'react-window';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import GroupsTaskListRow from './groupsTaskListRow';
import GroupsTaskListItem, {
  fragments as groupsTaskListItemFragments
} from './groupsTaskListItem';
import GroupTaskListHeader from './groupTaskListHeader';

import { cn } from '@/shadcn/lib/utils';

// some constants specific to groups task list
const DEFAULT_ORDER_BY = 'ALL_DESC';
const TASKLIST_PAGINATION_SIZE = 20;
const TASKLIST_PAGINATION_BUFFER = 10;

const getSortFromConfig = config => config.orderBy || DEFAULT_ORDER_BY;

/**
 * Returns filter object from configuration and workflows.
 */
export const getFilterFromConfig = (config, workflows) => {
  const workflowId = { equalTo: config.filterWorkflowId };
  let groupId;

  // Simplified groupId assignment
  if (config.filterGroupId) {
    groupId = { equalTo: config.filterGroupId };
  } else {
    groupId = { in: getGroupsIdsFilter(workflows, config.filterWorkflowId) };
  }

  return { workflowId, groupId };
};

/**
 * Returns the IDs of all groups to which the user belongs.
 */
const getGroupsIdsFilter = (workflows, filterWorkflowId) => {
  let currentWorkflows;

  // Handling potential undefined workflow
  if (filterWorkflowId) {
    const foundWorkflow = workflows.find(w => w.rowId === filterWorkflowId);
    currentWorkflows = foundWorkflow ? [foundWorkflow] : [];
  } else {
    currentWorkflows = workflows;
  }

  // Streamlining array manipulations
  const filteredGroups = currentWorkflows
    .flatMap(w => w.groups?.nodes ?? [])
    .filter(g => g.canActQuick)
    .map(g => g.rowId);

  return filteredGroups;
};

// 'member' functions

// load more priorities items from pagination
const onLoadMoreFn = (workflows, prioritiesQuery, config, fetchMorePromise) => {
  // If a fetch more query is in progress, do not try to load
  if (fetchMorePromise.current) return;

  const {
    priorities: { pageInfo: { endCursor } = {} } = {},
    fetchMore: fetchMorePriorities
  } = prioritiesQuery;

  // fetchMore will only fetch more priorities
  const filter = getFilterFromConfig(config, workflows);
  const orderBy = getSortFromConfig(config);

  fetchMorePromise.current = fetchMorePriorities({
    query: queries.prioritiesQuery,
    variables: {
      first: TASKLIST_PAGINATION_SIZE,
      cursor: endCursor,
      filter,
      orderBy
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const previousPriorities = previousResult.priorities;
      const newPriorities = fetchMoreResult.priorities;

      const { pageInfo: previousPageInfo, nodes: previousPrioritiesNodes } =
        previousPriorities;
      const { pageInfo: newPageInfo, nodes: newPrioritiesNodes } =
        newPriorities;

      return {
        priorities: {
          ...previousPriorities,
          // By updating page info with the `cursor` here, we update `fetchMore` function
          pageInfo: {
            ...previousPageInfo,
            ...newPageInfo
          },
          nodes: [
            // Put the new priorities after the previous ones
            ...previousPrioritiesNodes,
            ...newPrioritiesNodes
          ]
        }
      };
    }
  }).then(() => {
    fetchMorePromise.current = null;
  });
};

// groups task list of the user dashboard
export const GroupsTaskList = React.memo(
  ({
    className = '',
    workflows = [],
    prioritiesQuery,
    config = {},
    setConfig
  }) => {
    const fetchMorePromise = useRef(null);

    // state for refetching priorities when filterOwnerId changes
    const [refetching, setRefetching] = useState(false);

    const {
      loading: prioritiesQueryLoading,
      priorities,
      refetch: refetchPriorities
    } = prioritiesQuery;

    const {
      totalCount = 0,
      pageInfo: { hasNextPage = false } = {},
      nodes: prioritiesList = []
    } = priorities || {};

    // set filter workflow and group id
    const { filterWorkflowId, filterGroupId } = config;

    const filterWorkflow = workflows.find(w => w.rowId === filterWorkflowId);
    const filterGroup =
      filterWorkflow &&
      filterWorkflow.groups.nodes.find(g => g.rowId === filterGroupId);

    // pagination of priorities query
    const pagination = {
      totalCount,
      buffer: TASKLIST_PAGINATION_BUFFER,
      hasNextPage,
      onLoadMore: () =>
        onLoadMoreFn(workflows, prioritiesQuery, config, fetchMorePromise)
    };

    // component to display the priorities list for the chosen group (or for all)
    // if no element or loading we show a specific message
    // if no more than 5 items we show them in full
    // if more than 5 items we use a virtualization window
    let prioritiesListContent;
    switch (true) {
      case refetching || (prioritiesQueryLoading && !priorities):
        prioritiesListContent = (
          <div className="flex h-[300px] w-full flex-row flex-nowrap items-center justify-center opacity-100">
            <TraceIconSpinner className="m-0" small />
          </div>
        );
        break;
      case totalCount === 0:
        prioritiesListContent = (
          <div className="flex h-[300px] w-full flex-row flex-nowrap items-center justify-center opacity-100">
            <div className="text-card-foreground text-center text-sm">
              {filterGroup ? (
                <>
                  No trace awaiting{' '}
                  <b className="text-secondary">{filterGroup.name}</b> action
                </>
              ) : (
                'No trace awaiting your action'
              )}
            </div>
          </div>
        );
        break;
      case totalCount <= 5:
        prioritiesListContent = prioritiesList.map(priority => (
          <GroupsTaskListItem
            key={priority.id}
            priority={priority}
            displayGroupInfo={!filterGroupId}
          />
        ));
        break;
      default:
        prioritiesListContent = (
          <List
            className="reactWindowList"
            height={775}
            itemCount={prioritiesList.length}
            itemSize={150}
            itemData={{
              priorities: prioritiesList,
              pagination,
              displayGroupInfo: !filterGroupId
            }}
          >
            {GroupsTaskListRow}
          </List>
        );
    }

    return (
      <div
        className={cn('bg-card shrink-0 overflow-hidden px-4 py-8', className)}
      >
        <GroupTaskListHeader
          workflows={workflows}
          setConfig={setConfig}
          config={config}
          setRefetching={setRefetching}
          refetchPriorities={refetchPriorities}
        />
        <div className="relative min-h-[300px]">{prioritiesListContent}</div>
      </div>
    );
  }
);

GroupsTaskList.propTypes = {
  className: PropTypes.string,
  workflows: PropTypes.array,
  prioritiesQuery: PropTypes.object.isRequired,
  config: PropTypes.object,
  setConfig: PropTypes.func.isRequired
};
GroupsTaskList.defaultProps = {
  className: '',
  workflows: [],
  config: {}
};

export const queries = {
  prioritiesQuery: gql`
    query prioritiesQuery(
      $first: Int
      $cursor: Cursor
      $orderBy: [PrioritiesOrderBy!]
      $filter: PriorityFilter
    ) {
      priorities(
        first: $first
        after: $cursor
        orderBy: $orderBy
        filter: $filter
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          ...PriorityFragment
        }
      }
    }
    ${groupsTaskListItemFragments.priority}
  `
};

export default compose(
  graphql(queries.prioritiesQuery, {
    name: 'prioritiesQuery',
    options: ({ workflows, config = {} }) => {
      const filter = getFilterFromConfig(config, workflows);
      const orderBy = getSortFromConfig(config);
      return {
        variables: {
          first: TASKLIST_PAGINATION_SIZE,
          orderBy,
          filter
        },
        fetchPolicy: 'cache-and-network'
      };
    }
  })
)(GroupsTaskList);
