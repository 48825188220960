import { Button } from '@/shadcn/button';
import { cn } from '@/shadcn/lib/utils';
import { Download } from 'lucide-react';

type FileType = 'csv' | 'excel';

type Props = {
  onClick: () => void;
  fileType: FileType;
  changeTypeFile: (type: FileType) => void;
};

export const ExportTableMenu = ({
  onClick,
  fileType,
  changeTypeFile
}: Props) => (
  <div className="size-full">
    <p className="text-muted-foreground text-sm">Select a format</p>
    <div className="border-border bg-accent my-1 flex h-9 items-center gap-1 overflow-hidden rounded-md border p-[2px]">
      <FileToggle
        label="CSV"
        checked={fileType === 'csv'}
        onClick={() => changeTypeFile('csv')}
      />
      <FileToggle
        label="Excel"
        checked={fileType === 'excel'}
        onClick={() => changeTypeFile('excel')}
      />
    </div>
    <footer className="mt-4 flex justify-end">
      <Button onClick={onClick} className="flex w-full items-center gap-2">
        <Download />
        Export table as {fileType}
      </Button>
    </footer>
  </div>
);

type FileToggleProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
};

const FileToggle = ({ label, checked, onClick }: FileToggleProps) => (
  <div
    onClick={onClick}
    className={cn(
      'hover:border-border hover:bg-popover hover:text-popover-foreground flex h-full grow cursor-pointer items-center justify-center rounded border border-transparent text-sm transition-colors',
      checked && 'border-border bg-popover text-popover-foreground'
    )}
  >
    {label}
  </div>
);
