import { memo } from 'react';

import { ProseList } from '@stratumn/atomic';

import { getByPath } from '@/utils/widgets';
import { ListView } from './list.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: ListView;
  data: WidgetData;
};

const ListViewImpl = memo(({ view, data }: Props) => {
  const { path, ordered, small, light } = view;

  const listData = getByPath<string[]>(data, path);
  if (!listData) return null;

  return (
    <div className="[&_ul]:text-card-foreground!">
      <ProseList
        items={listData}
        ordered={ordered}
        small={small}
        light={light}
      />
    </div>
  );
});

// sort defaults to length on path
const getSortConfig = (view: ListView) => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: ListView) => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at path
const getStringifyFunction = (view: ListView) => {
  const { path } = view;
  return (data: WidgetData) => {
    const listData = getByPath<string[]>(data, path);
    if (!listData) return '';

    return listData.join(', ');
  };
};

export const listViewProvider = {
  component: ListViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
