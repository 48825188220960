export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 250,
    '& label': {
      fontFamily: 'EuclidCircularB',
      fontWeight: 600,
      fontSize: 16,
      padding: '8px 0',
      '& strong': {
        color: theme.warningRed,
        paddingRight: 5
      }
    },
    '& .react-datepicker__triangle': {
      display: 'none'
    },
    '& .react-datepicker-wrapper': {
      position: 'absolute',
      width: 0
    },
    '& .react-datepicker__current-month': {
      fontSize: 13,
      fontWeight: 800,
      fontFamily: 'EuclidCircularB',
      letterSpacing: '0.26px',
      color: theme.grey1,
      lineHeight: '16px',
      marginBottom: 10
    },
    '& .react-datepicker__header': {
      backgroundColor: theme.grey9,
      borderBottom: `1px solid ${theme.grey7}`
    },
    '& .react-datepicker__day-names': {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: 'EuclidCircularB',
      color: '#000080',
      borderTop: `1px solid ${theme.grey7}`
    },
    '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name':
      {
        margin: '0 2.3px'
      },
    '& .react-datepicker__week': {
      fontFamily: 'EuclidCircularB',
      fontSize: 14,
      lineHeight: '34px'
    },
    '& .react-datepicker__day': {
      padding: 0,
      margin: 0,
      width: 34,
      height: 32,
      '&:hover': {
        borderRadius: '50%'
      }
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: theme.blue50,
      color: theme.blue800,
      borderRadius: '50%',
      fontWeight: 700,
      fontFamily: 'EuclidCircularB',
      '&:hover': {
        backgroundColor: theme.blue50
      }
    },
    '& .react-datepicker__month': {
      margin: 0
    },
    '& .react-datepicker__day--keyboard-selected, & .react-datepicker__month-text--keyboard-selected, & .react-datepicker__quarter-text--keyboard-selected':
      {
        borderRadius: '50%',
        backgroundColor: theme.indigo3,
        '&:hover': {
          backgroundColor: theme.indigo2
        }
      },
    '& .react-datepicker__navigation': {
      border: `solid #999999`,
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      outline: 'none',
      top: 12
    },
    '& .react-datepicker__navigation--previous': {
      left: 60,
      transform: 'rotate(135deg)'
      // -webkit-transform: 'rotate(135deg)'
    },
    '& .react-datepicker__navigation--next': {
      right: 60,
      transform: 'rotate(-45deg)'
      // -webkit-transform: rotate(-45deg),
    },
    '& .react-datepicker__day--outside-month': {
      opacity: 0
    }
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  dateInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& [type="date"]': {
      height: 34,
      border: `1px solid ${theme.grey5}`,
      borderRadius: 5,
      padding: 5,
      fontSize: 14,
      letterSpacing: 0.28,
      textTransform: 'uppercase',
      color: theme.grey1,
      backgroundColor: 'transparent',
      outline: 'none',
      width: 135,
      textAlign: 'center',
      transition: 'border 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      '&[data-disabled=false]:focus': {
        color: theme.grey4
      },
      '&[data-error=true]': {
        border: `1px solid ${theme.warningRed}`
      },
      '&[data-disabled=true]': {
        opacity: 0.3
      },
      '&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
        display: 'none'
      },
      '::-webkit-input-placeholder': {
        visibility: 'hidden !important'
      }
    }
  },
  dateToday: {
    color: theme.indigo3,
    fontSize: 14,
    width: 100,
    cursor: 'pointer',
    paddingLeft: 56
  },
  iconInput: {
    color: theme.indigo2,
    position: 'relative',
    left: 148,
    width: 32,
    height: 32,
    cursor: 'pointer'
  },
  calendar: {
    margin: 0,
    marginBlock: '5px',
    position: 'relative',
    backgroundColor: theme.white1,
    border: `1px solid ${theme.grey5}`
  },
  calendarPuppet: {
    position: 'relative !important',
    transform: 'none !important',
    transition: 'all 0.2s cubic-bezier(0.19, 1, 0.22, 1)',
    height: 275
  }
});
