import { Toaster } from 'sonner';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import JssProvider from 'react-jss/lib/JssProvider';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Snackbars } from '@stratumn/atomic';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ApolloProvider } from '@apollo/client';
import * as routes from '@/constant/routes';
import { env } from '@/constant/env';
import { traceClient } from '@/gql';
import {
  PrivateRoute,
  // WorkflowBoard, // Kanban view removed
  WorkflowOverview,
  WorkflowExport,
  // GroupBoard, // Kanban view removed
  Login,
  NewLink,
  TraceInspector,
  ErrorBoundary,
  OAuth,
  PageNotFound,
  PageUnauthorized,
  UserDashboard,
  WorkflowGroups,
  GroupSettings,
  UnexpectedErrorBoundary,
  GdprPolicy
} from '@/components';

import theme from '@/style';
import '@/style/style.css';

import TicketBar, { TicketProvider } from '@/components/ui/ticketBar';
import generateClassName from '@/utils/generateClassName';
import { ShadcnThemeProvider } from '@/shadcn/theme/theme-provider';

export const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://69c6737c083df630ff7e60ad93086c2d@sentry.stratu.mn/5',
  environment: env.VITE_ENVIRONMENT,
  debug: env.VITE_SENTRY_DEBUG,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history
    }),
    Sentry.replayIntegration()
  ],
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications'
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    '.*api-public.*',
    '.*api-private.*',
    /^.*\.stratumn\.com/,
    new RegExp(`^.*\\.${env.ROOT_API_URL}`),
    /^\//
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Deny local errors
  denyUrls: [/^https?:\/\/([a-z-]+\.)*local\.stratumn\.com/]
});

const Root = () => (
  <ApolloProvider client={traceClient}>
    <ShadcnThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <ThemeProvider theme={theme}>
        <JssProvider generateClassName={generateClassName}>
          <UnexpectedErrorBoundary>
            <Router history={history}>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Snackbars>
                  <ErrorBoundary>
                    <TicketProvider>
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: 100,
                          left: 50,
                          bottom: 0
                        }}
                      >
                        <TicketBar />
                      </div>
                      <Switch>
                        <Route
                          path={routes.ROUTE_LOGIN}
                          exact
                          component={Login}
                        />
                        <Route
                          path={routes.ROUTE_OAUTH}
                          exact
                          component={OAuth}
                        />

                        <PrivateRoute
                          path={routes.ROUTE_WORKFLOW_DASHBOARD}
                          exact
                          component={UserDashboard}
                        />
                        {/* Kanban view removed */}
                        {/* <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_BOARD}
                      exact
                      component={WorkflowBoard}
                    /> */}
                        <PrivateRoute
                          path={routes.ROUTE_WORKFLOW_OVERVIEW}
                          exact
                          component={WorkflowOverview}
                        />
                        <PrivateRoute
                          path={routes.ROUTE_WORKFLOW_EXPORT}
                          exact
                          component={WorkflowExport}
                        />
                        {/* Kanban view removed */}
                        {/* <PrivateRoute
                      path={routes.ROUTE_GROUP_BOARD}
                      exact
                      component={GroupBoard}
                    /> */}
                        <PrivateRoute
                          path={routes.ROUTE_NEW_LINK}
                          exact
                          component={NewLink}
                        />
                        <PrivateRoute
                          path={[
                            routes.ROUTE_INSPECT_TRACE,
                            routes.ROUTE_INSPECT_TRACE_LINK
                          ]}
                          exact
                          component={TraceInspector}
                        />
                        <PrivateRoute path={routes.ROUTE_USER_DASHBOARD} exact>
                          <Redirect to={routes.ROUTE_WORKFLOW_DASHBOARD} />
                        </PrivateRoute>
                        <PrivateRoute
                          path={routes.ROUTE_WORKFLOW_GROUPS}
                          exact
                          component={WorkflowGroups}
                        />
                        <PrivateRoute
                          path={routes.ROUTE_GROUP_SETTINGS}
                          exact
                          component={GroupSettings}
                        />
                        {/* accessible by all environments except release */}
                        <PrivateRoute
                          path={routes.ROUTE_UNAUTHORIZED}
                          exact
                          component={PageUnauthorized}
                        />
                        <PrivateRoute
                          path={routes.ROUTE_GDPR_POLICY}
                          exact
                          component={GdprPolicy}
                        />
                        <PrivateRoute path="*" component={PageNotFound} />
                      </Switch>
                    </TicketProvider>
                  </ErrorBoundary>
                </Snackbars>
              </QueryParamProvider>
            </Router>
            <Toaster richColors closeButton position="bottom-left" />
          </UnexpectedErrorBoundary>
        </JssProvider>
      </ThemeProvider>
    </ShadcnThemeProvider>
  </ApolloProvider>
);

export default Root;
