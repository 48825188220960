import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';
import theme from 'style';

import { Icon } from '@stratumn/atomic';

import styles from './valueLabel.style';

// a single value element in the multiple values text area
export const ValueLabel = ({ classes, index, value, removeValue }) => {
  const remove = useCallback(() => {
    removeValue(index);
  }, [index, removeValue]);

  return (
    <div className={classes.label}>
      {value}
      <div
        className={classes.removeIcon}
        onClick={remove}
        data-cy="remove-label"
      >
        <Icon name="CircleCross" size={15} color={theme.white1} />
      </div>
    </div>
  );
};
ValueLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  removeValue: PropTypes.func.isRequired
};

export default compose(injectSheet(styles), React.memo)(ValueLabel);
