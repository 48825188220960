import { useMemo, useState } from 'react';
import { RJSFSchema, WidgetProps } from '@rjsf/utils';

import { guessType, asNumber } from './utils';

import withInlineValidation from '@/components/forms/wrappers/withInlineValidation';
import { isLockedWidget } from '@/components/forms/utils/links';
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/popover';

import { Button } from '@/shadcn/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/shadcn/command';
import { cn } from '@/shadcn/lib/utils';
import { FormWriterContext } from '../..';
import { Check, ChevronsUpDown } from 'lucide-react';

const nums = new Set(['number', 'integer']);

type Schema = {
  title: string;
  type: string;
  items: { type: string };
  enum: any;
};

type Options = {
  enumOptions: [{ label: string | undefined; value: string }];
};

type Props = {
  schema: Schema;
  options: Options;
  // invalid: boolean;
} & WidgetProps<any, RJSFSchema, FormWriterContext>;

export const SelectMenu = ({
  id,
  options,
  onChange,
  schema,
  value = '',
  disabled = false,
  invalid,
  formContext
}: Props) => {
  const [open, setOpen] = useState(false);
  const inputOptions = useMemo(() => {
    const optionsMapped = options.enumOptions.map(({ value, label }) => ({
      label,
      value
    }));
    return optionsMapped;
  }, [options]);

  const processValue = value => {
    if (value === undefined) return undefined;

    // 'enum' is a reserved word, so only 'type' and 'items' can be destructured
    const { type, items } = schema;
    if (value === '') {
      return undefined;
    }
    if (type === 'array' && items && nums.has(items.type)) {
      return value.map(asNumber);
    }
    if (type === 'boolean') {
      return value === 'true';
    }
    if (type === 'number') {
      return asNumber(value);
    }

    // If type is undefined, but an enum is present, try and infer the type from
    // the enum values
    if (schema.enum) {
      if (schema.enum.every(x => guessType(x) === 'number')) {
        return asNumber(value);
      }
      if (schema.enum.every(x => guessType(x) === 'boolean')) {
        return value === 'true';
      }
    }

    return value;
  };

  const handleChange = (newValue: string) => onChange(processValue(newValue));

  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext?.links);

  const selectedValue = useMemo(() => {
    return inputOptions.find(option => option.value === value)?.label;
  }, [inputOptions, value]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled || isLocked} asChild>
        <Button
          variant="outline"
          role="combobox"
          disabled={disabled || isLocked}
          aria-expanded={open}
          className={cn(
            'text-foreground border-input h-[var(--radix-select-trigger-height)] w-full justify-between text-sm font-normal normal-case',
            !selectedValue && 'text-foreground/60',
            invalid && 'border-destructive'
          )}
        >
          {selectedValue ? selectedValue : 'Select a value'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[var(--radix-popover-trigger-width)] p-0"
        align="center"
      >
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>No items found.</CommandEmpty>
            <CommandGroup>
              {inputOptions.map(option => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={currentValue => {
                    handleChange(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === option.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default withInlineValidation(SelectMenu);
