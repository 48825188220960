export default theme => ({
  root: {
    display: 'flex',
    width: '100%',
    maxWidth: 195,
    alignItems: 'baseline'
  },
  fullBar: {
    display: 'flex',
    height: 8,
    width: '100%',
    backgroundColor: theme.grey8,
    borderRadius: 10
  },
  dynamicBar: {
    height: '100%',
    width: props => `${props.completed}%` || 0,
    background: 'linear-gradient(90deg, #30CECA 0%, #6BEDD8 100%)',
    borderRadius: 'inherit',
    transition: 'width 200ms linear'
  },
  labelWrapper: {
    display: 'inline-flex',
    marginLeft: 5,
    '& span:last-child': {
      '&:first-letter': {
        textTransform: 'uppercase'
      }
    }
  },
  progressWrapper: {
    display: 'inline-flex',
    marginRight: 3,
    fontWeight: 600,
    '& span:first-child': {
      width: 15,
      textAlign: 'right'
    }
  }
});
