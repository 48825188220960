import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';
import { Oops } from '@stratumn/atomic';

const ErrorContext = React.createContext({});

export const withError = Component => props => (
  <ErrorContext.Consumer>
    {({ handleError }) => (
      <Component errorContext={{ handleError }} {...props} />
    )}
  </ErrorContext.Consumer>
);

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    error: null
  };

  setDocTitle() {
    const { error } = this.state;
    if (error) document.title = 'Oops! Page not found - Trace';
  }

  componentDidMount() {
    this.setDocTitle();
  }

  componentDidUpdate() {
    this.setDocTitle();
  }

  componentDidCatch(error, info) {
    this.setState({ error });
    Sentry.captureException(error, {
      extra: info
    });
  }

  handleError = (name, paramId, path) => {
    this.setState({
      error: {
        description:
          name &&
          paramId &&
          `The ${name} with the id ${paramId} does not exist.`,
        label: name && paramId && `Take me to the workflows' page`,
        path
      }
    });
  };

  goTo = route => {
    this.setState({ error: null });
    this.props.history.push(route);
  };

  renderErrorPage = () => {
    const { error } = this.state;

    return (
      <Oops
        onHomeClick={() => this.goTo(error.path || ROUTE_WORKFLOW_DASHBOARD)}
        label={error.label || 'take me home'}
        description={
          error.description ||
          "Sorry, we can't find the page you're looking for."
        }
      />
    );
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return (
      <ErrorContext.Provider value={{ handleError: this.handleError }}>
        {error ? this.renderErrorPage() : children}
      </ErrorContext.Provider>
    );
  }
}

export default compose(withRouter)(ErrorBoundary);
