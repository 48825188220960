import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Path from 'path-to-regexp';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import to from 'await-to-js';

import { LayoutKanban, Pushbutton } from '@stratumn/atomic';

import TraceIconSpinner from 'components/ui/traceIconSpinner';
import { Navbar } from 'components/layouts';

import { pluralize } from 'utils';
import { useToggle } from 'utils/hooks';

import {
  ROUTE_USER_DASHBOARD,
  ROUTE_WORKFLOW_GROUPS,
  ROUTE_WORKFLOW_OVERVIEW
} from 'constant/routes';
import { TOOLTIP_PORTAL } from 'constant/htmlIds';
import { withError } from 'components/errorBoundary';

import { notify } from 'components/toast';
import fragments from './fragments';
import styles from './groupSettings.style';

import { getGroupParticipants } from './utils';

import SubHeader from './subHeader';
import ParticipantsList from './participantsList';
import RemoveGroupMembersModal from './removeGroupMembersModal';
import AddParticipantsModal from './addParticipantsModal';

export const GroupSettings = ({
  errorContext: { handleError },
  history,
  match,
  classes,
  groupSettingsQuery,
  updateGroupMutation,
  addUsersMutation,
  updateGroupMembersMutation
}) => {
  const { loading, error, groupByRowId: group } = groupSettingsQuery;

  // set doc title at mount
  useEffect(() => {
    const { name } = group || {};
    if (name) document.title = `${name} - Group - Trace`;
  }, [group]);

  // Raise 404 page when group not found.
  const groupError = error || (!loading && !group);
  useEffect(() => {
    const { params } = match;

    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (groupError) {
      handleError('group', params.id, ROUTE_USER_DASHBOARD);
    }
  }, [groupError, match, handleError]);

  // memoized header config
  const header = useMemo(() => {
    const headerConfig = {
      loading: loading || !group?.workflow,
      bottomLevel: {
        workflowPage: false,
        infoContext: {
          links: [
            {
              icon: 'Group',
              label: 'Groups settings',
              path: group
                ? Path.compile(ROUTE_WORKFLOW_GROUPS)({
                    id: group.workflow.rowId
                  })
                : null
            },
            {
              label: group ? group.name : null
            }
          ]
        }
      }
    };
    if (group?.workflow) {
      headerConfig.bottomLevel.infoContext.links.unshift({
        icon: 'TableColumns',
        label: group.workflow?.name ?? 'Workflow',
        path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
          id: group.workflow?.rowId
        })
      });
    }

    return <Navbar config={headerConfig} />;
  }, [group]);

  // go back to workflow groups page
  const goBack = useCallback(() => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_GROUPS)({
        id: group.workflow.rowId
      })
    );
  }, [history, group]);

  // update group mutation callback
  const updateGroup = useCallback(
    async patch => {
      const updateGroupPromise = updateGroupMutation({
        variables: {
          groupRowId: group.rowId,
          patch: patch
        }
      });

      notify.promise(updateGroupPromise, {
        loading: '🛠️ Updating group name...',
        success: `Group ${patch.name || group.name} was correctly updated`,
        error: data => {
          console.error('Group update error: ', data);
          return 'Something went wrong during the group update...';
        }
      });
      await to(updateGroupPromise);
    },
    [group, updateGroupMutation]
  );

  // state to remove a set of members from the group
  const [membersToRemove, setMembersToRemove] = useState(null);
  // callback passed to children to trigger the confirmation modal
  const triggerRemoveGroupMemberModal = useCallback(
    (membersAccountIds, participantsStr) => {
      setMembersToRemove({ membersAccountIds, participantsStr });
    },
    []
  );
  // callback to cancel removal of group members
  const cancelRemoveFromGroup = useCallback(() => {
    setMembersToRemove(null);
  }, []);
  // callback to submit removal of group members
  const removeGroupMembers = useCallback(async () => {
    const { membersAccountIds, participantsStr } = membersToRemove;
    const removeParticipantPromise = updateGroupMembersMutation({
      variables: {
        groupId: group.rowId,
        members: membersAccountIds.map(memberId => ({
          memberId,
          action: 'REVOKE',
          role: 'READER'
        }))
      }
    });

    notify.promise(removeParticipantPromise, {
      loading: '🧹 Removing participants from the group...',
      success: `${participantsStr} correctly removed from Group ${group.name}`,
      error: data => {
        console.error('Group participants removal error: ', data);
        return 'Something went wrong during the group participants removal...';
      }
    });

    const [err] = await to(removeParticipantPromise);

    if (err) return;

    // clear the members to remove to close the modal
    setMembersToRemove(null);
  }, [membersToRemove, group, updateGroupMembersMutation]);

  // add participants modal state
  const [showAddParticipantsModal, switchShowAddParticipantsModal] =
    useToggle(false);

  // callback to submit addition of users from their email
  const addUsers = useCallback(
    async usersEmails => {
      const addUserPromise = addUsersMutation({
        variables: {
          groupRowId: group.rowId,
          emails: usersEmails
        }
      });
      notify.promise(addUserPromise, {
        loading: '🧹 Adding users to the group...',
        success: `${pluralize(
          usersEmails.length,
          'user'
        )} correctly added to Group ${group.name}`,
        error: data => {
          console.error('Group users addition error: ', data);
          return 'Something went wrong during the group users addition...';
        }
      });
      const [err] = await to(addUserPromise);

      if (err) return;

      // if everything went well close the modal
      switchShowAddParticipantsModal();
    },
    [group, addUsersMutation, switchShowAddParticipantsModal]
  );

  // callback to submit addition of teams from their accountId
  const addTeams = useCallback(
    async teamsAccountIds => {
      const addTeamPromise = updateGroupMembersMutation({
        variables: {
          groupId: group.rowId,
          members: teamsAccountIds.map(memberId => ({
            memberId,
            action: 'GRANT',
            role: 'READER'
          }))
        }
      });

      notify.promise(addTeamPromise, {
        loading: '🧹 Adding teams to the group...',
        success: `${pluralize(
          teamsAccountIds.length,
          'team'
        )} correctly added to Group ${group.name}`,
        error: data => {
          console.error('Group teams addition error: ', data);
          return 'Something went wrong during the group teams addition...';
        }
      });

      const [err] = await to(addTeamPromise);

      if (err) return;

      // if everything went well close the modal
      switchShowAddParticipantsModal();
    },
    [group, updateGroupMembersMutation, switchShowAddParticipantsModal]
  );

  // memoized group participants
  const {
    otherParticipants = [],
    teams = [],
    nbParticipants
  } = useMemo(
    () => (!group ? {} : getGroupParticipants(group.members.nodes)),
    [group]
  );

  // memoized sections list
  const sectionsList = useMemo(() => {
    if (loading) return <TraceIconSpinner />;

    // if no participant at all display a specific message
    if (!teams.length > 0 && !otherParticipants.length > 0)
      return (
        <div className={classes.noParticipantsMessage}>
          No participants to display here
        </div>
      );

    // otherwise display first the list of 'other participants'
    // and then all teams
    return (
      <div className={classes.sectionsList}>
        {otherParticipants.length > 0 && (
          <ParticipantsList
            title="Other Participants"
            participants={otherParticipants}
            removeGroupMembers={triggerRemoveGroupMemberModal}
          />
        )}
        {teams.map(team => (
          <ParticipantsList
            key={team.accountId}
            title={team.name}
            participants={team.participants}
            teamAccountId={team.accountId}
            removeGroupMembers={triggerRemoveGroupMemberModal}
          />
        ))}
      </div>
    );
  }, [loading, otherParticipants, teams, nbParticipants]);

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        {header}
        <div className={classes.content}>
          <SubHeader
            group={group}
            updateGroup={updateGroup}
            nbParticipants={nbParticipants}
            nbTeams={teams.length}
            openAddParticipantsModal={switchShowAddParticipantsModal}
          />
          <div className={classes.sectionsListContainer}>{sectionsList}</div>
          <div className={classes.footer}>
            <Pushbutton
              onClick={goBack}
              disabled={false}
              dataCy="go-back-button"
            >
              Go Back To Groups
            </Pushbutton>
          </div>
        </div>
      </LayoutKanban>
      {membersToRemove && (
        <RemoveGroupMembersModal
          groupName={group.name}
          participantsStr={membersToRemove.participantsStr}
          submit={removeGroupMembers}
          cancel={cancelRemoveFromGroup}
        />
      )}
      {showAddParticipantsModal && (
        <AddParticipantsModal
          groupName={group.name}
          groupId={group.rowId}
          submitAddUsers={addUsers}
          submitAddTeams={addTeams}
          cancel={switchShowAddParticipantsModal}
        />
      )}
    </>
  );
};

GroupSettings.propTypes = {
  errorContext: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  groupSettingsQuery: PropTypes.object.isRequired,
  updateGroupMutation: PropTypes.func.isRequired,
  addUsersMutation: PropTypes.func.isRequired,
  updateGroupMembersMutation: PropTypes.func.isRequired
};

const GROUP_SETTINGS_QUERY = gql`
  query groupSettingsQuery($groupRowId: BigInt!) {
    groupByRowId(rowId: $groupRowId) {
      ...GroupSettingsFragment
    }
  }
  ${fragments.groupSettings}
`;

const UPDATE_GROUP_MUTATION = gql`
  mutation updateGroupMutation($groupRowId: BigInt!, $patch: GroupPatch!) {
    updateGroupByRowId(input: { rowId: $groupRowId, patch: $patch }) {
      group {
        rowId
        name
        avatar
      }
    }
  }
`;

const ADD_USERS_MUTATION = gql`
  mutation addUsersMutation($groupRowId: BigInt!, $emails: [String]!) {
    inviteUsersToGroup(input: { groupRowId: $groupRowId, emails: $emails }) {
      group {
        ...GroupSettingsFragment
      }
    }
  }
  ${fragments.groupSettings}
`;

const UPDATE_GROUP_MEMBERS_MUTATION = gql`
  mutation updateGroupMembersMutation(
    $groupId: BigInt
    $members: [UpdateGroupMemberActionInput]
  ) {
    updateGroupMembers(input: { groupId: $groupId, members: $members }) {
      group {
        ...GroupSettingsFragment
      }
    }
  }
  ${fragments.groupSettings}
`;

export default compose(
  graphql(GROUP_SETTINGS_QUERY, {
    name: 'groupSettingsQuery',
    options: ({ match }) => ({
      variables: {
        groupRowId: match.params.id
      },
      fetchPolicy: 'cache-and-network'
    })
  }),
  graphql(UPDATE_GROUP_MUTATION, {
    name: 'updateGroupMutation'
  }),
  graphql(ADD_USERS_MUTATION, {
    name: 'addUsersMutation'
  }),
  graphql(UPDATE_GROUP_MEMBERS_MUTATION, {
    name: 'updateGroupMembersMutation'
  }),
  withRouter,
  withError,
  injectSheet(styles),
  React.memo
)(GroupSettings);
