import CheckboxWidget from './checkboxWidget';
import CheckboxesWidget from './checkboxesWidget';
import DateWidget from './dateWidget';
import SlateReaderWidget from './slateReaderWidget';
import NumericWidget from './numericWidget';
import Radio from './radio';
import TextWidget from './textWidget';
import GroupWidget from './groupWidget';

const widgets: {
  [componentName: string]: React.FC<any>;
} = {
  TextWidget: TextWidget,
  NumericWidget,
  CheckboxWidget,
  CheckboxesWidget,
  draft: SlateReaderWidget,
  TextareaWidget: TextWidget,
  SelectWidget: TextWidget,
  RadioWidget: Radio,
  'alt-date': DateWidget,
  'workflow-group': GroupWidget,
  MultiSelectWidget: CheckboxesWidget
};

export default widgets;
