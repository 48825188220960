import React, { PureComponent } from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import classNames from 'classnames';

import Autonumeric from 'autonumeric';
import numeral from 'numeral';

import styles from './numericInput.style';

interface Props {
  classes: ClassNameMap<string>;
  onChange: any;
  value?: number;
  [otherProp: string]: any;
}

interface State {
  value?: number;
}

export class NumericInput extends PureComponent<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>();

  state = {
    value: this.props.value
  };

  componentDidMount() {
    this.inputRef.current!.value = String(this.state.value);
    new Autonumeric(this.inputRef.current!);
  }

  handleChange = (event: any) => {
    const temp = numeral(event.target.value).value();
    const value = temp === null ? undefined : temp;
    this.setState({ value });
    this.props.onChange(value);
  };

  isNegative = () => this.state.value! < 0;

  render() {
    const { classes, ...otherProps } = this.props;
    return (
      <input
        ref={this.inputRef}
        // onChange={this.handleChange}
        className={classNames(
          classes.numericInput,
          this.isNegative() && classes.negative
        )}
        {...otherProps}
      />
    );
  }
}

export default injectSheet(styles)(NumericInput);
