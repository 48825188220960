import { PropsWithChildren } from 'react';

export const ButtonInspectorWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div className="before:bg-border relative pt-[30px] pr-[100px] pl-[50px] before:absolute before:top-[80px] before:left-0 before:-z-10 before:h-[1px] before:w-[90px] before:content-['']">
      <div className="flex flex-col items-center gap-2 py-[35px] pb-[17px]">
        {children}
      </div>
    </div>
  );
};
