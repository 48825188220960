import React from 'react';
import injectSheet from 'react-jss';

import { Label as LabelType } from '../types';

import styles from './label.style';

export const Label: React.FC<LabelType> = ({
  classes,
  filesCount = 0,
  errorsCount = 0
}) => (
  <div className={classes.root}>
    <div className={classes.filesInfo}>
      {errorsCount > 0 && (
        <span className={classes.errorsCount}>
          <span>{String(errorsCount)}</span>
          <span> error{errorsCount > 1 && 's'}</span>
        </span>
      )}
      <span className={classes.filesCount}>
        <span>{String(filesCount)}</span>
        <span> file{filesCount > 1 && 's'}</span>
      </span>
    </div>
  </div>
);

export default injectSheet(styles)(React.memo(Label));
