import { memo, useState, useEffect } from 'react';

import { Icon } from '@stratumn/atomic';

import { getByPath } from '@/utils/widgets';

import { Widget } from '@/components/ui/widget';

import { cn } from '@/shadcn/lib/utils';
import { WidgetData } from '../../widget.type';
import { KeyValueView } from './keyValue.type';

const localStorageKey = 'keyValueArraysCollapsed' as const;

const storeCollapsedState = (
  traceId: string,
  arrayKey: string,
  collapsedState: boolean
) => {
  let storage;
  try {
    const item = localStorage.getItem(localStorageKey);
    if (!item) throw new Error('No item found in localStorage');
    // Check if storage is a valid parsable JSON string
    storage = JSON.parse(item);
    // If no error, check if it is not null and that it is a real object (and not an array since the type of an array is 'object' in javascript)
    storage =
      !!storage && typeof storage === 'object' && !Array.isArray(storage)
        ? storage // If yes keep the value
        : {}; // If not, set the value as an empty object
  } catch {
    // If there is an error (this would happen at the JSON.parse), set the value as an empty object
    storage = {};
  }
  // Create the trace's key as an empty object if it doesn't exist yet
  storage[traceId] = storage[traceId] || {};
  // Store the collapsed state of the array's key
  storage[traceId][arrayKey] = collapsedState;
  // Store the complete object as a string in the localStorage key
  localStorage.setItem(localStorageKey, JSON.stringify(storage));
};

const getCollapsedState = (traceId: string, arrayKey: string) => {
  try {
    const item = localStorage.getItem(localStorageKey);
    if (!item) return undefined;
    const storage = JSON.parse(item);
    return storage?.[traceId]?.[arrayKey];
  } catch {
    return undefined;
  }
};

type Props = {
  view: KeyValueView;
  data: WidgetData;
  setSelectableItemData?: (data: any) => void;
};

const KeyValueViewImpl = memo(
  ({ view, data, setSelectableItemData = undefined }: Props) => {
    const { key, value } = view;

    const dataValueByPath =
      value.view.path && getByPath<unknown>(data, value.view.path);

    useEffect(() => {
      if (setSelectableItemData) setSelectableItemData(dataValueByPath);
    }, [dataValueByPath]);

    const {
      trace: { rowId: traceId }
    } = data;

    // Check if value is an array and if it should be collapsed (when the array contains more than 1 item)
    const isArray =
      // @ts-expect-error need to ignore this since arrayView does not have a path property
      // including it in the CoreViewType would break lot of types
      // ! The comment feature strikes again...
      value.view.type === 'array' && Array.isArray(dataValueByPath);
    const isCollapsable = isArray && dataValueByPath?.length > 1;

    // Collapsed State variable
    const [isCollapsed, setIsCollapsed] = useState(() => {
      const localStorageState = getCollapsedState(traceId, key);
      return localStorageState ?? isCollapsable;
    });

    // Event handler
    const toggleCollapse = () => {
      storeCollapsedState(traceId, key, !isCollapsed);
      setIsCollapsed(!isCollapsed);
    };

    if (!dataValueByPath) return null;

    return (
      <div className="group flex w-full flex-col flex-nowrap gap-[5px]">
        <div
          className={cn(
            'text-primary-deep flex items-center',
            isCollapsable && 'hover:text-primary-deep/70 cursor-pointer'
          )}
          onClick={isCollapsable ? toggleCollapse : undefined}
          data-cy="clickable-title"
        >
          <div
            className="truncate text-[10px] leading-[12px] font-semibold tracking-[0.5px] uppercase"
            title={key}
          >
            {key}
          </div>
          {isCollapsable && (
            <div
              className="ml-1 h-3 w-3 transition-transform duration-200 ease-in-out data-[is-collapsed=false]:rotate-180"
              data-is-collapsed={isCollapsed}
            >
              <Icon name="ArrowDownFill" />
            </div>
          )}
        </div>
        <div className={cn(isCollapsed && 'hidden')}>
          <Widget
            widget={value}
            data={data}
            className="border-border text-card-foreground group-hover:border-primary ml-2 flex min-h-[18px] flex-row flex-nowrap items-center truncate border-l-2 pl-4 text-sm"
          />
        </div>
      </div>
    );
  }
);

export const keyValueViewProvider = {
  component: KeyValueViewImpl
};
