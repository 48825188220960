import { Pushtext } from '@stratumn/atomic';

import { useSelectableItemsContext, getIsAllSelected } from '..';

const SelectAllButton = () => {
  const selectableItemsContext = useSelectableItemsContext();

  if (!selectableItemsContext) return null;

  const { handleSelectAll, treeValue } = selectableItemsContext;

  const isAllSelected = getIsAllSelected(treeValue);

  const handleClick = () => {
    handleSelectAll(!isAllSelected);
  };

  return (
    <div>
      <Pushtext mute onClick={handleClick}>
        {isAllSelected ? 'Unselect all' : 'Select all'}
      </Pushtext>
    </div>
  );
};

export default SelectAllButton;
