import { memo, useMemo } from 'react';

import { getByPath } from '@/utils/widgets';
import Table from '@/components/ui/table';
import { TableView } from './table.view.type';
import { TextView } from '../text/text.view.type';
import { TableRow, TableUpdate } from '@/components/ui/table/table.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: TableView;
  data: WidgetData;
  update?: TableUpdate | null;
};

const TableViewImpl = memo(({ view, data, update = null }: Props) => {
  const { path, config = {}, height = '75vh', userConfigKeyPath } = view;

  const tableData = getByPath<TableRow[]>(data, path) || [];

  // caching the user display config is configurable in the view
  // and key can be contextualized (like traceId_dataName)
  const userConfigKey = userConfigKeyPath
    ? getByPath(data, userConfigKeyPath)
    : undefined;

  const tableUpdate = useMemo(
    () => ({
      ...update,
      userDisplay: {
        localStorageKey: userConfigKey
      }
    }),
    [update, userConfigKey]
  );

  return (
    <div className="w-full" style={{ height }}>
      <Table data={tableData} config={config} update={tableUpdate} />
    </div>
  );
});

// sort defaults to length on path
const getSortConfig = (view: TableView) => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: TableView): TextView => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

export const tableViewProvider = {
  component: TableViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth
};
