export default theme => ({
  root: {
    '& h5': {
      marginBottom: 10,
      fontWeight: 600,
      fontSize: 11,
      lineHeight: ' 14px',
      color: theme.grey1,
      '&::first-letter': {
        textTransform: 'uppercase'
      }
    },
    '& li': {
      fontWeight: 'normal',
      fontSize: 10,
      lineHeight: ' 13px',
      color: theme.grey1,
      '&:not(:last-child)': {
        marginBottom: 5
      },
      '&::first-letter': {
        textTransform: 'uppercase'
      },
      '& span': {
        paddingLeft: 2,
        color: theme.grey4,
        '&[data-has-more-extension="true"]': {
          '&::after': {
            content: "'...'"
          }
        }
      }
    },
    '&[data-is-responsive=true]': {
      marginTop: 20,
      textAlign: 'center'
    }
  }
});
