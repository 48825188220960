import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import filesize from 'filesize';

import { Modal, ModalContent, LoaderTraceLogo } from '@stratumn/atomic';
import { OutsideLink, DocumentSearch } from '@stratumn/icons';

import Table from 'components/ui/table';
import {
  buildTableConfigFromMapping,
  buildTableConfigFromColumnNames
} from 'components/ui/dataImporter/utils';

import { downloadFile } from 'utils/downloadFile';
import { formatNumber } from 'utils';

// data importer reader component
const DataImporterReader = React.memo(({ classes, dataImporter, data }) => {
  // state displaying the table data modal
  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = useCallback(
    () => setShowModal(!showModal),
    [showModal]
  );

  // file downloading state
  const [loading, setLoading] = useState(false);

  const {
    importedDataKey = 'rows',
    importedDataTitle = 'Imported Data',
    fileKey = 'file',
    table: tableConfig,
    mapping
  } = dataImporter;

  const { [importedDataKey]: importedData, [fileKey]: file } = data;

  // if table modal is shown and no table config has been declared in the action schema
  // then build it from imported data (or mapping) as in dataImporter
  const usedTableConfig = useMemo(() => {
    if (!tableConfig) {
      if (mapping) {
        // use the mapping to build the table config
        return buildTableConfigFromMapping(mapping);
      }
      // just use the column names to build it
      let foundKeys = [];
      (importedData || []).forEach(row => {
        foundKeys = foundKeys.concat(
          Object.keys(row).filter(key => !foundKeys.includes(key))
        );
      });
      return buildTableConfigFromColumnNames(foundKeys);
    }
    return tableConfig;
  }, [tableConfig, mapping, importedData]);

  const nbImportsMsg = `${formatNumber((importedData || []).length)} rows`;
  const fileExtension = file?.name?.split('.').pop().toUpperCase();
  return (
    <>
      <div className={classes.readerItem}>
        <div className={classes.readerItemTitle}>{importedDataTitle}</div>
        <div className={classes.readerInterfaceContainer}>
          <div
            className={classnames(
              classes.readerInterfaceItem,
              classes.readerInterface
            )}
            onClick={toggleShowModal}
          >
            <div className={classes.readerInterfaceLink}>
              <DocumentSearch className={classes.readerInterfaceIcon} />
              <div>
                <div className={classes.readerInterfaceInfoHeader}>View</div>
                <div className={classes.readerInterfaceInfoSubHeader}>
                  {`imported data (${nbImportsMsg})`}
                </div>
              </div>
            </div>
          </div>
          {file && (
            <div
              className={classnames(
                classes.readerInterfaceItem,
                classes.readerInterfaceInfoSubHeader,
                classes.readerInterfaceDownloadContainer
              )}
            >
              <div>
                <div
                  className={classes.readerInterfaceFile}
                  onClick={() => downloadFile(file, setLoading, loading)}
                  data-is-loading={loading}
                >
                  <div>Download {fileExtension} file</div>
                  <OutsideLink className={classes.outsideIcon} />
                </div>
                <div className={classes.readerInterfaceFileSize}>
                  {filesize(file.size, { round: 0 })}
                </div>
              </div>
              {loading && <LoaderTraceLogo />}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={
            <div className={classes.readerModalTitle}>
              <div>{importedDataTitle}</div>
              <div className={classes.readerModalTitleSubHeader}>
                {nbImportsMsg}
              </div>
            </div>
          }
          handleCollapse={toggleShowModal}
          fullscreen
        >
          <ModalContent>
            <div className={classes.readerModalBody}>
              <Table data={importedData || []} config={usedTableConfig} />
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  );
});

DataImporterReader.propTypes = {
  classes: PropTypes.object.isRequired,
  dataImporter: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default DataImporterReader;
