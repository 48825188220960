import { memo, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormWriter } from 'components/forms';

import { uploadConfig } from 'client/media';
import { downloadFile } from 'utils/downloadFile';

import {
  FORMS_BATCH_DELETE,
  FORMS_BATCH_UPDATE,
  FORMS_BATCH_REPORT_VALIDATION_STATUS,
  FORMS_BATCH_TOGGLE_LOCK,
  FORMS_BATCH_TOGGLE_LINK
} from '../reducers';

import { cn } from '@/shadcn/lib/utils';
import { X } from 'lucide-react';

export const BatchForm = memo(
  ({
    workflowContext,
    schema,
    uiSchema,
    id,
    isMainForm = false,
    isInBatch = false,
    data,
    linkedPaths = [],
    lockedPaths = [],
    updateForms,
    uploadFile,
    uploadImage,
    validating,
    hasErrorFocus
  }) => {
    // callback to close a form
    const onFormClose = useCallback(() => {
      updateForms({
        type: FORMS_BATCH_DELETE,
        id
      });
    }, [id, updateForms]);

    // callback to update form data
    const onFormDataChange = useCallback(
      args => {
        const { formData } = args;
        updateForms({
          type: FORMS_BATCH_UPDATE,
          id,
          data: formData
        });
      },
      [id, updateForms]
    );

    // ref to a hidden submit button, to trigger form validation manually
    const sumbitBtnRef = useRef(null);
    useEffect(() => {
      // if validating state changes and is now true, trigger form validation manually
      // validation result will be reported thanks to reportSuccess and reportError
      if (validating && sumbitBtnRef.current) sumbitBtnRef.current.click();
    }, [validating]);
    const reportSuccess = useCallback(() => {
      updateForms({
        type: FORMS_BATCH_REPORT_VALIDATION_STATUS,
        id,
        success: true
      });
    }, [id, updateForms]);
    const reportError = useCallback(() => {
      updateForms({
        type: FORMS_BATCH_REPORT_VALIDATION_STATUS,
        id,
        success: false
      });
    }, [id, updateForms]);

    const links = useMemo(
      () => ({
        id,
        isMainForm,
        onClickLock: path => {
          updateForms({
            type: FORMS_BATCH_TOGGLE_LOCK,
            path
          });
        },
        onClickLink: path => {
          updateForms({
            type: FORMS_BATCH_TOGGLE_LINK,
            id,
            path
          });
        },
        linkedPaths,
        lockedPaths
      }),
      [id, isMainForm, linkedPaths, lockedPaths, updateForms]
    );

    const staticUploadConfig = useMemo(
      () => ({
        maxFileSize: uploadConfig.MAX_FILE_SIZE,
        fileExtensionWhitelistMap: uploadConfig.FILE_EXTENSION_WHITELIST_MAP
      }),
      []
    );

    return (
      <div
        className={cn(
          'border-border bg-card relative mr-5 w-[520px] shrink-0 rounded border p-4',
          isMainForm && 'sticky left-0 z-10 shadow-xs',
          hasErrorFocus && 'border-destructive'
        )}
        data-is-main={isMainForm}
        data-error-focus={hasErrorFocus}
      >
        <div className="text-muted-foreground relative flex h-6 w-full flex-row flex-nowrap items-center justify-end text-xs">
          {isInBatch &&
            (isMainForm ? (
              'Main form'
            ) : (
              <div
                className="text-muted-foreground hover:text-destructive absolute -right-1 cursor-pointer"
                onClick={onFormClose}
                data-cy="close-form"
              >
                <X size={20} />
              </div>
            ))}
        </div>
        <FormWriter
          id={id}
          schema={schema}
          uiSchema={uiSchema}
          onChange={onFormDataChange}
          formData={data}
          uploadFile={uploadFile}
          uploadImage={uploadImage}
          uploadConfig={staticUploadConfig}
          downloadImage={downloadFile}
          onSubmit={reportSuccess}
          onError={reportError}
          links={links}
          workflowContext={workflowContext}
        />
        <button type="submit" className="hidden" form={id} ref={sumbitBtnRef} />
      </div>
    );
  }
);
BatchForm.propTypes = {
  workflowContext: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isMainForm: PropTypes.bool,
  isInBatch: PropTypes.bool,
  data: PropTypes.object.isRequired,
  linkedPaths: PropTypes.arrayOf(PropTypes.string),
  lockedPaths: PropTypes.arrayOf(PropTypes.string),
  updateForms: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  validating: PropTypes.bool.isRequired,
  hasErrorFocus: PropTypes.bool.isRequired
};
