import React, { ReactNode } from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';
import { formatNumber } from '../../../../../utils/formatNumber';

import styles from './textWidget.style';
import { KeyValue } from '@stratumn/atomic';

type Props = {
  classes: ClassNameMap<string>;
} & Pick<WidgetProps, 'label' | 'value'>;

export const TextWidget: React.FC<Props> = ({
  classes,
  label = '',
  value = ''
}) => {
  if (label === 'Answer') {
    return (
      <div className={classes.value}>
        {!value && Number(value) ? formatNumber(value, {}) : value}
      </div>
    );
  }

  const formatTextValue = (value: string): ReactNode => {
    if (
      typeof value === 'string' &&
      (value.startsWith('http://') || value.startsWith('https://'))
    ) {
      return (
        <a
          className={classes.externalLink}
          href={value}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }

    return !value && Number(value) ? formatNumber(value, {}) : value;
  };

  return <KeyValue label={label}>{formatTextValue(value)}</KeyValue>;
};

export default injectSheet(styles)(TextWidget);
