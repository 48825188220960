import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import injectSheet, { ClassNameMap } from 'react-jss';
import Markdown from 'react-markdown';

import styles from './objectField.style';

type Props = {
  classes: ClassNameMap<string>;
  title?: string | null;
  description?: string | null;
  properties: any[];
} & WidgetProps;

const ObjectFieldWriter: React.FC<Props> = ({
  classes,
  title,
  description,
  properties
}) => (
  <>
    {(title || description) && (
      <div className={classes.styledBox}>
        {title && <p className={classes.styledText}>{title}</p>}
        {description && (
          <div className={classes.styledDiv}>
            <Markdown className="descriptionMarkdown" source={description} />
          </div>
        )}
      </div>
    )}
    {properties.map(property => property.content)}
  </>
);

export default injectSheet(styles)(ObjectFieldWriter);
