import {
  DATA_EDITOR_SET,
  DATA_EDITOR_UNSET,
  DATA_EDITOR_UPDATE,
  DATA_EDITOR_BATCH_UPDATE
} from '@/constant/dataEditor';

import { setAtPath, getByPath, setAtKey } from '@/utils/widgets';

/**
 * @desc apply a single modification to a data patch
 * @desc request coming from the view editors themseleves
 * @param {object} currentModifiedData - the current map of rowSelectors => data patches
 * @param {string} rowSelector - the key of the data patch to modify
 * @param {object} dataToChange - the {path,value} change to apply to the selected patch
 * @param {string} [cachedUpdatesKey] - the key to data patches cached in the local storage
 * @returns {object} the new map of rowSelectors => data patches
 */
const applyModifiedRowDataUpdate = (
  currentModifiedData,
  rowSelector,
  dataToChange,
  cachedUpdatesKey
) => {
  const newModifiedData = {
    ...currentModifiedData
  };

  // apply the modifiedData updates for this single row
  let modifiedRowData = {
    ...currentModifiedData[`${rowSelector}`]
  };

  const { path: modifiedDataPath, value: modifiedDataValue } = dataToChange;

  // do the actualisation
  setAtPath(modifiedRowData, modifiedDataPath, modifiedDataValue);

  // if no more patch, remove the modified row
  if (Object.keys(modifiedRowData).length === 0) {
    modifiedRowData = undefined;
  }
  setAtKey(newModifiedData, rowSelector, modifiedRowData); // note: this handles deletion if modifiedRowData is undefined

  if (cachedUpdatesKey) {
    localStorage.setItem(cachedUpdatesKey, JSON.stringify(newModifiedData));
  }

  return newModifiedData;
};

/**
 * @desc apply modification to a single row data
 * @desc from a 'batched' patch
 * @param {object} initialRowData - the object on which the patch is applied
 * @param {object} currentModifiedRowData - the current data patch for this row
 * @param {object} patch - the patch to propagate to this row
 * @param {array} editablePaths - the list of paths potentialy updated within the patch
 * @returns {object} the new data patch for this row
 */
export const applyModifiedRowDataUpdateFromPatch = (
  initialRowData,
  currentModifiedRowData,
  patch,
  editablePaths
) => {
  let modifiedRowData = {
    ...currentModifiedRowData
  };

  // parse the editable fields and check what to do on this specific row...
  editablePaths.forEach(editablePath => {
    // check whether the data at this path has been patched
    const patchData = getByPath(patch, editablePath);
    if (patchData !== undefined) {
      // now check what the initial data was
      const initialData = getByPath(initialRowData, editablePath);
      // in case of equality, unset the preceding row patch value, whatever it was
      // otherwise set the new patch value (even if it was the same previously)
      setAtPath(
        modifiedRowData,
        editablePath,
        initialData === patchData ? undefined : patchData
      );
    }
  });

  // if no more patch, remove the modified row
  if (Object.keys(modifiedRowData).length === 0) {
    modifiedRowData = undefined;
  }

  return modifiedRowData;
};

/**
 * @desc function to apply data modification updates embeded in a single 'batched' patch
 * @desc to several rows identified by their selector
 * @param {array} initialData - the initial array of objects on which the patch is applied
 * @param {string} dataSelectorPath - the path to the (ideally primary) key of data objects on which patches are applied
 * @param {object} currentModifiedData - the current map of rowSelectors => data patches
 * @param {array} rowSelectors - the list of row selectors of objects that should be updated with this single patch
 * @param {object} patch - the patch to propagate to all selected rows
 * @param {array} editablePaths - the list of paths potentialy updated within the patch
 * @param {string} [cachedUpdatesKey] - the key to data patches cached in the local storage
 * @returns {object} the new map of rowSelectors => data patches
 */
const applyModifiedDataBatchUpdate = (
  initalData,
  dataSelectorPath,
  currentModifiedData,
  rowSelectors,
  patch,
  editablePaths,
  cachedUpdatesKey
) => {
  const newModifiedData = {
    ...currentModifiedData
  };

  rowSelectors.forEach(rowSelector => {
    // get initial row
    const initalRowData = initalData.find(
      row => getByPath(row, dataSelectorPath) === rowSelector
    );

    // apply the modifiedData batch update for this single row
    const modifiedRowData = applyModifiedRowDataUpdateFromPatch(
      initalRowData,
      currentModifiedData[rowSelector],
      patch,
      editablePaths
    );

    setAtKey(newModifiedData, rowSelector, modifiedRowData); // note: this handles deletion if modifiedRowData is undefined
  });

  if (cachedUpdatesKey) {
    localStorage.setItem(cachedUpdatesKey, JSON.stringify(newModifiedData));
  }

  return newModifiedData;
};

/**
 * @desc reducer function to apply data modifications (ie produce a set of patches for data objects)
 * @param {array} initialData - the initial array of objects on which the patches are applied
 * @param {string} dataSelectorPath - the path to the (ideally primary) key of data objects on which patches are applied
 * @param {string} [cachedUpdatesKey] - the key to data patches cached in the local storage
 * @param {object} currentModifiedData - the current map of rowSelectors => data patches
 * @param {object} action - the action to apply
 * @returns {object} the new map of rowSelectors => data patches
 */
export const applyModifiedDataChange = (
  initialData,
  dataSelectorPath,
  cachedUpdatesKey,
  currentModifiedData,
  action
) => {
  const usedCacheUpdatesKey = action.disableCache
    ? undefined
    : cachedUpdatesKey;
  switch (action.type) {
    case DATA_EDITOR_SET:
      if (usedCacheUpdatesKey) {
        localStorage.setItem(
          usedCacheUpdatesKey,
          JSON.stringify(action.modifiedData)
        );
      }
      return action.modifiedData;
    case DATA_EDITOR_UNSET:
      if (usedCacheUpdatesKey) {
        localStorage.removeItem(usedCacheUpdatesKey);
      }
      return {};
    case DATA_EDITOR_UPDATE:
      return applyModifiedRowDataUpdate(
        currentModifiedData,
        action.rowSelector,
        action.dataToChange,
        usedCacheUpdatesKey
      );
    case DATA_EDITOR_BATCH_UPDATE:
      return applyModifiedDataBatchUpdate(
        initialData,
        dataSelectorPath,
        currentModifiedData,
        action.rowSelectors,
        action.patch,
        action.editablePaths,
        usedCacheUpdatesKey
      );
    default:
      throw new Error(`Unknown data reducer type ${action.type}`);
  }
};

export const getFinalData = (initialData, modifiedData, dataSelectorPath) => {
  return initialData.map(row => {
    const rowId = getByPath(row, dataSelectorPath);
    return {
      ...row,
      ...modifiedData[rowId]
    };
  });
};
/**
 * @param {unknown[]} initialData
 * @param {Record<string, unknown>} modifiedData
 * @param {string} dataSelectorPath
 * @returns {Record<string, Record<string, unknown>>}
 */
export const getFinalDataByKey = (
  initialData,
  modifiedData,
  dataSelectorPath
) => {
  return initialData.reduce((acc, row) => {
    const selector = getByPath(row, dataSelectorPath);
    acc[selector] = {
      ...row,
      ...modifiedData[selector]
    };
    return acc;
  }, {});
};
