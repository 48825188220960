import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { withApollo } from '@apollo/client/react/hoc';

import { uploadFile, handleUploadFileOnError } from 'client/media';
import to from 'await-to-js';
import { isValidURL } from 'utils';
import { notify } from 'components/toast';

import {
  ErrorMessage,
  FieldText,
  LoaderTraceLogo,
  Modal,
  ModalActions,
  Pushbutton,
  Tabs
} from '@stratumn/atomic';
import DropZone from 'components/ui/dropZone';
import DownloadFile from '../downloadFile';

import mutation from './mutation';

import styles from './uploadDocumentationModal.style';
import { withSpanAsync, SpanType } from '../../../../../tracing';

const TYPE_URL = 'url';
const TYPE_FILE = 'file';

export const regexHttp = new RegExp('^(http|https)://', 'i');

export const UploadDocumentationModal = React.memo(
  ({ classes, workflowRowId, updateWorkflowDocumentation, onClose }) => {
    const [url, setUrl] = React.useState('');
    const [validUrl, setValidUrl] = React.useState(true);
    const [fileImport, setFileImport] = React.useState(null);
    const [dropZoneActive, setDropZoneActive] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [submitted, setSubmit] = React.useState(false);

    const handleChange = event => {
      const targetUrl = event.target.value;
      /**
       * we clear the url stored within the state
       * as only one documentation can be uploaded
       */
      if (!fileImport) clearFileImport();

      setUrl(targetUrl);

      return setValidUrl(isValidURL(targetUrl));
    };

    const handleBlur = () =>
      isValidURL(url) ? setValidUrl(true) : setValidUrl(false);

    const handleNewFile = useCallback(file => {
      const inputFile = file[0];

      /**
       * we clear the url stored within the state
       * as only one documentation can be uploaded
       */
      setUrl('');
      return setFileImport(inputFile);
    }, []);

    const clearFileImport = useCallback(() => {
      setLoading(false);
      setFileImport(null);
      setSubmit(false);
      setDropZoneActive(false);
    }, []);

    // set unset active drop zone
    const onDropZoneEnter = useCallback(() => {
      setDropZoneActive(true);
    }, []);
    const onDropZoneLeave = useCallback(() => {
      setDropZoneActive(false);
    }, []);

    const onSubmit = () => {
      withSpanAsync(
        'updateWorkflowDocumentation',
        SpanType.processing,
        async () => {
          setSubmit(true);

          let inputType;

          const mutationInput = {
            rowId: workflowRowId,
            url: null,
            fileDigest: null
          };

          if (url) {
            // If user omits to add https, we'll add it for them
            mutationInput.url = !regexHttp.test(url) ? `https://${url}` : url;
            inputType = TYPE_URL;
          }

          if (fileImport) {
            setLoading(true);

            inputType = TYPE_FILE;

            const file = await uploadFile(
              fileImport,

              // onSuccess
              fl => {
                setSubmit(false);
                return fl;
              },

              // onError
              error => {
                clearFileImport();
                return error;
              },

              // onProgress
              null,

              // Disable encryption
              true
            );

            if (file && file.response) {
              handleUploadFileOnError(fileImport.name, file.response.status);
              return;
            }

            mutationInput.fileDigest = file && file.digest;
          }

          const promise = updateWorkflowDocumentation({
            variables: mutationInput
          });

          notify.promise(promise, {
            loading: 'Uploading documentation...',
            success: `${
              inputType === 'file' ? `File` : 'URL'
            } documentation uploaded`,
            error: `${
              inputType === 'file' ? `${fileImport.name} File` : 'URL'
            } documentation could not be uploaded`
          });

          const [err] = await to(promise);

          if (err) {
            setSubmit(false);
          }

          onClose();
        }
      );
    };

    const renderDocumentTab = () => {
      if (loading) return <LoaderTraceLogo />;

      if (fileImport) {
        return (
          <div className={classes.fileImportWrapper}>
            <DownloadFile data={fileImport} readonly />
            <div className={classes.fileImportRemoveButton}>
              <Pushbutton onClick={clearFileImport}>
                remove this document
              </Pushbutton>
            </div>
          </div>
        );
      }
      return (
        <div className={classes.dropZoneWrapper} data-cy="dropzone">
          <DropZone
            onDropZoneEnter={onDropZoneEnter}
            onDropZoneLeave={onDropZoneLeave}
            onDrop={handleNewFile}
            dropZoneActive={dropZoneActive}
            title="Drag a file here"
            description="or select a file from your device"
            error={null}
          />
        </div>
      );
    };

    const tabs = [
      {
        prefix: null,
        label: 'document',
        component: renderDocumentTab()
      },
      {
        prefix: null,
        label: 'url',
        component: (
          <div className={classes.fieldTextWrapper}>
            <FieldText
              onValueChange={handleChange}
              onBlur={handleBlur}
              label="Add a link"
              placeholder="link"
              value={url}
              invalid={!!url && !validUrl}
            />
            {!!url && !validUrl && <ErrorMessage text="invalid url" />}
          </div>
        )
      }
    ];

    const isDisabled = (!fileImport && !isValidURL(url)) || submitted;

    return (
      <Modal
        title="Add a workflow info"
        closeButtonLabel="Cancel"
        handleCollapse={onClose}
        hideCloseButton
        hideHeaderBorderBottom
      >
        <Tabs tabs={tabs} centered />

        <ModalActions
          adverseAction={<Pushbutton onClick={onClose}>cancel</Pushbutton>}
        >
          <Pushbutton primary onClick={onSubmit} disabled={isDisabled}>
            upload
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  }
);

UploadDocumentationModal.propTypes = {
  classes: PropTypes.object.isRequired,
  workflowRowId: PropTypes.string.isRequired,
  updateWorkflowDocumentation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default compose(
  graphql(mutation.updateWorkflowDocumentation, {
    name: 'updateWorkflowDocumentation'
  }),
  injectSheet(styles),
  withApollo
)(UploadDocumentationModal);
