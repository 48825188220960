/**
 * The ID of the portal for the right tray.
 */
export const TRAY_PORTAL_RIGHT = 'tray-portal-right' as const;

/**
 * The ID of the portal for the left tray.
 */
export const TRAY_PORTAL_LEFT = 'tray-portal-left' as const;

/**
 * The ID of the portal for the tooltip.
 */
export const TOOLTIP_PORTAL = 'tooltip-portal' as const;
