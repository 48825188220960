export default theme => ({
  readerWrapper: {
    overflowY: 'auto',
    minHeight: 84,
    maxHeight: 152,
    opacity: 0.5,
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    padding: '8px 11px 9px 11px'
  }
});
