export default theme => ({
  root: {
    paddingTop: 3
  },
  title: {
    fontFamily: 'EuclidCircularB',
    fontSize: 16,
    fontWeight: 600,
    color: theme.grey1
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.grey2,
    '& > small': {
      fontSize: 'inherit',
      color: theme.grey3
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  count: {
    fontSize: 13,
    fontWeight: 700,
    color: theme.grey1
  },
  downloadAll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '5px',
    fontSize: 12,
    backgroundColor: theme.blue50,
    color: theme.blue800,
    flexShrink: 0,
    padding: '3px 10px',
    margin: '-10px 0 -10px 20px',
    borderRadius: 14,
    transition: 'background-color 0.1s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.blue100
    }
  }
});
