export default theme => ({
  root: {
    padding: 10,
    border: `1px solid ${theme.grey5}`,
    '&[data-has-error=true]': {
      border: `1px solid ${theme.error}`
    },
    borderRadius: 5
  },
  fieldWrapper: {
    marginBottom: 20,
    padding: 14,
    backgroundColor: theme.grey9,
    border: `1px solid ${theme.grey5}`,
    borderRadius: 5
  },
  styledDiv: {
    color: '#34323A',
    fontWeight: 600,
    letterSpacing: 0,
    fontSize: 16,
    marginBottom: 10
  },
  styledBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});
