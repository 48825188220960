import * as Sentry from '@sentry/react';
import { getMediaClient } from 'client/media';
import to from 'await-to-js';
import { saveAs } from 'file-saver';
import { MEDIA_API_URL } from 'constant/api';
import { getAuthToken } from 'utils/localStorage';

import DownloadFileWorker from 'utils/downloadFile/downloadFile.worker?worker';
import { notify } from 'components/toast';

/**
 * @function downloadFile
 * @param {object} data
 * @param {Function} setLoading - onProgress
 * @param {boolean} loading
 * @param {Function} [getResponse] - Returns download result to the parent component
 * @param {boolean} [mustSave]
 */
export const downloadFile = async (
  data,
  setLoading,
  loading,
  getResponse,
  mustSave = true
) => {
  const { digest, key } = data;

  if (loading) return;

  setLoading(true);

  // Checking if workers are enabled and handling both cases
  let promise;
  if (window.Worker) {
    promise = downloadFileUsingWorker(
      digest,
      undefined /* onProgress */,
      key,
      getAuthToken(),
      MEDIA_API_URL
    );
  } else {
    promise = getMediaClient().downloadFile(
      digest,
      undefined /* onProgress */,
      key
    );
    // Checking if workers are enabled and handling both cases
  }

  if (mustSave) {
    notify.promise(promise, {
      loading: 'Downloading file...',
      success: 'File downloaded',
      error: 'Failed to download file.'
    });
  }

  const [err, res] = await to(promise);

  if (err) {
    setLoading(false);
    Sentry.captureException(err);
    if (getResponse) getResponse(null);
    return;
  }

  setLoading(false);

  // set a file name and save the file from media-sdk.
  if (mustSave) saveAs(res, res.name);
  // Return response object to parent
  if (getResponse) getResponse(res);
};

const downloadFileUsingWorker = async (
  digest,
  onProgress, // This callback will be used once the progress feature has been designed
  key,
  authToken,
  mediaApiUrl
) => {
  const downloadFileWorker = new DownloadFileWorker();
  return new Promise((resolve, reject) => {
    /**
     * Run the worker;
     * postMessage only accepts one argument.
     * We will pass an object of the necessary arguments.
     */
    downloadFileWorker.postMessage({ digest, key, authToken, mediaApiUrl });

    // return data to ui component where downloadFile has been triggered
    downloadFileWorker.onmessage = event => {
      if (event.data[0]) reject(event.data[0]);
      resolve(event.data[1]);
    };

    // report worker error
    downloadFileWorker.onerror = reject;
  }).finally(() => {
    // Stop listening to worker messages
    downloadFileWorker.terminate();
  });
};
