import {
  Editor,
  Element as SlateElement,
  Transforms,
  Range,
  Text,
  Node,
  Descendant,
  Path
} from 'slate';
import { ReactEditor } from 'slate-react';
import isUrl from 'is-url';
import escapeHtml from 'escape-html';

import { jsx } from 'slate-hyperscript';

import {
  BLOCK_TYPES,
  TEXT_ALIGN_TYPES,
  LIST_TYPES,
  FORMAT_TYPES
} from './constants';

import { LinkElement } from './custom-types.d';

export const defaultEmptyValue = JSON.stringify([
  {
    type: BLOCK_TYPES.PARAGRAPH,
    children: [{ text: '' }]
  }
]);

export const getStringFromValueFromRichTextEditor = (nodes: Node[]) =>
  nodes.map(n => Node.string(n)).join('');

export const isJson = str => {
  try {
    const result = JSON.parse(str);
    return !!str && typeof result === 'object';
  } catch (e) {
    return false;
  }
};

const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export const parseValue = (string: string | undefined) => {
  if (typeof string === 'string' && string !== '') {
    let parsedValue: Descendant[] = [];
    if (isJson(string)) {
      parsedValue = JSON.parse(string);
    } else if (isHtml(string)) {
      parsedValue = convertHtmlToSlate(string);
    } else {
      parsedValue = convertHtmlToSlate(`<p>${string}</p>`);
    }
    return parsedValue;
  }

  // If the input is not a string, return a default empty value
  // e.g: when value is empty, defaultEmptyValue are nested
  return JSON.parse(defaultEmptyValue);
};

export const focusEditor = (editor: Editor): void => {
  const subject = editor.children.find(node =>
    SlateElement.isElementType(node, BLOCK_TYPES.SUBJECT)
  );
  const subjectIsEmpty =
    SlateElement.isElement(subject) && Node.string(subject) === '';

  Transforms.select(
    editor,
    subjectIsEmpty ? Editor.start(editor, []) : Editor.end(editor, [])
  );
  ReactEditor.focus(editor);
};

export const isRichEditorEmpty = editorValue =>
  editorValue.every(
    item => item.isEmpty && item.type !== 'image' && item.type !== 'table'
  );

export const isMarkActive = (editor: Editor, format: string) => {
  // Put a try catch to avoid crash application
  try {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (err) {
    return false;
  }
};

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (
  editor: Editor,
  format: string,
  blockType = 'type'
) => {
  const { selection } = editor;
  if (!selection) return false;
  // Put a try catch to avoid crash application
  try {
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format
      })
    );

    return !!match;
  } catch (err) {
    return false;
  }
};

export const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else if (isActive) {
    newProperties = {
      type: BLOCK_TYPES.PARAGRAPH
    };
  } else if (isList) {
    newProperties = {
      type: 'list-item'
    };
  } else {
    newProperties = {
      type: format
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const withMentionsPlugin = editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = element =>
    element.type === 'mention' ? true : isInline(element);

  editor.isVoid = element =>
    element.type === 'mention' ? true : isVoid(element);

  return editor;
};

export const withIsEmptyAttribute = editor => {
  const { normalizeNode } = editor;
  editor.normalizeNode = ([node, path]) => {
    if (SlateElement.isElement(node)) {
      Editor.withoutNormalizing(editor, () => {
        Transforms.setNodes(
          editor,
          { isEmpty: Node.string(node).trim() === '' },
          { at: path }
        );
      });
    }
    normalizeNode([node, path]);
  };
  return editor;
};

export const insertSubject = (editor, selectedSubject) => {
  const subject = `${selectedSubject} `;
  Transforms.insertText(editor, subject, { at: [0, 0] });
};

export const insertMention = (editor, user) => {
  const mention = {
    type: 'mention',
    user,
    children: [{ text: `@${user.name}` }]
  };
  Transforms.insertNodes(editor, [
    mention,
    {
      text: ' '
    }
  ]);
  Transforms.move(editor);
};

export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => element.type === 'link' || isInline(element);

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [
      link,
      {
        text: ' '
      }
    ]);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }

  Transforms.move(editor, { distance: 1 });
};

export const getActiveNodeOfType = (editor: Editor, type: string): any => {
  // Put a try catch to avoid crash application
  try {
    const [node] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        SlateElement.isElementType(n, type)
    });
    return node;
  } catch (err) {
    return undefined;
  }
};

export const isLinkActive = editor => !!getActiveNodeOfType(editor, 'link');

export const isSubjectActive = editor =>
  !!getActiveNodeOfType(editor, BLOCK_TYPES.SUBJECT);

export const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  });
};

export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const promptUrl = (defaultValue?: string) => {
  const url = window.prompt('Enter the URL of the link:', defaultValue);
  if (!!url && !isUrl(url)) {
    window.alert('The URL is not valid');
    return promptUrl(url);
  }
  return url;
};

export const toggleLink = editor => {
  const activeLink = getActiveNodeOfType(editor, 'link');
  if (activeLink) {
    const [link, at]: [LinkElement, Path] = activeLink;
    const url = promptUrl(link.url);
    if (!url) {
      unwrapLink(editor);
    } else {
      Transforms.setNodes(editor, { url }, { at });
    }
  } else {
    const url = promptUrl();
    if (!url) return;
    insertLink(editor, url);
  }
};
const removeSubject = (editor, [child, childPath]) => {
  if (SlateElement.isElement(child)) {
    Transforms.setNodes(
      editor,
      { type: BLOCK_TYPES.PARAGRAPH },
      {
        at: childPath
      }
    );
  }
};

export const getWithSubjectLine = (withSubject, readOnly) => editor => {
  const { normalizeNode, insertBreak } = editor;

  editor.insertBreak = () => {
    if (withSubject && isSubjectActive(editor)) {
      Transforms.select(editor, Editor.end(editor, []));
    } else {
      insertBreak();
    }
  };

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (
        withSubject &&
        editor.children.length <= 1 &&
        Editor.string(editor, [0, 0]) === ''
      ) {
        const title = {
          type: BLOCK_TYPES.SUBJECT,
          children: [{ text: '' }]
        };
        Transforms.insertNodes(editor, title, {
          at: path.concat(0),
          select: true
        });
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        const isFirstChild = childPath[0] === 0;
        // Making sure we don't paste a subject in the body
        const editorHasSubject =
          editor.children?.filter(n => n.type === BLOCK_TYPES.SUBJECT).length >
          1;
        if (
          withSubject &&
          editorHasSubject &&
          SlateElement.isElementType(child, BLOCK_TYPES.SUBJECT)
        ) {
          removeSubject(editor, [child, childPath]);
        }
        if (readOnly) {
          if (withSubject && isFirstChild && Node.string(child) !== '') {
            enforceSubject(editor, [child, childPath]);
          } else if (SlateElement.isElementType(child, BLOCK_TYPES.SUBJECT)) {
            removeSubject(editor, [child, childPath]);
          }
        } else if (withSubject && isFirstChild) {
          enforceSubject(editor, [child, childPath]);
        }
      }
      const firstParagraphExists = node.children.find(
        p => p.type === BLOCK_TYPES.PARAGRAPH
      );
      // When deleting the content of the first paragraph this will reset
      // the node to an empty one
      if (!firstParagraphExists) {
        const paragraph = {
          type: BLOCK_TYPES.PARAGRAPH,
          children: [{ text: '' }]
        };
        Transforms.insertNodes(editor, paragraph, {
          at: path.concat(1),
          select: true
        });
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};

const enforceSubject = (editor, [child, childPath]) => {
  if (SlateElement.isElement(child)) {
    Editor.withoutNormalizing(editor, () => {
      // If the item is not a subject, we force the type
      if (child.type !== BLOCK_TYPES.SUBJECT) {
        Transforms.setNodes(
          editor,
          { type: BLOCK_TYPES.SUBJECT },
          {
            at: childPath
          }
        );
      }
      // We use the item raw text to re-define it's content
      // This will get rid of all the sub-items and their specific attributes (links, bold/italic text portions, ...)
      if (child.children.length > 1) {
        Transforms.insertText(editor, Node.string(child), {
          at: childPath
        });
      }
      // Check if the first (and now unique) child still has properties like "align", "bold", "italic", ... anything except "text".
      const firstChildProperties = Object.keys(child.children[0]).filter(
        prop => prop !== 'text'
      );
      // If yes, we need to remove those properties
      if (firstChildProperties.length) {
        // Create an object with each of these properties as keys and unedifined as value
        const newProperties = firstChildProperties.reduce(
          (acc, prop) => ({ ...acc, [prop]: undefined }),
          {}
        );
        // Transform this first child to remove the properties
        Transforms.setNodes(editor, newProperties, {
          at: childPath.concat(0)
        });
      }
    });
  }
};

export const withImages = editor => {
  const { insertData, isVoid } = editor;

  editor.isVoid = element =>
    element.type === BLOCK_TYPES.IMAGE ? true : isVoid(element);

  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor: Editor, url) => {
  const text = { text: '' };
  const image = { type: BLOCK_TYPES.IMAGE, url, children: [text] };
  Transforms.insertNodes(editor, image);
  const [match] = Editor.nodes(editor, {
    match: (n: Node) => (n as any).type === BLOCK_TYPES.IMAGE
  });
  const path = match ? match[1] : [];
  const nextNode: [Node, Path] | undefined = Editor.next(editor, { at: path });
  if (!nextNode || (nextNode[0] as any).type === BLOCK_TYPES.IMAGE) {
    const paragraph = { type: BLOCK_TYPES.PARAGRAPH, children: [{ text: '' }] };
    Transforms.insertNodes(editor, paragraph, { at: Path.next(path) });
  }
};

const isImageUrl = url => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop() || '';
  return ['jpeg', 'jpg', 'png', 'gif'].includes(ext);
};

const serializeHtml = (node, withSubject) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.strikethrough) {
      string = `<del>${string}</del>`;
    }
    if (node.highlight) {
      string = `<mark>${string}</mark>`;
    }
    return string;
  }

  const children = node.children
    .map(n => serializeHtml(n, withSubject))
    .join('');

  const tagHeading1 = withSubject ? 'h2' : 'h1';
  const tagHeading2 = withSubject ? 'h3' : 'h2';

  switch (node.type) {
    case BLOCK_TYPES.SUBJECT:
      return `<h1 class="subject">${children}</h1>`;
    case BLOCK_TYPES.HEADING1:
      return `<${tagHeading1}>${children}</${tagHeading1}>`;
    case BLOCK_TYPES.HEADING2:
      return `<${tagHeading2}>${children}</${tagHeading2}>`;
    case BLOCK_TYPES.PARAGRAPH:
      return `<p>${children}</p>`;
    case BLOCK_TYPES.BLOCKQUOTE:
      return `<blockquote>${children}</blockquote>`;
    case BLOCK_TYPES.ORDERED_LIST:
      return `<ol>${children}</ol>`;
    case BLOCK_TYPES.UNORDERED_LIST:
      return `<ul>${children}</ul>`;
    case BLOCK_TYPES.LIST_ITEM:
      return `<li>${children}</li>`;
    case BLOCK_TYPES.TABLE:
      return `<table>${children}</table>`;
    case BLOCK_TYPES.TABLE_ROW:
      return `<tr>${children}</tr>`;
    case BLOCK_TYPES.TABLE_CELL:
      return `<td>${children}</td>`;
    case BLOCK_TYPES.LINK:
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export const convertSlateToHtml = value => {
  const hasSubject = value.find(n => n.type === BLOCK_TYPES.SUBJECT);
  return value.map(n => serializeHtml(n, hasSubject)).join('');
};

const deserializeHtml = (el, markAttributes = {}) => {
  if (['STYLE', 'META'].includes(el.nodeName)) {
    return null;
  }

  if (el.nodeType === window.Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== window.Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes[FORMAT_TYPES.BOLD] = true;
      break;
    case 'EM':
      nodeAttributes[FORMAT_TYPES.ITALIC] = true;
      break;
    case 'U':
      nodeAttributes[FORMAT_TYPES.UNDERLINE] = true;
      break;
    case 'S':
      nodeAttributes[FORMAT_TYPES.STRIKETHROUGH] = true;
      break;
    case 'MARK':
      nodeAttributes[FORMAT_TYPES.HIGHLIGHT] = true;
      break;
    default:
      break;
  }

  let currentElement = el;
  if (el.nodeName === 'TABLE') {
    // Recreate a clean table without extra elements like thead, tbody, tfoot, caption, cols, colgroups...
    const cleanTable = document.createElement('table');
    el.querySelectorAll('tr').forEach(row => {
      const cleanRow = document.createElement('tr');
      row.querySelectorAll('td, th').forEach(cell => {
        const cleanCell = document.createElement('td');
        cleanCell.innerHTML = cell.textContent;
        cleanRow.appendChild(cleanCell);
      });
      cleanTable.appendChild(cleanRow);
    });
    currentElement = cleanTable;
  }

  const children = Array.from(currentElement.childNodes)
    .map(node => deserializeHtml(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push({
      text: ''
    });
  }

  switch (currentElement.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'H1':
      return jsx('element', { type: BLOCK_TYPES.HEADING1 }, children);
    case 'H2':
      return jsx('element', { type: BLOCK_TYPES.HEADING2 }, children);
    case 'P':
      return jsx('element', { type: BLOCK_TYPES.PARAGRAPH }, children);
    case 'A':
      return jsx(
        'element',
        { type: BLOCK_TYPES.LINK, url: currentElement.getAttribute('href') },
        children
      );
    case 'BLOCKQUOTE':
      return jsx('element', { type: BLOCK_TYPES.BLOCKQUOTE }, children);
    case 'OL':
      return jsx('element', { type: BLOCK_TYPES.ORDERED_LIST }, children);
    case 'UL':
      return jsx('element', { type: BLOCK_TYPES.UNORDERED_LIST }, children);
    case 'LI':
      return jsx('element', { type: BLOCK_TYPES.LIST_ITEM }, children);
    case 'TABLE':
      return jsx('element', { type: BLOCK_TYPES.TABLE }, children);
    case 'TR':
      return jsx('element', { type: BLOCK_TYPES.TABLE_ROW }, children);
    case 'TD':
      return jsx('element', { type: BLOCK_TYPES.TABLE_CELL }, children);
    default:
      return children;
  }
};

export const convertHtmlToSlate = html => {
  const normalizedString = html.replace(/>(\s+)</gm, '><');
  const document = new DOMParser().parseFromString(
    normalizedString,
    'text/html'
  );
  return deserializeHtml(document.body);
};
