import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import pathToRegexp from 'path-to-regexp';
import classnames from 'classnames';

import injectSheet from 'react-jss';

import moment from 'moment';

import { PushtextHref, AvatarGroup } from '@stratumn/atomic';

import { ROUTE_GROUP_SETTINGS } from 'constant/routes';
import { pluralize, nameToInitials } from 'utils';

import { getGroupCounts } from './utils';

import styles from './groupCard.style';

// A single group card linking to the group's page
// note: idx is just here to provide a different color to the avatar if it is not set, depending on its position in the list
export const GroupCard = ({ classes, idx, group }) => {
  const { rowId, name, avatar, createdAt, members } = group;

  const { teams, users, bots } = useMemo(
    () => getGroupCounts(members.nodes),
    [members]
  );

  return (
    <div className={classes.card}>
      <div className={classes.cardLeft}>
        <AvatarGroup
          size={28}
          src={avatar}
          nth={idx}
          initials={nameToInitials(name)}
        />
        <div className={classes.groupLabel}>
          <PushtextHref
            href={pathToRegexp.compile(ROUTE_GROUP_SETTINGS)({ id: rowId })}
          >
            {name}
          </PushtextHref>
        </div>
      </div>
      <div className={classes.cardRight}>
        <div
          className={classnames(classes.groupInfo, classes.creationDate)}
          data-is-collapsable
        >
          {`Created on ${moment(createdAt).format('dddd, Do MMMM YYYY')}`}
        </div>
        <div
          className={classnames(classes.groupInfo, classes.participantsCount)}
          data-is-collapsable
        >
          {pluralize(users + bots, 'participant', false, true)}
        </div>
        <div
          className={classnames(classes.groupInfo, classes.teamsCount)}
          data-is-collapsable
        >
          {pluralize(teams, 'team', false, true)}
        </div>
      </div>
    </div>
  );
};
GroupCard.propTypes = {
  classes: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired
};

export default compose(injectSheet(styles), React.memo)(GroupCard);
