export const TYPE_WORKFLOW = 'Workflow' as const;
export const TYPE_WORKFLOW_LIST = 'WorkflowList' as const;

export const TYPE_GROUP_LIST = 'GroupList' as const;

export const TYPE_INPUT = 'Input' as const;
export const TYPE_INPUT_LIST = 'InputList' as const;

export const TYPE_TRACE = 'Trace' as const;
export const TYPE_TRACE_LIST = 'TraceList' as const;

export const TYPE_SEGMENT = 'Segment' as const;
export const TYPE_SEGMENT_LIST = 'SegmentList' as const;

export const TYPE_STAGE = 'Stage' as const;
export const TYPE_STAGE_LIST = 'StageList' as const;

export const TYPE_PAGE_INFO = 'PageInfo' as const;

export const TYPE_WORKFLOW_CONFIG = 'WorkflowConfig' as const;
export const TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD =
  'UpdateWorkflowConfigPayload' as const;
export const TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD =
  'NewWorkflowConfigPayload' as const;

export const ENTITY_TYPE_USER = 'Account_User' as const;
export const ENTITY_TYPE_BOT = 'Account_Bot' as const;
export const ENTITY_TYPE_TEAM = 'Account_Team' as const;
export const ENTITY_TYPE_ORGANIZATION = 'Account_Organization' as const;
