import * as React from 'react';
import classnames from 'classnames';
import injectSheet, { ClassNameMap } from 'react-jss';

import styles from './button.style';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  classes: ClassNameMap<string>;
  children?: React.ReactNode;
}

export const Button = ({
  classes,
  className = '',
  children,
  onClick,
  type = 'button'
}: Props) => (
  <button
    onClick={onClick}
    className={classnames(classes.button, className)}
    type={type}
  >
    {children}
  </button>
);

export default injectSheet(styles)(Button);
