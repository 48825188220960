import { WidgetProps } from '@rjsf/utils';
import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';

import { KeyValue, ProseList } from '@stratumn/atomic';
import styles from './checkboxesWidget.style';

type Props = {
  classes: ClassNameMap<string>;
} & Pick<WidgetProps, 'schema' | 'options' | 'value'>;

export const CheckboxesWidget: React.FC<Props> = ({
  schema: { title },
  options = {
    inline: false
  },
  value
}) => {
  const { enumOptions } = options as {
    enumOptions: {
      label: string;
      value: string;
    }[];
  };

  if (!enumOptions || !Array.isArray(enumOptions) || enumOptions.length === 0) {
    return <></>;
  }

  const hasGDPR = title === 'GDPR regulation';

  const checkedItems = enumOptions
    .filter(option => value.indexOf(option.value) !== -1 && option.label)
    .map(option => option.label);

  return (
    <KeyValue label={title}>
      {!hasGDPR ? <ProseList items={checkedItems} /> : <> {checkedItems[0]} </>}
    </KeyValue>
  );
};

export default injectSheet(styles)(CheckboxesWidget);
