import { memo } from 'react';

import { getFilterComponent } from '../filters';

import { cn } from '@/shadcn/lib/utils';

type ColumnConfig = {
  key: string;
  width: number;
  def: {
    header: string;
  };
  filter?: boolean;
};

type Props = {
  selectBoxWidth?: number;
  columns: ColumnConfig[];
  setColumnFilter: (column: string, filter: string) => void;
  invalidFilters?: boolean;
};

export const TableFilters = memo(
  ({
    selectBoxWidth = 0,
    columns,
    setColumnFilter,
    invalidFilters = false
  }: Props) => (
    <div className="border-border bg-accent text-muted-foreground flex w-fit grow flex-row border-b text-left text-[10px]">
      {selectBoxWidth > 0 && (
        <div
          style={{
            width: selectBoxWidth
          }}
        />
      )}
      {columns.map((colConfig, index) => {
        // retrieve the appropriate filter component
        const filterComponent = getFilterComponent(colConfig, setColumnFilter);
        const isActive = colConfig.filter;
        return (
          <div
            key={`tableFilter_${index}`}
            className="relative px-1 py-3"
            style={{
              width: colConfig.width
            }}
          >
            {filterComponent}
            {isActive && (
              <div
                className={cn(
                  'bg-secondary absolute bottom-0 left-0 h-[3px] w-full',
                  invalidFilters && 'bg-destructive'
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  )
);

export default TableFilters;
