import { getStringFromValueFromRichTextEditor } from 'components/ui/richEditor/utils';
import { isJSON } from './textCheck';

export const filterComments = (list, searchValue) => {
  // Convert search value to lower case
  const searchValueLower = searchValue?.toLowerCase();

  // Function to remove HTML tags from a string
  const removeTags = value => value?.replace(/<\/?[^>]+(>|$)/g, '');

  // Function to check if any answer matches the search value
  const checkAnswerMatch = answers => {
    for (let i = 0; i < answers.length; i += 1) {
      let answerValue = answers[i].data.answer.trim();
      answerValue = removeTags(answerValue).toLowerCase();
      if (answerValue.includes(searchValueLower)) {
        return true;
      }
    }
    return false;
  };

  // Filter list of comments
  const filteredList = list.filter(item => {
    let commentValue = item.comment;
    if (isJSON(item.comment)) {
      commentValue = getStringFromValueFromRichTextEditor(
        JSON.parse(item.comment)
      );
    }
    commentValue = removeTags(commentValue);
    if (commentValue?.toLowerCase().includes(searchValueLower)) {
      return true;
    }
    if (item.answers && checkAnswerMatch(item.answers)) {
      return true;
    }
    return false;
  });
  // Return filtered list or empty array if no matches found
  return filteredList;
};
