import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './input.style';

export const Input = ({ classes, top = false, right = false, ...props }) => (
  <input className={classNames(classes.root, classes.rootDynamic)} {...props} />
);

Input.propTypes = {
  classes: PropType.object.isRequired,
  top: PropType.bool,
  right: PropType.bool
};

export default injectSheet(styles)(Input);
