import { useState, useCallback, useRef } from 'react';

import { Iconbutton, Tally } from '@stratumn/atomic';
import { Add, Minus } from '@stratumn/icons';

import InfoTooltip from 'components/ui/utils/infoTooltip';

type Props = {
  collapsed: boolean;
  rowCount: number;
  onToggle: () => void;
};

export const GroupButton = ({
  collapsed = false,
  rowCount = 0,
  onToggle
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const enableTooltip = useCallback(() => setShowTooltip(true), []);
  const disableTooltip = useCallback(() => setShowTooltip(false), []);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className="relative mr-[3px] w-[26px]">
      <Iconbutton
        variant={collapsed ? 'float' : 'outline'}
        label={collapsed ? 'Uncollapse' : 'Collapse'}
        onMouseOver={enableTooltip}
        onFocus={enableTooltip}
        onMouseLeave={disableTooltip}
        onBlur={disableTooltip}
        onClick={onToggle}
      >
        {collapsed ? <Add /> : <Minus />}
      </Iconbutton>
      {collapsed && (
        <div className="absolute top-0 right-0 translate-x-1/3 -translate-y-1/2 scale-75 transform">
          <Tally value={rowCount} color="white1" backgroundColor="indigo1" />
        </div>
      )}
      {showTooltip && collapsed && (
        <InfoTooltip
          clientRef={containerRef}
          text={`${rowCount} row${rowCount > 1 ? 's' : ''} grouped`}
          textColor="white"
          backgroundColor="indigo2"
          position={{
            place: 'right',
            placeShift: 3,
            adjustPlace: true,
            anchor: 'left'
          }}
          delay={100}
        />
      )}
    </div>
  );
};
