import * as Sentry from '@sentry/react';

export const SpanType = {
  reqIn: 'app.request.incoming',
  reqOut: 'app.request.outgoing',
  mutation: 'app.mutation',
  processing: 'app.processing',
  gqlResolver: 'app.gql.resolver',
  gqlQuery: 'app.gql.query',
  gqlMutation: 'app.gql.mutation'
} as const;

export type SpanType = (typeof SpanType)[keyof typeof SpanType];

/**
 * Represents a function that optionally takes a span. There are certain cases where
 * we want the function to end the span and not our wrapper such as async functions.
 */
export const withSpanAsync = async <
  T extends (spanInstance: Sentry.Span | undefined) => Promise<any>
>(
  name: string,
  spanType: SpanType,
  fn: T
) =>
  Sentry.startSpan({ name }, async spanInstance => {
    Sentry.setTag('spanType', spanType);
    try {
      // try to run our wrapped function
      return await fn(spanInstance);
    } catch (err) {
      console.log('Error in span: ', err);
      Sentry.captureException(err);
      return err;
    } finally {
      if (spanInstance) spanInstance.end();
    }
  });

/**
 * This represents a function that optionally takes a span. There are certain cases where
 * we want the function to end the span and not our wrapper such as async functions.
 */
export const withSpan = (
  name: string,
  spanType: SpanType,
  fn: (spanInstance: Sentry.Span | undefined) => unknown
) =>
  Sentry.startSpan({ name }, spanInstance => {
    Sentry.setTag('spanType', spanType);
    try {
      // try to run our wrapped function
      return fn(spanInstance);
    } finally {
      if (spanInstance) spanInstance.end();
    }
  });
