// group task list item error (missing trace) of the user dashboard
type Props = {
  title: string;
};

export const GroupsTaskListItemError = ({ title }: Props) => (
  <div className="bg-accent text-destructive flex h-[135px] items-center justify-center text-sm">
    <p>
      <strong className="font-semibold">Error:</strong> {title}
    </p>
  </div>
);
