import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { FieldTextCompact } from '@stratumn/atomic';

import injectSheet from 'react-jss';
import styles from './datepicker.style';
// TODO: Reimplement in @stratumn/atomic as another compact field

const CustomInput = ({
  label,
  value = null,
  onClick = () => {},
  onChange = () => {}
}) => {
  const initInput = ref => {
    if (!ref) return;
    ref.addEventListener('focus', onClick);
  };
  return (
    <FieldTextCompact
      label={label}
      value={value || 'DD/MM/YYYY'}
      inputRef={initInput}
      onValueChange={onChange}
    />
  );
};

CustomInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

const DatePicker = ({ classes, label, ...props }) => (
  <ReactDatePicker
    customInput={<CustomInput label={label} />}
    dateFormat="dd/MM/yyyy"
    calendarClassName={classes.datePicker}
    wrapperClassName={classes.wrapper}
    {...props}
  />
);

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object
};

DatePicker.defaultProps = {
  selected: null
};

export default injectSheet(styles)(DatePicker);
