import { SORT_ASCENDING, SORT_DESCENDING } from '@/utils/sorting/index';
import { getSortConfig } from '../sort';
import { SortConfig, TableColumn } from '../table.type';
import { SortSetup } from '@/utils/sorting/sorting.types';
// click event on sort icon
// sortSetup is the setup for sorting the table, ie a sorting configuration identified by a colKey and a sorting direction
// clicking on a sort icon will replace or modify the sorting configuration
export const onSortIconClick = (
  colConfig: TableColumn, // the config of the column to be used for the sort
  sortSetup: SortSetup | undefined, // the global table sort setup described above
  setSortSetup: (sortSetup: SortSetup | null, sortConfig: SortConfig) => void // function called to modify the table sorting setup
) => {
  // first check what the sort setup should be for this column
  let newSortSetup: SortSetup | null = null;
  if (sortSetup && sortSetup.key === colConfig.key) {
    if (sortSetup.direction === SORT_ASCENDING) {
      // next switch to descending
      newSortSetup = { key: colConfig.key, direction: SORT_DESCENDING };
    }
  } else {
    // sort ascending
    newSortSetup = { key: colConfig.key, direction: SORT_ASCENDING };
  }

  const sortConfig = getSortConfig(colConfig);
  setSortSetup(newSortSetup, sortConfig);
};
