import { useRef, useState } from 'react';

import injectSheet, { ClassNameMap } from 'react-jss';
import { Pushbutton } from '@stratumn/atomic';
import RichEditor, { RichEditorRef } from 'components/ui/richEditor';
import { isRichEditorEmpty } from 'components/ui/richEditor/utils';

import compose from 'lodash.flowright';
import { withUser } from 'contexts';
import styles from './commentForm.style';
import { ICommentData } from '../..';

interface CommentFormProps {
  classes: ClassNameMap<string>;
  data: ICommentData;
  id?: number;
  onSave: (comment: ICommentData, id?: number) => void;
  user: any;
  onCancel?: (id?: number) => void;
}

const checkIsEmptyComment = comment => {
  if (!comment) return true;
  return isRichEditorEmpty(JSON.parse(comment));
};

const CommentForm = ({
  classes,
  user,
  data,
  id,
  onSave,
  onCancel
}: CommentFormProps) => {
  const richEditorRef = useRef<RichEditorRef>();
  const [value, setValue] = useState<string | undefined>(data?.comment);

  const editMode = id !== undefined;

  const handleChange = (richValue: string | undefined) => {
    setValue(richValue);
  };

  if (user.loading || !user) {
    return null;
  }

  const { me } = user;

  const handleAddOrEditComment = () => {
    if (editMode) {
      const updatedComment = {
        ...data,
        comment: value
      };
      onSave(updatedComment, id);
    } else {
      const newComment = {
        comment: value,
        date: new Date().toISOString(),
        user: {
          accountId: me.rowId,
          name: me.name,
          avatar: me.avatar
        }
      };
      onSave(newComment);
    }
    if (richEditorRef.current) richEditorRef.current.clearEditor();
  };

  const handleCancel = () => {
    // Réinitialise l'éditeur et invoque onCancel pour fermer le mode édition
    if (richEditorRef.current) richEditorRef.current.clearEditor();
    if (onCancel) onCancel(id);
  };

  return (
    <div className={classes.addComment}>
      <RichEditor
        value={value}
        withSubject
        onChange={handleChange}
        autoFocus
        withMentions
        ref={richEditorRef}
        subjectPlaceholder={'Subject'}
        placeholder={'Enter your comment here.'}
      />
      <div className={classes.submitButton}>
        {editMode && (
          <Pushbutton onClick={handleCancel} dataCy="cancelComment-button">
            Cancel
          </Pushbutton>
        )}
        <Pushbutton
          primary
          onClick={handleAddOrEditComment}
          dataCy="addComment-button"
          disabled={checkIsEmptyComment(value)}
        >
          {editMode ? 'Save' : 'Add Comment'}
        </Pushbutton>
      </div>
    </div>
  );
};

export default compose(withUser, injectSheet(styles))(CommentForm);
