export default () => ({
  addComment: {
    padding: '30px 0',
    width: '100%'
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    '& > *:last-child': {
      marginLeft: 'auto'
    }
  }
});
