import { useContext, useState, useMemo, ChangeEvent } from 'react';

import debounce from 'debounce';
import { MagnifyingGlass } from '@stratumn/icons';
import shortid from 'shortid';
import { SearchContext } from './searchContext';
import { SearchBarConfig } from './search.type';
import { cn } from '@/shadcn/lib/utils';

type Props = {
  config: SearchBarConfig;
};

export const SearchBar = ({ config }: Props) => {
  const searchContext = useContext(SearchContext);
  const { state: searchState, dispatch } = searchContext || {};
  const searchString = searchState?.string || '';

  const handlerDispatch = (payload: string) => {
    dispatch && dispatch({ type: 'addString', payload: payload });
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    debounce(handlerDispatch(event.target.value), 500);

  const [hasFocus, setHasFocus] = useState(false);

  const searchId = useMemo(() => shortid.generate(), []);

  const placeholder = config?.placeholder || 'Search';

  return (
    <label
      className={cn(
        'border-border bg-card text-card-foreground flex h-7 max-w-[540px] grow items-center overflow-hidden rounded-md border pl-2 transition-colors duration-100',
        hasFocus && 'border-primary'
      )}
      htmlFor={searchId}
    >
      <MagnifyingGlass className="mr-2 size-4 fill-current" />
      <input
        className="bg-card text-card-foreground placeholder:text-muted-foreground mr-2 grow border-0 px-3 text-xs font-normal focus:ring-0 focus:outline-hidden"
        value={searchString}
        onChange={handleSearch}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        id={searchId}
        placeholder={placeholder}
      />
    </label>
  );
};
