import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DATA_EDITOR_UPDATE } from 'constant/dataEditor';

import { deepGet } from 'utils';
import { getByPath } from 'utils/widgets';
import { Widget } from 'components/ui/widget';
import InlineStyle from 'components/ui/utils/inlineStyle';
import { Check } from '@stratumn/atomic';

import { withTableContext } from '../tableContext';

import { GroupButton } from './groupButton/groupButton.cell';
import { cn } from '@/shadcn/lib/utils';

// check whether TableCell props are equal and no re-rendering is required
const shallowCompareProps = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj1[key] === obj2[key]);
const areEqual = (prevProps, nextProps) => {
  // check style and tableContext shallow contents
  const {
    style: prevStyle,
    tableContext: prevTableContext,
    ...prevRest
  } = prevProps;
  const {
    style: nextStyle,
    tableContext: nextTableContext,
    ...nextRest
  } = nextProps;
  return (
    shallowCompareProps(prevRest, nextRest) &&
    shallowCompareProps(prevStyle, nextStyle) &&
    shallowCompareProps(prevTableContext, nextTableContext)
  );
};
// Check if the current cell has an error in the errorSchema
/**
 * @returns {string[] | undefined}
 */
const getCellErrorMessages = (errorSchema, selector, key) => {
  if (!errorSchema) return [];
  const currentLine = errorSchema[selector];
  if (!currentLine) return [];
  return currentLine[key]?.__errors ?? [];
};

const getRowHasError = (errorSchema, rowSelector) => {
  if (!errorSchema) return false;
  const currentLine = errorSchema[rowSelector];
  if (!currentLine || typeof currentLine !== 'object') return false;
  return (
    currentLine.__errors?.length > 0 ||
    Object.values(currentLine).some(line => line.__errors?.length > 0)
  );
};

/* cell component */
const TableCell = memo(({ rowIndex, columnIndex, style, tableContext }) => {
  const {
    loading,
    isFixedGrid,
    data,
    columns,
    isEditing,
    selectedData,
    allowRowsSelection,
    dataSelectorPath,
    toggleRowSelected,
    pagination,
    displayDiffs,
    group,
    edit: { modifiedData, onDataModified } = {},
    rowInlineStyle,
    errorSchema
  } = tableContext;

  if (pagination) {
    const { buffer, hasNextPage, onLoadMore } = pagination;

    if (!loading && hasNextPage && rowIndex >= data.length - buffer) {
      onLoadMore();
    }
  }
  let rowData = null;
  let groupData = null;
  let isSelectable = false;
  let groupButton = null;
  let isGroupHeaderRow = false;
  const isGroupUncollapsed =
    group && group.display && group.display.selected !== undefined;
  // We use lodash.get instead of getByPath for group indexes because getByPath yields `undefined`
  // on null values, and we need to distinguish the group.display.selected === null from the
  // group.display.selected === undefined case
  const selectedRowIndex = isGroupUncollapsed
    ? data.findIndex(
        d => deepGet(d, group.indexPath) === group.display.selected
      )
    : null;
  if (isGroupUncollapsed) {
    /**
     * If a group has been uncollapsed, its underlying rows have to be integrated in the data row count
     * e.g. with group.display.selected = 'group2' and groups = [
     *   { index: "group1", rows: ["row1", "row2"] },
     *   { index: "group2", rows: ["row3", "row4"] },
     *   { index: "group3", rows: ["row5"] }
     * ]:
     * - selectedRowIndex = 1, corresponding to the 2nd item with index 'group2'
     * - with rowIndex = 0, the row should be "group1" (which is collapsed).
     *   rowIndex - selectedRowIndex = -1, and groupRows[-1] = undefined,
     *   0 < selectedRowIndex and data[0] = "group1".
     * - with rowIndex = 1, the row should be "row3" from "group2", which is uncollapsed.
     *   Since it's the first row of the group, it should have a collapse button as well.
     *   rowIndex - selectedRowIndex = 0, and groupRows[0] = "row3"
     * - with rowIndex = 2, the row should be "row4" from "group2"
     *   rowIndex - selectedRowIndex = 1, and groupRows[1] = "row4"
     * - with rowIndex = 3, the row should be "group3" (which is collapsed)
     *   rowIndex - selectedRowIndex = 2, and groupRows[2] = undefined,
     *   3 > selectedRowIndex, rowIndex - groupRows.length + 1 = 2, and data[2] = "group3"
     */
    const selectedGroupRows = getByPath(data[selectedRowIndex], group.rowsPath);
    const subRowIndex = rowIndex - selectedRowIndex;
    // If the rowIndex is inside the uncollapsed group, get the underlying row
    if (selectedGroupRows[subRowIndex]) {
      isSelectable = true;
      rowData = selectedGroupRows[subRowIndex];
      if (subRowIndex === 0) {
        groupButton = (
          <GroupButton onToggle={() => group.toggle(group.display.selected)} />
        );
      }
    } else {
      let groupIndex = rowIndex;
      if (rowIndex > selectedRowIndex) {
        groupIndex += 1 - selectedGroupRows.length;
      }
      groupData = data[groupIndex];
      // Get the first row of the group for row display
      [rowData] = getByPath(groupData, group.rowsPath) || [];
      const groupRowCount = getByPath(groupData, group.rowCountPath);
      groupButton = (
        <GroupButton
          collapsed
          rowCount={groupRowCount}
          onToggle={() => group.toggle(deepGet(groupData, group.indexPath))}
        />
      );
    }
  } else if (group) {
    // If collapsed group
    groupData = data[rowIndex];
    isGroupHeaderRow = true;
    // Get the first row of the group for row display
    [rowData] = getByPath(groupData, group.rowsPath) || [];
    const groupRowCount = getByPath(groupData, group.rowCountPath);
    groupButton = (
      <GroupButton
        collapsed
        rowCount={groupRowCount}
        onToggle={() => group.toggle(deepGet(groupData, group.indexPath))}
      />
    );
  } else {
    // If regular row
    isSelectable = true;
    rowData = data[rowIndex];
  }

  // get the value of the row selector
  const rowSelector = rowData && getByPath(rowData, dataSelectorPath);

  // check if the row is selected
  const isSelected = allowRowsSelection && selectedData.includes(rowSelector);

  // provide sub-components like views and wrappers
  // with a way to let this cell know that a patch is being applied
  const [isPatched, setIsPatched] = useState(false);

  // check if selector cell
  let cellContent = null;
  const patch = modifiedData ? modifiedData[rowSelector] : null;

  const rowHasError = useMemo(
    () => getRowHasError(errorSchema, rowSelector),
    [errorSchema, rowSelector]
  );
  // Initialize hasError variable
  let cellErrorMessages = [];

  const renderIndicator = ({
    isPatch = false,
    isError = false,
    isInitialItem = false
  }) => {
    if (isError || isPatch) {
      return (
        <div
          className={cn('bg-secondary absolute top-0 left-0 h-full w-[2px]', {
            'bg-muted': isInitialItem,
            'bg-destructive': isError
          })}
        />
      );
    }
    return null;
  };

  if (isFixedGrid && columnIndex === 0) {
    const toggleRowSelection = useCallback(() => {
      toggleRowSelected(rowSelector);
    }, [rowSelector, toggleRowSelected]);
    const checkContent = allowRowsSelection ? (
      <>
        <Check
          label=""
          checked={isSelected}
          showLabel={false}
          handleChange={toggleRowSelection}
          disabled={!rowSelector}
        />
        {renderIndicator({ isPatch: !!patch, isError: rowHasError })}
      </>
    ) : (
      <div className="truncate">{rowIndex + 1}</div>
    );

    // Only show fillers in first columns if a group is uncollapsed
    cellContent = (
      <div className="border-border flex size-full flex-row items-center justify-center overflow-hidden border-r p-1">
        {groupButton || (isGroupUncollapsed && <div className="w-[29px]" />)}
        {isSelectable
          ? checkContent
          : isGroupUncollapsed && <div className="w-[18px]" />}
      </div>
    );
  } else {
    // real table cell
    const { key, def } = columns[columnIndex - (isFixedGrid ? 1 : 0)];
    let { cell } = def;

    cellErrorMessages = useMemo(
      () => getCellErrorMessages(errorSchema, rowSelector, key),
      [errorSchema, rowSelector, key]
    );

    // if grouping is enabled and the group is collapsed,
    // only display the data of the specified column and display "..." in the other columns
    if (groupData && group.display.column && group.display.column !== key) {
      cell = {
        view: {
          type: 'text',
          path: "'...'"
        }
      };
    }

    // bind the onChange function to the current row selector
    const onChange = useCallback(
      modifiedCellData => {
        onDataModified({
          type: DATA_EDITOR_UPDATE,
          rowSelector,
          dataToChange: modifiedCellData
        });
      },
      [rowSelector, onDataModified]
    );

    // if no patch provided set is patched to false
    useEffect(() => {
      if (!patch) setIsPatched(false);
    }, [patch]);

    // bind an update object with the patch and the updater
    const update = useMemo(
      () => ({
        onChange: onDataModified ? onChange : null,
        patch,
        setIsPatched
      }),
      [onDataModified, onChange, patch]
    );

    if (rowData) {
      if (displayDiffs) {
        // display cell with diffs
        cellContent = (
          <div className="flex size-full flex-col flex-nowrap">
            <div className="text-foreground relative basis-1/2 font-normal">
              <Widget
                widget={cell}
                data={rowData}
                className={cn(
                  'border-border box-border flex size-full cursor-default flex-row flex-wrap items-center overflow-hidden border-r px-[6px] text-[13px]',
                  'hover:data-[is-hoverable=true]:border-primary data-[is-hoverable=true]:cursor-pointer hover:data-[is-hoverable=true]:border-2 hover:data-[is-hoverable=true]:px-[4px]',
                  'hover:data-[is-focus=true]:border-primary data-[is-focus=true]:cursor-pointer hover:data-[is-focus=true]:border-2 hover:data-[is-focus=true]:px-[4px]'
                )}
                disableWrappers={isEditing || isGroupHeaderRow}
              />
              {renderIndicator({
                isPatch: isPatched,
                isInitialItem: true
              })}
            </div>
            <div className="relative basis-1/2">
              <Widget
                widget={cell}
                data={rowData}
                update={update}
                className={cn(
                  'border-border box-border flex size-full cursor-default flex-row flex-wrap items-center overflow-hidden border-r px-[6px] text-[13px]',
                  'hover:data-[is-hoverable=true]:border-primary data-[is-hoverable=true]:cursor-pointer hover:data-[is-hoverable=true]:border-2 hover:data-[is-hoverable=true]:px-[4px]',
                  'hover:data-[is-focus=true]:border-primary data-[is-focus=true]:cursor-pointer hover:data-[is-focus=true]:border-2 hover:data-[is-focus=true]:px-[4px]'
                )}
                disableWrappers={isEditing || isGroupHeaderRow}
              />
              {renderIndicator({
                isPatch: isPatched
              })}
            </div>
          </div>
        );
      } else {
        // simple cell display
        cellContent = (
          <>
            {renderIndicator({
              isError: cellErrorMessages.length > 0,
              isPatch: isPatched
            })}
            <Widget
              widget={cell}
              data={rowData}
              update={update}
              className={cn(
                'border-border box-border flex size-full cursor-default flex-row flex-wrap items-center overflow-hidden border-r px-[6px] text-[13px]',
                'hover:data-[is-hoverable=true]:border-primary data-[is-hoverable=true]:cursor-pointer hover:data-[is-hoverable=true]:border-2 hover:data-[is-hoverable=true]:px-[4px]',
                'hover:data-[is-focus=true]:border-primary data-[is-focus=true]:cursor-pointer hover:data-[is-focus=true]:border-2 hover:data-[is-focus=true]:px-[4px]'
              )}
              disableWrappers={isEditing || isGroupHeaderRow}
            />
          </>
        );
      }
      // wrap with inline style
      if (rowInlineStyle) {
        cellContent = (
          <InlineStyle
            data={rowData}
            rules={rowInlineStyle}
            style={{ height: '100%' }}
          >
            {cellContent}
          </InlineStyle>
        );
      }
    } else {
      cellContent = (
        <div
          className={cn(
            'border-border box-border flex size-full cursor-default flex-row flex-wrap items-center overflow-hidden border-r px-[6px] text-[13px]',
            'hover:data-[is-hoverable=true]:border-primary data-[is-hoverable=true]:cursor-pointer hover:data-[is-hoverable=true]:border-2 hover:data-[is-hoverable=true]:px-[4px]',
            'hover:data-[is-focus=true]:border-primary data-[is-focus=true]:cursor-pointer hover:data-[is-focus=true]:border-2 hover:data-[is-focus=true]:px-[4px]'
          )}
        />
      );
    }
  }

  return (
    <div
      className={cn(
        isSelected && 'bg-primary/20! text-accent-foreground',
        isPatched && 'font-semibold',
        !(rowIndex % 2) && 'bg-accent/50'
      )}
      style={style}
      data-is-evenrow={!(rowIndex % 2)}
      data-is-selected={isSelected}
      data-is-patched={isPatched}
      data-cy="table-cell"
    >
      {cellContent}
    </div>
  );
}, areEqual);

TableCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  tableContext: PropTypes.object.isRequired
};

export default withTableContext(TableCell);
