import { SORT_ASCENDING } from '@/utils';
import { SortDirection } from '@/utils/sorting/sorting.types';
import {
  ArrowDownUp,
  ArrowUpNarrowWide,
  ArrowDownWideNarrow
} from 'lucide-react';

type Props = {
  isSorted: boolean | undefined;
  direction: SortDirection | undefined;
  size: number;
};

export const SortIcon = (props: Props) => {
  if (!props.isSorted) return <ArrowDownUp size={props.size} />;
  if (props.direction === SORT_ASCENDING)
    return <ArrowUpNarrowWide size={props.size} />;
  return <ArrowDownWideNarrow size={props.size} />;
};
