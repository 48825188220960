import {
  UI_FIELD,
  UI_ORDER,
  WIDGET_TYPE_FILE_UPLOAD
} from '../../../constant/formWriter';

interface ValidateUpload {
  name: string;
  required: boolean;
  formData: any;
  errors: [];
}

const validateUpload = ({
  name,
  required,
  formData,
  errors
}: ValidateUpload) => {
  if (formData && formData[name]) {
    const fileData = formData[name];
    // schema validation will throw an error if it is required and not set but
    // will not throw and error if it is set to an emtpy array so we have to check that manually
    if (required && fileData.length === 0) {
      errors[name].addError('at least one file must be uploaded');
    }
    // make sure there are no files in an uploading state
    if (fileData.some(file => file.uploading === true)) {
      errors[name].addError('all files must complete uploading');
    }
    // make sure there are no files that failed to upload
    if (fileData.some(file => file.error === true)) {
      errors[name].addError('failed files must be removed');
    }
  }
};

const validationMap = {
  [WIDGET_TYPE_FILE_UPLOAD]: validateUpload
};

export default (schema, uiSchema) => (formData, errors) => {
  Object.keys(uiSchema)
    .filter(x => x !== UI_ORDER)
    .forEach(formFieldName => {
      const required =
        schema.required && schema.required.includes(formFieldName);
      const fieldType = uiSchema[formFieldName][UI_FIELD];
      const validator = validationMap[fieldType];
      if (typeof validator === 'function')
        validator({ name: formFieldName, required, formData, errors });
    });
  return errors;
};
