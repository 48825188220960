import React from 'react';
import PropType from 'prop-types';
import compose from 'lodash.flowright';

import { UserContext, withUser } from 'contexts';

export class PasswordProtection extends React.PureComponent {
  static propTypes = {
    children: PropType.node.isRequired,
    user: PropType.object.isRequired
  };

  state = {
    password: undefined
  };

  setPassword = password => this.setState({ password });

  render() {
    const {
      user: { loading, me, ...rest }
    } = this.props;
    const { password } = this.state;

    return (
      <UserContext.Provider
        value={{
          ...rest,
          loading,
          me,
          password,
          setPassword: this.setPassword
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default compose(withUser)(PasswordProtection);
