import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import moment from 'moment';

import shortid from 'shortid';

import { Pen } from '@stratumn/icons';

import { Smartlabel } from '@stratumn/atomic';

import Tooltip from 'components/ui/utils/tooltip';
import DatePicker from 'components/ui/utils/datePicker';

import { useFocus } from 'utils/hooks';

import styles from './date.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// simple date input with date picker in a tooltip on focus
export const DateInput = ({
  classes,
  dataStr,
  onChange,
  inputFormat = null,
  displayFormat = null,
  placeholder = '',
  className = '',
  header = '',
  disabled = false
}) => {
  // get the displayed date
  // ie convert from input format to display format
  const dateToDisplay = useMemo(() => {
    if (!dataStr) return '';
    if (inputFormat === displayFormat) return dataStr;

    const momentDate = moment.utc(dataStr, inputFormat, true);
    return momentDate.isValid() ? momentDate.format(displayFormat) : '';
  }, [dataStr, inputFormat, displayFormat]);

  // focus state
  const [isFocused, focus, blur, btnRef] = useFocus(false, !header);

  // in compact mode (batch edition modal)
  // on focus, scroll to the date picker to ensure the users knows it opened
  const pickerWrapperRef = useRef(null);
  useEffect(() => {
    if (isFocused && pickerWrapperRef.current) {
      pickerWrapperRef.current.scrollIntoView(true);
    }
  }, [header, isFocused]);

  // callback to close tooltip on escape
  const closeOnEsc = useCallback(e => {
    if (e.key === 'Escape') {
      blur();
      e.stopPropagation();
    }
  }, []);

  // callback when a date is selected from the picker
  const onDateSelect = useCallback(
    newDateStr => {
      // update te data
      onChange(newDateStr || '');
      // close the tooltip
      blur();
    },
    [onChange]
  );

  const id = useMemo(() => shortid.generate(), []);

  if (disabled)
    return (
      <div className={classnames(className, classes.disabledInput)}>
        {dateToDisplay}
      </div>
    );

  const datePicker = (
    <DatePicker
      dateFormat={inputFormat}
      nbMonths={1}
      value={dataStr}
      onSelect={onDateSelect}
    />
  );

  // compact field is when the field is in a batch edition form, with the Smart Label
  const renderCompactField = () => (
    <>
      <div className={classnames(className, classes.dateCompactInputWrapper)}>
        <Smartlabel
          text={header}
          forId={id}
          inputHasFocus={isFocused}
          inputHasValue={!!dataStr}
        >
          <button
            id={id}
            className={classes.dateCompactInput}
            onClick={isFocused ? blur : focus}
            data-has-focus={isFocused}
            data-has-value={!!dataStr}
            data-cy="date-input"
          >
            <span>{dateToDisplay || header}</span>
            <span
              className={classes.dateCompactClose}
              data-is-visible={isFocused}
            >
              Close
            </span>
          </button>
        </Smartlabel>
      </div>
      {isFocused && (
        <div
          ref={pickerWrapperRef}
          className={classes.compactDatePickerWrapper}
        >
          {datePicker}
        </div>
      )}
    </>
  );

  // inline field is when the field is included in the parent view directly (like a table)
  const renderInlineField = () => (
    <>
      <button
        ref={btnRef}
        className={classnames(className, classes.dateInput)}
        onClick={focus}
        data-input-focused={isFocused}
        data-cy="date-input"
        onKeyDown={closeOnEsc}
      >
        <div className={classes.dateDisplay} data-is-unset={!dataStr}>
          {dateToDisplay || placeholder || 'Select a date...'}
        </div>
        <Pen className={classes.dateInputIcon} data-is-visible={!!dataStr} />
      </button>
      {isFocused && (
        <Tooltip
          clientEl={btnRef.current}
          portalEl={document.body}
          position={TOOLTIP_POSITION}
          onClickOutside={blur}
          delay={0}
        >
          <div className="border-border bg-popover z-10 flex min-h-[315px] flex-col flex-nowrap rounded-md border p-1 shadow-md">
            {datePicker}
          </div>
        </Tooltip>
      )}
    </>
  );

  return header ? renderCompactField() : renderInlineField();
};

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dataStr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inputFormat: PropTypes.string,
  displayFormat: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};

export default injectSheet(styles)(React.memo(DateInput));
