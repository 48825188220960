import React from 'react';
import { WidgetProps } from '@rjsf/utils';

import { Check } from '@stratumn/atomic';

import { isLockedWidget } from '../../../../../utils/links';

const CheckboxWidget: React.FC<
  Pick<
    WidgetProps,
    | 'label'
    | 'value'
    | 'disabled'
    | 'readonly'
    | 'onChange'
    | 'id'
    | 'formContext'
  >
> = ({ label, value, disabled, readonly, onChange, id, formContext }) => (
  <Check
    showLabel
    label={label}
    checked={typeof value === 'undefined' ? false : value}
    disabled={disabled || readonly || isLockedWidget(id, formContext.links)}
    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked)
    }
  />
);

export default CheckboxWidget;
