import { FieldProps, RJSFSchema } from '@rjsf/utils';

import {
  FormTableEditorValue,
  TableConfiguration
} from '@/components/ui/dataEditor/form/tableEditor.form';
import { FormWriterContext } from '@/components/forms/components/form/writer/index';
import Table from '@/components/ui/table';
import { useMemo } from 'react';
import { Button } from '@/shadcn/button';
import { Expand } from 'lucide-react';
import {
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  TooltipProvider
} from '@/shadcn/tooltip';
import { cn } from '@/shadcn/lib/utils';

type CustomFieldProps = FieldProps<
  FormTableEditorValue,
  RJSFSchema,
  FormWriterContext
>;

type TableRow = Record<string, unknown>;

type Props = CustomFieldProps & {
  uiSchema: Omit<CustomFieldProps['uiSchema'], 'ui:options'> & {
    'ui:options': {
      tableConfig: TableConfiguration;
    };
  };
};

export const TableEditorField = (props: Props) => {
  const config = props.uiSchema?.['ui:options']?.tableConfig;

  if (!config) {
    return <ErrorMessage message="Table editor config is missing" />;
  }

  if (!props.formData) {
    return <ErrorMessage message="Table data is missing" />;
  }

  const { title, description } = props.schema;

  const {
    modifiedDataKey = 'changes',
    initialDataKey = 'initialData',
    finalDataKey = 'data',
    allowDisplayDiffs = true,
    table: tableConfig
  } = config;

  const {
    [modifiedDataKey]: modifiedData,
    [initialDataKey]: initialData,
    [finalDataKey]: finalData
  } = (props.formData ?? {}) as Record<string, any>;

  const tableUpdate = useMemo(
    () => ({
      edit: {
        modifiedData: allowDisplayDiffs ? modifiedData : undefined,
        allowDisplayDiffs: allowDisplayDiffs
      }
    }),
    [modifiedData, allowDisplayDiffs]
  );

  const getData = (): TableRow[] => {
    if (allowDisplayDiffs !== false && modifiedData && initialData)
      return initialData;
    return finalData ?? [];
  };

  const data = useMemo(getData, [modifiedData, initialData, finalData]);

  return (
    <section className="flex size-full flex-col">
      <header className="flex h-16 w-full items-end justify-between pb-2 text-sm">
        <section className="flex flex-col">
          <h2
            className={cn(
              'text-base font-semibold',
              props.required &&
                'after:text-destructive after:ml-1 after:text-sm after:content-["*"]'
            )}
          >
            {title}
          </h2>
          {description && (
            <p className="text-xs text-neutral-500">{description}</p>
          )}
        </section>
        <section className="flex items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" className="-mb-1">
                  <Expand />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Expand the table</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </section>
      </header>
      <div className="h-96 w-full" id={props.id}>
        <div className="border-input size-full overflow-hidden rounded-md border">
          <Table data={data} config={tableConfig} update={tableUpdate} />
        </div>
      </div>
    </section>
  );
};

const ErrorMessage = ({ message }: { message: string }) => {
  return <div className="text-destructive">{message}</div>;
};
