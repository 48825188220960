import { memo, useMemo } from 'react';

import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem
} from '@/shadcn/select';
import { Label } from '@/shadcn/label';
import { cn } from '@/shadcn/lib/utils';

export type LabeledOption = {
  label: string;
  value: string;
};

export type SelectDropdownProps = {
  value?: string;
  options?: string[] | LabeledOption[];
  onChange: (value: string) => void;
  header?: string;
  placeholder?: string;
  disabled?: boolean;
};

// simple select input
export const SelectDropdown = memo((props: SelectDropdownProps) => {
  const isInBatchEdit = useMemo(() => !!props.header, [props.header]);
  if (!Array.isArray(props.options)) {
    console.error('SelectDropdown editor: options must be an array');
    return null;
  }

  const dropDownOptions = useMemo(() => {
    let unifiedOptions: LabeledOption[] = [];
    if (optionIsAnArrayOfObjects(props.options)) {
      unifiedOptions = props.options;
    } else {
      unifiedOptions = (props.options ?? []).map(option => ({
        label: option,
        value: option
      }));
    }

    // Some dropdowns can have current values that we don't want the user to select
    // (e.g. "Valid (from TA)" in the AGRe FF workflow).
    // We therefore add it as an option to make it visible in the dropdown.
    // TODO: Implement a cleaner way to define selectable and unselectable dropdown options
    if (
      !!props.value &&
      !unifiedOptions.find(({ value }) => value === props.value)
    ) {
      unifiedOptions.unshift({
        label: props.value,
        value: props.value
      });
    }
    return unifiedOptions;
  }, [props.value, props.options]);

  return (
    <div
      className={cn(
        'flex flex-col gap-2',
        !isInBatchEdit &&
          'ml-[0.2rem] h-[calc(100%-0.5rem)] w-[calc(100%-0.2rem)]'
      )}
    >
      {isInBatchEdit && <Label htmlFor={props.header}>{props.header}</Label>}
      <Select
        onValueChange={props.onChange}
        disabled={props.disabled}
        value={props.value}
      >
        <SelectTrigger
          id={props.header}
          className={cn(
            'hover:border-input hover:bg-card size-full border-transparent bg-transparent',
            isInBatchEdit && 'border-input'
          )}
        >
          <SelectValue placeholder={props.placeholder ?? 'Select an option'} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {dropDownOptions.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
});

const optionIsAnArrayOfObjects = (
  options: string[] | LabeledOption[] | undefined
): options is LabeledOption[] => {
  if (!options) return false;
  return options.length > 0 && typeof options[0] === 'object';
};
