import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import RichEditor from 'components/ui/richEditor';
import { Paperclip } from '@stratumn/icons';
import FormBody from 'components/traceInspector/segmentDetail/formBody';
import { formatDateWithoutSeconds, findNumberOfFiles, pluralize } from 'utils';
import { downloadFile } from 'utils/downloadFile';

import styles from './answerCard.style';

export const AnswerCard = ({
  classes,
  data,
  traceInspector = false,
  searchValue = ''
}) => {
  const avatar = data.createdBy?.avatar || data.link?.createdBy?.avatar;
  const name = data.createdBy?.name || data.link?.createdBy?.name;
  const group = data.group?.name || data.link?.group?.name;
  const date = data.createdAt || data.link?.createdAt;
  const answer = data.data?.answer || data.link?.answer;

  const numberOfFiles = findNumberOfFiles(data.data);

  return (
    data && (
      <div className={classes.answer}>
        <div
          className={classes.answerAvatar}
          style={{
            backgroundImage: avatar ? `url(${avatar})` : null
          }}
        />
        <div className={classes.answerContainer}>
          <ul>
            {name && (
              <li className={classes.userId}>
                <div className={classes.answerUserName}>{name}</div>
                {group && (
                  <div className={classes.answerUserGroup}>{group}</div>
                )}
              </li>
            )}
            <li>
              <p className={classes.answerDate}>
                on {formatDateWithoutSeconds(date.slice(0, 16))}
              </p>
            </li>
            {traceInspector ? (
              <FormBody
                linkCreatedByAccount={data?.linkCreatedByAccount}
                link={data?.link}
                traceId={data?.traceId}
                workflowContext={data?.workflowContext}
              />
            ) : (
              <>
                <li>
                  <div className={classes.answerRoot}>
                    <RichEditor
                      value={answer}
                      readOnly
                      searchValue={searchValue}
                      downloadImage={downloadFile}
                    />
                  </div>
                </li>
                {!!numberOfFiles && (
                  <li className={classes.files}>
                    <Paperclip className={classes.paperClip} />
                    {`${pluralize(
                      numberOfFiles,
                      'Attachment'
                    )} (${numberOfFiles})`}
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    )
  );
};

AnswerCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  traceInspector: PropTypes.bool,
  searchValue: PropTypes.string
};

export default injectSheet(styles)(AnswerCard);
