import { memo, useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Path from 'path-to-regexp';
import { RouteComponentProps } from 'react-router';
import { ROUTE_WORKFLOW_OVERVIEW } from '@/constant/routes';

import { FormsBatch } from '@/components/ui/formsBatch/formsBatch';
import { Footer } from '@/components/layouts';
import { TraceAction, WorkflowContextType } from '@/types/workflow.types';

const BATCH_MAX_FORMS = 10 as const;

type Props = {
  action: TraceAction;
  workflowContext: WorkflowContextType;
  toggleSignDialog: () => void;
  disabled: boolean;
  setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setCleanupFn: (fn: () => void) => void;
} & RouteComponentProps<
  { wfid: string },
  Record<string, never>,
  { from: string }
>;

export const Batch = memo((props: Props) => {
  const goBack = useCallback(() => {
    if (props.location.state && props.location.state.from) {
      props.history.push(props.location.state.from);
      return;
    }
    // by default go back to workflow overview
    props.history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: props.match.params.wfid })
    );
  }, [props.match, props.location]);

  const handleSubmit = useCallback(
    (data, fn) => {
      props.setFormData(data);
      props.setCleanupFn(fn);
      props.toggleSignDialog();
    },
    [props.setFormData, props.setCleanupFn, props.toggleSignDialog]
  );

  const { rowId: workflowRowId } = props.workflowContext;
  const formsBatchCachingKey = useMemo(
    () => `formsBatch_${workflowRowId}_${props.location.search}`,
    [workflowRowId, props.location.search]
  );

  const schema = useMemo(
    () =>
      props.action.form
        ? props.action.form.schema
        : {
            type: 'object',
            title: props.action.title,
            description: props.action.description
          },
    [props.action]
  );

  const uiSchema = useMemo(
    () => (props.action.form ? props.action.form.uiSchema : {}),
    [props.action]
  );

  return (
    <div className="bg-background mt-[35px] flex h-[calc(100vh-105px)] w-full flex-wrap items-start overflow-y-auto">
      <div className="bg-background flex size-full flex-col flex-nowrap justify-between">
        <FormsBatch
          // @ts-expect-error error since FormsBatch is not typed
          workflowContext={props.workflowContext}
          schema={schema}
          uiSchema={uiSchema}
          maxForms={BATCH_MAX_FORMS}
          onSubmit={handleSubmit}
          onCancel={goBack}
          submitDisabled={props.disabled}
          cachedUpdatesKey={formsBatchCachingKey}
        />
        <Footer />
      </div>
    </div>
  );
});

export default withRouter(Batch);
