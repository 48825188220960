import { Fragment, memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@stratumn/atomic';
import { formatNumber } from 'utils';
import { ENABLE_SUB_TOTAL_AGGREGATION } from 'constant/featureFlags';
import ContextMenu from 'components/ui/utils/contextMenu';

import {
  TableHeaderElement,
  HEADER_CHECKBOX,
  HEADER_CHECKBOX_INDETERMINATE
} from '../tableHeaderElement';

import { DisplayMenu } from '../displayMenu/displayMenu';
import SavedViewsMenu from '../savedViewsMenu';
import { ExportTableMenu } from '../exportTableMenu/exportTableMenu';

import { getAggregationConfig } from '../aggregationRow';
import { cn } from '@/shadcn/lib/utils';
import { Popover, PopoverTrigger, PopoverContent } from '@/shadcn/popover';
const columnsHaveAggregationSetup = columnsDisplayConfig =>
  !!columnsDisplayConfig &&
  columnsDisplayConfig.some(column => !!getAggregationConfig(column));

// table headers
const TableHeaders = memo(
  ({
    toggleFilters,
    isEditing,
    toggleEdition,
    tableConfig,
    displayConfig,
    toggleColumnSelection,
    setGroupBy,
    nbRows,
    currentNbRows = null,
    update,
    displayDiffs,
    toggleDisplayDiffs,
    onExport,
    onDeleteSelectedRows,
    showAggregation = false,
    toggleShowAggregation,
    nbRowsSelected,
    toggleSelectAll,
    resetDisplayConfig = null,
    shrinkRightIcons = false,
    saveConfig = null,
    updateUserDisplayConfig = null,
    changeTypeFile = null,
    fileType = 'csv',
    headerConfig = {}
  }) => {
    const [showSavedViewsMenu, setShowSavedViewsMenu] = useState(false);

    const {
      onClickConfig,
      configClicked,
      onClickNew,
      newClicked,
      onClickUpdate,
      updateClicked,
      edit: { allowDisplayDiffs, onClickBatchEdit, batchEditClicked } = {},
      pagination,
      group
    } = update;

    const { allowColumnsSelection = true } = tableConfig;
    const {
      applyFilters: showFilters = false,
      fixedColumns,
      columns
    } = displayConfig;

    const toggleShowSavedViewsMenu = useCallback(() => {
      setShowSavedViewsMenu(!showSavedViewsMenu);
    }, [showSavedViewsMenu]);

    const hasAggregationSetup = useMemo(
      () =>
        tableConfig.aggregation ||
        columnsHaveAggregationSetup(fixedColumns) ||
        columnsHaveAggregationSetup(columns),
      [fixedColumns, columns]
    );

    return (
      <div className="border-foreground/20 text-muted-foreground flex flex-row flex-nowrap items-center justify-between border-b py-2">
        <HeaderGroup className="justify-start" data-is-left>
          {[
            // allow select all only if onClickUpdate or onClickBatchEdit function are provided
            // AND there is no pagination or grouping
            (onClickUpdate || onClickBatchEdit) && !pagination && !group && (
              <TableHeaderElement
                label={
                  headerConfig.isCompact === true
                    ? ''
                    : `${formatNumber(nbRowsSelected)} rows selected`
                }
                icon={
                  nbRowsSelected === nbRows
                    ? HEADER_CHECKBOX
                    : HEADER_CHECKBOX_INDETERMINATE
                }
                clicked={nbRowsSelected > 0}
                onClick={toggleSelectAll}
              />
            ),
            nbRows > 0 && allowDisplayDiffs && (
              <TableHeaderElement
                label="Initial values"
                icon="PasswordHide"
                iconIfClicked="PasswordShow"
                clicked={displayDiffs}
                onClick={toggleDisplayDiffs}
              />
            ),
            onClickNew && (
              <TableHeaderElement
                label={headerConfig.newLabel || 'New'}
                icon="AddCircle"
                clicked={newClicked}
                onClick={onClickNew}
                className="bg-accent outline-accent hover:bg-accent/70 hover:outline-border px-3 font-semibold outline"
              />
            ),
            onClickUpdate && (
              <TableHeaderElement
                label="Update"
                icon="CircleUpload"
                clicked={updateClicked}
                onClick={onClickUpdate}
              />
            ),
            onClickBatchEdit && nbRowsSelected > 0 && (
              <TableHeaderElement
                label={headerConfig.isCompact ? null : 'Edit selection'}
                icon="Pen"
                clicked={batchEditClicked}
                onClick={onClickBatchEdit}
              />
            ),
            onDeleteSelectedRows && nbRowsSelected > 0 && (
              <TableHeaderElement
                icon="Trash"
                clicked={onDeleteSelectedRows}
                onClick={onDeleteSelectedRows}
                tooltip="Delete selected rows"
                className="hover:bg-destructive hover:text-destructive-foreground"
              />
            )
          ].map((tableHeaderElement, index, arr) => (
            <Fragment key={index}>
              {!!index && arr[index - 1] && <HeaderElementSeparator />}
              {tableHeaderElement}
            </Fragment>
          ))}
        </HeaderGroup>
        <HeaderGroup data-is-right>
          {
            // allow aggregation only if there is no pagination or grouping
            // and if aggregation has been setup on at least one column
            !pagination &&
              !group &&
              hasAggregationSetup &&
              ENABLE_SUB_TOTAL_AGGREGATION && (
                <Switch
                  label={
                    <div className="text-xs">
                      {tableConfig.aggregation?.label || 'Show sub-totals'}
                    </div>
                  }
                  showLabel
                  invert
                  on={showAggregation}
                  handleChange={toggleShowAggregation}
                  dataCy="toggle-show-aggregation"
                />
              )
          }
          {currentNbRows !== null && (
            <div className="px-2 text-xs">
              {currentNbRows} {currentNbRows > 1 ? 'rows' : 'row'}
            </div>
          )}

          <HeaderGroupSeparator />

          <div>
            <Popover>
              <PopoverTrigger asChild>
                <div>
                  <TableHeaderElement
                    label={shrinkRightIcons ? null : 'Export'}
                    icon="Download"
                    tooltip="Export current table data"
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <ExportTableMenu
                  onClick={onExport}
                  changeTypeFile={changeTypeFile}
                  fileType={fileType}
                />
              </PopoverContent>
            </Popover>
          </div>
          <HeaderGroupSeparator />
          <TableHeaderElement
            label={shrinkRightIcons ? null : 'Filter rows'}
            icon="Filter"
            clicked={showFilters}
            onClick={toggleFilters}
            tooltip={`${showFilters ? 'Disable' : 'Apply'} filters${
              !pagination ? `\nCurrently showing ${nbRows} rows` : ''
            }`}
          />
          <HeaderElementSeparator />
          <TableHeaderElement
            label={shrinkRightIcons ? null : 'Customize layout'}
            icon="CustomizeRuler"
            clicked={isEditing}
            onClick={toggleEdition}
            tooltip="Reorder columns, as well as resize columns width and rows height"
          />
          {allowColumnsSelection && (
            <>
              <HeaderElementSeparator />
              <div>
                <Popover>
                  <PopoverTrigger asChild>
                    <div>
                      <TableHeaderElement
                        label={shrinkRightIcons ? null : 'Display'}
                        icon="TableColumns"
                        tooltip="Select the columns you want to display"
                      />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <DisplayMenu
                      tableConfig={tableConfig}
                      displayConfig={displayConfig}
                      toggleColumnSelection={toggleColumnSelection}
                      setGroupBy={setGroupBy}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </>
          )}
          {
            // Reset button
            resetDisplayConfig && (
              <>
                <HeaderElementSeparator />
                <TableHeaderElement
                  label={shrinkRightIcons ? null : 'Reset'}
                  icon="Reset"
                  clicked={false}
                  onClick={resetDisplayConfig}
                  tooltip="Reset initial table view"
                />
              </>
            )
          }
          {allowColumnsSelection && (
            <>
              <HeaderGroupSeparator />
              <div>
                <TableHeaderElement
                  label={shrinkRightIcons ? null : 'Saved views'}
                  icon="Save"
                  clicked={showSavedViewsMenu}
                  onClick={toggleShowSavedViewsMenu}
                  tooltip="Create new table views or load existing ones"
                />
                {showSavedViewsMenu && (
                  <div className="relative">
                    <div
                      className="fixed inset-0 z-5"
                      onClick={toggleShowSavedViewsMenu}
                    />
                    <ContextMenu>
                      <SavedViewsMenu
                        saveConfig={saveConfig}
                        updateUserDisplayConfig={updateUserDisplayConfig}
                        resetDisplayConfig={resetDisplayConfig}
                        setVisible={setShowSavedViewsMenu}
                      />
                    </ContextMenu>
                  </div>
                )}
              </div>
            </>
          )}

          {onClickConfig && (
            <>
              <HeaderGroupSeparator />
              <TableHeaderElement
                label={shrinkRightIcons ? null : 'Config'}
                icon="Settings"
                clicked={configClicked}
                onClick={onClickConfig}
              />
            </>
          )}
        </HeaderGroup>
      </div>
    );
  }
);
TableHeaders.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEdition: PropTypes.func.isRequired,
  tableConfig: PropTypes.object.isRequired,
  displayConfig: PropTypes.object.isRequired,
  toggleColumnSelection: PropTypes.func.isRequired,
  setGroupBy: PropTypes.func.isRequired,
  nbRows: PropTypes.number.isRequired,
  currentNbRows: PropTypes.number,
  update: PropTypes.object.isRequired,
  displayDiffs: PropTypes.bool.isRequired,
  toggleDisplayDiffs: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func,
  showAggregation: PropTypes.bool,
  toggleShowAggregation: PropTypes.func.isRequired,
  nbRowsSelected: PropTypes.number.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  resetDisplayConfig: PropTypes.func,
  shrinkRightIcons: PropTypes.bool,
  saveConfig: PropTypes.func,
  updateUserDisplayConfig: PropTypes.func,
  changeTypeFile: PropTypes.func,
  fileType: PropTypes.string,
  headerConfig: PropTypes.shape({
    isCompact: PropTypes.bool,
    newLabel: PropTypes.string
  })
};

export default TableHeaders;

const HeaderElementSeparator = () => {
  return <div className="w-2" />;
};

const HeaderGroupSeparator = () => {
  return <div className="border-muted-foreground/40 mx-2 h-5 w-px border-l" />;
};

const HeaderGroup = ({ children, className }) => {
  return (
    <div
      className={cn(
        'text-muted-foreground mx-2 flex flex-row flex-nowrap items-center justify-end',
        className
      )}
    >
      {children}
    </div>
  );
};
