import { memo } from 'react';

import { Prose } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';

import { ProseView } from './prose.type';
import { TextView } from '../text/text.view.type';
import { WidgetData } from '../../widget.type';

type Props = {
  view: ProseView;
  data: WidgetData;
};

const ProseViewImpl = memo(({ view, data }: Props) => {
  const { path, small, light } = view;

  let proseData = getByPath<string | object>(data, path);
  if (!proseData) return null;

  if (typeof proseData === 'object') {
    proseData = JSON.stringify(proseData, null, 2);
  }

  return <Prose text={proseData} small={small} light={light} />;
});

// sort defaults to text on path
const getSortConfig = (view: ProseView): TextView => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: ProseView): TextView => ({
  type: 'text',
  path: view.path
});

// width defaults to 'xlarge'
const getDefaultWidth = () => 'xlarge';

// stringifies data at path
const getStringifyFunction = (view: ProseView) => {
  const { path } = view;
  return (data: WidgetData) => {
    let proseData = getByPath<string | object>(data, path);
    if (!proseData) return '';

    if (typeof proseData === 'object') {
      proseData = JSON.stringify(proseData, null, 2);
    }
    return proseData;
  };
};

export const proseViewProvider = {
  component: ProseViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
