import gql from 'graphql-tag';

export const userDashboardFragments = {
  // user-linked query
  dashboardUser: gql`
    fragment DashboardUserFragment on Query {
      # what are the entities the user belongs to
      me {
        id
        rowId
        name
        firstName
        lastName
        avatar
        email
        isSuperuser

        memberOf {
          nodes {
            rowId
            entity {
              ... on Account_Team {
                rowId
                name
                description
                avatar
                organization {
                  rowId
                  name
                  description
                  avatar
                }
              }
              ... on Account_Organization {
                rowId
                name
                description
                avatar
              }
              ... on Account_User {
                rowId
              }
            }
          }
        }
      }

      # for all the non-draft wfs the user can see what are the groups owned by which entity
      workflows(filter: { draft: { equalTo: false } }) {
        nodes {
          rowId
          groups {
            nodes {
              rowId
              name
              avatar
              canActQuick
            }
          }
        }
      }
    }
  `
};
