import React from 'react';
import ReactDOM from 'react-dom';

import { Globe } from 'lucide-react';
import { getFilterFromConfig } from './index';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem
} from '@/shadcn/select';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/avatar';

// some constants specific to groups task list
const DEFAULT_ORDER_BY = 'ALL_DESC';

const getSortFromConfig = config => config.orderBy || DEFAULT_ORDER_BY;

// groups task list of the user dashboard
export const GroupsTaskListHeader = React.memo(
  ({
    refetchPriorities,
    workflows = {},
    config = {},
    setConfig,
    setRefetching
  }) => {
    // set filter workflow and group id
    const { filterWorkflowId, filterGroupId } = config;

    const setConfigAndRefetch = newConfig => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig(newConfig);
        setRefetching(true);
      });

      // Refetch priorities with the new variables
      const filter = getFilterFromConfig(newConfig, workflows);
      const orderBy = getSortFromConfig(newConfig);
      return refetchPriorities({
        filter,
        orderBy
      }).then(() => {
        setRefetching(false);
      });
    };

    const setFilterWorkflowId = value => {
      if (value === config.filterWorkflowId) return null;

      // When changing the workflow ID filter,
      // unset the group ID filter
      const newConfig = {
        ...config,
        filterWorkflowId: value,
        filterGroupId: undefined
      };
      return setConfigAndRefetch(newConfig);
    };

    const setFilterGroupId = value => {
      if (value === config.filterGroupId) return null;

      const newConfig = {
        ...config,
        filterGroupId: value
      };
      return setConfigAndRefetch(newConfig);
    };

    const filterWorkflow = workflows.find(w => w.rowId === filterWorkflowId);
    const groups = filterWorkflow
      ? filterWorkflow.groups.nodes.filter(g => g.canActQuick)
      : [];
    const filterGroup =
      filterWorkflow &&
      filterWorkflow.groups.nodes.find(g => g.rowId === filterGroupId);

    return (
      <div className="flex flex-row flex-nowrap justify-between px-4">
        <div className="mr-8 flex shrink-0 flex-col flex-nowrap text-justify text-base font-semibold">
          Priority actions
          <div className="bg-secondary h-1 w-4 rounded-xl" />
        </div>
        <div
          className="mb-3 flex flex-row flex-nowrap items-center"
          data-is-button
        >
          <div className="mr-5 shrink-0 truncate text-sm">workflows</div>
          <Select
            value={filterWorkflow?.rowId}
            onValueChange={setFilterWorkflowId}
          >
            <SelectTrigger className="border-border min-w-[300px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem key={-1} value={undefined}>
                  All workflows
                </SelectItem>
                {workflows
                  .filter(w => w.groups.nodes.some(g => g.canActQuick))
                  .map(workflow => {
                    return (
                      <SelectItem key={workflow.rowId} value={workflow.rowId}>
                        {workflow.name}
                      </SelectItem>
                    );
                  })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="mb-3 flex flex-row flex-nowrap items-center">
          <div className="mr-5 shrink-0 truncate text-sm">groups</div>
          <Select
            disabled={!filterWorkflow}
            value={filterGroup?.rowId}
            onValueChange={setFilterGroupId}
          >
            <SelectTrigger className="border-border min-w-[300px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  key={-1}
                  value={undefined}
                  className="flex items-center gap-2"
                >
                  <div className="flex flex-row flex-nowrap items-center gap-3">
                    <Globe size={22} />
                    All groups
                  </div>
                </SelectItem>
                {groups.map(group => {
                  return (
                    <SelectItem key={group.rowId} value={group.rowId}>
                      <div className="flex flex-row flex-nowrap items-center gap-3">
                        {group.avatar && (
                          <Avatar className="size-5">
                            <AvatarImage src={group.avatar} />
                            <AvatarFallback className="bg-accent animate-pulse"></AvatarFallback>
                          </Avatar>
                        )}
                        <p className="truncate">{group.name}</p>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  }
);

export default GroupsTaskListHeader;
