import { PureComponent } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { Textarea } from '@stratumn/atomic';

import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../../../../utils/links';

type Props = {
  invalid: boolean;
} & WidgetProps;

export class TextArea extends PureComponent<Props> {
  static defaultProps = {
    id: '',
    label: '',
    value: '',
    placeholder: null,
    required: false,
    disabled: false,
    onChange: () => {}
  };

  handleChange = event => this.props.onChange(event.target.value);

  render() {
    const { id, value, placeholder, required, disabled, invalid, formContext } =
      this.props;

    // check if the widget is locked because of forms links
    const isLocked = isLockedWidget(id, formContext.links);

    const textAreaProps = {
      value,
      id: id,
      placeholder,
      disabled: disabled || isLocked,
      handleChange: this.handleChange,
      required,
      status: invalid ? 'invalid' : null
    };

    return <Textarea {...textAreaProps} hideErrorMessage />;
  }
}

export default withInlineValidation(TextArea);
