import { useState } from 'react';
import injectSheet from 'react-jss';

import compose from 'lodash.flowright';

import styles from './multiSelectMenu.style';
import { arraysMatch } from '../checkboxes';
import withInlineValidation from '../../../../../wrappers/withInlineValidation';
import { MultiSelect } from '@stratumn/atomic';
import { isLockedWidget } from '../../../../../utils/links';

// type Schema = {
//   title: string;
//   type: string;
//   items: {
//     enum: any[];
//     enumNames: any;
//     type: string;
//   };
// };

type Label = string;

type Option = {
  value: string;
  label: Label;
};

type UIOptions = {
  enumOptions: Option[];
  itemName?: string;
};

type Props = {
  classes: Record<string, string>;
  options: UIOptions;
  value: Label[];
  onChange: (value: Label[]) => void;
  id: string;
  invalid: boolean;
  disabled: boolean;
  formContext: any;
};

export const MultiSelectMenu = ({
  classes,
  id,
  options,
  onChange,
  value,
  disabled = false,
  invalid,
  formContext
}: Props) => {
  /**
   * Building an array of string values
   * in order to match with an array of results values.
   */
  const enumOptionsValues: string[] = Object.values(options.enumOptions).map(
    e => e.value
  );

  const [tempEnumOptions, setTempEnumOptions] = useState(enumOptionsValues);

  // /**
  //  * Check if the selectMenu option value has changed.
  //  * If so, we clear the array of results values.
  //  */
  if (!arraysMatch(enumOptionsValues, tempEnumOptions)) {
    setTempEnumOptions(enumOptionsValues);
    onChange([]);
  }
  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);

  return (
    <div className={classes.root}>
      <MultiSelect
        displayValue={options.itemName || 'item'}
        id={id}
        options={options.enumOptions}
        selectedValues={value}
        onSelect={onChange}
        disabled={disabled || isLocked}
        invalid={invalid}
      />
    </div>
  );
};

export default compose(
  withInlineValidation,
  injectSheet(styles)
)(MultiSelectMenu);
