import React, { useCallback, useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { getDownloadURL, uploadFile } from 'client/media';
import { getByPath } from 'utils/widgets';
import { UiconDocUploadFill, Icon, TextEllipsis } from '@stratumn/atomic';
import { notify } from 'components/toast';
import classnames from 'classnames';
import Ellipsis from 'components/ui/ellipsis';
import { Pen, Trash, Download } from '@stratumn/icons';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { getFileIconStr } from 'utils';
import styles from './uploadFile.style';

const UploadFile = ({
  classes,
  path,
  data,
  fileData,
  onChange,
  setIsPatched,
  disabled = false,
  defaultIcon = ''
}) => {
  const [isLoading, setIsloading] = useState(false);
  const inputRef = useRef();

  const handleNewFile = useCallback(
    async evt => {
      const inputFile = evt.target.files[0];

      if (inputFile) {
        await uploadFile(
          inputFile,

          // onSuccess
          file => {
            onChange({ path, value: file });
            setIsloading(false);
          },

          // onError
          () => {
            notify.error(`Document could not be uploaded`);
          },

          // onProgress
          () => {
            setIsloading(true);
          },

          // Disable encryption
          false
        );
      }
    },
    [path, onChange]
  );

  const removeFile = () => onChange({ path, value: undefined });

  useEffect(() => {
    const currentData = getByPath(data, path);
    if (setIsPatched) {
      setIsPatched(currentData !== fileData);
    }
  }, [fileData]);

  const openBrowserFile = () => inputRef.current.click();

  const iconStr = getFileIconStr(
    fileData?.mimetype,
    fileData?.name,
    defaultIcon || 'DocumentDownload'
  );

  if (disabled)
    return (
      <div
        className={classnames(classes.uploadFileContainer, {
          [classes.noCursor]: !fileData
        })}
      >
        {fileData ? (
          <a
            className={classes.fileView}
            href={getDownloadURL(fileData)}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name={iconStr} size={24} />
            <TextEllipsis
              className={classes.fileName}
              text={fileData?.name}
              middleEllipsis
            />
          </a>
        ) : null}
      </div>
    );

  return (
    <LoadingIndicator spinning={isLoading} showSuccess={false}>
      <div className={classes.uploadFileContainer}>
        <input ref={inputRef} type="file" hidden onChange={handleNewFile} />
        {fileData ? (
          <div className={classes.uploadFileInput}>
            <Ellipsis maxWidth="150px">
              <span onClick={openBrowserFile}>{fileData.name}</span>
            </Ellipsis>
            <div>
              <Pen className={classes.editableIcon} onClick={openBrowserFile} />
              <a
                href={getDownloadURL(fileData)}
                target="_blank"
                rel="noreferrer"
              >
                <Download className={classes.editableIcon} />
              </a>
              <Trash className={classes.editableIcon} onClick={removeFile} />
            </div>
          </div>
        ) : (
          <div
            className={classnames(
              classes.uploadFileInput,
              classes.uploadFileBtn
            )}
            onClick={openBrowserFile}
          >
            Upload file
            <UiconDocUploadFill />
          </div>
        )}
      </div>
    </LoadingIndicator>
  );
};

UploadFile.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  fileData: PropTypes.object.isRequired,
  setIsPatched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultIcon: PropTypes.string
};

export default injectSheet(styles)(React.memo(UploadFile));
