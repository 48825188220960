import React, { memo, useMemo } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { SmartDropdown, AvatarGroup } from '@stratumn/atomic';

import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../../../../utils/links';
import {
  WorkflowContext,
  WorkflowGroupOptions,
  buildSelectableGroupsList
} from '../../../../../utils/workflowContext';

type Schema = {
  title: string;
  type: string;
  format: string;
  formatOptions: WorkflowGroupOptions;
};

type Props = {
  schema: Schema;
  invalid?: boolean;
} & WidgetProps;

export const GroupSelection: React.FC<Props> = ({
  id,
  onChange,
  value = '',
  disabled = false,
  invalid = false,
  formContext,
  schema
}) => {
  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);

  const inputOptions = useMemo(() => {
    // get selectable options from the form's workflowContext
    const groups = buildSelectableGroupsList(
      formContext.workflowContext as WorkflowContext,
      schema.formatOptions
    );

    return groups.map(({ label, name, avatar }) => ({
      value: label,
      label: name,
      prefix: <AvatarGroup src={avatar} size={22} />
    }));
  }, [formContext.workflowContext, schema.formatOptions]);

  return (
    <SmartDropdown
      id={id}
      options={inputOptions}
      value={value}
      onSelect={onChange}
      label={schema.title}
      disabled={disabled || isLocked}
      invalid={invalid}
      shadows
    />
  );
};

export default withInlineValidation(memo(GroupSelection));
