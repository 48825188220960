import { File as FileType } from './types';
import {
  ADD_FILES,
  REMOVE_FILE,
  ERROR,
  PROGRESS,
  SUCCESS
} from './constant/actions';
import { ERROR_TYPE_DEFAULT } from './constant/errors';

/**
 * @function reducer - manages the state of FileUpload
 * @param files
 * @param action.type
 * @param action.payload
 */
const reducer = (files: FileType[], { type, payload }) => {
  const idx = !Array.isArray(files)
    ? -1
    : files.findIndex(f => payload.id === f.id);

  switch (type) {
    case ADD_FILES: {
      return [...files, ...payload.mergedFiles];
    }

    case REMOVE_FILE: {
      return [...files.filter(p => p.id !== payload.id)];
    }

    case PROGRESS: {
      if (idx === -1) return files;

      const fileUploading = {
        ...files[idx],
        uploading: true, // safeguard from submitting a file in progress
        uploadPercent: payload.percentage
      };

      return [...files.slice(0, idx), fileUploading, ...files.slice(idx + 1)];
    }

    case ERROR: {
      if (idx === -1) return files;

      const fileError = {
        ...files[idx],
        error: true, // safeguard from submitting a file error
        errorType: ERROR_TYPE_DEFAULT
      };

      return [...files.slice(0, idx), fileError, ...files.slice(idx + 1)];
    }

    case SUCCESS: {
      if (idx === -1) return files;

      const fileSuccess = {
        ...payload.file,
        uploading: false,
        error: false
      };

      return [...files.slice(0, idx), fileSuccess, ...files.slice(idx + 1)];
    }

    default: {
      return files;
    }
  }
};

/**
 * @function applyFilesChange - apply an action to a list of files and call the onChange callback with the new set of files
 * @param files
 * @param action
 * @param onChange
 */
export const applyFilesChange = (
  files: FileType[],
  action: { type: string; payload: object },
  onChange: (files: FileType[]) => void
): void => {
  const newFiles = reducer(files, action);
  onChange(newFiles);
};
