// import './utils/wrr';
import ReactDOM from 'react-dom/client';
import { Root } from './components';

// configure internationalization on load
import 'utils/i18n';

// polyfills
import 'utils/polyfills';
import './index.css';

const termsToFilter = [
  // TODO
  'Support for defaultProps will be removed from function components in a future major release.',
  // TODO
  'Support for defaultProps will be removed from memo components in a future major release. Use JavaScript default parameters instead.',
  // TODO
  'uses the legacy contextTypes API which is no longer supported and will be removed in the next major release. Use React.createContext() with static contextType instead.',
  // TODO
  'uses the legacy childContextTypes API which is no longer supported and will be removed in the next major release. Use React.createContext() instead'
] as const;
const consoleError = console.error;

console.error = (...args: any[]) => {
  const errorMessage = args[0];
  if (
    typeof errorMessage === 'string' &&
    termsToFilter.some(term => errorMessage.includes(term))
  ) {
    return;
  }
  consoleError(...args);
};

ReactDOM.createRoot(document.getElementById('root')!).render(<Root />);
