import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { getByPath } from 'utils';
import merge from 'lodash.merge';

import injectSheet from 'react-jss';
import DynamicIcon from 'components/ui/dynamicIcon';
import styles from './commentFeed.style';
import { CommentViewStyled } from '../comment';
import CommentForm from './editors/commentForm';
import { PATHS } from '../comment/constants.comment';

export interface ICommentData {
  date: string;
  user: {
    name: string;
    avatar: string;
    accountId: string;
  };
  group?: string;
  action?: string;
  comment: any;
  groupId?: string;
  groupLabel?: string;
}

interface ICommentFeedViewProps {
  classes: any;
  view: { path: string; editor: any };
  data: any;
  update: any;
}

const viewPath = {
  namePath: PATHS.NAME,
  avatarPath: PATHS.AVATAR,
  groupPath: PATHS.GROUP,
  datePath: PATHS.DATE,
  actionPath: PATHS.ACTION,
  commentPath: PATHS.COMMENT
};

const isSameArray = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const CommentFeedView: FC<ICommentFeedViewProps> = memo(
  ({ classes, view, data, update }) => {
    const { path, editor } = view;
    const { patch, onChange, setIsPatched } = update || {};

    const initialComments = getByPath(data, path) as ICommentData[];

    const dataWithPatchApplied = useMemo(
      () => merge({}, data, patch),
      [data, patch]
    );
    const patchedComments = getByPath(dataWithPatchApplied, path) || undefined;

    useEffect(() => {
      if (setIsPatched)
        setIsPatched(!isSameArray(patchedComments, initialComments));
    }, [initialComments, patchedComments]);

    const [updatedComment, setUpdatedComment] =
      useState<ICommentData[]>(patchedComments);

    const [editingComments, setEditingComments] = useState<number[]>([]);

    const isNewComment = index => {
      if (!initialComments?.length) {
        return true;
      }
      return index > initialComments?.length - 1;
    };

    const handleChange = useCallback(
      value => {
        const isSameAsData =
          isSameArray(value, initialComments) ||
          (initialComments === undefined && value.length === 0);
        if (onChange) {
          onChange({ path, value: isSameAsData ? undefined : value });
        }
      },
      [path, onChange]
    );

    useEffect(() => {
      handleChange(updatedComment);
    }, [updatedComment]);

    const handleEdit = (index: number) => {
      setEditingComments(prevEditingComments => [
        ...new Set([...prevEditingComments, index])
      ]);
    };
    const handleCancelEdit = (index: number) => {
      setEditingComments(prevEditingComments =>
        prevEditingComments.filter(i => i !== index)
      );
    };
    const handleRemove = (index: number) => {
      setUpdatedComment(prevComments =>
        prevComments.filter((_, i) => i !== index)
      );
    };

    const saveComment = (comment: any, index?: number) => {
      setUpdatedComment(prevComments => {
        const newComments = prevComments ? [...prevComments] : [];
        if (index !== undefined) {
          newComments[index] = comment;
        } else {
          newComments.push(comment);
        }
        return newComments;
      });
      if (index !== undefined) {
        handleCancelEdit(index);
      }
    };

    return (
      <div className={classes.commentFeed}>
        <div className={classes.commentList}>
          {(patchedComments?.length === 0 || !patchedComments) && (
            <div className={classes.noComments}>Comments is empty</div>
          )}
          {patchedComments?.map((cmmnt, index) => (
            <div key={index} className={classes.comment}>
              {editingComments.includes(index) ? (
                <>
                  <CommentForm
                    id={index}
                    data={cmmnt}
                    onSave={saveComment}
                    onCancel={handleCancelEdit}
                  />
                </>
              ) : (
                <>
                  {isNewComment(index) && (
                    <div className={classes.commentPatch} />
                  )}
                  <CommentViewStyled
                    key={JSON.stringify(cmmnt)}
                    data={cmmnt}
                    view={viewPath}
                  />
                  {isNewComment(index) && onChange && (
                    <div className={classes.commentActions}>
                      <span onClick={() => handleEdit(index)}>
                        <DynamicIcon size={20} icon="Pen" />
                      </span>
                      <span onClick={() => handleRemove(index)}>
                        <DynamicIcon size={20} icon="Trash" />
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        {editor?.type === 'addComment' && onChange && (
          <CommentForm
            onChange={handleChange}
            onSave={saveComment}
            onCancel={handleCancelEdit}
          />
        )}
      </div>
    );
  }
);

// get edited path
// returns path if editor is set, null otherwise
const getEditedPath = view => {
  const { path, editor } = view;
  return editor ? path : null;
};

export default {
  component: injectSheet(styles)(CommentFeedView),
  getEditedPath
};
