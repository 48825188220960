import { numberViewProvider } from './number/number.view';
import { progressViewProvider } from './progress/progress.view';
import { textViewProvider } from './text/text.view';
import { linkViewProvider } from './link/link.view';
import { labelsViewProvider } from './labels/labels.view';
import DateView from './date';
import ActivityView from './activity';
import IconView from './icon';
import { proseViewProvider } from './prose/prose.view';
import { listViewProvider } from './list/list.view';
import HTMLView from './html';
import CodeView from './code';
import { windowViewProvider } from './window/window.view';
import { keyValueViewProvider } from './keyValue/keyValue.view';
import BoxView from './box';
import ArrayView from './array';
import FileView from './file';
import CommentView from './comment';
import { tableViewProvider } from './table/table.view';
import DataTabView from './dataTab';
import FileCompactView from './fileCompact';
import AvatarView from './avatar';
import ListCompactView from './listCompact';
import BooleanView from './boolean';
import AnswerView from './answer';
import { statusViewProvider } from './status/status.view';
import CommentFeedView from './commentFeed';

/* views map */
// views are plain object literals that can provide access to
// 1 - component: the component to display
// 2 - getSortConfig: a default sorting configuration
// 3 - getFilterConfig: a default filtering configuration
// 4 - getDefaultWidth: a default width, for example for table columns sizing
// 5 - getStringifyFunction: a function to report how the view exports itself to string
// 6 - getEditedPath: a function to report if and which path can be edited in this view
const viewsMap = {
  number: numberViewProvider,
  progress: progressViewProvider,
  text: textViewProvider,
  status: statusViewProvider,
  link: linkViewProvider,
  labels: labelsViewProvider,
  date: DateView,
  activity: ActivityView,
  icon: IconView,
  prose: proseViewProvider,
  list: listViewProvider,
  html: HTMLView,
  code: CodeView,
  window: windowViewProvider,
  keyValue: keyValueViewProvider,
  box: BoxView,
  array: ArrayView,
  file: FileView,
  comment: CommentView,
  table: tableViewProvider,
  dataTab: DataTabView,
  fileCompact: FileCompactView,
  avatar: AvatarView,
  listCompact: ListCompactView,
  boolean: BooleanView,
  answer: AnswerView,
  commentFeed: CommentFeedView
};

export type ViewType = keyof typeof viewsMap;
type SimpleView = { type: ViewType };
// get the relevant view
export const getView = <V extends SimpleView>(view?: V) => {
  if (!view) return textViewProvider;
  return viewsMap[view.type] || textViewProvider;
};
