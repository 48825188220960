export default theme => {
  const labelColor = theme.grey1;

  return {
    labelWrapper: {
      display: 'flex',
      flexDirection: 'column',
      '&[data-is-inline=true]': {
        flexDirection: 'row'
      }
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.grey3,
      marginBottom: 3
    },
    label: {
      display: 'flex',
      fontSize: 14,
      color: labelColor,
      '&[data-is-inline=true]': {
        marginRight: 7
      }
    },
    gdprTitle: {
      fontSize: 10,
      fontWeight: 600,
      color: theme.grey3,
      marginBottom: 3
    },
    gdprLabel: {
      display: 'flex',
      fontSize: 9,
      color: labelColor,
      '&[data-is-inline=true]': {
        marginRight: 7
      }
    },
    emDash: {
      display: 'inline-flex',
      alignSelf: 'center',
      paddingTop: 1,
      marginRight: 4,
      width: 7,
      backgroundColor: labelColor
    }
  };
};
