import React, { useState, useCallback } from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { Download, CheckboxTick } from '@stratumn/icons';
import filesize from 'filesize';
import styles from './item.style';

interface Props {
  classes: ClassNameMap<string>;
  file: {
    name: string;
    size: number;
  };
  downloadFile: (
    file: { name: string; size: number },
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isLoading: boolean,
    setDownloadResponse: React.Dispatch<unknown>
  ) => void;
}

export const Item = ({ classes, file, downloadFile }: Props) => {
  const [hovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadResponse, setDownloadResponse] = useState<unknown>(null);

  const handleMouseEnter = useCallback(() => setHovered(true), [hovered]);
  const handleMouseLeave = useCallback(() => setHovered(false), [hovered]);

  const renderDownloadStatus = () =>
    downloadResponse === null ? (
      <Download className={classes.labelIcon} />
    ) : (
      <CheckboxTick className={classes.labelTickIcon} />
    );

  const renderDownloadFile = () => (
    <div
      data-cy="download-file"
      className={classes.label}
      onClick={() =>
        downloadFile(file, setIsLoading, isLoading, setDownloadResponse)
      }
    >
      {renderDownloadStatus()}
      <p>
        {file.name} <span>{filesize(file.size)?.split(' ').join('')}</span>
      </p>
    </div>
  );
  return (
    <div
      data-cy="file-wrapper"
      className={classes.root}
      title={file.name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.attachment}>{renderDownloadFile()}</div>
    </div>
  );
};

export default injectSheet(styles)(React.memo(Item));
