import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';
import styles from './slateReaderWidget.style';
import { KeyValue } from '@stratumn/atomic';
import RichEditor from 'components/ui/richEditor';

type Props = {
  classes: ClassNameMap<string>;
  options: {
    withSubject: boolean;
    withMentions: boolean;
  };
} & Pick<WidgetProps, 'value' | 'label' | 'schema' | 'formContext'>;

const isJson = string => {
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const SlateReaderWidget: React.FC<Props> = ({
  classes,
  value = '',
  label = 'Comment',
  options = {
    withSubject: false,
    withMentions: false
  },
  formContext
}) => {
  const isJSON = isJson(value);

  if (label === 'Answer') {
    return (
      <RichEditor
        value={value}
        withSubject={isJSON && options.withSubject}
        withMentions={isJSON && options.withMentions}
        readOnly
        className={classes.richEditor}
        downloadImage={formContext.downloadImage}
      />
    );
  }

  return (
    <KeyValue label={label}>
      <RichEditor
        value={value}
        withSubject={isJSON && options.withSubject}
        withMentions={isJSON && options.withMentions}
        readOnly
        className={classes.richEditor}
        downloadImage={formContext.downloadImage}
      />
    </KeyValue>
  );
};

export default injectSheet(styles)(SlateReaderWidget);
