import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';

import styles from './acceptedFilesDescription.style';

interface Props {
  classes: ClassNameMap<string>;
  fileExtensionWhitelistMap: any;
  responsive: boolean;
  filesLength: number;
}

export const AcceptedFilesDescription: React.FC<Props> = ({
  classes,
  fileExtensionWhitelistMap,
  responsive,
  filesLength
}) => {
  if (!fileExtensionWhitelistMap) return null;
  const fileExtensionsWhiteList: any[] = Object.entries(
    fileExtensionWhitelistMap
  );

  return (
    <div
      className={classes.root}
      style={{ marginBottom: responsive && filesLength > 0 ? '14px' : 0 }}
      data-is-responsive={responsive}
    >
      <h5>accepted file types</h5>
      <ul>
        {/* First we extract the category and its extensions */}
        {fileExtensionsWhiteList.map(([category, extensionsList]) => {
          const extensionLength = extensionsList.length;

          return (
            <li key={category}>
              {category}

              {/* Then, we map on a category's extensions */}
              {extensionsList.map((extension, index) => {
                if (index < 3) return <span key={extension}>{extension},</span>;
                if (index === 3 && extensionLength > 3)
                  // If we have more than 4 extensions, add the rest of the array in the html title attribute (aka tooltip)
                  return (
                    <span
                      key={extension}
                      data-has-more-extension={extensionLength > 4}
                      title={extensionsList.slice(index + 1)}
                    >
                      {extension}
                    </span>
                  );
                return null;
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default injectSheet(styles)(React.memo(AcceptedFilesDescription));
