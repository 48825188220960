import { memo } from 'react';

import { cn } from '@/shadcn/lib/utils';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from '@/shadcn/select';
import {
  type DisplayConfig,
  type TableGroup,
  type TableConfig
} from '../table.type';

type Props = {
  tableConfig: TableConfig;
  displayConfig: DisplayConfig;
  toggleColumnSelection: (columnKey: string) => void;
  setGroupBy: (group: TableGroup | undefined) => void;
};

export const DisplayMenu = memo(
  ({
    tableConfig,
    displayConfig,
    toggleColumnSelection,
    setGroupBy
  }: Props) => {
    const selectedColumnsKeys = displayConfig.columns.map(
      colDisplayConfig => colDisplayConfig.key
    );

    const handleChangeGroupBy = (groupByPath: string | undefined) => {
      if (!tableConfig.groups) return;
      const group = tableConfig.groups.find(
        group => group.path === groupByPath
      );
      setGroupBy(group);
    };

    return (
      <div className="flex flex-col gap-3">
        <section className="text-xs">
          <div className="text-muted-foreground mb-2 font-semibold">
            Choose which columns to display
          </div>
          <div className="flex min-w-[200px] flex-wrap gap-[5px]">
            {tableConfig.columns.map(colDef => (
              <button
                key={colDef.key}
                className={cn(
                  'border-border text-accent-foreground hover:bg-accent m-0 inline-flex h-6 min-w-6 shrink-0 items-center justify-center rounded-sm border px-2 align-top whitespace-nowrap shadow-xs',
                  !selectedColumnsKeys.includes(colDef.key) &&
                    'border-transparent bg-transparent shadow-transparent'
                )}
                onClick={() => toggleColumnSelection(colDef.key)}
              >
                <span>{colDef.header}</span>
              </button>
            ))}
          </div>
        </section>
        {tableConfig.groups && (
          <section>
            <div className="text-muted-foreground mb-2 text-xs font-semibold">
              Group by
            </div>
            <div>
              <Select
                onValueChange={handleChangeGroupBy}
                value={displayConfig.groupBy?.path || 'null'}
              >
                <SelectTrigger className="h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="null">No grouping</SelectItem>
                  {tableConfig.groups.map(group => (
                    <SelectItem key={group.label} value={group.path}>
                      {group.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </section>
        )}
      </div>
    );
  }
);

export default DisplayMenu;
