import { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatDateWithoutSeconds, findNumberOfFiles } from 'utils';
import { Icon } from '@stratumn/atomic';
import styles from './whoDidWhatWhen.style';

export class WhoDidWhatWhen extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired
  };

  renderNbDocuments = () => {
    const { classes, link } = this.props;

    if (!link?.data) return null;

    const fileLength = findNumberOfFiles(link.data);

    if (fileLength === 0) return null;
    let filesText;

    if (fileLength === 1) {
      filesText = ' 1 file';
    } else if (fileLength > 1) {
      filesText = ` ${fileLength} files`;
    }
    return (
      <span className={classes.whoDidWhat}>
        <div className={classes.fileIcon}>
          <Icon name="Doc" size={14} />
        </div>
        {filesText}
      </span>
    );
  };

  render = () => {
    const { classes, link } = this.props;
    const createdAt = moment.utc(link.createdAt).local();

    const whatGroup = link?.group?.name;

    return (
      <div className={classes.whoDidWhatWhen}>
        <div>
          Submitted {whatGroup ? ` by ${whatGroup},` : null} on{' '}
          {formatDateWithoutSeconds(createdAt)}
        </div>
        {this.renderNbDocuments()}
      </div>
    );
  };
}

export default injectSheet(styles)(WhoDidWhatWhen);
