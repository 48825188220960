import { memo, useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import { withRouter, Link } from 'react-router-dom';
import Path from 'path-to-regexp';

import moment from 'moment';

import { Pulldown, PulldownMenuItem } from '@stratumn/atomic';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/avatar';
import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import { ROUTE_NEW_LINK, ROUTE_INSPECT_TRACE } from '@/constant/routes';

import { GroupsTaskListItemError } from '../groupsTaskListItemError/groupsTaskListItemError';

import { Button } from '@/shadcn/button';
import { cn } from '@/shadcn/lib/utils';

// buffer to put the deadline display in a warning mode
const DEADLINE_WARNING_BUFFER = 0;

// group task list item of the user dashboard
export const GroupsTaskListItem = memo(
  ({ history, displayGroupInfo = false, priority }) => {
    // Missing trace error check
    if (!priority.trace) {
      return (
        <div className="border-border mx-4 my-0 overflow-hidden rounded border">
          <GroupsTaskListItemError title="Missing trace data" />
        </div>
      );
    }

    // Valid priority
    const {
      group: { label: groupLabel, name: groupName },
      trace: {
        rowId: traceRowId,
        name: traceName,
        workflow: {
          rowId: workflowRowId,
          name: workflowName,
          actions: { nodes: workflowActions }
        },
        state: {
          meta: {
            head: {
              action: {
                icon: headActionIcon = 'Document',
                title: headActionTitle
              },
              createdAt: headCreatedAt,
              group: { avatar: headGroupAvatar, name: headGroupName },
              createdBy: { avatar: headAuthorAvatar, name: headAuthorName }
            }
          },
          nextActions,
          tasks: { deadlines = [], groups: groupsTasks = {} } = {}
        }
      }
    } = priority;

    // check available next actions for this group
    // if there is a "todo" in this specific group's tasks, display them
    // otherwise display all nextActions
    let groupNextActions = nextActions[groupLabel];
    if (
      groupsTasks[groupLabel] &&
      Array.isArray(groupsTasks[groupLabel].todo) &&
      groupsTasks[groupLabel].todo.length > 0
    ) {
      groupNextActions = groupsTasks[groupLabel].todo;
    }

    // The next actions structure can either be an array (v1 next actions)
    // or an object (v2 next actions/transitions)
    let actions;
    if (!groupNextActions) {
      actions = [];
    } else if (groupNextActions.length > 0) {
      actions = groupNextActions
        .map(action =>
          workflowActions.find(wfAction => wfAction.key === action)
        )
        .filter(Boolean);
    } else {
      actions = Object.entries(groupNextActions).reduce(
        (arr, [key, allowAction]) => {
          if (allowAction) {
            const action = workflowActions.find(a => a.key === key);
            if (action) {
              arr.push(action);
            }
          }
          return arr;
        },
        []
      );
    }

    // navigate to the referenced trace
    const traceLink = useMemo(
      () => ({
        pathname: Path.compile(ROUTE_INSPECT_TRACE)({
          id: traceRowId
        }),
        state: {
          from: history.location
        }
      }),
      [traceRowId, history]
    );

    // navigate to the newlink tab with a selected action on this trace for this group
    const getActionLink = useCallback(
      actionKey => ({
        pathname: Path.compile(ROUTE_NEW_LINK)({
          wfid: workflowRowId
        }),
        search: `?groupKey=${groupLabel}&actionKey=${actionKey}&traceIds=${traceRowId}`,
        state: {
          from: history.location
        }
      }),
      [workflowRowId, groupLabel, traceRowId, history]
    );

    // single button if only one nextActions, pulldown otherwise
    let actionsBtn;
    switch (true) {
      case actions.length === 0:
        break;
      case actions.length === 1:
        actionsBtn = (
          <Link
            className="text-card-foreground cursor-pointer no-underline"
            to={getActionLink(actions[0].key)}
            data-cy="action-link"
          >
            <Button>{actions[0].title}</Button>
          </Link>
        );
        break;
      default:
        actionsBtn = (
          <Pulldown buttonLabel={`${actions.length} next actions`} primary>
            {actions.map(action => (
              <Link
                key={action.key}
                className="text-card-foreground cursor-pointer no-underline"
                to={getActionLink(action.key)}
                data-cy="action-link"
              >
                <PulldownMenuItem
                  icon={
                    <DynamicIcon
                      icon={action.icon || 'Document'}
                      className="block h-[18px] w-[18px] shrink-0"
                    />
                  }
                  onClick={() => {}} // note: onClick is required by atomic PulldownMenuItem, but here we overload it with Link
                >
                  {action.title}
                </PulldownMenuItem>
              </Link>
            ))}
          </Pulldown>
        );
    }

    // handle deadline warning display
    let countdownStr;
    let warning = false;
    if (deadlines.length > 0) {
      const timeToUpcomingDeadline = moment
        .utc(deadlines[0].date)
        .local()
        .diff(moment(), 'days');

      warning = timeToUpcomingDeadline - DEADLINE_WARNING_BUFFER <= 0;

      switch (true) {
        case timeToUpcomingDeadline === 0:
          countdownStr = 'Due today';
          break;
        case timeToUpcomingDeadline > 0:
          countdownStr = `Due in ${timeToUpcomingDeadline} day${
            timeToUpcomingDeadline > 1 ? 's' : ''
          }`;
          break;
        default:
          countdownStr = `${-timeToUpcomingDeadline} day${
            timeToUpcomingDeadline < -1 ? 's' : ''
          } overdue`;
      }
    }

    return (
      <div className="border-border mx-4 my-4 overflow-hidden rounded border">
        <div className="border-border dark:bg-accent relative flex w-full flex-row flex-nowrap items-center justify-between border-b bg-inherit p-2">
          <Link
            className="text-card-foreground mr-5 flex cursor-pointer flex-row flex-nowrap items-center justify-start overflow-hidden px-1 py-3 no-underline hover:underline"
            to={traceLink}
            data-cy="trace-link"
          >
            <div className="text-card-foreground mr-5 text-base font-semibold">
              {traceName}
            </div>
            <div className="text-muted-foreground mr-5 flex flex-row flex-nowrap items-center truncate text-xs">
              <div className="truncate">{workflowName}</div>
              {displayGroupInfo && (
                <>
                  <div className="mx-3 my-0">|</div>
                  <div className="shrink truncate">{groupName}</div>
                </>
              )}
            </div>
          </Link>
          <div className="flex shrink-0 flex-row flex-nowrap items-center justify-end">
            {countdownStr && (
              <div
                className={cn(
                  'mr-4 font-medium',
                  warning
                    ? 'bg-destructive text-destructive-foreground dark:text-destructive rounded-lg px-4 py-1 text-[10px] uppercase dark:bg-transparent dark:font-semibold'
                    : 'text-muted-foreground text-xs'
                )}
              >
                {countdownStr}
              </div>
            )}
            {actionsBtn}
          </div>
        </div>
        <div className="bg-background px-5 py-3">
          <div>
            <div className="text-foreground mb-1 text-xs font-semibold">
              Last action
            </div>
            <div className="flex flex-row flex-nowrap items-center justify-between">
              <div className="mr-4 flex grow basis-0 flex-row flex-nowrap items-center overflow-hidden">
                <DynamicIcon
                  icon={headActionIcon}
                  className="block h-[18px] w-[18px] shrink-0"
                  data-is-svg
                />
                <div className="text-foreground ml-2 truncate text-sm">
                  {headActionTitle}
                </div>
              </div>
              <div className="mr-4 flex grow basis-0 flex-row flex-nowrap items-center overflow-hidden">
                <DynamicIcon
                  icon="Clock"
                  className="block h-[18px] w-[18px] shrink-0"
                  data-is-svg
                />
                <div className="text-foreground ml-2 truncate text-sm">
                  {moment.utc(headCreatedAt).local().fromNow()}
                </div>
              </div>
              <div className="mr-4 flex grow basis-0 flex-row flex-nowrap items-center overflow-hidden">
                {headGroupAvatar && (
                  <Avatar className="size-4">
                    <AvatarImage src={headGroupAvatar} />
                    <AvatarFallback className="bg-accent animate-pulse"></AvatarFallback>
                  </Avatar>
                )}
                <div className="text-foreground ml-2 truncate text-sm">
                  {headGroupName}
                </div>
              </div>
              <div className="mr-4 flex grow basis-0 flex-row flex-nowrap items-center overflow-hidden">
                {headAuthorAvatar && (
                  <Avatar className="size-4">
                    <AvatarImage src={headAuthorAvatar} />
                    <AvatarFallback className="bg-accent animate-pulse"></AvatarFallback>
                  </Avatar>
                )}
                <div className="text-foreground ml-2 truncate text-sm">
                  {headAuthorName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default withRouter(GroupsTaskListItem);

// export the priority fragment
export const fragments = {
  priority: gql`
    fragment PriorityFragment on Priority {
      id
      responsibility
      investment
      urgency
      group {
        id
        rowId
        label
        name
        avatar
      }
      trace {
        rowId
        name
        workflow {
          rowId
          name
          description
          actions {
            nodes {
              key
              title
              icon
              description
            }
          }
        }
        state {
          rowId
          data
          nextActions
          meta
          tasks
        }
      }
    }
  `
};
