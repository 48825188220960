import { memo, useCallback, useEffect, useMemo } from 'react';
import RichEditor from 'components/ui/richEditor';
import merge from 'lodash.merge';

import { getByPath, stringifyData } from '@/utils/widgets';

import { downloadFile } from '@/utils/downloadFile';
import {
  TextInput,
  NumberInput,
  CommentInput,
  MultiselectInput,
  DateInput,
  SelectDropdown
} from './editors';
import { TextView } from './text.view.type';
import { CellEditor } from './editors/editors.types';
import { WidgetData } from '../../widget.type';

// text editor types
export const TEXT_EDITOR_INPUT = 'input' as const;
export const TEXT_EDITOR_NUMBER = 'number' as const;
export const TEXT_EDITOR_SELECT = 'select' as const;
export const TEXT_EDITOR_COMMENT = 'comment' as const;
export const TEXT_EDITOR_MULTISELECT = 'multiselect' as const;
export const TEXT_EDITOR_DATE = 'date' as const;

const getTextData = (
  data: Record<string, unknown>,
  path: string,
  defaultValue?: string
) => {
  const textData = getByPath<string>(data, path);
  return stringifyData(textData, defaultValue);
};

type Props = {
  view: TextView;
  data: WidgetData;
  update?: Record<string, any> | null;
};

// simple text to display
const TextViewImpl = memo(({ view, data, update = null }: Props) => {
  const { path, default: defaultValue, editor, isRichText } = view;
  const { patch, onChange, setIsPatched, editorOverrides = {} } = update || {};
  let textData;

  const disabledPath = editor?.disabledPath;
  const disabledFromData = !!getByPath(data, disabledPath);

  if (view?.aggregationValue) {
    textData = view.aggregationValue;
  } else {
    textData = getTextData(data, path, defaultValue);
  }
  // if no editor and no patch, directly return a simple text view
  if (!editor && !patch)
    if (!isRichText) {
      return <div className="overflow-hidden text-ellipsis">{textData}</div>;
    } else {
      return (
        <RichEditor value={textData} downloadImage={downloadFile} readOnly />
      );
    }

  // if the view is editable display a specific view

  // check if the data to display has been patched
  const dataWithPatchApplied = useMemo(
    () => merge({}, data, patch),
    [data, patch]
  );

  const displayData = getTextData(dataWithPatchApplied, path);

  const handleChange = useCallback(
    (value: string) => {
      const isSameAsData =
        value === textData || (textData === undefined && value === ''); // handle the case where initial data is unset and the text input was cleared
      onChange({ path, value: isSameAsData ? undefined : value });
    },
    [path, onChange]
  );

  useEffect(() => {
    if (setIsPatched) setIsPatched(displayData !== textData);
  }, [textData, displayData]);

  // if no editor but patch, just output the potential patch value
  if (!editor) {
    return <div className="overflow-hidden text-ellipsis">{displayData}</div>;
  }

  const { placeholder } = editor;
  const { header } = editorOverrides;

  const disabled =
    editorOverrides.disabled ||
    editor.disabled ||
    !onChange ||
    disabledFromData;

  switch (editor.type?.toLowerCase()) {
    case TEXT_EDITOR_NUMBER: {
      const numberEditor = editor as CellEditor<'number'>;
      return (
        <NumberInput
          dataStr={displayData || null}
          onChange={handleChange}
          format={numberEditor.format}
          saveFormatted={numberEditor.saveFormatted}
          placeholder={placeholder}
          delay={numberEditor.delay}
          header={header}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_SELECT: {
      const selectEditor = editor as CellEditor<'select'>;
      let { options } = selectEditor;
      if (selectEditor.optionsPath) {
        options = getByPath(dataWithPatchApplied, selectEditor.optionsPath);
      }

      return (
        <SelectDropdown
          value={displayData}
          options={options}
          onChange={handleChange}
          header={header}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_COMMENT: {
      const commentEditor = editor as CellEditor<'comment'>;
      return (
        <CommentInput
          dataStr={displayData || ''}
          onChange={handleChange}
          placeholder={placeholder}
          delay={commentEditor.delay}
          header={header}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_MULTISELECT: {
      const multiselectEditor = editor as CellEditor<'multiselect'>;
      let { options } = multiselectEditor;
      if (multiselectEditor.optionsPath) {
        options = getByPath(
          dataWithPatchApplied,
          multiselectEditor.optionsPath
        );
      }

      return (
        <MultiselectInput
          data={displayData ? JSON.parse(displayData) : []}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          header={header}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_DATE: {
      const dateEditor = editor as CellEditor<'date'>;
      return (
        <DateInput
          dataStr={displayData || ''}
          onChange={handleChange}
          inputFormat={dateEditor.inputFormat}
          displayFormat={dateEditor.displayFormat}
          placeholder={placeholder}
          header={header}
          disabled={disabled}
        />
      );
    }
    default: {
      const editorInput = editor as CellEditor<'input'>;
      return (
        <TextInput
          dataStr={displayData || ''}
          onChange={handleChange}
          placeholder={placeholder}
          delay={editorInput.delay}
          header={header}
          disabled={disabled}
        />
      );
    }
  }
});

// sort defaults to text on path
const getSortConfig = (view: TextView): TextView => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = (view: TextView): TextView => ({
  type: 'text',
  path: view.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = (view: TextView) => {
  const { path, default: defaultValue } = view;
  return (data: WidgetData) => getTextData(data, path, defaultValue);
};

// get edited path
// returns path if editor is set, null otherwise
const getEditedPath = (view: TextView) => {
  const { path, editor } = view;
  return editor ? path : null;
};

const getAggregationConfig = (view: TextView) => {
  const { path, aggregationValue } = view;
  let aggregatedView;
  if (aggregationValue) {
    aggregatedView = {
      view: {
        path: path,
        aggregationValue: aggregationValue
      }
    };
    return aggregatedView;
  }
  return null;
};

export const textViewProvider = {
  component: TextViewImpl,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction,
  getEditedPath,
  getAggregationConfig
};
