import React, { PureComponent } from 'react';
import { WidgetProps } from '@rjsf/utils';

import Markdown from 'react-markdown';

import { FormLinks, getFieldPath, canLink } from '../../../utils/links';

import { cn } from '@/shadcn/lib/utils';
import { CopyPlusIcon, Lock, LockOpenIcon } from 'lucide-react';

const lockFieldTooltip = 'Persist this value across forms';
const unlockFieldTooltip = 'Disable persistence of this value across forms';
const lockedFieldTooltip =
  "This field is locked across all forms. To make this field's value unique, disable the persistence lock from the main form.";
const linkFieldTooltip = 'Persist this value from the main form';
const unlinkFieldTooltip =
  'Disable persistence of this value from the main form';

const ICON_SIZE = 16 as const;

type Props = {
  children: React.ReactNode;
  displayLabel: boolean;
  hidden?: boolean;
  rawDescription?: string;
  rawErrors?: any[];
  rawHelp?: string;
} & WidgetProps;
export class Field extends PureComponent<Props> {
  static defaultProps = {
    label: undefined,
    rawDescription: undefined,
    rawErrors: [],
    rawHelp: '',
    hidden: false,
    required: false,
    readonly: false,
    disabled: false,
    displayLabel: true
  };

  renderDescription() {
    const { schema, rawDescription } = this.props;
    if (rawDescription && schema.type !== 'object')
      return (
        <div className="text-xs">
          <Markdown source={rawDescription} />
        </div>
      );

    return null;
  }

  renderLinkIcon() {
    const { id, formContext = {} } = this.props;

    // handle links between forms
    const { links } = formContext;
    const {
      id: formId,
      isMainForm,
      onClickLock,
      lockedPaths = [],
      onClickLink,
      linkedPaths = []
    } = (links || {}) as FormLinks;
    const fieldPath = getFieldPath(formId, id);
    const isLockedField = lockedPaths.includes(fieldPath);
    const isLinkedField = !isLockedField && linkedPaths.includes(fieldPath);
    const canLinkField = canLink(fieldPath);

    let linkIcon: React.ReactNode = null;
    if (links && canLinkField) {
      if (isMainForm) {
        const onClick = () => onClickLock(fieldPath);

        linkIcon = isLockedField ? (
          <div
            className="text-primary ml-[5px] cursor-pointer"
            data-is-clickable
            onClick={onClick}
            title={unlockFieldTooltip}
            data-cy="unlock"
          >
            <Lock size={ICON_SIZE} />
          </div>
        ) : (
          <div
            className="text-foreground ml-[5px] cursor-pointer"
            onClick={onClick}
            title={lockFieldTooltip}
            data-cy="lock"
          >
            <LockOpenIcon size={ICON_SIZE} />
          </div>
        );
      } else if (isLockedField) {
        linkIcon = (
          <div className="text-primary ml-[5px]" title={lockedFieldTooltip}>
            <Lock size={ICON_SIZE} />
          </div>
        );
      } else {
        const onClick = () => onClickLink(fieldPath);

        linkIcon = isLinkedField ? (
          <div
            className="text-foreground ml-[5px] cursor-pointer"
            onClick={onClick}
            title={unlinkFieldTooltip}
            data-cy="unlink"
          >
            <CopyPlusIcon size={ICON_SIZE} />
          </div>
        ) : (
          <div
            className="text-primary ml-[5px] cursor-pointer"
            onClick={onClick}
            title={linkFieldTooltip}
            data-cy="link"
          >
            <CopyPlusIcon size={ICON_SIZE} />
          </div>
        );
      }
    }

    return {
      linkIcon,
      isLargeLabel: !isMainForm && !isLockedField
    };
  }

  render() {
    const { id, label, children, rawHelp, hidden, required, displayLabel } =
      this.props;

    if (hidden) return children;

    // handle links between forms
    const { linkIcon, isLargeLabel } = this.renderLinkIcon();

    return (
      <div className="mb-5">
        <div className="mb-1">
          {displayLabel && label && (
            <div className="relative inline-flex w-full max-w-[90%] flex-row flex-nowrap items-center bg-inherit pb-2">
              <label
                className={cn(
                  'text-foreground text-base font-semibold',
                  required &&
                    'after:text-destructive after:ml-1 after:content-["*"]',
                  isLargeLabel && 'grow'
                )}
                htmlFor={id}
                data-input-is-required={required}
                data-is-large={isLargeLabel}
              >
                <span>{label}</span>
              </label>
              {linkIcon}
            </div>
          )}
          {this.renderDescription()}
          {children}
        </div>
        {rawHelp && <div className="text-xs">{rawHelp}</div>}
      </div>
    );
  }
}

export default Field;
