import { env } from './env';

export const CLIENT_ID = env.VITE_CLIENT_ID;
export const ROOT_API_URL = env.VITE_API_URL;
export const GRAPHQL_API_URL = `${ROOT_API_URL}/graphql` as const;
export const NOTIFICATION_API_URL = `${ROOT_API_URL}/graphql`.replace(
  /^http/,
  'ws'
);

export const MEDIA_API_URL = env.VITE_MEDIA_API_URL;
export const TICKET_API_URL = env.VITE_TICKET_API_URL;

export const ACCOUNT_API_URL = env.VITE_ACCOUNT_API_URL;
export const GRAPHQL_ACCOUNT_API_URL = `${ACCOUNT_API_URL}/graphql` as const;

export const ACCOUNT_URL = env.VITE_ACCOUNT_URL;
export const ANALYTICS_URL = env.VITE_ANALYTICS_URL;

export const ACCOUNT_LOGIN_URL = `${ACCOUNT_URL}/login` as const;
