import { Component } from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Pushtext, Avatar, Icon } from '@stratumn/atomic';
import { addTextToClipBoard } from 'utils';

export class SegmentUserDetail extends Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    linkCreatedByAccount: PropTypes.object.isRequired
  };

  state = {
    publicKeyCopied: false
  };

  componentWillUnmount() {
    clearTimeout(this.publicKeyTimer);
  }

  copyPublicKey = () =>
    addTextToClipBoard(this.props.linkCreatedByAccount.signingKey.publicKey);

  handlePublicKeyClick = () => {
    this.copyPublicKey();
    this.setState({ publicKeyCopied: true });
    clearTimeout(this.publicKeyTimer);
    this.publicKeyTimer = setTimeout(() => {
      this.setState({ publicKeyCopied: false });
    }, 3000);
  };

  renderPublicKey = () => {
    let icon = 'Key';
    let text = 'Copy Public Key';
    let size = 26;
    if (this.state.publicKeyCopied) {
      icon = 'Clipboard';
      text = 'Public key copied';
      size = 20;
    }
    return (
      <Pushtext
        prefix={<Icon name={icon} size={size} />}
        onClick={this.handlePublicKeyClick}
      >
        <p className="text-muted-foreground hover:text-primary text-xs">
          {text}
        </p>
      </Pushtext>
    );
  };

  parsePhoneNumber = phoneNumber => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) return parsedPhoneNumber.formatInternational();
    return phoneNumber;
  };

  render = () => {
    const { link, linkCreatedByAccount } = this.props;

    // Links are created by an account.
    // However, its entity can be null if the current user is a collaborator
    // and the entity is not visible to the group.
    // The display falls back to the link group in that case.
    if (!linkCreatedByAccount?.entity) {
      return (
        <Root>
          <AvatarContainer>
            <Avatar size={44} src={link.group.avatar} />
          </AvatarContainer>
          <div>
            <div className="flex w-full items-start gap-4 px-1">
              <Name>{link.group.name}</Name>
            </div>
          </div>
        </Root>
      );
    }
    const createdBy = linkCreatedByAccount.entity;

    return (
      <Root>
        <AvatarContainer>
          <Avatar size={44} src={createdBy.avatar} />
        </AvatarContainer>
        <div>
          <NameContainer>
            <Name>{createdBy.name}</Name>
            {this.renderPublicKey()}
          </NameContainer>
          <div className="text-muted-foreground text-sm">
            {createdBy.email && (
              <div>
                <a
                  className="hover:text-primary text-inherit no-underline"
                  href={`mailto:${createdBy.email}`}
                >
                  {createdBy.email}
                </a>
              </div>
            )}
          </div>
        </div>
      </Root>
    );
  };
}

export default SegmentUserDetail;

const Root = ({ children }) => {
  return (
    <div className="border-border text-foreground flex w-full items-center gap-2 border-b pb-4 break-words">
      {children}
    </div>
  );
};

const AvatarContainer = ({ children }) => {
  return <div className="mr-2 shrink-0 pt-1">{children}</div>;
};

const NameContainer = ({ children }) => {
  return <div className="flex w-full items-center gap-4 py-1">{children}</div>;
};

const Name = ({ children }) => {
  return <div className="mr-2 text-lg font-semibold">{children}</div>;
};
