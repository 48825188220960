import { useRef } from 'react';
import PropTypes from 'prop-types';
import { MessagesSquare } from 'lucide-react';
import { AvatarGroup } from '@stratumn/atomic';

import DynamicIcon from 'components/ui/dynamicIcon';
import Tooltip from 'components/ui/utils/tooltip';

import { shortFormat } from 'utils/dates';

import { useToggle } from 'utils/hooks';
import { countFn as count } from '../count';

import { cn } from '@/shadcn/lib/utils';

const Segment = ({
  link,
  isActive = false,
  interval = null,
  isFirst = false,
  isLast = false,
  answersCount = 0
}) => {
  const listViewRef = useRef(null);
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  const hasAnswer = !!answersCount;
  const renderActionIcon = () => {
    const icon = (link.action && link.action.icon) || 'Doc';

    return (
      <div
        className={cn(
          'bg-card text-muted-foreground relative col-start-2 flex h-9 w-11 items-center justify-center px-1',
          (isActive || isLast) && 'text-foreground'
        )}
      >
        <DynamicIcon icon={icon} size={35} />
      </div>
    );
  };

  const renderInterval = () => {
    if (!interval) return null;
    return (
      <div className="bg-card text-muted-foreground max-w-[35px] shrink-0 truncate px-[2px] pb-[6px] text-[9px]">
        {interval}
      </div>
    );
  };

  const renderWhat = () => {
    const { action, actionKey, referencedBy, references } = link;
    const what = action ? action.title : actionKey;
    const postfix =
      !!referencedBy.totalCount || !!references.totalCount ? '*' : '';
    return `${postfix}${what.slice(0, 35).trim()}${what.slice(35) && '…'}`;
  };

  const renderActiveIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        aria-hidden="true"
        className={cn(
          'fill-card-foreground block h-[10px] w-[10px] -translate-y-full transition-transform duration-200',
          { 'translate-y-0': isActive }
        )}
      >
        <path d="M50 89.489L0 0h100L50 89.489z" />
      </svg>
    );
  };

  return (
    <div
      className={cn(
        'group relative z-1 mb-4 flex w-[161px] appearance-none flex-col items-end justify-start border-none bg-transparent p-0 focus:outline-hidden',
        'before:bg-border before:absolute before:top-20 before:left-0 before:z-[-1] before:h-px before:w-20',
        'after:bg-border after:absolute after:top-20 after:right-0 after:z-[-1] after:h-px after:w-20',
        { 'w-[126px]': isFirst && isLast },
        { 'w-[126px] before:w-0': isFirst && !isLast }
      )}
    >
      <div
        className={cn(
          'w-[126px] opacity-60 transition-opacity duration-200 group-hover:opacity-100',
          isActive && 'opacity-100'
        )}
      >
        <div className="mx-auto h-[14px] w-[10px] overflow-hidden">
          {renderActiveIcon()}
        </div>
        <div className="relative top-[3px] py-[3px] text-center text-[9px] leading-4 font-normal">
          {count(link.height)}
        </div>
        <div className="truncate text-center text-[11px] leading-4 font-bold">
          {renderWhat()}
        </div>
        {hasAnswer && (
          <>
            <div
              ref={listViewRef}
              className="bg-card absolute top-0 right-0 px-2 py-1 text-xs shadow-xs"
              onMouseEnter={toggleShowTooltip}
              onMouseLeave={toggleShowTooltip}
            >
              <MessagesSquare size={20} className="text-primary" />
            </div>
            {showTooltip && (
              <Tooltip
                clientEl={listViewRef.current}
                portalEl={document.body}
                arrowUp={<div className="border-card border-b-[6px]" />}
              >
                <div className="flex justify-center gap-1 pt-2">
                  {answersCount} answer{answersCount > 1 && 's'}
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
      <div
        className="relative mb-[2px] flex"
        data-is-first={isFirst}
        data-is-last={isLast}
      >
        <div className="relative top-[2px] z-1 flex w-[35px] shrink-0 items-center justify-center">
          {renderInterval()}
        </div>
        <div className="mx-auto grid h-[60px] grid-flow-col grid-cols-[42px_38px_42px] grid-rows-1 items-center gap-[6px]">
          {renderActionIcon()}
        </div>
      </div>
      <div
        className={cn(
          'w-[126px] opacity-60 transition-opacity duration-200 group-hover:opacity-100',
          isActive && 'opacity-100'
        )}
        data-is-active={isActive}
      >
        <div className="text-center text-[10px]">
          {shortFormat(link.createdAt)}
        </div>
        <div className="w-full truncate text-center text-[11px] font-bold">
          {link.group.name}
        </div>
        <div className="relative z-1 w-full shrink-0">
          <div className="bg-card mx-auto h-8 w-9" data-is-active={isActive}>
            <AvatarGroup size={36} src={link.group.avatar} />
          </div>
        </div>
      </div>
    </div>
  );
};

Segment.propTypes = {
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  interval: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  answersCount: PropTypes.number
};

export default Segment;
