import { Transforms, Editor, Path, Point } from 'slate';
import { BLOCK_TYPES } from '../constants';
import {
  ParagraphElement,
  TableElement,
  TableRowElement,
  TableCellElement
} from '../custom-types.d';

export function createTable(columns: number, rows: number): TableElement {
  const rowNodes = [...new Array(rows)].map(() => createRow(columns));
  return {
    type: BLOCK_TYPES.TABLE,
    children: rowNodes
  };
}

export function createRow(columns: number): TableRowElement {
  const cellNodes: TableCellElement[] = [...new Array(columns)].map(() =>
    createCell()
  );
  return {
    type: BLOCK_TYPES.TABLE_ROW,
    children: cellNodes
  };
}

export function createCell(): TableCellElement {
  return {
    type: BLOCK_TYPES.TABLE_CELL,
    children: [{ text: '' }]
  };
}

export const insertParagraph = (
  editor: Editor,
  at: Path | Point,
  text = ''
) => {
  const paragraphNode: ParagraphElement = {
    type: BLOCK_TYPES.PARAGRAPH,
    children: [{ text }]
  };
  Transforms.insertNodes(editor, paragraphNode, {
    at
  });
};
