export default theme => ({
  wrapper: {
    display: 'flex',
    fontSize: 14,
    color: theme.grey1
  },
  emDash: {
    display: 'inline-flex',
    alignSelf: 'center',
    paddingTop: 1,
    marginRight: 4,
    width: 7,
    backgroundColor: theme.grey1
  }
});
