import {
  Editor,
  Element as SlateElement,
  Transforms,
  Range,
  Node,
  Point,
  Path,
  Text,
  Location,
  NodeEntry
} from 'slate';
import isHotkey from 'is-hotkey';
import { BLOCK_TYPES } from '../constants';
import { convertHtmlToSlate, getActiveNodeOfType } from '../utils';
import { createCell, createRow, createTable, insertParagraph } from './create';
import { TableRowElement } from '../custom-types.d';

export const isTableActive = editor =>
  !!getActiveNodeOfType(editor, BLOCK_TYPES.TABLE);

export const handleTableMouseDown = (_event, editor) => {
  editor.isTableKeyboardSelecting = false;
};
export const handleTableKeydown = (event, editor) => {
  const tabKeys = ['Tab', 'Shift+Tab'];
  if (tabKeys.some(key => isHotkey(key, event))) {
    const moveForward = isHotkey('Tab')(event);
    const { selection } = editor;
    const activeCell = getActiveCell(editor, selection.focus.path);
    const activeRow = getActiveRow(editor, selection.focus.path);
    const activeTable = getActiveTable(editor, selection.focus.path);
    if (activeCell) {
      const nextCell = moveForward
        ? Editor.next(editor, { at: activeCell[1] })
        : Editor.previous(editor, { at: activeCell[1] });
      if (nextCell) {
        // console.log('nextCell :>> ', nextCell);
        Transforms.select(editor, Editor.end(editor, nextCell[1]));
        event.preventDefault();
      } else if (activeRow) {
        // console.log('activeRow :>> ', activeRow);
        const nextRow = moveForward
          ? Editor.next(editor, { at: activeRow[1] })
          : Editor.previous(editor, { at: activeRow[1] });
        if (nextRow) {
          // console.log('nextRow :>> ', nextRow);
          const targetCell = moveForward
            ? Editor.first(editor, nextRow[1])
            : Editor.last(editor, nextRow[1]);
          Transforms.select(editor, Editor.end(editor, targetCell[1]));
          event.preventDefault();
        } else {
          const targetNode = moveForward
            ? Editor.next(editor, { at: activeTable[1] })
            : Editor.previous(editor, { at: activeTable[1] });
          if (targetNode) {
            Transforms.select(
              editor,
              moveForward
                ? Editor.start(editor, targetNode[1])
                : Editor.end(editor, targetNode[1])
            );
            event.preventDefault();
          }
        }
      }
    }
  }

  const movingSelectionKeys = ['Up', 'Shift+Up', 'Down', 'Shift+Down'];
  if (movingSelectionKeys.some(key => isHotkey(key, event))) {
    editor.isTableKeyboardSelecting = true;
  } else {
    editor.isTableKeyboardSelecting = false;
  }
};

const overrideSelectionChange = (editor, currentSelection, nextSelection) => {
  const isCollapsed = Range.isCollapsed(nextSelection);
  const { path: currentPath } = currentSelection.focus;
  const { path: nextPath } = nextSelection.focus;
  const currentCell = getActiveCell(editor, currentPath);
  const nextCell = getActiveCell(editor, nextPath);
  // ensure next focus point of selection is actually in a cell and that it's not the same cell as the previous focus point
  if (currentCell && nextCell && !Path.equals(currentCell[1], nextCell[1])) {
    const currentCellPath = currentCell[1];
    const nextCellPath = nextCell[1];
    const currentCellIndex = currentCellPath.slice(-1)[0];
    // Check position of next cell in relation to previous cell. 1 means after, -1 means before
    const moveForward = Path.compare(nextCellPath, currentCellPath) === 1;
    const [, currentRowPath] = getActiveRow(editor, currentCellPath);
    const nextRow = moveForward
      ? Editor.next(editor, { at: currentRowPath })
      : Editor.previous(editor, { at: currentRowPath });
    if (nextRow) {
      const nextRowPath = nextRow[1];
      const nextRowCells = Array.from(Node.children(editor, nextRowPath));
      const targetCellPath = nextRowCells[currentCellIndex][1];
      const nextPoint = Editor.end(editor, targetCellPath);
      Transforms.setSelection(editor, {
        anchor: isCollapsed ? nextPoint : undefined,
        focus: nextPoint
      });
    } else {
      const [, activeTablePath] = getActiveTable(editor, currentPath);
      const nextPoint = moveForward
        ? Editor.start(editor, Path.next(activeTablePath))
        : Editor.end(editor, Path.previous(activeTablePath));
      Transforms.setSelection(editor, {
        anchor: isCollapsed ? nextPoint : undefined,
        focus: nextPoint
      });
    }
  }
};

export const PreserveSpaceAfter = new Set([BLOCK_TYPES.TABLE]);
export const PreserveSpaceBefore = new Set([BLOCK_TYPES.TABLE]);
const preserveSpace = (editor: Editor, entry: NodeEntry): boolean | void => {
  let preserved = false;
  const [node, path] = entry;
  if (SlateElement.isElement(node)) {
    const { type } = node;

    if (PreserveSpaceAfter.has(type)) {
      const next: [Node, Path] | undefined = Editor.next(editor, {
        at: path
      });
      if (
        !next ||
        (SlateElement.isElement(next[0]) &&
          PreserveSpaceAfter.has(next[0].type))
      ) {
        insertParagraph(editor, Path.next(path));
        preserved = true;
      }
    }

    if (PreserveSpaceBefore.has(type)) {
      if (path[path.length - 1] === 0) {
        insertParagraph(editor, path);
        preserved = true;
      } else {
        const prev: [Node, Path] | undefined = Editor.previous(editor, {
          at: path
        });
        if (
          !prev ||
          (SlateElement.isElement(prev[0]) &&
            PreserveSpaceAfter.has(prev[0].type))
        ) {
          insertParagraph(editor, path);
          preserved = true;
        }
      }
    }
  }

  return preserved;
};

const getActiveTable = (editor: Editor, at?: Location) => {
  const [table] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE),
      at
    })
  );
  return table;
};

const getActiveRow = (editor: Editor, at?: Location) => {
  const [row] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW),
      at
    })
  );
  return row as [TableRowElement, Path];
};

const getActiveCell = (editor: Editor, at?: Location) => {
  const [cell] = Array.from(
    Editor.nodes(editor, {
      match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_CELL),
      at
    })
  );
  return cell;
};

const getSelectionCommonNode = editor => {
  if (editor.selection) {
    const range = Editor.unhangRange(editor, editor.selection, { voids: true });

    const commonNode = Node.common(editor, range.anchor.path, range.focus.path);

    if (
      Editor.isBlock(editor, commonNode[0] as any) ||
      Editor.isEditor(commonNode[0])
    ) {
      return commonNode;
    }
    return Editor.above(editor, {
      at: commonNode[1],
      match: (n: any) => Editor.isBlock(editor, n) || Editor.isEditor(n)
    });
  }
  return undefined;
};

export const insertRow = (editor, before: boolean = false) => {
  const [row, path] = getActiveRow(editor);
  const newRow = createRow(row.children.length);
  const at = before ? path : Path.next(path);
  Transforms.insertNodes(editor, newRow, { at });
};
export const deleteRow = editor => {
  Transforms.removeNodes(editor, {
    match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW)
  });
};

export const insertColumn = (editor, before: boolean = false) => {
  const [, activeCellPath] = getActiveCell(editor);
  const activeCellIndex = activeCellPath.slice(-1)[0];

  const [, activeTablePath] = getActiveTable(editor);

  const activeTableRows = Array.from(Node.children(editor, activeTablePath));

  activeTableRows.forEach(([_row, rowPath]) => {
    const rowCells = Array.from(Node.children(editor, rowPath));
    const [, targetCellPath] =
      rowCells[activeCellIndex] || rowCells.slice(-1)[0];
    const newCell = createCell();
    const at = before ? targetCellPath : Path.next(targetCellPath);
    Transforms.insertNodes(editor, newCell, { at });
  });
};

export const deleteColumn = (editor: Editor) => {
  const [, activeCellPath] = getActiveCell(editor);
  const activeCellIndex = activeCellPath.slice(-1)[0];

  const [, activeTablePath] = getActiveTable(editor);

  const activeTableRows = Array.from(Node.children(editor, activeTablePath));

  activeTableRows.forEach(([_row, rowPath]) => {
    const rowCells = Array.from(Node.children(editor, rowPath));
    const [, targetCellPath] =
      rowCells[activeCellIndex] || rowCells.slice(-1)[0];
    Transforms.removeNodes(editor, {
      at: targetCellPath
    });
  });
};

export const insertTable = editor => {
  const newTable = createTable(3, 3);
  Transforms.insertNodes(editor, newTable);
};

export const deleteTable = editor => {
  Transforms.removeNodes(editor, {
    match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE)
  });
};

const decorateSelectedNodes = editor => {
  const selectionCommonNode = getSelectionCommonNode(editor);
  if (selectionCommonNode) {
    const [commonParent] = selectionCommonNode;

    // Remove all selected attributes from previously selected elements
    Transforms.unsetNodes(editor, 'isSelected', {
      match: n => (n as any).isSelected,
      at: []
    });

    if (
      SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE) ||
      SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_ROW)
    ) {
      // If selection is inside a table and covers multiple rows or cells,
      // we want to show the entire row as selected
      Transforms.setNodes(
        editor,
        { isSelected: true },
        {
          match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW)
        }
      );
    } else if (
      !SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_CELL) &&
      (SlateElement.isElement(commonParent) || Editor.isEditor(commonParent))
    ) {
      // If selection the selection covers some tables but is not restricted inside it,
      // we want to show the entire table as selected (since the entire table would be removed)
      Transforms.setNodes(
        editor,
        { isSelected: true },
        {
          match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE)
        }
      );
    }
  }
};

export const getWithTables = readOnly => editor => {
  const {
    deleteBackward,
    deleteForward,
    deleteFragment,
    insertBreak,
    insertText,
    insertData,
    normalizeNode,
    onChange
  } = editor;

  editor.onChange = () => {
    if (!readOnly) {
      const selectionOperation = editor.operations.find(
        op => op.type === 'set_selection'
      );
      if (selectionOperation) {
        // We need to check if selection is truely different since any update to elements (e.g. adding or removing data-selected attribute) would trigger this change and would cause an infinite loop
        const selectionHasChanged =
          JSON.stringify(selectionOperation.newProperties) !==
          JSON.stringify(selectionOperation.properties);
        if (selectionHasChanged) {
          if (isTableActive(editor) && editor.isTableKeyboardSelecting) {
            const { selection } = editor;
            const newSelection = {
              ...selection,
              ...selectionOperation.newProperties
            };
            const currentSelection = {
              ...selection,
              ...selectionOperation.properties
            };
            overrideSelectionChange(editor, currentSelection, newSelection);
          }
          decorateSelectedNodes(editor);
        }
      }
    }
    return onChange(editor);
  };

  editor.deleteFragment = () => {
    const selectionCommonNode = getSelectionCommonNode(editor);
    if (selectionCommonNode) {
      // Handle deletion when selection covers multiple table elements to prenvent removing isolated cells and breaking table layout
      // (we only want to remove complete lines or tables)
      const [commonParent, commonParentPath] = selectionCommonNode;

      if (SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_ROW)) {
        // If selection covers multiple cells but is restricted inside a single row,
        // we want to remove that row, or the entire table if it is the only row
        const isOnlyRow =
          Node.parent(editor, commonParentPath).children.length === 1;
        const typeToRemove = isOnlyRow
          ? BLOCK_TYPES.TABLE
          : BLOCK_TYPES.TABLE_ROW;
        Transforms.removeNodes(editor, {
          match: n => SlateElement.isElementType(n, typeToRemove)
        });
      } else if (SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE)) {
        // If selection covers multiple rows inside a single table
        const selectedRows = Array.from(
          Editor.nodes(editor, {
            match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW)
          })
        );
        const tableRows = Array.from(
          Editor.nodes(editor, {
            match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW),
            at: commonParentPath
          })
        );
        // If selection covers all rows, we want to remove the entire table
        if (tableRows.length === selectedRows.length) {
          Transforms.removeNodes(editor, {
            match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE)
          });
        } else {
          // If selection covers only some rows, we want to remove only the selected rows
          Transforms.removeNodes(editor, {
            match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE_ROW)
          });
        }
      } else if (
        !SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_CELL) &&
        (SlateElement.isElement(commonParent) || Editor.isEditor(commonParent))
      ) {
        // If selection the selection covers some tables but is not restricted inside it,
        // we want to remove completely remove the tables
        Transforms.removeNodes(editor, {
          match: n => SlateElement.isElementType(n, BLOCK_TYPES.TABLE)
        });
      }
    }

    deleteFragment(editor);
  };

  editor.deleteBackward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      // Get current cell of selection
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === BLOCK_TYPES.TABLE_CELL
      });

      // Prevent deleting backward if selection is at the starting point of the cell
      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);
        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      // Get current cell of selection
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === BLOCK_TYPES.TABLE_CELL
      });

      // Prevent deleting forward if selection is at the ending point of the cell
      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);
        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }

      // Before deleting forward we need to check if:
      // - the current selection is not in a table
      // - the next point is in a table
      const selectionNotInTable = !getActiveTable(editor);
      if (selectionNotInTable) {
        const nextPoint = Editor.after(editor, selection.anchor);
        if (nextPoint) {
          const [nextPointTable] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === BLOCK_TYPES.TABLE,
            at: nextPoint.path
          });
          const nextPointInTable = !!nextPointTable;
          // If it's the case, we don't want to delete forward because that would pop out the content of the first cell and remove it from the row
          if (nextPointInTable) return;
        }
      }
    }

    deleteForward(unit);
  };

  editor.insertText = text => {
    const selectionCommonNode = getSelectionCommonNode(editor);
    if (selectionCommonNode) {
      // Prevent text insertion across multiple table elements (i.e. in case of a selection from a cell to another)
      // When this happens, we want to move the selection range to a single point (the end of that range) before the
      const [commonParent] = selectionCommonNode;

      if (
        SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE) ||
        SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_ROW) ||
        (getActiveTable(editor) &&
          !SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_CELL) &&
          (SlateElement.isElement(commonParent) ||
            Editor.isEditor(commonParent)))
      ) {
        const { selection } = editor;
        Transforms.setSelection(editor, {
          anchor: selection.focus,
          focus: selection.focus
        });
      }
    }

    insertText(text);
  };

  editor.insertBreak = () => {
    // If selection is in table, we don't want to allow breaks but instead we will just insert a soft break (new line)
    const { selection } = editor;
    if (selection) {
      const table = getActiveTable(editor);
      const selectionCommonNode = getSelectionCommonNode(editor);
      if (table && selectionCommonNode) {
        const [commonParent] = selectionCommonNode;
        if (
          Range.isCollapsed(selection) ||
          Text.isText(commonParent) ||
          SlateElement.isElementType(commonParent, BLOCK_TYPES.TABLE_CELL)
        ) {
          editor.insertText('\n');
        }
        return;
      }
    }
    insertBreak();
  };

  editor.normalizeNode = entry => {
    // Ensure that there is always a block before and after each table
    // (without it it would be difficult to re-create it manually)
    if (preserveSpace(editor, entry)) return;
    normalizeNode(entry);
  };

  editor.insertData = data => {
    // Pasting html data into rich text editor
    const html = data.getData('text/html');

    if (html) {
      const fragment = convertHtmlToSlate(html);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};
