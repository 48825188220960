import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import withInlineValidation from '../../../../wrappers/withInlineValidation';

import NumericInput from '../../../numericInput';

class NumericInputWidget extends React.PureComponent<WidgetProps> {
  static defaultProps = {
    value: '',
    placeholder: '',
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {}
  };

  handleFocus = ({ target: { value } }: any) => {
    this.props.onFocus(this.props.id, value);
  };

  handleBlur = ({ target: { value } }: any) => {
    this.props.onBlur(this.props.id, value);
  };

  handleChange = (value: any) => {
    this.props.onChange(value !== null ? value : '');
  };

  render() {
    const {
      id,
      placeholder,
      value,
      disabled,
      readonly: readOnly,
      autofocus: autoFocus
    } = this.props;

    const numericInputProps = {
      id,
      placeholder,
      value,
      disabled,
      readOnly,
      autoFocus,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus
    };

    return <NumericInput {...numericInputProps} />;
  }
}

export default withInlineValidation(NumericInputWidget);
