import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import injectSheet from 'react-jss';

import { Pushbutton, ModalContent, ModalActions } from '@stratumn/atomic';

import { ROUTE_USER_DASHBOARD } from 'constant/routes';
import { withError } from 'components/errorBoundary';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import { pluralize } from 'utils';

import Team from './team';

import fragments from './fragments';

import styles from './addTeams.style';

// Modal content to add a set of teams that belong to the organization
export const AddTeams = ({
  errorContext: { handleError },
  classes,
  groupName,
  submit,
  cancel,
  userOrganizationTeamsQuery
}) => {
  const { loading, error, me } = userOrganizationTeamsQuery;

  // Raise 404 page when group not found.
  const groupError = error || (!loading && !me);
  useEffect(() => {
    // This check avoids rerendering the component after the errorBoundary has been triggered
    if (groupError) {
      handleError('user', null, ROUTE_USER_DASHBOARD);
    }
  }, [groupError, handleError]);

  // state for handling list of teams accountIds
  const [teamsIds, setTeamsIds] = useState([]);

  // callback to toggle a team being selected for addition to the group
  const toggleTeam = useCallback(
    teamId => {
      const newTeamsIds = [...teamsIds];
      const teamIdx = newTeamsIds.indexOf(teamId);
      if (teamIdx > -1) {
        newTeamsIds.splice(teamIdx, 1);
      } else {
        newTeamsIds.push(teamId);
      }
      setTeamsIds(newTeamsIds);
    },
    [teamsIds]
  );

  // memoized parsed organization teams
  const teamsList = useMemo(() => {
    if (loading) return null;

    const organization = me.organizations.nodes[0];
    if (!organization) return null;

    const organizationTeams = organization.teams?.nodes || [];
    return organizationTeams
      .map(({ accountId, name, avatar, members, bots }) => ({
        accountId,
        name,
        avatar,
        nbParticipants: members.totalCount + bots.totalCount
      }))
      .sort((team1, team2) => team1.name.localeCompare(team2.name));
  }, [loading, me]);

  // memoized teams components
  const teamsComponents = useMemo(() => {
    if (loading)
      return (
        <div className={classes.teams}>
          <TraceIconSpinner className={classes.loadingIcon} />
        </div>
      );

    if (!teamsList)
      return (
        <div className={classes.noTeamsMessage}>
          You don&#39;t belong to any organization
        </div>
      );

    if (!teamsList.length)
      return (
        <div className={classes.noTeamsMessage}>
          No teams added to this organization yet
        </div>
      );

    return (
      <div className={classes.teams}>
        {teamsList.map(({ accountId, avatar, name, nbParticipants }, idx) => (
          <Team
            key={accountId}
            accountId={accountId}
            idx={idx}
            avatar={avatar}
            name={name}
            nbParticipants={nbParticipants}
            isSelected={teamsIds.includes(accountId)}
            toggleTeam={toggleTeam}
          />
        ))}
      </div>
    );
  }, [loading, teamsList, teamsIds, toggleTeam]);

  return (
    <>
      <ModalContent>
        <p className={classes.message}>
          {'Select teams from the list below to add them to '}
          <span className={classes.groupName}>{groupName}</span>
          {
            ' group. Note that all the participants of the selected team will become participants to the workflow'
          }
        </p>
        <div className={classes.teamsWrapper}>{teamsComponents}</div>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          primary
          onClick={() => submit(teamsIds)}
          disabled={!teamsIds.length}
          dataCy="submit-button"
        >
          {teamsIds.length
            ? `Add ${pluralize(teamsIds.length, 'team', false, true)}`
            : 'Add team'}
        </Pushbutton>
      </ModalActions>
    </>
  );
};
AddTeams.propTypes = {
  errorContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  userOrganizationTeamsQuery: PropTypes.object.isRequired
};

const USER_ORGANIZATION_TEAMS_QUERY = gql`
  query userOrganizationTeamsQuery {
    me {
      ...UserOrganizationTeamsFragment
    }
  }
  ${fragments.organizationTeams}
`;

export default compose(
  graphql(USER_ORGANIZATION_TEAMS_QUERY, {
    name: 'userOrganizationTeamsQuery',
    options: () => ({
      fetchPolicy: 'cache-and-network'
    })
  }),
  withError,
  injectSheet(styles),
  React.memo
)(AddTeams);
