import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import {
  Pushbutton,
  Modal,
  ModalContent,
  ModalActions,
  FieldTextCompact
} from '@stratumn/atomic';

import { useInput } from 'utils/hooks';

import styles from './addGroupModal.style';

// The modal to add a new group to a workflow
export const AddGroupModal = ({ submit, cancel }) => {
  const [groupName, updateGroupName] = useInput('');

  return (
    <Modal
      title="Add group"
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
    >
      <ModalContent>
        <FieldTextCompact
          label="Group Name"
          onValueChange={updateGroupName}
          value={groupName}
          dataCy="group-name-input"
        />
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          primary
          onClick={() => submit(groupName)}
          disabled={!groupName}
          dataCy="submit-button"
        >
          create
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};
AddGroupModal.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default compose(injectSheet(styles), React.memo)(AddGroupModal);
