import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/fr';
import compose from 'lodash.flowright';
import 'react-datepicker/dist/react-datepicker.css';

import { Icon } from '@stratumn/atomic';
import styles from './dateEntry.style';
import { validYMD } from '../../utils/dateValidationRules';
import {
  convertToYMD,
  convertToDate,
  getTodayDateStr
} from '../../utils/dateConversionRules';
import withInlineValidation from '../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../utils/links';

registerLocale('en', en);

interface Props {
  classes: ClassNameMap<string>;
  label: string;
  required: boolean;
  readOnly?: boolean;
  todayVisible?: boolean;
  hideLabel?: boolean;
  id?: string;
  onChange?: (x) => void;
  value?: string;
  formContext?: any;
}

export const DateEntry: React.FC<Props> = ({
  classes,
  label,
  required,
  readOnly = false,
  todayVisible = true,
  hideLabel = true,
  id = '',
  onChange = () => {},
  value = '',
  formContext = {}
}) => {
  // parse the date and check for errors
  const error = !!value && !validYMD(value);

  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);

  // open or close calendar
  const [activeCalendar, setActiveCalendar] = useState(false);
  const calendarRef: any | null = useRef(null);
  useLayoutEffect(() => {
    if (!isLocked) calendarRef.current.setOpen(activeCalendar);
  }, [activeCalendar, isLocked]);

  const handleDatePickerChange = newDateStr => {
    onChange(convertToYMD(newDateStr));
    return setActiveCalendar(false);
  };

  // reset dates to todays day
  const setToday = () => {
    if (readOnly) return;
    onChange(getTodayDateStr());
  };

  /**
   * Here forwardRef enables us to pass the ref down to children component
   * where the parent is a state component (e.g: react-datepicker).
   */
  const CustomInput = forwardRef((_props, ref) => (
    <div
      className={classes.iconInput}
      onClick={() => (readOnly ? null : setActiveCalendar(!activeCalendar))}
      data-cy="forms-js-calendar"
    >
      <Icon
        name={activeCalendar ? 'CalendarFill' : 'Calendar'}
        size={32}
        ref={ref}
      />
    </div>
  ));

  const disabled = readOnly || isLocked;
  return (
    <div className={classes.root}>
      {!hideLabel && (
        <label htmlFor={id}>
          {required && <strong>*</strong>}
          {label}
        </label>
      )}
      <div className={classes.dateWrapper}>
        <div className={classes.dateInput}>
          <input
            id={id}
            type="date"
            value={value}
            onChange={e => onChange(e.target.value)}
            readOnly={disabled}
            onClick={e => e.preventDefault()}
            data-error={error}
            data-disabled={disabled}
            required={required}
            data-cy="forms-js-date-manual"
          />
          {!isLocked && todayVisible && (
            <p
              className={classes.dateToday}
              onClick={setToday}
              data-cy="forms-js-date-today"
            >
              Today
            </p>
          )}
        </div>

        {!isLocked && (
          <DatePicker
            selected={value && !error ? convertToDate(value) : null}
            onChange={handleDatePickerChange}
            dateFormat="dd/MM/yyyy"
            calendarClassName={classes.calendar}
            showPopperArrow={false}
            shouldCloseOnSelect
            locale="en"
            popperClassName={classes.calendarPuppet}
            readOnly={readOnly}
            ref={calendarRef}
            onClickOutside={() => setActiveCalendar(false)}
            placeholderText="dd/mm/yyyy"
            customInput={<CustomInput />}
            disabledKeyboardNavigation
          />
        )}
      </div>
    </div>
  );
};

export default compose(withInlineValidation, injectSheet(styles))(DateEntry);
