import TextInput from './textInput';
import TextArea from './textArea';
import SelectMenu from './selectMenu';
import MultiSelectMenu from './multiSelectMenu';
import DateEntry from '../../../dateEntry';
import Radio from './radio';
import NumericWidget from './numericWidget';
import CheckboxWidget from './checkbox';
import CheckboxesWidget from './checkboxes';
import SlateWidget from './slate';
import GroupSelection from './groupSelection';

const widgets = {
  CheckboxWidget,
  CheckboxesWidget,
  draft: SlateWidget,
  NumericWidget: NumericWidget,
  RadioWidget: Radio,
  SelectWidget: SelectMenu,
  MultiSelectWidget: MultiSelectMenu,
  textarea: TextArea,
  TextWidget: TextInput,
  'alt-date': DateEntry,
  'workflow-group': GroupSelection
} as const satisfies Record<string, React.FC<any>>;

export default widgets;
