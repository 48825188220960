export default () => ({
  button: {
    fontSize: 14,
    textAlign: 'left',
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    padding: 0
  }
});
