import { cn } from '@/shadcn/lib/utils';

type Props = {
  className?: string;
};

export const Footer = ({ className }: Props) => {
  return (
    <footer
      className={cn(
        'text-foreground absolute bottom-0 flex gap-6 px-3 py-[3px] text-[10px]',
        className
      )}
    >
      <span>Stratumn © {new Date().getFullYear()}. All rights reserved.</span>
      <span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/gdpr-privacy-policy"
          className="no-underline hover:underline"
        >
          GDPR privacy policy
        </a>
      </span>
    </footer>
  );
};

export default Footer;
