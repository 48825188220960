import { memo, useEffect, useRef, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';

import { CirclePlusIcon } from 'lucide-react';

import { uploadFile, handleUploadFileOnError } from 'client/media';

import theme from 'style';
import { useTooltip } from 'utils/hooks';
import { base64ToFile } from 'utils/base64';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import { v4 as uuidv4 } from 'uuid';
import {
  initFormsData,
  FORMS_BATCH_MAIN_FORM_ID,
  FORMS_BATCH_ADD,
  FORMS_BATCH_INIT_VALIDATION,
  FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING,
  FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS,
  applyFormsDataChange
} from './reducers';

import { BatchForm } from './batchForm/batchForm';
import {
  FORMS_WIDTH,
  FORMS_MARGIN_RIGHT
} from './batchForm/constants.batchForm';
import ErrorsSnackbar from './errorsSnackbar';

import { Button } from '@/shadcn/button';
import { cn } from '@/shadcn/lib/utils';
const PLUS_ZONE_TOOLTIP_POSITION = {
  place: 'above',
  adjustPlace: true
};

export const FormsBatch = memo(
  ({
    workflowContext,
    schema,
    uiSchema,
    maxForms = 10,
    onSubmit,
    submitDisabled = false,
    onCancel,
    cachedUpdatesKey = null
  }) => {
    // forms data
    const formsDataReducer = useCallback(
      (currentFormsData, action) =>
        applyFormsDataChange(currentFormsData, action, cachedUpdatesKey),
      [cachedUpdatesKey]
    );
    const [
      { mainFormData, locks, additionalForms, globalValidationStatus, errors },
      updateForms
    ] = useReducer(formsDataReducer, { cachedUpdatesKey }, initFormsData); // use lazy initialiser to avoid running this function at each rerender

    // callback to upload files
    const handleUploadFile = useCallback(
      (file, onSuccess, onError, onProgress, disableEncryption) => {
        // upload the file
        uploadFile(
          file,
          onSuccess,
          err => {
            handleUploadFileOnError(
              file.name,
              err.response && err.response.status
            );
            return onError(err);
          },
          onProgress,
          disableEncryption
        );
      },
      []
    );

    // callback to upload files
    const handleUploadImage = useCallback((file, onSuccess, onError) => {
      if (!file) return;
      const image = base64ToFile(file, uuidv4());
      uploadFile(
        image,
        onSuccess,
        err => {
          handleUploadFileOnError('image', err.response && err.response.status);
          return onError(err);
        },
        null,
        false
      );
    }, []);

    // trigger global validation
    const runValidation = useCallback(() => {
      updateForms({ type: FORMS_BATCH_INIT_VALIDATION });
    }, [updateForms]);
    useEffect(() => {
      // if the validaiton state is completed and successful, submit all the forms together
      if (
        globalValidationStatus === FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS
      ) {
        // trigger submission function
        onSubmit([{ data: mainFormData }, ...additionalForms], () => {
          if (cachedUpdatesKey) {
            localStorage.removeItem(cachedUpdatesKey);
          }
        });
      }
    }, [globalValidationStatus]);

    // if active error form id changes, scroll to it
    const activeErrorFormId = errors ? errors.activeFormId : undefined;
    const formsContainerRef = useRef(null);

    useEffect(() => {
      if (activeErrorFormId && formsContainerRef.current) {
        // find the position of the form to scroll to
        if (activeErrorFormId === FORMS_BATCH_MAIN_FORM_ID) {
          // scroll to the start of the container
          formsContainerRef.current.scrollTo({
            left: 0,
            behavior: 'smooth'
          });
        } else {
          const formIdx = additionalForms.findIndex(
            ({ id }) => id === activeErrorFormId
          );

          formsContainerRef.current.scrollTo({
            left: (FORMS_WIDTH + FORMS_MARGIN_RIGHT) * formIdx,
            behavior: 'smooth'
          });
        }
      }
    }, [errors]);

    // tooltip of the plus zone
    const plusZoneIconRef = useRef(null);
    const [onPlusZoneMouseEnter, onPlusZoneMouseLeave, showPlusZoneToolip] =
      useTooltip(true);

    const nbForms = 1 + additionalForms.length;
    const validating =
      globalValidationStatus ===
      FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING;
    return (
      <>
        <div
          className="relative flex h-full flex-row flex-nowrap items-start overflow-auto p-4"
          ref={formsContainerRef}
        >
          <BatchForm
            workflowContext={workflowContext}
            schema={schema}
            uiSchema={uiSchema}
            id={FORMS_BATCH_MAIN_FORM_ID}
            isMainForm
            isInBatch={nbForms > 1}
            data={mainFormData}
            lockedPaths={locks}
            updateForms={updateForms}
            uploadFile={handleUploadFile}
            uploadImage={handleUploadImage}
            validating={validating}
            hasErrorFocus={activeErrorFormId === FORMS_BATCH_MAIN_FORM_ID}
            dataCy={FORMS_BATCH_MAIN_FORM_ID}
          />
          {additionalForms.map(({ id, data, links }) => (
            <BatchForm
              key={id}
              workflowContext={workflowContext}
              schema={schema}
              uiSchema={uiSchema}
              id={id}
              isInBatch
              data={data}
              lockedPaths={locks}
              linkedPaths={links}
              updateForms={updateForms}
              uploadFile={handleUploadFile}
              uploadImage={handleUploadImage}
              validating={validating}
              hasErrorFocus={activeErrorFormId === id}
              dataCy="additional-form"
            />
          ))}
          <div
            className={cn(
              'border-muted-foreground text-muted-foreground sticky top-0 mr-5 flex h-[150px] w-[350px] shrink-0 cursor-pointer items-center justify-center rounded border-2 border-dashed',
              nbForms === maxForms
                ? 'cursor-not-allowed'
                : 'hover:border-foreground hover:text-foreground'
            )}
            onMouseEnter={onPlusZoneMouseEnter}
            onMouseLeave={onPlusZoneMouseLeave}
            onClick={() =>
              nbForms < maxForms && updateForms({ type: FORMS_BATCH_ADD })
            }
            data-is-disabled={nbForms === maxForms}
            data-cy="plus-zone"
          >
            <div ref={plusZoneIconRef}>
              <CirclePlusIcon className="size-8" />
            </div>
          </div>
        </div>
        <div className="border-border bg-card z-20 flex w-full shrink-0 flex-row flex-nowrap items-end justify-between border-t px-4 py-3 text-sm shadow-sm">
          <Button onClick={onCancel} dataCy="cancel" variant="outline">
            CANCEL
          </Button>
          <Button
            variant="default"
            onClick={runValidation}
            disabled={submitDisabled}
            dataCy="submit"
          >
            {`SUBMIT TRACE${nbForms > 1 ? 'S' : ''}`}
          </Button>
        </div>
        {showPlusZoneToolip && (
          <InfoTooltip
            clientRef={plusZoneIconRef}
            text={
              nbForms < maxForms
                ? 'Add another trace'
                : `You have reached the maximum number of forms for a single batch ( ${maxForms} )`
            }
            textColor={theme.white1}
            backgroundColor={
              nbForms < maxForms ? theme.grey2 : theme.warningRed
            }
            position={PLUS_ZONE_TOOLTIP_POSITION}
            delay={100}
          />
        )}
        {errors && <ErrorsSnackbar errors={errors} updateForms={updateForms} />}
      </>
    );
  }
);
FormsBatch.propTypes = {
  workflowContext: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  maxForms: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  cachedUpdatesKey: PropTypes.string
};
