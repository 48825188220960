import { FC } from 'react';
import injectSheet from 'react-jss';
import { shortFormat } from 'utils/dates';
import { AvatarGroup, Tally } from '@stratumn/atomic';
import DynamicIcon from 'components/ui/dynamicIcon';
import { LinkQueryResult } from 'components/traceInspector/traceInspector.types';
import styles from '../segment.style';
import { countFn as count } from '../../count';
import { cn } from '@/shadcn/lib/utils';

interface Props {
  classes: any;
  isLast: boolean;
  isFirst: boolean;
  interval: string;
  group: LinkQueryResult[];
  firstAction: LinkQueryResult;
  lastAction: LinkQueryResult;
}

const SegmentGroup: FC<Props> = ({
  classes,
  isLast,
  isFirst,
  interval,
  group,
  firstAction,
  lastAction
}) => {
  const renderWhat = () => {
    const { action, actionKey, referencedBy, references } = firstAction;
    const what = action ? action.title : actionKey;
    const postfix =
      !!referencedBy.totalCount || !!references.totalCount ? '*' : '';
    return `${postfix}${what.slice(0, 35).trim()}${what.slice(35) && '…'}`;
  };

  const renderInterval = () => {
    if (!interval) return null;
    return <div className={classes.interval}>{interval}</div>;
  };

  const renderActionIcon = () => {
    const icon = (firstAction.action && firstAction.action.icon) || 'Doc';

    return (
      <div className={classes.actionIcon} data-is-last={isLast}>
        <div className={classes.tallyContainer}>
          <Tally
            value={group.length}
            color="white1"
            backgroundColor="indigo2"
          />
        </div>
        <DynamicIcon icon={icon} size={35} />
      </div>
    );
  };

  const numberOfGroups = new Set(group.map(action => action.group.name)).size;

  const plageActionNumber = `${count(firstAction.height)} - ${count(
    lastAction.height
  )}`;

  return (
    <div
      className={cn(
        'group relative z-1 mb-4 flex w-[161px] appearance-none flex-col items-end justify-start border-none bg-transparent p-0 focus:outline-hidden',
        'before:bg-border before:absolute before:top-20 before:left-0 before:z-[-1] before:h-px before:w-20',
        'after:bg-border after:absolute after:top-20 after:right-0 after:z-[-1] after:h-px after:w-20',
        { 'w-[126px]': isFirst && isLast },
        { 'w-[126px] before:w-0': isFirst && !isLast }
      )}
      style={{ paddingTop: `14px` }}
    >
      <div className={classes.top}>
        <div className={classes.topCount}>{plageActionNumber}</div>
        <div className={classes.topWhat}>{renderWhat()}</div>
      </div>
      <div
        className={classes.middle}
        data-is-first={isFirst}
        data-is-last={isLast}
      >
        <div className={classes.middleInterval}>{renderInterval()}</div>
        <div className={classes.middleAction}>{renderActionIcon()}</div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomWhen}>
          {`${shortFormat(firstAction.createdAt)} - ${shortFormat(
            lastAction.createdAt
          )}`}
        </div>
        <div className={classes.bottomWho}>{numberOfGroups} teams</div>
        <div className={classes.bottomAvatar}>
          <div className={classes.avatarContainer}>
            <AvatarGroup size={36} src={firstAction.group.avatar} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(SegmentGroup);
