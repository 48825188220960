import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import styles from './count.style';

export const countFn = label => label.toString().padStart(2, '0');

const Count = ({ count, classes, className = null }) => (
  <div className={classnames(classes.count, className)}>{countFn(count)}</div>
);

Count.propTypes = {
  count: PropType.number.isRequired,
  classes: PropType.object.isRequired,
  className: PropType.string
};

export default injectSheet(styles)(Count);
