export type FormLinks = {
  id: string; // form id, used here to extract fields path
  isMainForm: boolean; // whether this form is the main form of a batch, 'driving' the locking mechanism
  lockedPaths?: string[]; // list of locked fields path
  onClickLock: (path: string) => void; // function to toggle the locking of a field from the main form
  linkedPaths?: string[]; // list of linked fields path for this form
  onClickLink: (path: string) => void; // function to toggle the linking of a field from the main form onto this specific form
};

// extract field path form a field id
export const getFieldPath = (
  formId: string = 'root',
  fieldId: string
): string => {
  // !!! IMPORTANT: no way (yet) to get the data path from here
  // so we rely on the field id which follows the pattern ${formId}_path_to_field
  // however if the form has keys that have "_", there is no way to retrieve the real concrete path...
  // so this won't work for forms data that exist below the root
  if (formId === fieldId) return ''; // this is the form root

  return fieldId.substr(formId.length + 1); // remove "${formId}_"
};

// so far disable linking if the field id has '_', ie either 'deep' path or root path with a '_' inside the key
// that's the safest we can do given the context provided by react-jsonschema-form so far...
export const canLink = (path: string) => !path.includes('_');

// check if a field widget should be locked
// ie disabled because linked
export const isLockedWidget = (id?: string, links?: FormLinks): boolean => {
  if (!id || !links) return false;
  const { id: formId, isMainForm, lockedPaths = [], linkedPaths = [] } = links;
  const fieldPath = getFieldPath(formId, id);
  return (
    !isMainForm &&
    (lockedPaths.includes(fieldPath) || linkedPaths.includes(fieldPath))
  );
};
