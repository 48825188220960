import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import filesize from 'filesize';
import { notify } from 'components/toast';

import { uploadConfig } from 'client/media';

import Dropzone from 'react-dropzone';

import { Document } from '@stratumn/icons';

import InfoTooltipWrapper from 'components/ui/widget/wrappers/infoTooltip';

import styles from './dropZone.style';

export const DropZone = React.memo(
  ({
    classes,
    onDropZoneEnter,
    onDropZoneLeave,
    onDrop,
    dropZoneActive,
    title = 'Drag a file here',
    buttonLabel = 'or select a file from your device',
    error = null,
    maxFileSize = uploadConfig.MAX_FILE_SIZE
  }) => {
    const dropZoneRef = useRef(null);

    const clickFileButton = useCallback(() => {
      dropZoneRef.current.open();
    }, [dropZoneRef]);

    const handleOnDropRejected = file => {
      const { name, size } = file[0];

      notify.error(
        `The size limit is ${filesize(maxFileSize)}, your ${name} is ${filesize(
          size
        )}`
      );
    };

    const { MAX_FILE_SIZE } = uploadConfig;

    return (
      <Dropzone
        className={classes.dropZone}
        onDragEnter={onDropZoneEnter}
        onDragLeave={onDropZoneLeave}
        onDrop={onDrop}
        maxSize={MAX_FILE_SIZE}
        onDropRejected={handleOnDropRejected}
        data-drag-enter={dropZoneActive}
        disableClick
        ref={dropZoneRef}
      >
        <div className={classes.dropZoneTitle}>{title}</div>
        <div className={classes.dropZoneIcons}>
          <Document className={classes.dropZoneFileIcon1} />
          <Document className={classes.dropZoneFileIcon2} />
        </div>
        <button
          data-cy="select-file-from-device"
          className={classes.dataImporterButton}
          type="button"
          onClick={clickFileButton}
        >
          {buttonLabel}
        </button>
        {error && (
          <div className={classes.dropZoneError}>
            <div className={classes.dropZoneErrorFileName}>
              {error.fileName}
            </div>
            <div>{error.message}</div>
            {error.additionalInfo && (
              <div className={classes.dropZoneErrorInfoWrapper}>
                <InfoTooltipWrapper
                  text={error.additionalInfo}
                  textColor="white"
                  backgroundColor="warningRed"
                  position={{
                    place: 'below',
                    adjustPlace: true,
                    anchor: 'right',
                    anchorShift: -10
                  }}
                >
                  <div className={classes.dropZoneErrorInfo}>?</div>
                </InfoTooltipWrapper>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
);
DropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  onDropZoneEnter: PropTypes.func.isRequired,
  onDropZoneLeave: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  dropZoneActive: PropTypes.bool.isRequired,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  maxFileSize: PropTypes.number,
  error: PropTypes.object
};

export default injectSheet(styles)(DropZone);
