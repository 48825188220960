import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import { withError } from 'components/errorBoundary';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import { Navbar, Footer } from 'components/layouts';
import TraceIconSpinner from 'components/ui/traceIconSpinner';

import { LayoutKanban, LayoutKanbanHeader } from '@stratumn/atomic';

import { ROUTE_LOGIN } from 'constant/routes';
import * as Sentry from '@sentry/react';
import { history } from 'components/root';
import { userDashboardFragments } from './fragments';

import { UserDashboardHeader } from './userDashboardHeader/userDashboardHeader';
import GroupsTaskList from './groupsTaskList';
import WorkflowsList, {
  fragments as workflowsListFragments
} from './workflowsList';

// some constants specific to dashboard
const LOCAL_STORAGE_KEY = `userDashboardConfig`;

export const getUserDisplayConfig = () => {
  try {
    const localStorageDisplayConfigStr =
      localStorage.getItem(LOCAL_STORAGE_KEY);

    if (localStorageDisplayConfigStr) {
      return JSON.parse(localStorageDisplayConfigStr);
    }

    return null;
  } catch (err) {
    return null;
  }
};

export const saveUserDisplayConfig = cfg => {
  const lsKey = LOCAL_STORAGE_KEY;
  if (!cfg) {
    localStorage.removeItem(lsKey);
    return;
  }
  localStorage.setItem(lsKey, JSON.stringify(cfg));
};

export const UserDashboard = React.memo(({ dashboardUserQuery }) => {
  // set doc title at mount
  useEffect(() => {
    document.title = 'Trace';
  }, []);

  // state init
  const [userDisplayConfig, setUserDisplayConfig] = useState(
    getUserDisplayConfig() || {}
  );
  const { collapsedHeader, groupsTaskList: groupsTaskListConfig } =
    userDisplayConfig;

  // actions logged in userDisplayConfig
  // overlay a partial config onto the main config
  const updateUserDisplayConfig = useCallback(
    overlayConfig => {
      // update the user display config and store it
      const newUserDisplayConfig = {
        ...userDisplayConfig,
        ...overlayConfig
      };
      setUserDisplayConfig(newUserDisplayConfig);
      saveUserDisplayConfig(newUserDisplayConfig);
    },
    [userDisplayConfig]
  );

  // set collapsed dashboard header
  const setCollapsedHeader = useCallback(
    newCollapsedHeader => {
      updateUserDisplayConfig({ collapsedHeader: newCollapsedHeader });
    },
    [updateUserDisplayConfig]
  );

  // set groups task list config
  const setGroupsTaskListConfig = useCallback(
    newGroupsTaskListConfig => {
      updateUserDisplayConfig({ groupsTaskList: newGroupsTaskListConfig });
    },
    [updateUserDisplayConfig]
  );

  // main header memo
  const header = useMemo(
    () => (
      <LayoutKanbanHeader>
        <Navbar />
      </LayoutKanbanHeader>
    ),
    []
  );

  const { loading: userQueryLoading, me, workflows } = dashboardUserQuery;
  useEffect(() => {
    // bug do isSuperuser
    if (userQueryLoading === false && (!workflows || !me)) {
      Sentry.setContext('workflows', {
        data: JSON.stringify(workflows)
      });
      Sentry.captureMessage('User dashboard without user', 'error');

      history.push(ROUTE_LOGIN);
    }
  }, [userQueryLoading]);

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        {header}
        <div className="bg-background fixed top-[70px] h-[calc(100%-70px)] w-full overflow-x-auto overflow-y-scroll">
          {/* can load infinitly be careful */}
          {userQueryLoading || !me || !workflows ? (
            <TraceIconSpinner />
          ) : (
            <div className="relative flex flex-col flex-nowrap p-4">
              <UserDashboardHeader
                className="border-border m-2 rounded-2xl border"
                me={me}
                collapsed={collapsedHeader}
                setCollapsed={setCollapsedHeader}
              />
              <WorkflowsList
                className="border-border m-2 rounded-2xl border"
                workflows={workflows}
                isSuperuser={me.isSuperuser}
                userId={me.rowId}
              />
              <GroupsTaskList
                className="border-border m-2 rounded-2xl border"
                me={me}
                workflows={workflows.nodes}
                config={groupsTaskListConfig}
                setConfig={setGroupsTaskListConfig}
              />
              <Footer />
            </div>
          )}
        </div>
      </LayoutKanban>
    </>
  );
});

UserDashboard.propTypes = {
  dashboardUserQuery: PropTypes.object.isRequired
};

const queries = {
  dashboardUserQuery: gql`
    query dashboardUserQuery {
      ...DashboardUserFragment
      ...WorkflowsFragment
    }
    ${userDashboardFragments.dashboardUser}
    ${workflowsListFragments.workflows}
  `
};

export default compose(
  graphql(queries.dashboardUserQuery, {
    name: 'dashboardUserQuery'
  }),
  withError
)(UserDashboard);
