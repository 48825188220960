import { useState, useCallback } from 'react';

/**
 * @function useFunctionAsState
 * @desc  a hook to help manage state variables that are function and bypass the lazy initialization mechanism of useState
 * @desc  inspired from https://github.com/facebook/react/issues/14087#issuecomment-574060340
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export default (fn: Function): [Function, (newFn: Function) => void] => {
  const [val, setVal] = useState(() => fn);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const setFunc = useCallback((newFn: Function) => {
    setVal(() => newFn);
  }, []);

  return [val, setFunc];
};
