import { memo, useMemo, useEffect, useState, useCallback } from 'react';
import { flushSync } from 'react-dom';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import qs from 'query-string';
import Path from 'path-to-regexp';
import to from 'await-to-js';
import { fromLinkObject } from '@stratumn/js-chainscript';
import * as Sentry from '@sentry/react';

import {
  withLeavingAlertContext,
  withLeavingAlertProvider
} from 'components/beforeLeavingAlert';

import { traceClient } from 'gql';
import { deepGet } from 'utils';
import { TRAY_PORTAL_RIGHT, TOOLTIP_PORTAL } from 'constant/htmlIds';
import { useFunctionAsState } from 'utils/hooks';
import { Navbar } from 'components/layouts';
import SignLinkDialog from 'components/signLinkDialog';

import { NotFound } from '@stratumn/atomic';
import { withUser } from 'contexts';

import {
  ROUTE_WORKFLOW_DASHBOARD,
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_INSPECT_TRACE,
  ROUTE_INSPECT_TRACE_LINK
} from 'constant/routes';

import { buildWorkflowContext } from 'utils/workflowContext';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import { notify, CustomToast } from 'components/toast';
import { SpanType, withSpanAsync } from '../../tracing';

import Form from './form';
import AnswerGroupDialog from './answerGroupDialog';
import fragments from './fragments';

import DataTool from './dataTool';
import Batch from './batch';
import Signature from './signatureTool';

export const NewLink = memo(props => {
  const {
    match,
    history,
    location,
    actionQuery,
    createLinksMutation,
    setAskBeforeLeaving,
    setLeavingAlertModalConfig,
    user,
    answer = false,
    answerData = {},
    refetchTraceQuery = () => {}
  } = props;

  const { workflowByRowId: workflow = {}, loading } = actionQuery;

  const { rowId: workflowId, rawActionByKey, actionByKey } = workflow;
  const action = !rawActionByKey ? null : { ...rawActionByKey, ...actionByKey };

  const [signDialogOpen, setSignDialogOpen] = useState(false);
  const [answerGroupDialogOpen, setAnswerGroupDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [formData, setFormData] = useState(null);
  const [mutationProgress, setMutationProgress] = useState(false);
  const [cleanupFn, setCleanupFn] = useFunctionAsState(null);

  // Set document title and BeforeLeaving Alert content based on workflow data
  useEffect(() => {
    const {
      name: workflowName,
      rawActionByKey: { title: actionTitle } = {},
      traces: { nodes: traces = [] } = {}
    } = workflow;

    if (actionTitle) {
      const nbTraces = traces.length;
      let traceName = null;
      if (nbTraces > 0) {
        traceName = 'Batch update';
        if (nbTraces === 1) {
          traceName = traces[0].name;
        }
      }
      document.title = `${actionTitle}${
        traceName ? ` - ${traceName}` : ''
      } - ${workflowName} - Trace`;

      setLeavingAlertModalConfig({
        title: `Quit **${actionTitle}** action`,
        content: `You're about to quit **${actionTitle}** action without saving your changes.<br>All unsaved changes could be lost.<br>Are you sure?`,
        confirmBtnText: `QUIT ACTION`
      });
    }
  }, [workflow]);

  const toggleSignDialog = useCallback(
    () => setSignDialogOpen(!signDialogOpen),
    [signDialogOpen, setSignDialogOpen]
  );

  const toggleAnswerGroupDialog = useCallback(
    () => setAnswerGroupDialogOpen(!answerGroupDialogOpen),
    [answerGroupDialogOpen, setAnswerGroupDialogOpen]
  );

  const goToHome = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goToNewLink = async ({ links, firstlinkHash }) => {
    if (action.key === 'answerComment') {
      toggleSignDialog();
      // refetching trace query to allow display of the very first answer
      // saved to the trace.
      refetchTraceQuery();
      return;
    }
    if (links[0]?.link?.meta) {
      const { mapId } = links[0].link.meta;
      history.replace(
        Path.compile(ROUTE_INSPECT_TRACE_LINK)({
          id: mapId,
          linkid: firstlinkHash
        })
      );
      return;
    }
    // by default go back to workflow overview
    goToWorkflowOverview();
  };

  // build the workflow context passed to each interface
  const workflowContext = useMemo(() => {
    const { groupKey: authorGroupLabel } = qs.parse(location.search);
    return buildWorkflowContext(workflow, authorGroupLabel);
  }, [workflow, location]);

  // tracesList is a list of { traceId, formData }
  const sendLinksRequest = async (signingKey, tracesList) => {
    // 1 - init links for all selected traces
    // add the index as a 'cacheBuster' field to the variables so that Apollo
    // never sees the initAttestationLink queries as duplicates
    // even when all required variables are the same
    const links = await Promise.all(
      tracesList.map(async ({ traceId, formData: traceFormData }, idx) => {
        const {
          data: { initAttestationLink }
        } = await traceClient.query({
          query: INIT_ATTESTATION_QUERY,
          variables: {
            traceId,
            formData: traceFormData,
            actionKey: action.key,
            groupId: !answer ? workflowContext.author.rowId : selectedGroup,
            cacheBuster: idx
          },
          fetchPolicy: 'no-cache'
        });
        const newLink = fromLinkObject(initAttestationLink.link);
        newLink.sign(signingKey, '[version,data,meta]');
        return { ...newLink, data: traceFormData };
      })
    );

    // 2 - send them in a batch
    const createLinksResponse = await createLinksMutation({
      variables: { input: links }
    });

    return {
      links,
      firstlinkHash: createLinksResponse?.data?.createLinks?.links[0]?.linkHash
    };
  };

  const createLinks = async (signingKey, formDataTmp = formData) => {
    withSpanAsync('newLinks', SpanType.mutation, async () => {
      const { traceIds } = qs.parse(location.search);
      let tracesList;
      if (traceIds) {
        // if traceIds is not null this is an action to update existing traces
        // check whether this is an actual batch by checking the number of ','-separated trace ids
        tracesList = traceIds
          .split(',')
          .map(traceId => ({ traceId, formData: formDataTmp }));
      } else if (answerData?.traceId) {
        const dataWithLinkHash = {
          commentLinkHash: answerData.actionLinkHash,
          ...formDataTmp
        };
        tracesList = answerData.traceId
          .split(',')
          .map(traceId => ({ traceId, formData: dataWithLinkHash }));
      } else {
        // no traceIds provided so this is a trace creation
        // action being batchable is driven by its config
        // and if action is batched the formData is actually a list
        tracesList = action.canBatch
          ? formDataTmp.map(({ data }) => ({ formData: data }))
          : [{ formData: formDataTmp }]; // otherwise formData is the actual form data to submit
      }
      setMutationProgress(true);
      const sendLinksRequestPromise = sendLinksRequest(signingKey, tracesList);

      notify.promise(sendLinksRequestPromise, {
        loading: 'Submitting action...',
        success: 'Action submitted',
        error: data => {
          return (
            <CustomToast
              type="error"
              title="Action submission failed"
              description={
                data?.networkError?.result?.[0]?.errors?.[0]?.message ??
                data?.message ??
                'Unknown error'
              }
            />
          );
        }
      });

      Sentry.setContext('Link info', {
        traceId: tracesList[0].traceId,
        workflowId: workflowId,
        formData: JSON.stringify(tracesList[0].formData, null, 2),
        actionKey: action.key
      });

      Sentry.setTag('Workflow', workflow.name);
      Sentry.setTag('ActionKey', action.key);

      const [err, res] = await to(sendLinksRequestPromise);

      // catch potential errors of the sendLinksRequest function
      if (err) {
        setMutationProgress(false);
        toggleSignDialog();
        return;
      }

      // if a cleanup function has been set by the action interface provider
      // (eg data tool cleanu local storage if successful)
      // call it now
      if (cleanupFn) cleanupFn();

      // Since the new link was sent, we disable the "Before leaving alert" so it won't ask user confirmation (before changing the route)
      // Force state update with flushSync
      // https://react.dev/reference/react-dom/flushSync
      flushSync(() => {
        setAskBeforeLeaving(false);
      });
      goToNewLink(res);
      setMutationProgress(false);
    });
  };

  const header = useMemo(() => {
    if (!workflow || !action) return <Navbar />;

    /**
     * Batch update logic for Header Configuration:
     *
     * If we have more than one trace, we don't want to
     * display, in the left sub-header breadcrumbs,
     * the first trace.name.
     */

    let traceLink = {
      label: 'Batch update'
    };

    if (workflow.traces.nodes.length === 1) {
      traceLink = {
        icon: 'Trace',
        label: workflow.traces?.nodes[0]?.name ?? 'Trace',
        path: Path.compile(ROUTE_INSPECT_TRACE)({
          id: workflow.traces?.nodes[0]?.rowId
        })
      };
    }

    const rawActionByKeyLabel = {
      icon: workflow.rawActionByKey?.icon ?? null,
      label: `${deepGet(workflow, 'rawActionByKey.title', null)} | ${deepGet(
        workflowContext,
        'author.name'
      )}`
    };

    const bottomLevelLinks = [
      {
        icon: 'TableColumns',
        label: workflow.name ?? 'Workflow',
        path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
          id: workflow.rowId
        })
      },

      traceLink,
      rawActionByKeyLabel
    ];

    const configNavbar = {
      loading,
      bottomLevel: {
        workflowPage: true,
        infoContext: { links: bottomLevelLinks }
      }
    };

    return <Navbar config={configNavbar} />;
  }, [workflow, loading]);
  const body = useMemo(() => {
    if (loading) return <TraceIconSpinner />;
    if (!workflow) return <NotFound onHomeClick={goToHome} />;
    if (!action || !workflowContext.author)
      return <NotFound onHomeClick={goToWorkflowOverview} />;

    // resolve the type of interface provided
    const isDataTool = !!(action.dataImporter || action.dataEditor);
    const { traces: { nodes: traces = [] } = {} } = workflow;
    const isBatchCreation = !traces.length && action.canBatch;
    const isSignature = !!action.eSignature;

    switch (true) {
      case isSignature:
        return (
          <Signature
            action={action}
            email={user?.me?.email}
            user={user}
            traces={traces}
            workflowContext={workflowContext}
            createLinks={createLinks}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      case isDataTool:
        // either data editor or data importer interface
        return (
          <DataTool
            action={action}
            workflowContext={workflowContext}
            traces={workflow.traces.nodes}
            toggleSignDialog={toggleSignDialog}
            disabled={mutationProgress}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      case isBatchCreation:
        // batched traces creation
        return (
          <Batch
            action={action}
            workflowContext={workflowContext}
            toggleSignDialog={toggleSignDialog}
            disabled={mutationProgress}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      default:
        // single form (potentially batched updates)
        return (
          <Form
            action={action}
            workflowContext={workflowContext}
            traces={workflow?.traces?.nodes}
            disabled={mutationProgress}
            toggleSignDialog={toggleSignDialog}
            toggleAnswerGroupDialog={toggleAnswerGroupDialog}
            formData={formData}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
            cacheKey="newLinkForm_"
          />
        );
    }
  }, [
    actionQuery,
    formData,
    toggleSignDialog,
    toggleAnswerGroupDialog,
    mutationProgress,
    workflowContext
  ]);

  return (
    <>
      {answer && action ? (
        <Form
          action={action}
          workflowContext={workflowContext}
          formData={formData}
          setFormData={setFormData}
          cacheKey="answerForm_"
          traces={answerData.traces}
          disabled={mutationProgress}
          setCleanupFn={setCleanupFn}
          toggleSignDialog={toggleSignDialog}
          toggleAnswerGroupDialog={toggleAnswerGroupDialog}
          actionLinkHash={answerData.actionLinkHash}
          answer
        />
      ) : (
        <>
          <div id={TOOLTIP_PORTAL} />
          <div
            id={TRAY_PORTAL_RIGHT}
            className="fixed top-0 right-0 bottom-0 z-20 flex w-auto flex-row-reverse flex-nowrap data-[is-left=true]:[right:unset] data-[is-left=true]:left-0"
          />
          {header}
          {body}
        </>
      )}
      {answerGroupDialogOpen && (
        <AnswerGroupDialog
          open={answerGroupDialogOpen}
          groups={answerData.groups}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          onClose={toggleAnswerGroupDialog}
          toggleSignDialog={toggleSignDialog}
        />
      )}
      {!action?.eSignature && (
        <SignLinkDialog
          open={signDialogOpen}
          disabled={mutationProgress}
          onClose={toggleSignDialog}
          groupId={workflowContext?.author?.rowId}
          workflowId={workflowId}
          title="Digital signature"
          description="Sign this action with your private signing key."
          onSendLink={createLinks} // note: clear local storage if successful ?
        />
      )}
    </>
  );
});

NewLink.propTypes = {
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  actionQuery: PropType.object.isRequired,
  user: PropType.object.isRequired,
  createLinksMutation: PropType.func.isRequired,
  setAskBeforeLeaving: PropType.func.isRequired,
  setLeavingAlertModalConfig: PropType.func.isRequired,
  answer: PropType.bool,
  answerData: PropType.object,
  refetchTraceQuery: PropType.func
};

const INIT_ATTESTATION_QUERY = gql`
  query newLinkInitQuery(
    $traceId: UUID
    $actionKey: String
    $groupId: BigInt!
    $formData: JSON
  ) {
    initAttestationLink(
      traceId: $traceId
      action: $actionKey
      groupId: $groupId
      formData: $formData
      type: FREE
      hashed: true
    )
  }
`;
const ACTION_QUERY = gql`
  query actionQuery(
    $actionKey: String!
    $workflowId: BigInt!
    $traceIds: [String!]
  ) {
    workflowByRowId(rowId: $workflowId) {
      ...NewLinkFragment
    }
  }
  ${fragments.actionQuery}
`;

const CREATE_LINKS_MUTATION = gql`
  mutation createLinks($input: [CreateLinkInput!]) {
    createLinks(input: $input) {
      links {
        id
        linkHash
      }
    }
  }
`;
const actionQueryVariable = (match, location, answerData) => {
  const locationSearch = !answerData
    ? qs.parse(location?.search)
    : answerData.location.search;
  const variables = {
    actionKey: locationSearch.actionKey,
    workflowId: match.params.wfid,
    traceIds: (locationSearch.traceIds || '').split(',') || []
  };
  if (answerData) {
    return variables;
  }
  return variables;
};

export default compose(
  graphql(ACTION_QUERY, {
    name: 'actionQuery',
    options: ({ match, location, answerData }) => ({
      variables: actionQueryVariable(match, location, answerData),
      fetchPolicy: 'cache-and-network'
    })
  }),
  graphql(CREATE_LINKS_MUTATION, {
    name: 'createLinksMutation',
    options: {
      awaitRefetchQueries: true
    }
  }),
  withUser,
  withRouter,
  withLeavingAlertProvider,
  withLeavingAlertContext
)(NewLink);
