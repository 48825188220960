import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './body.styles';

export class BodyLayout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    white: PropTypes.bool,
    children: PropTypes.node
  };

  static defaultProps = {
    white: false,
    children: null
  };

  render() {
    const { classes, children, white } = this.props;

    const bodyClasses = {
      [classes.root]: true,
      [classes.white]: white,
      [classes.indigo]: !white
    };

    return (
      <div className={classNames(bodyClasses)}>
        <div>{children}</div>
      </div>
    );
  }
}

export default injectSheet(styles)(BodyLayout);
