import { z } from 'zod';

const configSchema = z.object({
  VITE_API_URL: z.string().url(),
  VITE_ANALYTICS_URL: z.string().url(),
  VITE_MEDIA_API_URL: z.string().url(),
  VITE_ACCOUNT_API_URL: z.string(),
  VITE_ACCOUNT_URL: z.string(),
  VITE_TICKET_API_URL: z.string().url(),
  VITE_SENTRY_DEBUG: z.boolean(),
  VITE_CLIENT_ID: z.string(),
  VITE_ENVIRONMENT: z.enum([
    'local',
    'staging',
    'preprod',
    'preprod_us',
    'release',
    'release_us',
    'demo'
  ]),
  ROOT_API_URL: z.string(),
  VITE_ENABLE_SUB_TOTAL_AGGREGATION: z.boolean(),
  VITE_ENABLE_BATCH_DOWNLOAD: z.boolean()
});

type Config = z.infer<typeof configSchema>;

const configs = {
  local: {
    VITE_API_URL: 'http://trace-api.local.stratumn.com:4100',
    VITE_ACCOUNT_API_URL: 'http://account-api.local.stratumn.com:4200',
    VITE_ACCOUNT_URL: 'http://account.local.stratumn.com:4201',
    VITE_ANALYTICS_URL: 'http://analytics.local.stratumn.com:4802',
    VITE_MEDIA_API_URL: 'http://media-api.local.stratumn.com:4500',
    VITE_TICKET_API_URL: 'http://ticket-api.local.stratumn.com:4700',
    VITE_CLIENT_ID: '1',
    ROOT_API_URL: 'local.stratumn.com',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: false,
    VITE_ENVIRONMENT: 'local'
  },
  staging: {
    VITE_MEDIA_API_URL: 'https://media-api.staging.stratumn.com',
    VITE_API_URL: 'https://trace-api.staging.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.staging.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.staging.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.staging.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.staging.stratumn.com',
    VITE_ENVIRONMENT: 'staging',
    ROOT_API_URL: 'staging.stratumn.com'
  },
  preprod: {
    VITE_MEDIA_API_URL: 'https://media-api.preprod.stratumn.com',
    VITE_API_URL: 'https://trace-api.preprod.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.preprod.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.preprod.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.preprod.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.preprod.stratumn.com',
    VITE_ENVIRONMENT: 'preprod',
    ROOT_API_URL: 'preprod.stratumn.com'
  },
  preprod_us: {
    VITE_MEDIA_API_URL: 'https://media-api.preprod.us.stratumn.com',
    VITE_API_URL: 'https://trace-api.preprod.us.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.preprod.us.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.preprod.us.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.preprod.us.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.preprod.us.stratumn.com',
    VITE_ENVIRONMENT: 'preprod_us',
    ROOT_API_URL: 'preprod.us.stratumn.com'
  },
  release: {
    VITE_MEDIA_API_URL: 'https://media-api.stratumn.com',
    VITE_API_URL: 'https://trace-api.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.stratumn.com',
    VITE_ENVIRONMENT: 'release',
    ROOT_API_URL: 'stratumn.com'
  },
  release_us: {
    VITE_MEDIA_API_URL: 'https://media-api.us.stratumn.com',
    VITE_API_URL: 'https://trace-api.us.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.us.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.us.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.us.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.us.stratumn.com',
    VITE_ENVIRONMENT: 'release_us',
    ROOT_API_URL: 'us.stratumn.com'
  },
  demo: {
    VITE_MEDIA_API_URL: 'https://media-api.demo.stratumn.com',
    VITE_API_URL: 'https://trace-api.demo.stratumn.com',
    VITE_ACCOUNT_API_URL: 'https://account-api.demo.stratumn.com',
    VITE_TICKET_API_URL: 'https://ticket-api.demo.stratumn.com',
    VITE_CLIENT_ID: '1',
    VITE_SENTRY_DEBUG: false,
    VITE_ENABLE_SUB_TOTAL_AGGREGATION: true,
    VITE_ENABLE_BATCH_DOWNLOAD: true,
    VITE_ACCOUNT_URL: 'https://account.demo.stratumn.com',
    VITE_ANALYTICS_URL: 'https://analytics.demo.stratumn.com',
    VITE_ENVIRONMENT: 'demo',
    ROOT_API_URL: 'demo.stratumn.com'
  }
} satisfies Record<string, Config>;

type EnvName = keyof typeof configs;

const getConfig = () => {
  const hostname = window.location.hostname;
  let envConfig: Config;

  if (hostname.includes('staging')) {
    envConfig = configs.staging;
  } else if (hostname.includes('preprod.us')) {
    envConfig = configs.preprod_us;
  } else if (hostname.includes('preprod')) {
    envConfig = configs.preprod;
  } else if (hostname.includes('.us.')) {
    envConfig = configs.release_us;
  } else if (hostname.includes('local')) {
    envConfig = configs.local;
  } else if (hostname.includes('demo')) {
    envConfig = configs.demo;
  } else {
    envConfig = configs.release;
  }

  const mode = import.meta.env.VITE_MODE as EnvName | undefined;
  if (mode && mode in configs) {
    envConfig = configs[mode];
  }

  const mergedConfig = Object.keys(envConfig).reduce(
    (acc, key) => {
      const envValue = import.meta.env[key as keyof ImportMeta];
      acc[key] = envValue !== undefined ? envValue : envConfig[key];
      return acc;
    },
    {} as Record<string, any>
  );

  const validatedConfig = configSchema.safeParse(mergedConfig);

  if (!validatedConfig.success) {
    console.error(validatedConfig.error);
    throw new Error('Configuration validation failed');
  }

  return validatedConfig.data;
};

export const env = getConfig();
