export default theme => ({
  wrapper: {
    '& input': {
      width: 120
    }
  },
  datePicker: {
    fontFamily: theme.fontFamily,
    '& [class*="selected"], [class*="in-range"], [class*="-in-selecting-range"]':
      {
        backgroundColor: theme.indigo3,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.indigo2
        }
      }
  }
});
