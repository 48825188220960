import { Children, useState, useEffect } from 'react';
import { FieldProps } from '@rjsf/utils';

import { cn } from '@/shadcn/lib/utils';

const fieldTypes = new Set(['string', 'boolean', 'number', 'integer', 'array']);

const Field = ({ children, schema, uiSchema }: FieldProps) => {
  const [marginBottom, setMarginBottom] = useState(false);
  const {
    props: { children: childrenProps }
  }: any = children;

  useEffect(() => {
    if (fieldTypes.has(schema.type as string)) {
      setMarginBottom(true);
    }
  }, [schema.type]);

  const uiWidget = uiSchema?.['ui:widget'];
  if (uiWidget === 'hidden') return null;

  const hasValue = Children.toArray(childrenProps).reduce(
    (acc, { props: { formData } }: any) =>
      acc !== (typeof formData === 'undefined'),
    true
  );

  return hasValue && uiWidget !== 'hidden' ? (
    <div className={cn(marginBottom && 'mb-4')}>{children}</div>
  ) : null;
};

export default Field;
