import {
  useState,
  useCallback,
  useMemo,
  useReducer,
  useEffect,
  memo
} from 'react';

import {
  DATA_EDITOR_BATCH_UPDATE,
  DATA_EDITOR_SET
} from '@/constant/dataEditor';
import { v4 as uuidv4 } from 'uuid';
import Table from '@/components/ui/table';
import { deepCopy, formatNumber, getByPath } from '@/utils';
import BatchEditModal from '@/components/ui/dataEditor/batchEditModal';
import {
  applyModifiedDataChange,
  getFinalData,
  getFinalDataByKey
} from '../reducers';
import { Button } from '@/shadcn/button';
import { ErrorSchema } from '@rjsf/utils';
import { Expand } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/shadcn/tooltip';
import { cn } from '@/shadcn/lib/utils';
import { TableConfig } from '../../table/table.type';
import { TraceContext } from '@/components/newLink/newLink.types';

type TableRow<T = any> = Record<string, T>;
type RowKey = string;

export type TableConfiguration = {
  /**
   * The name of the property that will contain the modified data
   * @default 'changes'
   */
  modifiedDataKey?: string;
  /**
   * The name of the property that will contain the initial data
   * @default 'initialData'
   */
  initialDataKey?: string;
  /**
   * The name of the property that will contain the final data (modified data merged with initial data)
   * @default 'data'
   */
  finalDataKey?: string;
  /**
   * The path that will be used to get the initial data from the trace context
   * @example 'state.data.tableData'
   */
  prefillPath?: string;
  /**
   * Whether to display the diffs between the initial and final data in the table reader
   * @default true
   */
  allowDisplayDiffs?: boolean;
  /**
   * The table's configuration.
   */
  table: TableConfig;
};

export type FormTableEditorValue<TData = any> = {
  changes: Record<string, Partial<TableRow<TData>>>;
  data: TableRow<TData>[];
  dataByKey: Record<string, TableRow<TData>>;
  initialData: TableRow<TData>[];
};

type Props = {
  title?: string;
  description?: string;
  required: boolean | undefined;
  value?: TableRow[];
  config: TableConfiguration;
  onValueChange: (value: FormTableEditorValue) => void;
  cachedUpdatesKey?: string;
  errorSchema?: ErrorSchema<Record<string, any>>;
  traceContext?: TraceContext;
};

export const FormTableEditor = memo((props: Props) => {
  const {
    modifiedDataKey = 'changes',
    initialDataKey = 'initialData',
    finalDataKey = 'data',
    prefillPath,
    table: tableConfig
  } = props.config;

  const [batchUpdateCache, setBatchUpdateCache] = useState<{
    cache: Record<string, any>;
    nbRows: number;
  } | null>(null);

  const getInitialData = useCallback(() => {
    if (prefillPath && props.traceContext) {
      return getByPath(props.traceContext, prefillPath) || [];
    }
    return [];
  }, [prefillPath, props.traceContext]);

  const [tableData, setTableData] = useState<TableRow[]>(getInitialData());

  const dataSelectorPath = useMemo<string>(
    () => tableConfig.dataSelectorPath || 'rowId',
    [tableConfig.dataSelectorPath]
  );

  const [modifiedData, onDataModified] = useReducer(
    (currentModifiedData, action) => {
      return applyModifiedDataChange(
        tableData,
        dataSelectorPath,
        props.cachedUpdatesKey,
        currentModifiedData,
        action
      );
    },
    {}
  );

  useEffect(() => {
    const data = {
      [modifiedDataKey]: modifiedData,
      [finalDataKey]: getFinalData(tableData, modifiedData, dataSelectorPath),
      [initialDataKey]: tableData,
      dataByKey: getFinalDataByKey(tableData, modifiedData, dataSelectorPath)
    } as FormTableEditorValue;

    props.onValueChange(data);
  }, [modifiedData, props.onValueChange, tableData, dataSelectorPath]);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const toggleBatchModal = useCallback(
    () => setShowBatchModal(!showBatchModal),
    [showBatchModal]
  );

  const onAddRow = () => {
    setTableData([
      ...tableData,
      {
        [dataSelectorPath]: uuidv4()
      }
    ]);
  };

  const onDeleteSelectedRows = useCallback(
    (rowIds: string[]) => {
      const filteredRows = tableData.filter(row => {
        const id = row[dataSelectorPath] as RowKey;
        return !rowIds.includes(id);
      });
      setTableData(filteredRows);
    },
    [selectedRows, tableData, dataSelectorPath]
  );

  const tableUpdate = useMemo(
    () => ({
      userDisplay: {
        localStorageKey: `${props.cachedUpdatesKey}_tableConfig`
      },
      setSelectedRows,
      edit: {
        modifiedData,
        onDataModified: onDataModified,
        onClickBatchEdit: toggleBatchModal,
        batchEditClicked: showBatchModal,
        allowDisplayDiffs: false,
        onDeleteSelectedRows: onDeleteSelectedRows
      },
      onClickNew: onAddRow,
      newClicked: false,
      errorSchema: props.errorSchema
    }),
    [
      props.cachedUpdatesKey,
      props.errorSchema,
      modifiedData,
      showBatchModal,
      toggleBatchModal
    ]
  );

  const submitBatchPatch = useCallback(
    (patch, editablePaths) => {
      // close the modal
      toggleBatchModal();

      // keep track of modifiedData just before batch edit
      setBatchUpdateCache({
        cache: deepCopy(modifiedData),
        nbRows: selectedRows.length
      });

      // update the batch
      onDataModified({
        type: DATA_EDITOR_BATCH_UPDATE,
        rowSelectors: selectedRows,
        patch,
        editablePaths
      });

      // set the timeout to clear the cache
      const batchUpdateTimeout = setTimeout(() => {
        setBatchUpdateCache(null);
      }, 5000);

      // clear the timeout at unmount
      return () => {
        clearTimeout(batchUpdateTimeout);
      };
    },
    [selectedRows, toggleBatchModal]
  );
  const undoBatchUpdate = useCallback(() => {
    onDataModified({
      type: DATA_EDITOR_SET,
      modifiedData: batchUpdateCache?.cache
    });
    setBatchUpdateCache(null);
  }, [batchUpdateCache]);

  // temp message to indicate that a batch edit has just been applied
  // with option to undo
  const batchUpdateMessage = batchUpdateCache && (
    <div className="">
      <b>{`${formatNumber(batchUpdateCache.nbRows)} row${
        batchUpdateCache.nbRows > 1 ? 's' : ''
      }`}</b>
      {` ${batchUpdateCache.nbRows > 1 ? 'have' : 'has'} been edited`}
      <button onClick={undoBatchUpdate}>Undo</button>
    </div>
  );

  const headerConfig = useMemo(
    () => ({
      isCompact: true,
      newLabel: 'New row'
    }),
    []
  );
  // summary data editor (confirmation step)
  return (
    <section className="flex size-full flex-col">
      <header className="flex h-16 w-full items-end justify-between pb-2 text-sm">
        <section className="flex flex-col">
          <h2
            className={cn(
              'text-base font-semibold',
              props.required &&
                'after:text-destructive after:ml-1 after:text-sm after:content-["*"]'
            )}
          >
            {props.title}
          </h2>
          {props.description && (
            <p className="text-xs text-neutral-500">{props.description}</p>
          )}
        </section>
        <section className="flex items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" className="-mb-1">
                  <Expand />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Expand the table</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </section>
      </header>
      <div className="relative flex size-full flex-col flex-nowrap items-center">
        {batchUpdateMessage}
        <div className="border-input size-full overflow-hidden rounded-md border">
          <Table
            data={tableData}
            config={tableConfig}
            headerConfig={headerConfig}
            update={tableUpdate}
          />
        </div>
      </div>
      {showBatchModal && (
        <BatchEditModal
          modifiedData={modifiedData}
          selectedRows={selectedRows}
          tableConfig={tableConfig}
          onSubmit={submitBatchPatch}
          onCancel={toggleBatchModal}
        />
      )}
    </section>
  );
});
