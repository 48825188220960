import { memo, useCallback, useMemo, useEffect } from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import Path from 'path-to-regexp';
import { FormWriter } from '@/components/forms';
import { getByPath } from '@/utils';

import { WorkflowContext } from '@/utils/workflowContext';
import { base64ToFile } from '@/utils/base64';
import { downloadFile } from '@/utils/downloadFile';
import { LocalStorageContext } from '@/contexts';
import { v4 as uuidv4 } from 'uuid';

import { withLeavingAlertContext } from '@/components/beforeLeavingAlert';

import {
  getUserInfoDisplayConfig,
  manageLocalStorage,
  sectionsLocalStorage
} from '@/components/ui/utils/localStorage';

import { Widget } from '@/components/ui/widget';
import { Footer } from '@/components/layouts';
import { ROUTE_WORKFLOW_OVERVIEW } from '@/constant/routes';
import { uploadFile, uploadConfig } from '@/client/media';

import { cn } from '@/shadcn/lib/utils';
import { Button } from '@/shadcn/button';

const FORM_ID = 'formId';

export const Form = memo(props => {
  const {
    match,
    history,
    location,
    action,
    workflowContext,
    traces,
    disabled,
    toggleSignDialog,
    formData = null,
    setFormData,
    setCleanupFn,
    setAskBeforeLeaving,
    setLeavingCallback,
    cacheKey,
    toggleAnswerGroupDialog = () => {},
    answer = false
  } = props;
  // localStorage key for backup cache
  const cachedUpdatesKey = useMemo(
    () => `${cacheKey}${window.location}`,
    [location]
  );

  // Cached data
  const cachedFormData = useMemo(() => {
    const localStorageValue = localStorage.getItem(cachedUpdatesKey);
    return localStorageValue ? JSON.parse(localStorageValue) : null;
  }, [cachedUpdatesKey]);

  // at first mount if there is cached data:
  useEffect(() => {
    if (cachedFormData) {
      // call setFormData with it
      setFormData(cachedFormData);
      // set leaving alert condition to true
      setAskBeforeLeaving(true);
    }
  }, [cachedFormData]);

  // set cleanup function (triggered after form submission) and leaving callback (triggered after user confirmed leaving)
  useEffect(() => {
    if (cachedUpdatesKey) {
      const cleanupLocalStorage = () => {
        localStorage.removeItem(cachedUpdatesKey);
        setFormData(null);
      };
      cleanupLocalStorage();
      setLeavingCallback(cleanupLocalStorage);
      setCleanupFn(cleanupLocalStorage);
    }
  }, [cachedUpdatesKey]);

  const handleFormChange = useCallback(
    ({ formData: data }) => {
      // If data is null or undefined, ensure we don't proceed with further operations
      if (!data) {
        localStorage.removeItem(cachedUpdatesKey);
        setFormData(null);
        setAskBeforeLeaving(false);
        return;
      }

      localStorage.setItem(cachedUpdatesKey, JSON.stringify(data));
      setFormData(data);

      const values = Object.values(data);
      // checks if all values are undefined or empty
      const valuesAreAllUndefinedOrEmpty = values.every(value => {
        if (typeof value === 'string' && value.trim() === '') {
          return true; // value is an empty string
        }
        if (Array.isArray(value) && value.length === 0) {
          return true; // value is an empty array
        }
        if (value === undefined) {
          return true; // value is undefined
        }
        return false; // value is not empty or undefined
      });
      // sets asksBeforeLeaving to false when all values are empty and/or undefined
      // and to true if at least one is not empty or defined
      if (valuesAreAllUndefinedOrEmpty) {
        setAskBeforeLeaving(false);
      } else {
        setAskBeforeLeaving(true);
      }
    },
    [cachedUpdatesKey]
  );

  const handleLocalStorage = useCallback(
    ({ index, isCollapsed }) => {
      const [trace] = traces;
      return manageLocalStorage(trace, { index, isCollapsed });
    },
    [traces]
  );

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goBack = () => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // By default go back to workflow overview
    goToWorkflowOverview();
  };

  const handleUploadFile = async (
    file,
    onSuccess,
    onError,
    onProgress,
    disableEncryption
  ) => {
    uploadFile(file, onSuccess, onError, onProgress, disableEncryption);
  };

  const handleUploadImage = useCallback(
    async (file, onSuccess, onError) => {
      if (!file) return;
      const image = base64ToFile(file, uuidv4());
      uploadFile(image, onSuccess, onError, null, false);
    },
    [uploadFile]
  );

  const [trace] = traces;

  const schema = action.form
    ? action.form.schema
    : {
        type: 'object',
        title: action.title,
        description: action.description
      };
  const uiSchema = action.form ? action.form.uiSchema : {};

  // Hide trace info for batch update
  const batchUpdate = traces.length > 1;

  const { MAX_FILE_SIZE, FILE_EXTENSION_WHITELIST_MAP } = uploadConfig;

  const wfUserDisplayConfig = useMemo(
    () => getUserInfoDisplayConfig(trace?.workflow?.rowId) || undefined,
    [trace?.workflow?.rowId]
  );

  const localStorageContext = useMemo(
    () => ({
      userInfoConfig:
        (wfUserDisplayConfig && wfUserDisplayConfig[trace?.rowId]) ||
        sectionsLocalStorage(
          trace?.workflow?.config?.info?.view?.sections || []
        ),
      setLocalStorage: handleLocalStorage
    }),
    [trace?.rowId]
  );

  /** Maps over the properties (inputs name) of `uiSchema`.
   * Checks if each property is an object that has the property `statePath`.
   * @returns {Array} An array of objects containing the input name and the state path.
   */
  const getPrefillInfos = () =>
    Object.entries(uiSchema)
      .filter(([, propValue]) => propValue.statePath)
      .map(([inputName, { statePath }]) => ({ inputName, statePath }));

  useEffect(() => {
    const prefillInfos = getPrefillInfos();
    if (!prefillInfos?.length) return;

    // Maps over the `prefillInfos` array and JMESPath search if the `trace` has a value for each `statePath`.
    // If it does, the formData is updated.

    setFormData(prevFormData => {
      const updatedFormData = { ...prevFormData };
      prefillInfos.forEach(({ inputName, statePath }) => {
        const value = getByPath(trace, statePath);
        if (value !== undefined) {
          let finalValue = value;
          // JMESPath returns arrays with null values, so we need to filter them
          if (Array.isArray(value)) {
            finalValue = value.filter(item => item !== null);
          }
          updatedFormData[inputName] = finalValue;
        }
      });
      return updatedFormData;
    });
  }, []);

  const onSubmit = e => {
    if (!answer) {
      toggleSignDialog(e);
    } else {
      toggleAnswerGroupDialog(e);
    }
  };

  return (
    <div
      className={cn('w-full', {
        'bg-background mt-[35px] mr-[2px] flex h-[calc(100vh-105px)] flex-wrap items-start overflow-y-auto pt-6':
          !answer,
        'border-border mt-5 flex items-start overflow-y-auto border-t pt-[10px]':
          answer
      })}
    >
      <div
        className={cn('relative mx-auto gap-5', {
          'mb-6 grid w-full max-w-(--breakpoint-2xl) grid-cols-1 px-3 md:px-10 lg:grid-cols-5 lg:px-20':
            !answer,
          'flex w-full flex-wrap': answer
        })}
      >
        <div
          className={cn('bg-card box-border h-fit grow', {
            'border-border col-span-1 rounded-md border p-5 lg:col-span-3':
              !answer,
            'h-full rounded-sm': answer
          })}
          data-cy="formjs-writer"
          data-has-traceinfo={
            trace && !batchUpdate && !!trace?.workflow?.config.info
          }
        >
          <FormWriter
            id={FORM_ID}
            title={action.title}
            description={action.description}
            schema={schema}
            uiSchema={uiSchema}
            onChange={handleFormChange}
            formData={formData || cachedFormData}
            uploadFile={handleUploadFile}
            uploadImage={handleUploadImage}
            uploadConfig={{
              maxFileSize: MAX_FILE_SIZE,
              fileExtensionWhitelistMap: FILE_EXTENSION_WHITELIST_MAP
            }}
            traceContext={trace}
            downloadImage={downloadFile}
            onSubmit={onSubmit}
            workflowContext={workflowContext}
            withSubject
          />
          <div className="mt-12 flex w-full justify-between gap-4">
            <div>
              {!answer && (
                <Button
                  onClick={() => goBack()}
                  variant="outline"
                  dataCy="cancel"
                  className=""
                >
                  CANCEL
                </Button>
              )}
            </div>
            <Button
              formId={FORM_ID}
              disabled={disabled}
              dataCy="submit"
              onClick={onSubmit}
              variant="default"
            >
              {!answer ? 'SUBMIT' : 'ANSWER'}
            </Button>
          </div>
        </div>
        {trace && !batchUpdate && trace?.workflow?.config.info && (
          <div
            data-cy="trace-info"
            className="col-span-1 grow rounded-sm bg-inherit lg:col-span-2"
          >
            <WorkflowContext.Provider value={workflowContext}>
              <LocalStorageContext.Provider value={localStorageContext}>
                <Widget
                  widget={trace?.workflow?.config.info}
                  data={trace.state}
                />
              </LocalStorageContext.Provider>
            </WorkflowContext.Provider>
          </div>
        )}
      </div>
      {!answer && <Footer className="relative w-full self-end" />}
    </div>
  );
});

Form.propTypes = {
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  action: PropType.object.isRequired,
  workflowContext: PropType.object.isRequired,
  traces: PropType.arrayOf(PropType.object).isRequired,
  disabled: PropType.bool.isRequired,
  toggleSignDialog: PropType.func.isRequired,
  formData: PropType.object,
  setFormData: PropType.func.isRequired,
  setCleanupFn: PropType.func.isRequired,
  setAskBeforeLeaving: PropType.func.isRequired,
  setLeavingCallback: PropType.func.isRequired,
  cacheKey: PropType.string.isRequired,
  toggleAnswerGroupDialog: PropType.func,
  answer: PropType.bool
};

export default compose(withRouter, withLeavingAlertContext)(Form);
