import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import TextWidget from './textWidget';

/**
 * If no UISchema is specified for this widget, it will default to
 * the checkbox widget styling.
 * If no enumNames property has been set in JSONSchema, it will default to 'yes' or 'no' values.
 */
const Radio: React.FC<WidgetProps> = ({ value = '', ...rest }) => {
  const { enumOptions } = rest.options as {
    enumOptions: any[];
  };
  const selectedOption = enumOptions.find(option => option.value === value) || {
    label: value
  };

  return <TextWidget value={selectedOption.label} {...rest} />;
};

export default Radio;
