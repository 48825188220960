import React from 'react';
import injectSheet, { ClassNameMap } from 'react-jss';
import { WidgetProps } from '@rjsf/utils';
import compose from 'lodash.flowright';

import { Check } from '@stratumn/atomic';

import withInlineValidation from '../../../../../wrappers/withInlineValidation';

import { isLockedWidget } from '../../../../../utils/links';

import styles from './checkboxes.style';

type Props = {
  classes: ClassNameMap<string>;
  options: {
    enumOptions: any[];
    enumDisabled: any[];
    inline: boolean;
    largeLabel: boolean;
  };
} & Pick<
  WidgetProps,
  'disabled' | 'value' | 'readonly' | 'onChange' | 'id' | 'formContext'
>;

/**
 * Utils
 */
export const arraysMatch = (arr1: string[], arr2: string[]) => {
  for (let i = 0; i < arr1.length; i += 1) {
    if (!arr2.includes(arr1[i])) return false;
  }
  return true;
};

export const selectValue = (value: any, selected: any[], all: any[]) => {
  const updated = [...selected, value];
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  const sorted = updated.sort((a, b) =>
    all.indexOf(a) > all.indexOf(b) ? 1 : -1
  );
  return sorted;
};

export const deselectValue = (value: boolean, selected: any[]) =>
  selected.filter(v => v !== value);

/**
 * Widget Component
 */
export const CheckboxesWidget: React.FC<Props> = props => {
  const {
    classes,
    disabled,
    options: { enumOptions, enumDisabled, inline = false, largeLabel },
    value,
    readonly,
    onChange,
    id,
    formContext
  } = props;

  /**
   * Building an array of string values
   * in order to match with an array of results values.
   */
  const enumOptionsValues: string[] = Object.values(enumOptions).map(
    e => e.value
  );

  const [tempEnumOptions, setTempEnumOptions] =
    React.useState(enumOptionsValues);

  /**
   * Check if the selectMenu option value has changed.
   * If so, we clear the array of results values.
   */
  if (!arraysMatch(enumOptionsValues, tempEnumOptions)) {
    setTempEnumOptions(enumOptionsValues);
    onChange([]);
  }

  const handleSingleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionValue: boolean
  ) => {
    const all = enumOptions.map(({ value }) => value);

    if (event.target.checked) {
      return onChange(selectValue(optionValue, value, all));
    }
    return onChange(deselectValue(optionValue, value));
  };

  const handleAllChecked = () => {
    const all = enumOptions.map(({ value }) => value);
    if (arraysMatch(enumOptionsValues, value)) {
      return onChange([]);
    }
    return onChange(all);
  };

  const rootProps = {
    className: classes.root,
    'data-is-inline': inline
  };

  // check if the widget is locked because of forms links
  const isLocked = isLockedWidget(id, formContext.links);

  const allDisabled = disabled || readonly || isLocked;

  return (
    <div {...rootProps}>
      {enumOptions.length > 1 && (
        <div className={classes.selectAllCheck}>
          <Check
            showLabel
            checked={arraysMatch(enumOptionsValues, value)}
            disabled={allDisabled}
            handleChange={handleAllChecked}
            label="Select all"
            darkLabel
            largeLabel={largeLabel}
            bold
          />
        </div>
      )}

      {enumOptions.map(({ value: optionValue, label }) => {
        const checked = value.indexOf(optionValue) !== -1;
        const itemDisabled =
          enumDisabled && enumDisabled.indexOf(optionValue) !== -1;
        return (
          <div key={optionValue} className={classes.checkboxWrapper}>
            <Check
              showLabel
              checked={checked}
              disabled={allDisabled || itemDisabled}
              handleChange={e => handleSingleCheck(e, optionValue)}
              label={label}
              darkLabel
              largeLabel={largeLabel}
            />
          </div>
        );
      })}
    </div>
  );
};

export default compose(
  withInlineValidation,
  injectSheet(styles)
)(CheckboxesWidget);
