export default () => ({
  numericInput: {
    textAlign: 'right',
    fontSize: 14,
    fontFamily: 'inherit',
    fontWeight: 'normal',
    boxSizing: 'border-box',
    padding: 11,
    outline: 'none',
    background: 'transparent',
    color: 'inherit',
    margin: 0,
    border: '1px solid rgba(0, 0, 0, 0.33)',
    borderRadius: 0,
    width: '100%',
    backgroundColor: 'white'
  },

  negative: {
    color: 'red'
  }
});
