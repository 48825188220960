import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { Pushbutton } from '@stratumn/atomic';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import GroupCard from './groupCard';

import styles from './groupsList.style';

export const GroupsList = ({
  classes,
  loading,
  workflow = null,
  getMoreGroups,
  hasFilters
}) => {
  if (loading && !workflow) return <TraceIconSpinner />;

  const {
    groups: { totalCount: nbGroups, nodes: groups, pageInfo }
  } = workflow;

  return (
    <div className={classes.groups}>
      {!nbGroups ? (
        <div className={classes.noGroupsMessage}>
          {`No groups to display here${
            hasFilters ? ' (a filter is being applied)' : ''
          }`}
        </div>
      ) : (
        groups.map((group, idx) => (
          <GroupCard key={group.rowId} group={group} idx={idx} />
        ))
      )}
      {loading ? (
        <TraceIconSpinner className={classes.refetchIcon} />
      ) : (
        pageInfo.hasNextPage && (
          <div className={classes.loadMoreButton}>
            <Pushbutton
              secondary
              onClick={getMoreGroups}
              dataCy="load-more-button"
            >
              Show More Groups
            </Pushbutton>
          </div>
        )
      )}
    </div>
  );
};

GroupsList.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  workflow: PropTypes.object,
  getMoreGroups: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool.isRequired
};

export default compose(injectSheet(styles), React.memo)(GroupsList);
