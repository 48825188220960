import Loadable from 'react-loadable';

// code viewer util
export default Loadable({
  loader: async () => {
    const module = await import('./codeViewerImpl');
    return module.default; // Ensure we're returning the default export
  },
  loading: () => 'Loading Code Viewer...'
});
