import { getOperationAST } from 'graphql';
import { disableFragmentWarnings } from 'graphql-tag';
import { ApolloClient, ApolloLink, split, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RestLink } from 'apollo-link-rest';
import * as Sentry from '@sentry/react';
import {
  GRAPHQL_API_URL,
  ROOT_API_URL,
  NOTIFICATION_API_URL
} from 'constant/api';
import { getAuthToken } from 'utils';
import errorLink from './errorLink';
import { SpanType } from '../../tracing';

// Disable warning if multiple fragments have the same name.
disableFragmentWarnings();

// tell the browser to send the authentication
// cookie along with the request.
const httpLink = new BatchHttpLink({
  uri: GRAPHQL_API_URL,
  credentials: 'include',
  fetch: (uri, options) =>
    Sentry.startSpan(
      {
        name: uri,
        op: SpanType.reqOut
      },
      () => fetch(uri, options)
    )
});

const restLink = new RestLink({
  uri: ROOT_API_URL,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  },
  fetch: (uri, options) =>
    Sentry.startSpan(
      {
        name: uri,
        op: SpanType.reqOut
      },
      () => fetch(uri, options)
    )
});

// Replace SubscriptionClient with GraphQLWsLink
// const wsClient = createClient({
//   url: NOTIFICATION_API_URL,
//   connectionParams: () => ({
//     authToken: getAuthToken()
//   }),
//   shouldRetry: true,
//   lazy: true
// });

const webSocketLink = new WebSocketLink({
  uri: NOTIFICATION_API_URL,
  options: {
    lazy: true,
    connectionParams: () => ({
      authToken: getAuthToken()
    }),
    reconnect: true
  }
});

// const wsLink = new GraphQLWsLink(wsClient);

const wsLinkSplitter = operation => {
  // check if it is a subscription
  const operationAST = getOperationAST(
    operation.query,
    operation.operationName
  );
  return !!operationAST && operationAST.operation === 'subscription';
};

const splitLink = split(
  wsLinkSplitter,
  webSocketLink,
  ApolloLink.from([errorLink, restLink, httpLink])
);

export default new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  connectToDevTools: true
});
