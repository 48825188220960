import React, { useState } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Modal, ModalContent } from '@stratumn/atomic';

import styles from './modalWrapper.style';

const ModalWrapper = React.memo(
  ({
    classes,
    children,
    title,
    body,
    setClientFocus = () => {},
    large = false,
    fullscreen = false
  }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleShowModal = () => {
      setShowModal(!showModal);
      setClientFocus(!showModal);
    };

    // Marked as updated, if the widget wrap by the tray has an update
    // If we don't do that, the update mark will only be displayed when the modal is opened
    if (body.props) {
      const { widget, update = {} } = body.props;
      const viewPath = widget.view.path;

      if (update) {
        const { patch, setIsPatched } = update;

        if (patch && setIsPatched && viewPath in patch) {
          setIsPatched(true);
        }
      }
    }

    return (
      <>
        <div onClick={toggleShowModal} className={classes.modalWrapper}>
          {children}
        </div>
        {showModal && (
          <Modal
            title={title}
            handleCollapse={toggleShowModal}
            large={large}
            fullscreen={fullscreen}
          >
            <ModalContent>
              <div className={classes.modalBodyContainer}>{body}</div>
            </ModalContent>
          </Modal>
        )}
      </>
    );
  }
);

ModalWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  setClientFocus: PropTypes.func,
  large: PropTypes.bool,
  fullscreen: PropTypes.bool
};

export default injectSheet(styles)(ModalWrapper);
