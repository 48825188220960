import { memo, useMemo } from 'react';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';

import WorkflowRow, { fragments as workflowRowFragments } from './row';

import styles from './workflowsList.style';
import { cn } from '@/shadcn/lib/utils';

// workflows list of the user dashboard
export const WorkflowsList = memo(
  ({
    classes,
    className = '',
    workflows = {},
    isSuperuser = false,
    userId
  }) => {
    const { nodes: workflowsList = [] } = workflows;

    const columnsClassNames = useMemo(
      () => ({
        columnName: classes.columnName,
        columnTraces: classes.columnTraces,
        columnGroups: classes.columnGroups,
        columnDocumentation: classes.columnDocumentation,
        columnActions: classes.columnActions
      }),
      [classes]
    );

    const workflowsListContent =
      workflowsList.length > 0 ? (
        <table className={cn(classes.workflowsTable)}>
          <thead className="bg-card text-muted-foreground">
            <tr className="bg-card [&>th]:px-4">
              <th className="w-1/2 min-w-37 font-medium">Name</th>
              <th className="font-medium whitespace-nowrap">Traces</th>
              <th className="font-medium whitespace-nowrap">Groups</th>
              <th className="font-medium">Workflow documentation</th>
              <th className="w-0 font-medium" />
            </tr>
          </thead>
          <tbody className="text-card-foreground">
            {workflowsList.map(w => (
              <WorkflowRow
                key={w.rowId}
                columnsClassNames={columnsClassNames}
                workflow={w}
                isSuperuser={isSuperuser}
                userId={userId}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classes.listInfoContainer}>
          <div className={classes.emptyListMessage}>No workflow.</div>
        </div>
      );

    return (
      <div
        className={cn(
          className,
          'bg-card flex shrink-0 flex-col flex-nowrap overflow-visible px-4 py-8'
        )}
      >
        <div className="mb-4 flex flex-row justify-between px-4">
          <div className="mr-8 flex shrink-0 flex-col flex-nowrap text-justify text-base font-semibold">
            Your workflows
            <div className="bg-secondary h-1 w-4 rounded-lg" />
          </div>
        </div>
        <div className="relative max-h-[238px] overflow-y-auto">
          {workflowsListContent}
        </div>
      </div>
    );
  }
);

export default injectSheet(styles)(WorkflowsList);

// export the workflows table query
export const fragments = {
  workflows: gql`
    fragment WorkflowsFragment on Query {
      workflows(filter: { draft: { equalTo: false } }) {
        totalCount
        nodes {
          rowId
          ...WorkflowRowFragment
        }
      }
    }
    ${workflowRowFragments.workflowRow}
  `
};
