import React from 'react';
import { JSONSchema7 } from 'json-schema';
import Form from '@rjsf/core';
import { ErrorTransformer, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import validate from './validate';

import {
  FieldTemplate,
  ObjectFieldTemplate,
  ArrayFieldTemplate
} from '@/components/forms/components/formTemplates';

import widgets from './widgets';
import fields from './fields';

import { DownloadFileFn, UploadFileFn } from '@/components/forms/utils/media';
import { FormLinks } from '@/components/forms/utils/links';
import {
  WorkflowContext,
  workflowGroupCustomFormat
} from '@/components/forms/utils/workflowContext';
import { TraceContext } from '@/components/newLink/newLink.types';
import { isRichEditorEmpty } from '@/components/ui/richEditor/utils';

interface Props {
  id: string;
  schema: JSONSchema7;
  uiSchema: UiSchema;
  disabled?: boolean;
  children?: React.ReactNode;
  submitLabel?: string;
  formData?: JSONSchema7;
  onChange: any;
  onSubmit: (form: Record<string, unknown>) => void;
  onError?: (errors: []) => void;
  uploadFile: UploadFileFn;
  uploadImage: UploadFileFn;
  uploadConfig?: {
    maxFileSize?: number;
    fileExtensionWhitelistMap?: Record<string, string[]>;
  };
  downloadImage: DownloadFileFn;
  links?: FormLinks;
  workflowContext?: WorkflowContext;
  isAnswer?: boolean;
  traceContext?: TraceContext;
}
type BlurHandlerFn = (id: string) => void;

type FormWriterState = {
  onErrorTriggered: boolean;
  blurredFields: string[];
};

export type FormWriterContext = FormWriterState &
  Pick<
    Props,
    | 'uploadFile'
    | 'uploadImage'
    | 'uploadConfig'
    | 'downloadImage'
    | 'links'
    | 'workflowContext'
    | 'isAnswer'
    | 'traceContext'
  > & {
    onBlur: BlurHandlerFn;
  };

export class FormWriter extends React.PureComponent<Props> {
  static defaultProps = {
    // Pass empty div to disable submit button by default
    children: <div />,
    formData: {},
    disabled: false
  };

  state: FormWriterState = {
    onErrorTriggered: false,
    blurredFields: []
  };

  resetBlurredFields = () => this.setState({ blurredFields: [] });

  onError = errors => {
    // ! NOTE: Not sure if this is needed
    const { formData, onChange } = this.props;
    onChange({ formData, errors });
    this.setState({ onErrorTriggered: true });
    // check if a callback has been provided in the props
    const onErrorProp = this.props.onError;
    if (onErrorProp) onErrorProp(errors);
  };

  onSubmit = value => {
    const { onSubmit } = this.props;
    onSubmit(value);
    this.resetBlurredFields();
  };

  transformErrors: ErrorTransformer = errors => {
    // ! NOTE: Not sure if this is needed, we might want to remove it if this slows down the app
    return errors.map(error => {
      if (error.name === 'pattern') error.message = 'should match the format';
      if (error.params.format === 'draft')
        error.message = 'should not be empty';
      return error;
    });
  };

  onBlurHandler: BlurHandlerFn = id => {
    const { blurredFields } = this.state;
    const newBlurredFields: string[] = [...blurredFields];

    if (!newBlurredFields.includes(id)) {
      newBlurredFields.push(id);
      this.setState({ blurredFields: newBlurredFields });
    }
  };

  render() {
    const {
      id,
      schema,
      uiSchema,
      children,
      formData,
      onChange,
      uploadFile,
      uploadImage,
      uploadConfig,
      downloadImage,
      links,
      workflowContext,
      traceContext
    } = this.props;

    const { blurredFields, onErrorTriggered } = this.state;

    const context: FormWriterContext = {
      uploadFile,
      uploadImage,
      uploadConfig,
      downloadImage,
      onErrorTriggered,
      blurredFields,
      onBlur: this.onBlurHandler,
      links,
      workflowContext,
      traceContext
    };

    const validator = customizeValidator({
      customFormats: {
        'workflow-group': workflowGroupCustomFormat(workflowContext),
        draft: data => {
          return !isRichEditorEmpty(JSON.parse(data));
        }
      }
    });

    return (
      <div className="w-full">
        <Form
          id={id}
          idPrefix={id}
          schema={schema}
          uiSchema={uiSchema}
          widgets={widgets as any}
          fields={fields as any}
          templates={{
            // @ts-expect-error we do not actually use the template Props
            FieldTemplate,
            ObjectFieldTemplate,
            ArrayFieldTemplate
          }}
          formData={formData || {}}
          formContext={context}
          noHtml5Validate
          onError={this.onError}
          transformErrors={this.transformErrors}
          liveValidate
          // !!! NOTE: This slows down the app A LOT, we need to optimize it or remove it
          customValidate={validate(this.props.schema, this.props.uiSchema)}
          validator={validator}
          showErrorList={false}
          onChange={onChange}
          onSubmit={this.onSubmit}
          traceContext={traceContext}
        >
          {children}
        </Form>
      </div>
    );
  }
}

export default FormWriter;
